import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import { INSPECTION_STATUS } from "../../../../constant/constant";
import parse from "html-react-parser";
import Loader from "../../../../library/common/Loader";

const NewOpenItem = ({ inQuestion }) => {
  const [compressedImages, setCompressedImages] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchImageAsFile = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], "image.jpg", { type: blob.type });
  };

  useEffect(() => {
    const compressImages = async () => {
      setLoading(true);

      const imagesByLocation = {};

      await Promise.all(
        inQuestion?.detailsReportView?.flatMap(
          async (itemDetail, itemDetailIndex) => {
            await Promise.all(
              itemDetail?.locationsReportView?.map(
                async (item, locationIndex) => {
                  const files = await Promise.all(
                    item?.files?.map(async (img) => {
                      const file = await fetchImageAsFile(img.filePath);
                      const maxSizeKB = 100;
                      const maxSizeMB = maxSizeKB / 1024;

                      const compressedFile = await imageCompression(file, {
                        maxSizeMB: maxSizeMB,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true,
                      });
                      return {
                        filePath: URL.createObjectURL(compressedFile),
                        fileId: img.fileId,
                      };
                    })
                  );
                  imagesByLocation[locationIndex] = files;
                }
              )
            );
          }
        )
      );

      setCompressedImages(imagesByLocation);
      setLoading(false);
    };

    compressImages();
  }, [inQuestion]);

  return (
    <>
      {loading && <Loader />}
      {inQuestion?.detailsReportView?.map((itemDetail, itemDetailIndex) => (
        <>
          {itemDetail?.locationsReportView?.map((item, locationIndex) => (
            <tr className="tableBody" key={locationIndex}>
              <td>{item?.location}</td>
              <td>
                <div className="itemFondText">
                  <span>
                    {itemDetail?.itemFound}
                    {itemDetail?.details && <> - </>}
                  </span>
                  {itemDetail?.details && (
                    <span>{parse(unescape(itemDetail?.details))}</span>
                  )}
                </div>
              </td>
              <td>
                {compressedImages[locationIndex]?.map((img) => (
                  <img
                    src={img.filePath}
                    alt=""
                    className="imgViewReport"
                    key={img.fileId}
                  />
                ))}
              </td>
              <td>
                <div className="statuss">
                  {item?.statusId === INSPECTION_STATUS?.Open ? (
                    <div className="open">Open</div>
                  ) : (
                    <div className="closed">Closed</div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </>
      ))}
    </>
  );
};

export default NewOpenItem;
