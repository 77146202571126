import React, { useState } from "react";
import { Button } from "@mui/material";
import "./fileUploadInputStyle.scss";
import AttachFileIcon from "@mui/icons-material/AttachFile";
const FileUploadInput = ({
  ticketReplyData,
  setticketReplyData,
  handleUploadFile,
  ticketId,
}) => {
  const [textFieldInput, settextFieldInput] = useState("");
  const [selectedFile, setselectedFile] = useState();
  const [isFilePicked, setisFilePicked] = useState(false);
  const handleFile = (event) => {
    setselectedFile(event.target.files[0]);
    setisFilePicked(true);
    // const fileUploaded = event.target.files[0];
    // const fileName = fileUploaded.name;
    // handleUploadFile(fileUploaded, fileName);

    
    const fileUploaded = event.target.files[0];
    const fileName = fileUploaded.name;
    handleUploadFile(fileUploaded, fileName);
  };
  const handleText = (event) => {
    setticketReplyData({
      ...ticketReplyData,
      message: event.target.value,
      ticketId: ticketId,
    });

    settextFieldInput(event.target.value);
  };
  return (
    <>
      <div className="overideFileUploadInput">
        <div className="inputContainer">
          <input
            type="text"
            placeholder="Enter here"
            className="textInput"
            onChange={(event) => {
              handleText(event);
            }}
            value={textFieldInput}
          />

          <div className="btnBody">
            <Button variant="text" component="label">
              <AttachFileIcon className="btnColor" />
              <input
                hidden
                multiple
                type="file"
                accept="image/*"
                onChange={(event) => {
                  handleFile(event);
                }}
              />
            </Button>
          </div>
        </div>
        {isFilePicked && (
          <div className="imageNameBody">
            <div className="nameTxt">
              <AttachFileIcon className="btnColor" /> Image:{" "}
              <span className="name">{selectedFile?.name}</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploadInput;
