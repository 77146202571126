import { Grid, Popover } from "@mui/material";
import React, { useEffect, useState } from "react";
import Buttons from "../../../../library/custom/button/Button";
import BarChart from "../../../../library/custom/charts/barchart";
import {
  COLORS,
  ORG_ID,
  PAGE_SIZE_100,
} from "../../../../constant/constant";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";

import {
  downloadExcel,
  downloadPDF,
  getAssetTypeValue,
  getDayNameAndDate,
  getFormatedDate,
  useInfiniteScroll,
} from "../../../../utils/helper";
import { useDispatch } from "react-redux";
import { getInspectionRportList } from "../../../../redux/actions/incpectionAction";
import Table from "../../../../library/custom/table/table";
import commonImages from "../../../../assets";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../../../library/common/Loader";
import { INSPECTION_REPORT } from "../../../../constant/routeContant";

const InspectedPerDay = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState([]);
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { inpsViewList, isLoading } = useSelector(
    (state) => state.inspViewReportList
  );
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [barDate, setBarDate] = useState();

  const data = props?.trendData?.trendPassData || [];
  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 7;

  const getPaginatedData = () => {
    const start = currentPage * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return data.slice(start, end);
  };

  const paginatedData = getPaginatedData();

  const InspectedDay = {
    labels: paginatedData.map((item) => getDayNameAndDate(item?.date)),
    datasets: [
      {
        label: getAssetTypeValue(props?.trendData?.apiData?.assetType),
        data: paginatedData.map((item) => item?.totalInspectionCount),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if ((currentPage + 1) * ITEMS_PER_PAGE < data.length) {
      setCurrentPage(currentPage + 1);
    }
  };
  const truncateLabel = (label, maxLength = 30) => {
    if (label?.length <= maxLength) {
      return label;
    }
    return `${label?.substring(0, maxLength)}...`;
  };
  const handleBarClick = (evt, label) => {
    setBarDate(label);
  };
  const options = {
    onClick: function (evt, element) {
      if (element.length > 0) {
        const elementIndex = element[0].index;
        const clickedLabel = InspectedDay.labels[elementIndex];
        handleBarClick(evt, clickedLabel);
      }
    },

    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "end",
        formatter: function (value, context) {
          return truncateLabel(context.chart.data.labels[context.dataIndex]);
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index];
          return label;
        },
      },
    },
  };

  useEffect(() => {
    if (inpsViewList) {
      setDataTable(inpsViewList?.inspectionReports);
    }
  }, [inpsViewList]);

  const fetchMoreData = async (pageNumber) => {
    try {
      const response = await dispatch(
        getInspectionRportList({
          compnayId: undefined,
          divisionId: undefined,
          startDate: barDate && getFormatedDate(barDate),
          endDate: barDate && getFormatedDate(barDate),
          status: 5,
          pageSize: PAGE_SIZE_100 ?? 10,
          page: pageNumber,
          organisationId: ORG_ID(),
          assetTypeId: props?.trendData?.apiData?.assetType,
          userId: 0,
        })
      );
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    const moreData = await fetchMoreData(pageNumber);
    setPage(pageNumber);
    setDataTable((prevData) => [...prevData, ...moreData]);
  };

  const handleOnClick = (reportId) => {
    navigate(INSPECTION_REPORT, {
      state: { reportId: reportId },
    });
  };

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...dataTable].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setDataTable(sortedData);
  };

  useEffect(() => {
    setDataTable([]);
  }, []);

  const columns = [
    {
      id: "reportId",
      label: "Report ID",
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
    },
    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
    },
    {
      id: "inspectionType",
      label: "Inspection Type",
      sortable: true,
      isSorted: sortColumn === "count",
      sortDirection,
    },
    {
      id: "asset",
      label:
        props?.trendData?.apiData?.assetType === 3
          ? "Vehicle Number"
          : "Make And Model And Serial Number",
      sortable: true,
      isSorted: sortColumn === "asset",
      sortDirection,
    },
    {
      id: "inspectionBy",
      label: "Inspected By",
      sortable: true,
      isSorted: sortColumn === "inspectionBy",
      sortDirection,
    },
    {
      id: "blank1",
      label: "",
    },
  ];

  const rows = dataTable?.map((ins, index) => (
    <tr className="row" key={index}>
      <td>{ins.reportId}</td>
      <td>{ins.dateOfEntry}</td>
      <td>{ins.inspectionType}</td>
      <td>{ins.asset}</td>
      <td>{ins.inspectionBy}</td>
      <td>
        <ReportRowManipulte
          rows={ins}
          view
          viewOnClick={() => handleOnClick(ins.reportId)}
          viewLabel={"View Report"}
        />
      </td>
    </tr>
  ));

  const rowsPdf = dataTable.map((ins) => [
    ins.reportId,
    ins.dateOfEntry,
    ins.inspectionType,
    ins.asset,
    ins.inspectionBy,
  ]);

  useEffect(() => {
    if (barDate) {
      dispatch(
        getInspectionRportList({
          compnayId: undefined,
          divisionId: undefined,
          startDate: barDate && getFormatedDate(barDate),
          endDate: barDate && getFormatedDate(barDate),
          status: 5,
          pageSize: PAGE_SIZE_100 ?? 10,
          page: 1,
          organisationId: ORG_ID(),
          userId: 0,
          assetTypeId: props?.trendData?.apiData?.assetType,
        })
      );
    }
  }, [barDate]);

  return (
    <>
      {isLoading && <Loader />}

      <Grid container className="overviewIns">
        <Grid item md={12} xs={12}>
          <Grid className="commonBoxShodow">
            <h2 className="titletop">
              {getAssetTypeValue(props?.trendData?.apiData?.assetType)}{" "}
              Inspected Per Day
            </h2>
            <div className="date-range">
              Date Range{" "}
              <span>
                {paginatedData[0]?.date} {"-"} {paginatedData[6]?.date}
              </span>
            </div>
            <BarChart
              labels={InspectedDay.labels}
              datasets={InspectedDay.datasets}
              options={options}
              height="auto"
            />
            <div className="pagination-controls">
              <Buttons
                label={"Previous"}
                varientContained={true}
                onClick={handlePrevious}
                disabled={currentPage === 0}
              />

              <Buttons
                label={"Next"}
                varientContained={true}
                onClick={handleNext}
                disabled={(currentPage + 1) * ITEMS_PER_PAGE >= data.length}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      {barDate && (
        <Grid mt={4}>
          <div className="topHeaderDrill">
            <div className="topHeaderDrillLeft">
              <div className="title">Inspection Completed on {barDate}</div>
            </div>
            <div className="topHeaderDrillRight">
              <div
                className="downloadBox"
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                <div className="downloadIcon">
                  <img src={commonImages?.downloadWhite} alt="Download" />
                </div>
                <div className="downloadText">Download Data</div>
              </div>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className="downloadListBox">
                  <div className="downloadList">
                    <div className="downloads">
                      <div
                        className="downloadListIcon"
                        onClick={() =>
                          downloadPDF(
                            rowsPdf,
                            columns.map((col) => col.label),
                            "PerDayInspection.pdf"
                          )
                        }
                      >
                        <img src={commonImages.pdfFill} alt="" />
                        <span>Download PDF</span>
                      </div>
                    </div>
                  </div>
                  <div className="downloadList">
                    <div className="downloads">
                      <div
                        className="downloadListIcon"
                        onClick={() =>
                          downloadExcel(dataTable, "PerDayInspection.xlsx")
                        }
                      >
                        <img src={commonImages.excelDownload} alt="" />
                        <span>Download .xlsx</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          <div className="customTableWrapper">
            <Table
              columns={columns}
              rows={rows}
              tableRef={tableRef}
              onSort={handleSort}
            />
          </div>
        </Grid>
      )}
    </>
  );
};

export default InspectedPerDay;
