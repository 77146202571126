import { getApiUrl } from "../config";
import { post, get } from "./axiosRequest";
import { ORG_ID } from "../constant/constant";

export const createQuiz = async ({ data }) => {
  const url = `${getApiUrl().lmsApiUrl}/Lms/CreateQuiz`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getQuizList = async ({ data }) => {
  const { quizId } = data;

  const url = `${
    getApiUrl().lmsApiUrl
  }/Lms/GetQuizDetails?quizId=${quizId}&orgId=${ORG_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const submitQuiz = async ({ data }) => {
  const url = `${getApiUrl().lmsApiUrl}/Lesson/QuizSubmitviaUser`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
// detail Api getQuizDetails
export const getQuizDetails = async ({ data }) => {
  const url = `${
    getApiUrl().lmsApiUrl
  }/Lms/GetQuizDetails?orgId=${ORG_ID()}&quizId=${data}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
//quiz update Api

export const updateQuizList = async ({ data }) => {
  const url = `${getApiUrl().lmsApiUrl}/Lms/UpdateQuiz`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
