import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import { PAGE_SIZE_100 } from "../../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item } from "../../../../constant/routeContant";
import {
  downloadExcel,
  downloadPDF,
  useInfiniteScroll,
} from "../../../../utils/helper";
import commonImages from "../../../../assets";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";

const MostInjuredBodyPart = (props) => {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);

  useEffect(() => {
    if (props?.InjuredBodyPartLists) {
      setData((prevData) => [...prevData, ...props?.InjuredBodyPartLists]);
    }
  }, [props?.InjuredBodyPartLists]);
  const fetchMoreData = async (pageNumber) => {
    const { apiData } = props?.drillDetailFull;
    const data = {
      incidentType: apiData?.incidentType > 0 ? apiData?.incidentType : 0,
      fromDate: apiData?.startDate,
      toDate: apiData?.endDate,
      page: pageNumber,
      pageSize: PAGE_SIZE_100,
      divisionId: apiData?.divisionId,
      locationId: apiData?.locationId,
    };
    try {
      const response = await props?.getInjuredBodyPartLists(data);
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    const moreData = await fetchMoreData(pageNumber);
    setPage(pageNumber);
    setData((prevData) => [...prevData, ...moreData]);
  };

  useEffect(() => {
    setData([]);
  }, []);

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const columns = [
    {
      id: "bpiId",
      label: `Body Part`,
      sortable: true,
      isSorted: sortColumn === "bpiId",
      sortDirection,
    },

    {
      id: "count",
      label: "Number of Injuries",
      sortable: true,
      isSorted: sortColumn === "count",
      sortDirection,
    },
  ];

  const rows = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.bpiType}</td>
      <td>
        <span className="link" onClick={() => handleOnClick(asset?.bpiId)}>
          {asset?.count}
        </span>
      </td>
    </tr>
  ));
  const handleOnClick = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item, {
      state: {
        bodyPartId: id,
        drillDetail: props?.drillDetail,
        drillDetailFull: props?.drillDetailFull,
        itemTypeValue: "Most_injured_body_part",
      },
    });
  };
  const rowsPdf = data?.map((bodypart) => [bodypart?.bpiType, bodypart?.count]);
  return (
    <>
      <div className="topHeaderDrill">
        <div className="topHeaderDrillLeft">
          <div className="title">{"Most Injured Body Part"}</div>
        </div>
        <div className="topHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadPDF(
                        rowsPdf,
                        columns.map((col) => col.label),
                        "All-most-injured-body-part.pdf"
                      )
                    }
                  >
                    <img src={commonImages.pdfFill} alt={""} />
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>

              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadExcel(data, "All-most-injured-body-part.xlsx")
                    }
                  >
                    <img src={commonImages.excelDownload} alt="" />
                    <span>Download.xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="customTableWrapper">
        <Table
          columns={columns}
          rows={rows}
          tableRef={tableRef}
          onSort={handleSort}
        />
      </div>
    </>
  );
};

export default MostInjuredBodyPart;
