import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import { useNavigate } from "react-router-dom";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import ProcoreUserList from "../component/users/procoreUserList";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../library/common/Loader";

function ProcoreUserListContainer() {
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const userListData = useSelector((state) => state.userlistData);
  const userSyncWithProcoreData = useSelector(
    (state) => state.userSyncWithProcoreData
  );
  const showLoader = useSelector(
    (state) => state?.userSyncWithProcoreData?.isLoading
  );

  const getuserLists = (data) => {
    dispatch(userAction.getUserList(data));
  };

  const getSyncProcoreUserLists = (data) => {
    dispatch(userAction.getSyncProcoreUserList(data));
  };

  const companies = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.companies
  );

  const jobTitles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.jobTitles
  );

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "1,12",
  };
  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  useEffect(() => {
    const data = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: "",
      orgId: ORG_ID(),
      IsProcoreUsers: true,
    };
    getuserLists(data);
  }, []);

  useEffect(() => {
    if (userSyncWithProcoreData.status === "SUCCESS" && isSuccess) {
      const data = {
        page: 1,
        pageSize: PAGE_SIZE,
        search: "",
        orgId: ORG_ID(),
        IsProcoreUsers: true,
      };
      getuserLists(data);
    }
  }, [userSyncWithProcoreData]);

  useEffect(() => {
    if (
      userSyncWithProcoreData.status === "SUCCESS" &&
      userSyncWithProcoreData?.data?.responseMessages?.responseCode ===
        "HBNG001" &&
      isSuccess
    ) {
      toast("Users Synced Successfully");
    }
  }, [userSyncWithProcoreData]);

  useEffect(() => {
    setIsSuccess(false);
  }, []);
  return (
    <>
      <div>
        <ToastContainer />
      </div>
      {showLoader && <Loader />}
      <ProcoreUserList
        loading={userListData.isLoading}
        userListData={userListData?.data}
        getuserLists={getuserLists}
        companies={companies}
        jobTitles={jobTitles}
        getSyncProcoreUserLists={getSyncProcoreUserLists}
        setIsSuccess={setIsSuccess}
      />
    </>
  );
}

export default ProcoreUserListContainer;
