import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import {
  API_STATUS,
  LOGIN_ORG_ID,
  ORG_ID,
  USER_ID,
} from "../../../constant/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import * as assetsAction from "../../../redux/actions/assetsAction";
import * as projectAction from "../../../redux/actions/projectAction";
import JHASetUpAlt from "../component/jha/jhaSetupAlt";
import Loader from "../../../library/common/Loader";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { useLocation } from "react-router-dom";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";

const JHASetUpContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isSuccess, setisSuccess] = useState(false);
  const editMode = location?.state?.editMode;

  const [assetType, setassetType] = useState();
  const [template, setTemplate] = useState();
  const [isAssetType, setisAssetType] = useState(false);
  const [isTemplate, setisTemplate] = useState(false);
  const [frequencies, setFrequencies] = useState();
  const [isFrequency, setisFrequency] = useState(false);
  const [supervisorRequiredTypeId, setsupervisorRequiredTypeId] = useState("0");

  const getJhaSetupDetailsData = useSelector(
    (state) => state?.getJhaSetupDetails
  );
  let arr = [];
  useEffect(() => {
    if (
      editMode &&
      getJhaSetupDetailsData?.data &&
      getJhaSetupDetailsData?.status === "SUCCESS"
    ) {
      setTemplate(getJhaSetupDetailsData?.data?.templateId);
      setassetType(getJhaSetupDetailsData?.data?.assetTypeId);
      setFrequencies(getJhaSetupDetailsData?.data?.frequencyId);
      setsupervisorRequiredTypeId(
        getJhaSetupDetailsData?.data?.supervisorRequiredTypeId
      );

      getJhaSetupDetailsData?.data?.divisionMappingKeys?.forEach((cat) => {
        let actArr = [];
        cat?.assetMappingKeys?.map((act) =>
          actArr.push({
            activityId: act.assetId,
            activityName: act.assetName,
            isSelected: true,
            categoryId: cat.divisionId,
          })
        );
        arr.push({
          categoryId: cat.divisionId,
          categoriesName: cat.divisionName,
          activities: actArr,
        });
      });
      setcatActArr(arr);
    }
  }, [getJhaSetupDetailsData]);

  const templateListData = useSelector((state) => state.getJhaTemplateList);

  const getJHAReferenceData = useSelector((state) => state.getJHAReferenceData);

  useEffect(() => {
    if (editMode) {
      getTemplateListData({
        page: 1,
        pageSize: 100,
        search: "",
      });
      dispatch(jhaAction.getJhaSetupDetails(location?.state?.setupId));
    }
  }, []);

  const [templeteList, setTempleteList] = useState([]);
  function mapTempleteKeyValue() {
    let arr = [];
    templateListData?.data?.result?.itemList.map((i) =>
      arr.push({
        key: i.id,
        value: i.templateName,
        assetType: i.assetType,
      })
    );
    setTempleteList(arr);
  }

  //get templete list
  const defaultData = {
    page: 1,
    pageSize: 100,
    search: "",
  };
  const getTemplateListData = (data) => {
    dispatch(jhaAction.getJhaTemplateList(data));
  };

  useEffect(() => {
    dispatch(jhaAction.getJHAReferenceData());
    // getTemplateListData(defaultData);
  }, []);
  useEffect(() => {
    if (
      templateListData.status === API_STATUS.SUCCESS &&
      templateListData?.isLoading === false
      // clearalert
    ) {
      mapTempleteKeyValue();
    }
  }, [templateListData]);

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: [6, 17],
    productId: PRODUCT_ID(),
  };
  useEffect(() => {
    getDivisionData(getDropdownDataKeyValueData);
  }, []);
  const getDivisionData = (data) => {
    dispatch(adminAction.getDropdownDataKeyValue(data));
  };
  const organisationDivision = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const divisionList = userInfo.isClient
    ? organisationDivision
    : LOGIN_ORG_ID() === ORG_ID()
    ? userInfo.divisions?.filter((i) => i?.serviceType == PRODUCT_ID())
    : organisationDivision;
  // const [assetType, setassetType] = useState();
  function getAssetList(template, data) {
    const type = templeteList.filter((i) => {
      if (template === i.key) return i.assetType;
    });
    // setassetType(type[0]?.assetType);
    if (type[0]?.assetType === "Projects") {
      dispatch(projectAction.getProjectList(data));
    }
    if (type[0]?.assetType === "Custom assets") {
      dispatch(assetsAction.getAssetList(data));
    }
  }
  const projectsListData = useSelector((state) => state.projectList);
  const assetList = useSelector((state) => state.assetList);
  const [customAssetsArr, setcustomAssetsArr] = useState([]);
  const [projectArr, setprojectArr] = useState([]);
  function mapAssetKeyValue() {
    let arr = [...customAssetsArr];
    assetList?.data?.assetData?.forEach((i) => {
      const index = arr?.findIndex((obect) => obect.key === i?.id);
      if (index === -1)
        arr.push({
          key: i.id,
          value: i.assetName,
          divisionId: i.divisionId,
        });
    });
    setcustomAssetsArr(arr);
  }
  useEffect(() => {
    mapAssetKeyValue();
  }, [assetList]);
  function mapProjectKeyValue() {
    let arr = [...projectArr];
    projectsListData?.data?.projectData?.map((i) =>
      arr.push({
        key: i.id,
        value: i.projectName,
        divisionId: i.divisionId,
      })
    );
    setprojectArr(arr);
  }

  useEffect(() => {
    mapProjectKeyValue();
  }, [projectsListData]);
  const [catActArr, setcatActArr] = useState([]);
  useEffect(() => {
    if (catActArr.length > 0) {
      let arr = [...catActArr];
      const activityDaata = [];
      // arr[arr.length - 1].activities =
      if (assetType === 1) {
        projectsListData?.data?.projectData?.forEach((act) => {
          activityDaata.push({
            categoryId: act.divisionId,
            activityId: act.id,
            isSelected: false,
            activityName: act.projectName,
          });
        });
        arr[arr.length - 1].activities = activityDaata;
      }
      if (assetType === 4) {
        assetList?.data?.assetData?.forEach((act) => {
          activityDaata.push({
            categoryId: act.divisionId,
            activityId: act.id,
            isSelected: false,
            activityName: act.assetName,
          });
        });
        arr[arr.length - 1].activities = activityDaata;
      }
      setcatActArr(arr);
    }
  }, [projectsListData, assetList]);

  function submit(data) {
    dispatch(jhaAction.clearJha(data));
    setisSuccess(true);
    if (editMode)
      dispatch(
        jhaAction.updateJhaSetup({
          templateId: data.templateId,
          supervisorRequiredTypeId: data.supervisorRequiredTypeId,
          frequencyId: data?.frequencyId,
          setupId: location?.state?.setupId,
          modifiedBy: parseInt(USER_ID()),
          divisionMappingKeys: data.divisionMappingKeys,
        })
      );
    else dispatch(jhaAction.addJhaSetup(data));
  }

  const addJhaSetupData = useSelector((state) => state?.addJhaSetup);
  const updateJhaSetupData = useSelector((state) => state?.updateJhaSetup);
  const successScreen = (id, message, message1) => {
    return (
      <SuccessScreen
        id={editMode ? updateJhaSetupData?.data.id : addJhaSetupData?.data.id}
        isUpdate={
          editMode ? "Setup updated Successfully" : "Setup Added Successfully"
        }
        route={-1}
        label1="Setup ID"
        label4="Hazard Analysis service in "
        label2={editMode ? "has been updated" : "has been generated"}
      ></SuccessScreen>
    );
  };
  const showLoader =
    templateListData.isLoading ||
    addJhaSetupData.isLoading ||
    projectsListData?.isLoading ||
    assetList.isLoading || updateJhaSetupData.isLoading;

  useEffect(() => {
    if (assetType)
      getTemplateListData({
        page: 1,
        pageSize: 10000,
        search: "",
        assetType: assetType,
      });
  }, [assetType]);
  return (
    <div>
      {showLoader && <Loader />}
      {addJhaSetupData.status === "SUCCESS" && isSuccess && addJhaSetupData ? (
        successScreen(
          addJhaSetupData?.data.id,
          "Setup Added Successfully",
          "has been generated"
        )
      ) : updateJhaSetupData.status === "SUCCESS" &&
        isSuccess &&
        updateJhaSetupData ? (
        successScreen(
          updateJhaSetupData?.data.id,
          "Setup Updated Successfully",
          "has been updated"
        )
      ) : (editMode && templateListData.isLoading) ||
        addJhaSetupData.isLoading ||
        getJhaSetupDetailsData.isLoading ||
        updateJhaSetupData.isLoading ||
        getJHAReferenceData.isLoading ? (
        <Loader />
      ) : (
        <JHASetUpAlt
          templeteList={templeteList}
          organisationDivision={divisionList}
          getAssetList={getAssetList}
          setcatActArr={setcatActArr}
          catActArr={catActArr}
          submit={submit}
          frequencyList={getJHAReferenceData?.data?.frequency ?? []}
          editMode={editMode}
          getJhaSetupDetailsData={getJhaSetupDetailsData}
          template={template}
          setTemplate={setTemplate}
          isTemplate={isTemplate}
          isAssetType={isAssetType}
          setisAssetType={setisAssetType}
          setisTemplate={setisTemplate}
          frequencies={frequencies}
          setFrequencies={setFrequencies}
          isFrequency={isFrequency}
          setisFrequency={setisFrequency}
          supervisorRequiredTypeId={supervisorRequiredTypeId}
          setsupervisorRequiredTypeId={setsupervisorRequiredTypeId}
          assetType={assetType}
          setassetType={setassetType}
          getTemplateListData={getTemplateListData}
        />
      )}
    </div>
  );
};

export default JHASetUpContainer;
