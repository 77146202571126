import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import Buttons from "../custom/button/Button";
import { Add, Remove } from "@mui/icons-material";
import { getFormdata, responseQuestionSet } from "../../utils/incpectionHelper";
import NotAcceptableBoxDetails from "./notAcceptableBoxDetails";
import { createFileUpload } from "../../services/fileUploadService";
import * as incpectionAction from "../../redux/actions/incpectionAction";
import { useEffect } from "react";
import {
  API_STATUS,
  GET_COUNT_TYPE,
  ORG_ID,
} from "../../constant/constant";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../common/Loader";
import * as userAction from "../../redux/actions/userAction";
import { checkPrivileges } from "../../utils/rolesHelper";

const NotAcceptableBox = ({
  qestSetState,
  setQestSetState,
  parentElementData,
  empType,
  setImage,
  filesId,
  filesUrl,
  assetId,
  assetTypeValue,
  userClear,
}) => {
  const [loadingImg, setLoadingImg] = React.useState(false);
  const dispatch = useDispatch();
  const [idStore, setIdStore] = useState(null);
  const [eventCapture, setEventCapture] = useState(null);

  const inspectionViolationCounts = useSelector(
    (state) => state.getInspectionViolationCounts
  );
  const inspectionViolationCountsData = (data) => {
    dispatch(incpectionAction.getInspectionViolationCounts(data));
  };
  const countLoader = useSelector(
    (state) => state.getInspectionViolationCounts.isLoading
  );

  const allUsers = useSelector((state) => state.allUsers);
  function handleAllUserSearch(
    event,
    type,
    divisionId,
    assetType,
    assetId,
    priviledgeId
  ) {
    if (checkPrivileges([12, 139, 144]) ? assetId?.key : assetId) {
      dispatch(
        userAction.searchAllUsers(
          event.target.value,
          type,
          divisionId,
          checkPrivileges([12, 139, 144]) ? assetId?.key : assetId,
          assetTypeValue,
          priviledgeId
        )
      );
    }
  }
  const isAutoSearchDataLoading = allUsers.isLoading;

  useEffect(() => {
    const acceptData = { ...data };
    const notacceptable = [...acceptData?.notacceptable];
    if (inspectionViolationCounts?.status === API_STATUS.SUCCESS) {
      notacceptable.forEach((notacceptables) => {
        if (notacceptables.id === idStore) {
          notacceptables.empCount = [
            ...notacceptables.empCount,
            {
              count: inspectionViolationCounts?.data?.result,
              key: eventCapture?.key,
            },
          ];
        }
      });
      formHandler(acceptData);
    }
  }, [inspectionViolationCounts]);

  const data = React.useMemo(() => {
    const keyParent = parentElementData?.itemKey;
    const { keySub, key } = empType;
    if (keyParent && key) {
      const dataForm = getFormdata(qestSetState, keyParent, keySub, key);
      const dataForms = { ...dataForm };
      return dataForms;
    }
    return qestSetState;
  }, [qestSetState, empType, parentElementData]);
  const { iscorrectiveitem } = data?.notacceptable ?? {};

  const uploadImg = async (val) => {
    setLoadingImg(true);
    const files = new FormData();
    const fileImg = val.target.files;
    if (fileImg && fileImg[0]) {
      files.append("", fileImg[0]);
    }
    try {
      const res = await createFileUpload({ data: files, fileType: 1 });
      const fileRes = (res && res?.data && res?.data?.files[0]) ?? "";
      setLoadingImg(false);
      if (Object.keys(fileRes).length > 0) {
        const acceptData = { ...data };
        const notacceptable = { ...acceptData?.notacceptable };
        notacceptable.issuefound = {
          ...notacceptable.issuefound,
          file: fileRes,
        };
        acceptData.notacceptable = notacceptable;
        formHandler(acceptData);
      }
    } catch (err) {
      setLoadingImg(false);
    }
  };
  const formHandler = (value) => {
    const keyParent = parentElementData?.itemKey;
    const { keySub, key } = empType;
    if (keyParent) {
      setQestSetState((prevState) =>
        responseQuestionSet(prevState, parentElementData, keySub, key, value)
      );
    }
  };

  const { notacceptable } = data;
  const handleOnChanges = (event, id, fieldName) => {
    setIdStore(id);
    const acceptData = { ...data };
    const notacceptable = [...acceptData?.notacceptable];

    notacceptable.forEach((notacceptables) => {
      if (notacceptables.id === id) {
        if (fieldName === "commentFeild") {
          // if (event.target.value.length <= 500) {
          notacceptables.comment = event;
          // }
        }
        if (fieldName === "Involved Party Type") {
          notacceptables.involdevPaty = event.target.value;
          notacceptables.employee = [{ key: "", value: "" }];
          notacceptables.subcontractor = [{ key: "", value: "" }];
          notacceptables.empCount = [];
        }
        console.log("notacceptables.employee", notacceptables.employee);
        if (fieldName === "Add Involved Employee") {
          if (
            !notacceptables?.employee?.some((item) => item.key === event?.key)
          ) {
            notacceptables.employee = [
              ...notacceptables.employee,
              { key: event?.key ?? "", value: event?.value ?? "" },
            ];
            setEventCapture(event);
            const data = {
              id: event?.key,
              type: GET_COUNT_TYPE?.VIOLATION,
              organisationId: parseInt(ORG_ID()),
            };
            if (event?.key) {
              inspectionViolationCountsData(data);
            }
          }
        }
        if (fieldName === "Add Involved Company") {
          if (
            !notacceptables?.subcontractor?.some(
              (item) => item.key === event?.key
            )
          ) {
            notacceptables.subcontractor = [
              ...notacceptables.subcontractor,
              { key: event?.key ?? "", value: event?.value ?? "" },
            ];
            setEventCapture(event);
            const data = {
              id: event?.key,
              organisationId: parseInt(ORG_ID()),
              type: GET_COUNT_TYPE?.VIOLATION,
            };
            if (event?.key) {
              inspectionViolationCountsData(data);
            }
          }
        }
        if (fieldName === "Escalate to Reprimand") {
          notacceptables.escalatetoreprimandIsChecked = event.target.value;
        }
        if (fieldName === "Escalate to Reprimand comment") {
          // if (event.target.value.length <= 500) {
          notacceptables.escalatetoreprimandComment = event;
          // }
        }
        if (fieldName === "Item Status") {
          notacceptables.itemStatusIsChecked = event.target.value;
          notacceptables.itemStatusComment = "";
          notacceptables.itemStatusCommentUser = "";
        }
        if (fieldName === "Item Status Comment") {
          // if (event.target.value.length <= 500) {
          notacceptables.itemStatusComment = event;
          // }
        }
        if (fieldName === "items user") {
          notacceptables.itemStatusCommentUser = {
            key: event?.key ?? "",
            value: event?.value ?? "",
          };
        }
      }
    });
    formHandler(acceptData);
  };

  const onChangeButton = () => {
    const acceptData = { ...data };
    if (acceptData.notacceptable.length < 4) {
      acceptData.notacceptable.push({
        id: Date.now() + Math.floor(Math.random() * 100),
        comment: "",
        involdevPaty: "",
        employee: [],
        subcontractor: [],
        escalatetoreprimandIsChecked: "No",
        escalatetoreprimandComment: "",
        itemStatusIsChecked: "",
        itemStatusComment: "",
        itemStatusCommentUser: "",
        media: [],
        filess: [],
        imgUrl: "",
        empCount: [],
      });
      formHandler(acceptData);
    }
  };
  const deleteuser = (e, id) => {
    const acceptData = { ...data };
    const notacceptable = [...acceptData?.notacceptable];
    notacceptable.forEach((notacceptables) => {
      if (notacceptables.id === id) {
        notacceptables.employee = notacceptables.employee.filter(
          (items) => items.key !== e
        );
        notacceptables.empCount = notacceptables.empCount.filter(
          (items) => items.key !== e
        );
      }
    });
    formHandler(acceptData);
  };
  const deleteSubContractor = (e, id) => {
    const acceptData = { ...data };
    const notacceptable = [...acceptData?.notacceptable];
    notacceptable.forEach((notacceptables) => {
      if (notacceptables.id === id) {
        notacceptables.subcontractor = notacceptables.subcontractor.filter(
          (items) => items.key !== e
        );
        notacceptables.empCount = notacceptables.empCount.filter(
          (items) => items.key !== e
        );
      }
    });
    formHandler(acceptData);
  };
  const deleteResponsibilityuser = (e, id) => {
    const acceptData = { ...data };
    const notacceptable = [...acceptData?.notacceptable];
    notacceptable.forEach((notacceptables) => {
      if (notacceptables.id === id) {
        notacceptables.itemStatusCommentUser =
          notacceptables.itemStatusCommentUser.filter(
            (items) => items.key !== e
          );
      }
    });
    formHandler(acceptData);
  };

  const deleteform = (id) => {
    const acceptData = { ...data };
    const notacceptable = [...acceptData?.notacceptable];
    if (notacceptable.length > 1) {
      acceptData.notacceptable = notacceptable.filter(
        (items) => items.id !== id
      );
    }
    formHandler(acceptData);
  };

  const setImages = (photo, fileDataId, uniqueId) => {
    const acceptData = { ...data };
    const notacceptable = [...acceptData?.notacceptable];
    notacceptable.forEach((notacceptables) => {
      if (notacceptables.id === uniqueId) {
        notacceptables.media = fileDataId;
        notacceptables.imgUrl = photo;
      }
    });
    formHandler(acceptData);
  };

  const handleDeleteImage = (urls, index, uniqueId) => {
    const acceptData = { ...data };
    const notacceptable = [...acceptData?.notacceptable];
    notacceptable.forEach((notacceptables) => {
      if (notacceptables.id === uniqueId) {
        notacceptables.imgUrl = notacceptables.imgUrl
          .split("|")
          .filter((url) => url !== urls)
          .join("|");
        notacceptables.media.splice(index, 1);
      }
    });
    formHandler(acceptData);
  };

  useEffect(() => {
    if (userClear) {
      const acceptData = { ...data };
      const notacceptable = [...acceptData?.notacceptable];
      notacceptable.forEach((notacceptables) => {
        notacceptables.employee = [];
        notacceptables.subcontractor = [];
        notacceptables.itemStatusCommentUser = "";
      });
      formHandler(acceptData);
    }
  }, [userClear]);

  return (
    <>
      {countLoader && <Loader />}
      <div className="overideNotAc">
        <Box>
          {notacceptable.map((acce, index) => {
            return (
              <NotAcceptableBoxDetails
                formHandler={formHandler}
                commentValue={acce.comment}
                involdevPatyValue={acce.involdevPaty}
                employeeValue={acce.employee}
                subcontractorValue={acce.subcontractor}
                escalatetoreprimandIsCheckedValue={
                  acce.escalatetoreprimandIsChecked
                }
                escalatetoreprimandCommentValue={
                  acce.escalatetoreprimandComment
                }
                itemStatusIsCheckedValue={acce.itemStatusIsChecked}
                itemStatusCommentValue={acce.itemStatusComment}
                itemStatusCommentUserValue={acce.itemStatusCommentUser}
                uniqueId={acce.id}
                setImage={setImages}
                filesId={filesId}
                filesUrl={acce.imgUrl}
                data={data?.notacceptable ?? {}}
                handleOnChange={(e, actionFeild, fieldName) =>
                  handleOnChanges(e, actionFeild, fieldName)
                }
                deleteuser={(e, id) => deleteuser(e, id)}
                deleteSubContractor={(e, id) => deleteSubContractor(e, id)}
                deleteResponsibilityuser={(e, id) =>
                  deleteResponsibilityuser(e, id)
                }
                uploadImg={(e) => uploadImg(e)}
                loadingImg={loadingImg}
                deleteform={(id) => deleteform(id)}
                notacceptablelength={notacceptable.length}
                deleteImageURLS={handleDeleteImage}
                itemCount={acce.empCount}
                isLoading={isAutoSearchDataLoading}
                handleAllUserSearch={handleAllUserSearch}
                allUsers={allUsers && allUsers?.data}
                filess={acce.filess}
                assetId={assetId}
                key={index}
              />
            );
          })}
          <Grid className="inputGrid" mt={1} mb={3} gap={2}>
            <Buttons
              primary={true}
              label={"Add Corrective Item"}
              disabled={notacceptable.length === 4 ? true : false}
              startIcon={!iscorrectiveitem ? <Add /> : <Remove />}
              redBg={true}
              onClick={() => onChangeButton()}
            />
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default React.memo(NotAcceptableBox);
