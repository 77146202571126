import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./incident.scss";
import GridTable from "..//..//..//library/custom/gridTable/GridTable";
import SettingTable from "./SettingTable";
import ReportRowManipulte from "./ReportRowManipulte";
import { Box } from "@mui/system";
import DialogBox from "../../../library/common/DialogBox";
import AlertModalAction from "./AlertModalAction";
import Buttons from "../../../library/custom/button/Button";
import AddIcon from "@mui/icons-material/Add";
import { getApiUrl } from "../../../config";
import {
  NEAR_MISS,
  PERSONAL_INJURY,
  STOLEN_PROPERTY,
  UTILITY_DAMAGE,
  VEHICLE_ACCIDENT,
  VIEW_NEAR_MISS,
  VIEW_INCIDENT_REPORT,
  VIEW_STOLEN_PROPERTY,
  VIEW_UTILITY_DAMAGE,
  VIEW_VEHICLE_ACCIDENT,
} from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { ORG_ID } from "../../../constant/constant";

export default function IncidentListId(props) {
  const { isFullAccess, isWriteAccess, isReadAccess } = props;
  const [caseLists, setCaseLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportIdOfChekbox, setReportIdOfChekbox] = useState([]);

  let { incidentId } = useParams();
  useEffect(() => {
    setLoading(true);
    const url = `${
      getApiUrl().incidentApiUrl
    }/getcase?orgId=${ORG_ID()}&CaseId=${incidentId}`;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("id_token"),
      },
    })
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        setCaseLists(resp);
      })
      .catch()
    setLoading(false);
  }, [incidentId]);

  const caseID = caseLists.caseId;
  const createdBy = caseLists.createdByName;
  const location = caseLists.location;
  const dateOfEvent = caseLists.dateOfCase;
  const timeOfEvent = caseLists.timeOfCase;
  const notes = caseLists.description;
  const associatedIncident = caseLists?.associatedIncident;
  const navigate = useNavigate();

  const handleOnclick = (rows) => {
    if (rows?.incidentId === 2) {
      navigations(VEHICLE_ACCIDENT, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 1) {
      navigations(PERSONAL_INJURY, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 4) {
      navigations(UTILITY_DAMAGE, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 3) {
      navigations(STOLEN_PROPERTY, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 5) {
      navigations(NEAR_MISS, rows?.reportId, rows?.caseId);
    }
  };

  const handleViewOnClick = (rows) => {
    if (rows?.incidentId === 2) {
      navigations(VIEW_VEHICLE_ACCIDENT, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 1) {
      navigations(VIEW_INCIDENT_REPORT, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 4) {
      navigations(VIEW_UTILITY_DAMAGE, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 3) {
      navigations(VIEW_STOLEN_PROPERTY, rows?.reportId, rows?.caseId);
    } else if (rows?.incidentId === 5) {
      navigations(VIEW_NEAR_MISS, rows?.reportId, rows?.caseId);
    }
  };

  function navigations(navigationPath, incidentId, caseId) {
    navigate(navigationPath, {
      state: {
        caseId: caseId,
        incidentId: incidentId,
        editMode: true,
        isDirect: true,
      },
    });
  }

  const columns = [
    { field: "reportId", headerName: "Report ID", width: 120 },
    { field: "dateOfEntry", headerName: "Date Of Entry", width: 190 },
    { field: "incidentType", headerName: "Incident Type", width: 190 },
    { field: "enteredBy", headerName: "Entered By", width: 190 },
    { field: "status", headerName: "Status", width: 170 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={props?.incidentList?.data?.noOfRecords ?? 0}
          numberOfItemsPerPage={5}
          incidentLists={props?.incidentList?.data?.incident ?? []}
        />
      ),
      width: 170,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            viewOnClick={(currentRow) => handleViewOnClick(currentRow)}
            editOnClick={(currentRow) => handleOnclick(currentRow)}
            deleteOnClick={() => deleteIncident(rows?.row)}
            edit={isFullAccess || isWriteAccess}
            view={isReadAccess || isFullAccess || isWriteAccess}
            deleted={isFullAccess}
            editLabel={"Edit Incident"}
            viewLabel={"View Incident"}
            deleteLabel={"Delete Incident"}
          />
        );
      },
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");

  const deletehandle = () => {
    setOpenModal(false);
    props.deleteIncidents(selectedRow);
  };

  const deleteIncident = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      handleViewOnClick(rows?.row);
    }
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">View Cases</div>
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={"Are you sure you want to delete this Incident?"}
              view
            />
          )}
          {(isFullAccess || isWriteAccess) && (
            <div className="addCaseListBtn">
              <Box mr={3}>
                <Buttons
                  varientContained={true}
                  label={"Add To Existing"}
                  startIcon={<AddIcon />}
                  onClick={() =>
                    navigate(PERSONAL_INJURY, {
                      state: {
                        caseId: caseID,
                        editMode: false,
                        isDirect: true,
                      },
                    })
                  }
                />
              </Box>
              <Box>
                <Buttons
                  varientContained={true}
                  label={"Create New Case"}
                  startIcon={<AddIcon />}
                  onClick={() => navigate(PERSONAL_INJURY)}
                />
              </Box>
            </div>
          )}
        </div>
        <div className="grid_main_body">
          {loading ? (
            <Loader />
          ) : (
            <div className="row_uniq section_box">
              <div className="caseDiv">
                <div className="caseidinc">Case ID : {caseID}</div>
                <div className="detaildata">
                  <div className="content_grid_ui">
                    <div>
                      <div className="prepared">Prepared by :</div>
                      <div className="preparedvalue">{createdBy}</div>
                    </div>
                    <div>
                      <div className="location">Establishment : </div>
                      <div className="locationvalue">{location}</div>
                    </div>

                    <div>
                      <div className="dateofincident">Date of case:</div>
                      <div className="dateofincidentvalue">{dateOfEvent}</div>
                    </div>
                    <div>
                      <div className="timeofincident">Time of case : </div>
                      <div className="timeofincidentvalue">{timeOfEvent}</div>
                    </div>
                  </div>

                  <div className="summer_of_case">
                    <div className="caseofsummry">Summary of Case : </div>
                    <div className="caseofsummryvalue overRideTextEditor">
                      {notes}
                    </div>
                  </div>

                  <div className="summer_of_case caseofsummryBox">
                    <div className="caseofsummry">Associated Incident:</div>
                    <div className="caseofsummryvalue caseofsummryFlex">
                      {associatedIncident?.map((associatedItem) => {
                        return (
                          <div
                            key={associatedItem?.key}
                            className="caseofsummryText"
                          >
                            {associatedItem?.value}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <section className="grid_main_sec">
        <div className="grid_main_header">
          <div className="grid_heading_style">List of Incidents</div>
        </div>
        <div className="grid_main_body">
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.reportId}
                rows={props?.incidentList?.data?.incident ?? []}
                columns={columns}
                pageSize={5}
                checkboxSelection={false}
                onClick={(rows) => onRowClick(rows)}
                rowCount={props?.incidentList?.data?.noOfRecords ?? 0}
                loading={loading}
                pagination
                rowsPerPageOptions={[10, 20, 30]}
                onPageChange={(newPage) => props.pageSet(newPage + 1)}
                backIconButtonProps={{ disabled: false }}
                onSelectionModelChange={(reportId) =>
                  setReportIdOfChekbox(reportId)
                }
                disableSelectionOnClick
              />
            </div>
          </div>
        </div>
      </section>

      {reportIdOfChekbox.length > 0 && (
        <AlertModalAction
          reportIdOfChekbox={reportIdOfChekbox}
          incidentLists={props?.incidentList?.data?.incident ?? []}
          filename={"list-of-incident.csv"}
        />
      )}
    </>
  );
}
