import React, { useEffect, useState } from "react";
import Table from "../../../../library/custom/table/table";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import { REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item } from "../../../../constant/routeContant";
import commonImages from "../../../../assets";
import {
  downloadExcel,
  downloadPDF,
  useInfiniteScroll,
} from "../../../../utils/helper";
import { PAGE_SIZE_100 } from "../../../../constant/constant";

function VehicleWithMostIncident(props) {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props?.vehiclewithmostincidentsList?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.vehiclewithmostincidentsList?.records,
      ]);
    }
  }, [props?.vehiclewithmostincidentsList?.records]);
  const fetchMoreData = async (pageNumber) => {
    const { startDate, endDate, incidentType, divisionId, locationId } =
      props?.drillDetail;
    const data = {
      page: pageNumber,
      pageSize: PAGE_SIZE_100,
      incidentType: incidentType > 0 ? incidentType : 0,
      fromDate: startDate,
      toDate: endDate,
      divisionId: divisionId,
      locationId: locationId,
    };
    try {
      const response = await props?.getVehicleWithmostIncidentsData(data);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    const moreData = await fetchMoreData(pageNumber);
    setPage(pageNumber);
    setData((prevData) => [...prevData, ...moreData]);
  };

  useEffect(() => {
    setData([]);
  }, []);

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };
  const handleOnClick = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item, {
      state: {
        VehicleId: id,
        drillDetail: props?.drillDetail,
        drillDetailFull: props?.drillDetailFull,
        itemTypeValue: "vehicle_most_incident",
      },
    });
  };
  const rows = data?.map((inc, index) => (
    <tr className="row" key={index}>
      <td>{inc?.vehicleId}</td>
      <td>{inc?.unitNo}</td>
      <td>{inc?.makeAndModel}</td>

      <td>{inc?.licensePlateNumber}</td>
      <td>
        <span className="link" onClick={() => handleOnClick(inc?.vehicleId)}>
          {inc?.numberOfIncident}
        </span>
      </td>
    </tr>
  ));
  const columns = [
    {
      id: "vehicleId",
      label: `Vehicle ID`,
      sortable: true,
      isSorted: sortColumn === "vehicleId",
      sortDirection,
    },
    {
      id: "unitNo",
      label: `Unit Number`,
      sortable: true,
      isSorted: sortColumn === "unitNo",
      sortDirection,
    },
    {
      id: "makeAndModel",
      label: `Make & Model`,
      sortable: true,
      isSorted: sortColumn === "makeAndModel",
      sortDirection,
    },
    {
      id: "licensePlateNumber",
      label: `License Plate Number`,
      sortable: true,
      isSorted: sortColumn === "licensePlateNumber",
      sortDirection,
    },

    {
      id: "numberOfIncident",
      label: "Number of Incidents",
      sortable: true,
      isSorted: sortColumn === "numberOfIncident",
      sortDirection,
    },
  ];
  const rowsPdf = data?.map((veh) => [
    veh?.vehicleId,
    veh?.unitNo,
    veh?.makeAndModel,
    veh?.licensePlateNumber,
    veh?.numberOfIncident,
  ]);

  return (
    <>
      <div className="topHeaderDrill">
        <div className="topHeaderDrillLeft">
          <div className="title">{"Vehicle with the Most Incidents"}</div>
        </div>
        <div className="topHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadPDF(
                        rowsPdf,
                        columns.map((col) => col.label),
                        "All-Vehicle-with-most-incidents.pdf"
                      )
                    }
                  >
                    <img src={commonImages.pdfFill} alt={""} />
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>

              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadExcel(
                        data,
                        "All-Vehicle-with-most-incidents.xlsx"
                      )
                    }
                  >
                    <img src={commonImages.excelDownload} alt="" />
                    <span>Download.xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="customTableWrapper">
        <Table
          columns={columns}
          rows={rows}
          tableRef={tableRef}
          onSort={handleSort}
        />
      </div>
    </>
  );
}

export default VehicleWithMostIncident;
