import * as incidentAction from "../actions/incidentAction";
import { incidentType } from "../constants/incidentType";
import {
  getIncidentReferenceData,
  getIncidentList,
  getCasesList,
  deleteIncident,
  getActionsList,
  closeActionService,
  getCaseReport,
  closeIncident,
  deleteCase,
  getIncidentReport,
  getIncidentInjuredBodyPartReport,
  getIncidentInjuredBodyPartListReport,
  getIncidentMostCommonInjury,
  getIncidentMostCommonInjuryList,
  getIncidentMostCommonDayOfInjury,
  getIncidentUserWithmostNoofIncident,
  getIncidentUserWithmostNoofIncidentList,
  getIncidentUserWithmostNoofActionItems,
  getIncidentUserWithmostNoofActionItemsList,
  getProjectWithMostIncidents,
  getProjectWithMostIncidentsList,
  getVehicleWithMostIncidents,
  getVehicleWithMostIncidentsList,
} from "../../services/incidentService";
import { call, put, takeLatest } from "redux-saga/effects";
import { apiError } from "../../utils/helper";

export function* incidentReferenceData() {
  console.debug("incidentSaga");
  try {
    const { data, status, error } = yield call(getIncidentReferenceData);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentDataSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Referencedata Failed" };
    yield put(incidentAction.getIncidentDataFailure(data));
  }
}

export function* incidentListSaga(params) {
  console.debug("incidentListSaga");
  try {
    const { data, status, error } = yield call(getIncidentList, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentListSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident List Failed" };
    yield put(incidentAction.getIncidentDataFailure(data));
  }
}

function* deleteIncidentsSaga(payload) {
  console.debug("deleteIncidentsSaga");
  try {
    const { data, status, error } = yield call(deleteIncident, payload);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.deleteIncidentsSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident List Failed" };
    yield put(incidentAction.deleteIncidentsFailure(data));
  }
}

export function* actionListSaga(params) {
  console.debug("actionListSaga");
  try {
    const { data, status, error } = yield call(getActionsList, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.getActionListSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Action List Failed" };
    yield put(incidentAction.getActionListFailure(data));
  }
}

export function* closeActionItemSaga(params) {
  console.debug("closeActionItemSaga");
  try {
    const { data, status, error } = yield call(closeActionService, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.closeActionItemSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Close Item failed" };
    yield put(incidentAction.closeActionItemFailure(data));
  }
}

export function* casesListSaga(params) {
  console.debug("casesListSaga");
  try {
    const { data, status, error } = yield call(getCasesList, params);
    if (!data && status !== 200) throw apiError(error);
    const casesPage = incidentType.CASES_PAGE;
    yield put(
      incidentAction.getCasesListSuccess({
        casesPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Case List Failed" };
    yield put(incidentAction.getCasesListFailure(data));
  }
}

export function* caseReport({ caseId }) {
  console.debug("caseReport");
  try {
    const { data, status, error } = yield call(getCaseReport, caseId);
    if (!data && status !== 200) throw apiError(error);
    const CaseReportPage = incidentType.CASEVIEW_PAGE;
    yield put(
      incidentAction.getCaseDetailsSuccess({
        CaseReportPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Case Report Failed" };
    yield put(incidentAction.getCaseDetailsFailure(data));
  }
}

export function* closeIncidentSaga(params) {
  console.debug("closeIncidentSaga");
  try {
    const { data, status, error } = yield call(closeIncident, params);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.closeIncidentSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Close Item failed" };
    yield put(incidentAction.closeIncidentFailure(data));
  }
}

function* deleteCaseSaga(payload) {
  console.debug("deleteCaseSaga");
  try {
    const { data, status, error } = yield call(deleteCase, payload);
    if (!data && status !== 200) throw apiError(error);
    const incidentPage = incidentType.INCIDENT_PAGE;
    yield put(
      incidentAction.deleteCaseSuccess({
        incidentPage,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident List Failed" };
    yield put(incidentAction.deleteCaseFailure(data));
  }
}
export function* getIncidentReportSaga(params) {
  console.debug("getInspecionReportSaga");
  try {
    const { data, status, error } = yield call(getIncidentReport, params);
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentReportSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Report Failure" };
    yield put(incidentAction.getIncidentReportFailure(data));
  }
}
export function* getIncidentReportInjuredBodyPartSaga(params) {
  try {
    const { data, status, error } = yield call(
      getIncidentInjuredBodyPartReport,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentReportInjuredBodyPartSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Injured Body Report Failure" };
    yield put(incidentAction.getIncidentReportInjuredBodyPartFailure(data));
  }
}
export function* getIncidentReportInjuredBodyPartListSaga(params) {
  try {
    const { data, status, error } = yield call(
      getIncidentInjuredBodyPartListReport,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentReportInjuredBodyPartListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Injured Body List Report Failure" };
    yield put(incidentAction.getIncidentReportInjuredBodyPartListFailure(data));
  }
}

export function* getIncidentReportMostCommonInjurySaga(params) {
  try {
    const { data, status, error } = yield call(
      getIncidentMostCommonInjury,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentReportMostCommonInjurySuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Most Common Injury Failure" };
    yield put(incidentAction.getIncidentReportMostCommonInjuryFailure(data));
  }
}
export function* getIncidentReportMostCommonInjuryListSaga(params) {
  try {
    const { data, status, error } = yield call(
      getIncidentMostCommonInjuryList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentReportMostCommonInjuryListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Most Common Injury Failure" };
    yield put(incidentAction.getIncidentReportMostCommonInjuryFailure(data));
  }
}
export function* getIncidentReportMostCommonDayOfInjuryListSaga(params) {
  try {
    const { data, status, error } = yield call(
      getIncidentMostCommonDayOfInjury,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getIncMostCommonDayOfInjurySuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Most Common Day Injury Failure" };
    yield put(incidentAction.getIncMostCommonDayOfInjuryFailure(data));
  }
}

export function* getIncReportUsrWithMostIncidentSaga(params) {
  try {
    const { data, status, error } = yield call(
      getIncidentUserWithmostNoofIncident,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentUserWithMostNoOfIncidentSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get user most IncidentFailure" };
    yield put(incidentAction.getIncidentUserWithMostNoOfIncidentFailure(data));
  }
}
export function* getIncReportUsrWithMostIncidentListSaga(params) {
  try {
    const { data, status, error } = yield call(
      getIncidentUserWithmostNoofIncidentList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentUserWithMostNoOfIncidentListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get user most Incident List Failure" };
    yield put(
      incidentAction.getIncidentUserWithMostNoOfIncidentListFailure(data)
    );
  }
}
export function* getIncReportUsrWithMostActionItemSaga(params) {
  try {
    const { data, status, error } = yield call(
      getIncidentUserWithmostNoofActionItems,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentUserWithMostNoOfActionItemSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Action Items Failure" };
    yield put(
      incidentAction.getIncidentUserWithMostNoOfActionItemFailure(data)
    );
  }
}
export function* getIncReportUsrWithMostActionItemListSaga(params) {
  try {
    const { data, status, error } = yield call(
      getIncidentUserWithmostNoofActionItemsList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getIncidentUserWithMostNoOfActionItemListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Most Action Items ListFailure" };
    yield put(
      incidentAction.getIncidentUserWithMostNoOfActionItemListFailure(data)
    );
  }
}
export function* projectMostIncidentsSaga(params) {
  try {
    const { data, status, error } = yield call(
      getProjectWithMostIncidents,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getProjectWithmostIncidentsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get project witj most incidents Failure" };
    yield put(incidentAction.getProjectWithmostIncidentsListFailure(data));
  }
}
export function* projectListMostIncidentsSaga(params) {
  try {
    const { data, status, error } = yield call(
      getProjectWithMostIncidentsList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getProjectWithmostIncidentsListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Most projects ListFailure" };
    yield put(incidentAction.getProjectWithmostIncidentsListFailure(data));
  }
}
export function* vehicleMostIncidentsSaga(params) {
  try {
    const { data, status, error } = yield call(
      getVehicleWithMostIncidents,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getVehicleWithmostIncidentsSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Most Action Items ListFailure" };
    yield put(incidentAction.getVehicleWithmostIncidentsFailure(data));
  }
}
export function* vehicleListMostIncidentsSaga(params) {
  try {
    const { data, status, error } = yield call(
      getVehicleWithMostIncidentsList,
      params
    );
    if (!data && status !== 200) throw apiError(error);
    const page = incidentType.REPORT_INCIDENT_PAGE;
    yield put(
      incidentAction.getVehicleWithmostIncidentsListSuccess({
        page,
        data,
      })
    );
  } catch (error) {
    const data =
      error && error.message
        ? error
        : { message: error || "Get Incident Most vehice Items ListFailure" };
    yield put(incidentAction.getVehicleWithmostIncidentsListFailure(data));
  }
}
const myIncidentSaga = [
  takeLatest(incidentType.GET_INCIDENT, incidentReferenceData),
  takeLatest(incidentType.GET_INCIDENT_LIST, incidentListSaga),
  takeLatest(incidentType.GET_CASES_LIST, casesListSaga),
  takeLatest(incidentType.DELETE_INCIDENT, deleteIncidentsSaga),
  takeLatest(incidentType.GET_ACTION_LIST, actionListSaga),
  takeLatest(incidentType.CLOSE_ACTION_ITEM, closeActionItemSaga),
  takeLatest(incidentType.GET_CASE_DETAILS_PENDING, caseReport),
  takeLatest(incidentType.CLOSE_INCIDENT, closeIncidentSaga),
  takeLatest(incidentType.DELETE_CASE, deleteCaseSaga),
  takeLatest(incidentType.GET_INCIDENT_REPORT, getIncidentReportSaga),
  takeLatest(
    incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART,
    getIncidentReportInjuredBodyPartSaga
  ),
  takeLatest(
    incidentType.GET_INCIDENT_REPORT_INJURED_BODYPART_LIST,
    getIncidentReportInjuredBodyPartListSaga
  ),
  takeLatest(
    incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY,
    getIncidentReportMostCommonInjurySaga
  ),
  takeLatest(
    incidentType.GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST,
    getIncidentReportMostCommonInjuryListSaga
  ),
  takeLatest(
    incidentType.GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY,
    getIncidentReportMostCommonDayOfInjuryListSaga
  ),

  takeLatest(
    incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS,
    getIncReportUsrWithMostIncidentSaga
  ),
  takeLatest(
    incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST,
    getIncReportUsrWithMostIncidentListSaga
  ),
  takeLatest(
    incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS,
    getIncReportUsrWithMostActionItemSaga
  ),
  takeLatest(
    incidentType.GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST,
    getIncReportUsrWithMostActionItemListSaga
  ),
  takeLatest(incidentType.PROJECT_WITH_MOST_INCIDENT, projectMostIncidentsSaga),
  takeLatest(
    incidentType.PROJECT_WITH_MOST_INCIDENT_LIST,
    projectListMostIncidentsSaga
  ),
  takeLatest(incidentType.VEHICLE_WITH_MOST_INCIDENT, vehicleMostIncidentsSaga),
  takeLatest(
    incidentType.VEHICLE_WITH_MOST_INCIDENT_LIST,
    vehicleListMostIncidentsSaga
  ),
];

export default myIncidentSaga;
