import { getApiUrl } from "../config";
import { LOGIN_ORG_ID, ORG_ID } from "../constant/constant";
import { get } from "./axiosRequest";

export const getInspections = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Vehicle/GetInspections`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getDropdownDataKeyValue = async ({ data }) => {
  const { orgId = ORG_ID(), flags, productId = 0,Clientid } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Common/GetDropdownDataKeyValue?orgId=${orgId}&flags=${flags}&productId=${productId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: Clientid ? Clientid : (LOGIN_ORG_ID() === ORG_ID() ? LOGIN_ORG_ID() : ORG_ID()),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getSupervisorByProject = async ({ data }) => {
  const { divisionId, projectId } = data;
  const url = `${
    getApiUrl().adminApiUrl
  }/Project/GetSupervisorByProject?divisionId=${divisionId}&projectId=${projectId}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
