import React, { useState, useEffect } from "react";
import AddSite from "../components/addSite";
import * as stateCityAction from "../../../../redux/actions/stateCityAction";
import { useDispatch, useSelector } from "react-redux";
import * as siteAction from "../../../../redux/storm-water/actions/siteAction";
import { useLocation } from "react-router-dom";
import SuccessScreen from "../../../../library/custom/success/successScreen";
import Loader from "../../../../library/common/Loader";
import { ADMIN_SITE_PROJECTS_LIST } from "../../../../constant/routeContant";
import { API_STATUS, ORG_ID } from "../../../../constant/constant";
import * as adminAction from "../../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";

function AddSiteContainer() {
  const [successScreens, setIsSuccess] = useState(false);
  const location = useLocation();
  // const [editMode, setEditMode] = useState()
  // useEffect(() => {
  //   setEditMode(location?.state?.editMode)
  // }, [location?.state])

  let editMode = location?.state?.editMode;
  const siteId = location?.state?.siteid;
  const customer = location?.state?.customerName;
  const dispatch = useDispatch();
  const stateData = useSelector((state) => state.state);
  const cityData = useSelector((state) => state.city);

  const addSite = (data, formType) => {
    setIsSuccess(true);
    if (formType === 2) {
      dispatch(siteAction.updateSite(data));
    } else {
      dispatch(siteAction.site(data));
    }
  };

  const showLoader = useSelector(
    (state) =>
      state.siteData.isLoading ||
      state.updateSiteData.isLoading ||
      state.getSiteDetsilsData.isLoading ||
      state.state.isLoading ||
      state.city.isLoading
  );

  const siteData = useSelector((state) => state.siteData);
  const siteDataStatus = useSelector((state) => state.siteData.status);
  const updateSiteData = useSelector((state) => state.updateSiteData);
  const updateSiteDataStatus = useSelector(
    (state) => state.updateSiteData.status
  );
  const getSiteDetsilsData = useSelector((state) => state.getSiteDetsilsData);
  const division = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );

  const getStateData = () => {
    dispatch(stateCityAction.getState());
  };
  const getCity = (stateId) => {
    dispatch(stateCityAction.getCity(stateId));
  };

  useEffect(() => {
    getStateData();
  }, []);

  const getSiteDetsil = (data) => {
    dispatch(siteAction.getSiteDetsils(data));
  };

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "6",
    productId: PRODUCT_ID(),
  };

  useEffect(() => {
    if (editMode && siteId) {
      getSiteDetsil(siteId);
    }
  }, []);

  useEffect(() => {
    setIsSuccess(false);
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);
  const successScreen = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          editMode ? "Site Updated Successfully" : " Site  Added Successfully"
        }
        route={ADMIN_SITE_PROJECTS_LIST}
        label1=" "
        label2=" "
        label3="You will be redirected to"
        label4="Site List"
      ></SuccessScreen>
    );
  };

  const dataBack = {
    title: "Back to Sites",
    route: ADMIN_SITE_PROJECTS_LIST,
  };

  return (
    <>
      {showLoader && <Loader />}
      {siteDataStatus === API_STATUS.SUCCESS &&
      siteData.data &&
      successScreens ? (
        successScreen(siteData?.data?.id)
      ) : updateSiteDataStatus === API_STATUS.SUCCESS &&
        updateSiteData?.data &&
        successScreens ? (
        successScreen(updateSiteData?.data?.id)
      ) : (
        <AddSite
          state={stateData && stateData.data?.getState}
          getCity={getCity}
          city={cityData && cityData.data?.cities}
          addSite={addSite}
          editMode={editMode}
          // setEditMode={setEditMode}
          getSiteDetsilsData={getSiteDetsilsData}
          siteId={siteId}
          dataBack={dataBack}
          customer={customer}
          divisions={division}
        />
      )}
    </>
  );
}

export default AddSiteContainer;
