import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FromToDatePicker from "..//..//..//library/custom/datePicker/DatePicker";
import { Card, CardContent, Box, CardActions } from "@mui/material";
import Buttons from "../../../library/custom/button/Button";
import Grid from "@mui/material/Grid";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import { ASSETTYPE } from "../../../constant/inspectionConstant";
import "./FilterList.scss";
import { INS_STATUS } from "../../../constant/constant";

const FilterList = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleSearch,
  handleClear,
  filterType,
  typeOnChanges,
  typeVaue,
  handleApply,
  typeOnAssetChanges,
  assetTypeId,
  toDateHandler,
  toDate,
  fromDate,
  fromDateHandler,
  typeOnStatusChanges,
  status
}) => {
  const [filter, setFilter] = useState({
    jobsite: "",
    startDate: "",
    endDate: "",
    searchType: "",
  });
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases filter_mui_1">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Box className="" sx={{ pb: 2 }}>
                  <Typography className="filterLabel" gutterBottom variant="h5">
                    Time Period
                  </Typography>

                  <Box className="datePicker_row">
                    <div>
                      <Typography variant="subtitle1">From</Typography>
                      <FromToDatePicker
                        value={fromDate}
                        onChangeHandler={(value) => fromDateHandler(value)}
                      />
                    </div>
                    <div>
                      <Typography variant="subtitle1">To</Typography>
                      <FromToDatePicker
                        value={toDate}
                        onChangeHandler={(value) => toDateHandler(value)}
                      />
                    </div>
                  </Box>
                </Box>
                <Box className="" sx={{ pb: 0.5 }}>
                  <Typography className="filterLabel" gutterBottom variant="h5">
                    Asset Type
                  </Typography>
                </Box>
                <Box className="" sx={{ pb: 2 }}>
                  <SelectMenu
                    name={"assetType"}
                    listData={ASSETTYPE}
                    onchangehandler={(e) => typeOnAssetChanges(e)}
                    value={assetTypeId}
                    placeholder="Please select"
                  />
                </Box>
                <Box className="" sx={{ pb: 0.5 }}>
                  <Typography className="filterLabel" gutterBottom variant="h5">
                    Inspection Type
                  </Typography>
                </Box>
                <Box className="" sx={{ pb: 2 }}>
                  <SelectMenu
                    name={"Category"}
                    listData={filterType}
                    onchangehandler={(e) => typeOnChanges(e)}
                    value={typeVaue}
                    placeholder="Please select"
                  />
                </Box>
                <Box className="" sx={{ pb: 0.5 }}>
                  <Typography className="filterLabel" gutterBottom variant="h5">
                    Status
                  </Typography>
                </Box>
                <Box className="" sx={{ pb: 2 }}>
                  <SelectMenu
                    name={"Status"}
                    listData={INS_STATUS}
                    onchangehandler={(e) => typeOnStatusChanges(e)}
                    value={status}
                    placeholder="Please select"
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Buttons
              primary={true}
              label={"Reset"}
              onClick={(e) => handleClear(e, filter)}
            />
            <Buttons
              primary={true}
              label={"Apply"}
              onClick={(e) => handleApply(e, filter)}
            />
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export default FilterList;
