import React, { useEffect } from "react";
import DrillDown from "../component/drillDown";
import { useLocation } from "react-router-dom";
import Loader from "../../../../library/common/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as incpectionAction from "../../../../redux/actions/incpectionAction";
import { PAGE_SIZE_100 } from "../../../../constant/constant";

const DrillDownContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const drillDetail = location?.state;

  const getInspectionReportAssetDetails = useSelector(
    (state) => state.inspectionReportAssetDetails
  );
  const getInspectionReportAssetDetailsData = (data) => {
    dispatch(incpectionAction.getInspectionReportAssetDetails(data));
  };
  const isLoading = useSelector(
    (state) => state.inspectionReportAssetDetails?.isLoading
  );

  useEffect(() => {
    if (
      (drillDetail && drillDetail.page !== "trendingcategories") ||
      drillDetail.page !== "trendingcategories"
    ) {
      const data = {
        assetType: drillDetail?.apiData?.assetType,
        customAssetType: drillDetail?.apiData?.customAssetType,
        startDate: drillDetail?.apiData?.startDate,
        endDate: drillDetail?.apiData?.endDate,
        divisionId: drillDetail?.apiData?.divisionId,
        assetId: drillDetail?.apiData?.assetId,
        assetCatId: drillDetail?.apiData?.assetCatId,
        reportDetails: drillDetail?.reportDetails,
        page: drillDetail?.apiData?.page ?? 1,
        pageSize: PAGE_SIZE_100,
      };
      getInspectionReportAssetDetailsData(data);
    }
  }, [drillDetail]);

  return (
    <>
      {isLoading && <Loader />}

      <DrillDown
        drillDetail={drillDetail}
        inspectionReportAssetDetails={getInspectionReportAssetDetails?.data}
        getInspectionReportAssetDetailsData={
          getInspectionReportAssetDetailsData
        }
      />
    </>
  );
};

export default DrillDownContainer;
