import React, { useState } from "react";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { TableLoader } from "../../../../library/common/Loader";
import {
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  USER_ID,
  ROW_PER_PAGE_SIZE,
  CLIENT_SATATUS_FILTER,
} from "../../../../constant/constant";
import DialogBox from "../../../../library/common/DialogBox";

import ReportRowManipulte from "../../shared/ReportRowManipulate";
import NORow from "../../../../library/custom/noRow/noRow";
import { useNavigate } from "react-router-dom";
import { ADMIN_ADD_VEHICLES } from "../../../../constant/routeContant";
import { Grid } from "@mui/material";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import FilterListIcon from "@mui/icons-material/FilterList";
import ServiceeFilterList from "../jha/serviceListFilter";

const VehicleTable = ({
  vehicles,
  getVehicleList,
  isLoading,
  filterShow,
  deleteVehicle,
  isProfile = false,
  divisionDropdown,
  isDivisionDropdown = true,
  vehicleCategory,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const [isTableLoading, setisTableLoading] = useState(false);
  const openFilter = Boolean(anchorEl);
  const id = openFilter ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [filterFormValue, setFilterFormValue] = useState({
    category: "",
    isActive: "",
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [openModal, setOpenModal] = useState(false);

  const [selectedRow, setSelectedRow] = useState("");

  const [division, setDivision] = useState(
    divisionDropdown ? divisionDropdown[0]?.key : 0
  );

  const handleEditOnclick = (rows) => {
    navigate(ADMIN_ADD_VEHICLES, {
      state: { id: rows.row.vehicalId, editMode: true },
    });
  };
  const deletehandle = () => {
    setOpenModal(false);
    deleteVehicle(selectedRow);
  };

  const deleteVehicleHandle = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };
  const columns = [
    { field: "vehicalId", headerName: "Vehical ID", flex: 1 },
    { field: "unitNumber", headerName: "Unit Number", flex: 1 },
    { field: "year", headerName: "Year", flex: 1 },
    { field: "makeAndModel", headerName: "Make and Model", flex: 2 },
    { field: "divisionName", headerName: "Division", flex: 1 },
    { field: "categoryValue", headerName: "Category", flex: 2 },
    { field: "vehicalStartDate", headerName: "Start Date", flex: 1.5 },
    { field: "status", headerName: "Status", flex: .75 },

    {
      field: "icon",
      renderHeader: () =>
        filterShow ? (
          <SettingTable
            noOfRecords={vehicles?.itemsCount ?? 0}
            numberOfItemsPerPage={pageSize}
            incidentLists={vehicles?.vehicleList ?? []}
            fileNameXL="vehicleList"
          />
        ) : (
          ""
        ),
      flex: .75,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return filterShow ? (
          <ReportRowManipulte
            rows={rows}
            deleteOnClick={() => {
              deleteVehicleHandle(rows.row.vehicalId);
            }}
            deleted={checkPrivileges([78, 12])}
            edit={checkPrivileges([78, 79, 12])}
            editOnClick={() => handleEditOnclick(rows)}
            deleteLabel="Delete Vehicle"
            editLabel="Edit Vehicle"
          />
        ) : (
          ""
        );
      },
    },
  ];

  const handleautoSearch = (event) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      pageSize: pageSize,
      statusIds: "",
      compnayName: "",
      divisionId: division === 1 ? 0 : division,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      search: searchText ? searchText : "",
      category: filterFormValue.category,
      status: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getVehicleList(data);
  };
  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      page: newPage + 1,
      pageSize: pageSize,
      search: search,
      statusIds: "",
      compnayName: "",
      divisionId: division === 1 ? 0 : division,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      category: filterFormValue.category,
      status: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
    };

    getVehicleList(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      pageSize: newPazeSize,
      search: search,
      page: 1,
      statusIds: "",
      compnayName: "",
      divisionId: division === 1 ? 0 : division,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      category: filterFormValue.category,
      status: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getVehicleList(data);
  };
  const onChangeDivision = (e) => {
    setDivision(e.target.value);
    setSearch("");
    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      statusIds: "",
      compnayName: "",
      divisionId: e.target.value === 1 ? 0 : e.target.value,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      category: filterFormValue.category,
      status: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getVehicleList(data);
    setPage(1);
  };
  function handleFilterClear(params) {
    setFilterFormValue({
      category: "",
      isActive: "",
    });
    setPage(1);
    const data = {
      page: 1,
      pageSize: pageSize,
      statusIds: "",
      compnayName: "",
      divisionId: division === 1 ? 0 : division,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      search: search ? search : "",
      category: "",
      status: "",
    };
    setSearch("");
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getVehicleList(data);
    setAnchorEl(null);
    window.close();
  }

  function handleFilterApply(params) {
    setPage(1);
    const data = {
      page: 1,
      pageSize: pageSize,
      statusIds: "",
      compnayName: "",
      divisionId: division === 1 ? 0 : division,
      userId: isProfile ? parseInt(USER_ID()) : 0,
      organisationId: isProfile ? LOGIN_ORG_ID() : ORG_ID(),
      search: search ? search : "",
      category: filterFormValue.category,
      status: filterFormValue.isActive === 2 ? 0 : filterFormValue.isActive,
    };

    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    getVehicleList(data);
    setAnchorEl(null);
    window.close();
  }
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          {openModal && (
            <DialogBox
              open={openModal}
              rows={[]}
              header={"Confirm Deletion"}
              deletehandle={deletehandle}
              handleCloseModal={() => setOpenModal(false)}
              content={"Are you sure you want to delete this Vehicle?"}
              view
            />
          )}
        </div>
        <div className="grid_main_body divisionWithsearch">
          <Grid container>
            <Grid item lg={4} xs={12} sm={6} md={4} mr={2} pt={3}>
              <div className="filter_of_caselist">
                <div className="searchcaseid">
                  <label htmlFor="search">
                    <span>
                      <SearchIcon />
                    </span>
                    <div>
                      <input
                        id="search"
                        placeholder="Search"
                        type="text"
                        value={search}
                        className=""
                        onChange={handleautoSearch}
                        autoComplete="off"
                      />
                    </div>
                  </label>
                </div>
              </div>
            </Grid>

            <Grid item lg={2} xs={12} sm={6} md={4} mr={2} pt={3}>
              <div className="filter_of_caselist">
                <div className="filter_btn divisionWithFilter">
                  <button
                    aria-describedby={id}
                    variant="contained"
                    type="button"
                    name="btn"
                    onClick={(e) => handleClick(e)}
                  >
                    <FilterListIcon id="icn" /> Filters
                  </button>

                  <ServiceeFilterList
                    id={id}
                    open={openFilter}
                    anchorEl={anchorEl}
                    handleClose={() => {
                      setAnchorEl(null);
                      window.close();
                    }}
                    handleApply={() => handleFilterApply()}
                    firstLabel="Category"
                    secondLabel="Status"
                    firstLebelFilter={vehicleCategory}
                    secondLebelFilter={CLIENT_SATATUS_FILTER}
                    firstLebelValue={filterFormValue.category}
                    secondLebelValue={filterFormValue.isActive}
                    firstLebelOnChanges={(e) =>
                      setFilterFormValue({
                        ...filterFormValue,
                        category: e.target.value,
                      })
                    }
                    secondLebelOnChanges={(e) =>
                      setFilterFormValue({
                        ...filterFormValue,
                        isActive: e.target.value,
                      })
                    }
                    handleClear={() => handleFilterClear()}
                  />
                </div>
              </div>
            </Grid>
            {isDivisionDropdown && (
              <Grid item lg={3} xs={12} sm={4} md={4}>
                <FormControl fullWidth>
                  <FormLabels label="Select Division" isRequired={false} />
                  <SelectMenu
                    disabledDefault={true}
                    listData={divisionDropdown}
                    value={division === 0 ? 1 : division}
                    onchangehandler={(e) => onChangeDivision(e)}
                    isgroupplaceholder={true}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </div>
        {isTableLoading ? (
          ""
        ) : (
          <div className="desc_box row_uniq">
            <div className="table_style_ui">
              <GridTable
                getRowId={(r) => r.vehicalId}
                rows={vehicles?.vehicleList ?? []}
                rowCount={vehicles?.itemsCount ?? 0}
                loading={isLoading}
                columns={columns}
                page={page}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePagignation}
                backIconButtonProps={{ disabled: false }}
                disableSelectionOnClick
                components={{
                  LoadingOverlay: TableLoader,
                  NoRowsOverlay: NORow,
                }}
              />
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default VehicleTable;
