import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const FilterList = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleChange,
  priorityState,
  statusState,
  isAdmin,
  isUser,
  isFeedback,
}) => {
  const { low, medium, high } = priorityState;
  return (
    <div className="overridePopover" style={{ marginTop: "10px" }}>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases filter_mui_1">
          <CardContent>
            {isAdmin && (
              <Grid container p={1}>
                <Grid>
                  <Typography className="filterLabel" gutterBottom >
                    Priority
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={low}
                          onChange={handleChange}
                          name="low"
                        />
                      }
                      label="Low"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={medium}
                          onChange={handleChange}
                          name="medium"
                        />
                      }
                      label="Medium"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={high}
                          onChange={handleChange}
                          name="high"
                        />
                      }
                      label="High"
                    />
                  </FormGroup>
                </Grid>
                <Grid>
                  <Typography className="filterLabel" gutterBottom >
                    Status
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={statusState.openT}
                          onChange={handleChange}
                          name="openT"
                        />
                      }
                      label="Open"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={statusState.closedT}
                          onChange={handleChange}
                          name="closedT"
                        />
                      }
                      label="Closed"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={statusState.inProgress}
                          onChange={handleChange}
                          name="inProgress"
                        />
                      }
                      label="In Progress"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            )}
            {(isUser || isFeedback) && (
              <Box sx={{ display: "flex" }}>
                <FormControl
                  sx={{ m: 3 }}
                  component="fieldset"
                  variant="standard"
                >
                  <Typography className="filterLabel" gutterBottom variant="h5">
                    Priority
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={low}
                          onChange={handleChange}
                          name="low"
                        />
                      }
                      label="Low"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={medium}
                          onChange={handleChange}
                          name="medium"
                        />
                      }
                      label="Medium"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={high}
                          onChange={handleChange}
                          name="high"
                        />
                      }
                      label="High"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            )}
          </CardContent>
        </Card>
      </Popover>
    </div>
  );
};

export default FilterList;
