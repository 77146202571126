import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Incidents from "../component/incidents";
import * as incidentAction from "../../../../redux/actions/incidentAction";
import Loader from "../../../../library/common/Loader";
import * as adminAction from "../../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import { LOGIN_ORG_ID, ORG_ID } from "../../../../constant/constant";

const IncidentsContainer = (props) => {
  const location = useLocation();
  const backDetail = location?.state;

  const dispatch = useDispatch();
  const IncidentReportData = useSelector((state) => state.getIncidentReport);
  const isLoading = useSelector((state) => state.getIncidentReport?.isLoading);
  const getIncidentReportData = (data) => {
    dispatch(incidentAction.getIncidentReport(data));
  };
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const getDivisionData = (data) => {
    dispatch(adminAction.getDropdownDataKeyValue(data));
  };
  const organisationDivision = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const divisionList = userInfo.isClient
    ? organisationDivision
    : LOGIN_ORG_ID() === ORG_ID()
    ? userInfo.divisions?.filter((i) => i?.serviceType == PRODUCT_ID())
    : organisationDivision;

  const incident = useSelector((state) => state.incident.data);
  const getIncidentData = () => {
    dispatch(incidentAction.getIncidentData());
  };
  useEffect(() => {
    getIncidentData();
  }, []);
  return (
    <>
      {isLoading && <Loader />}
      <Incidents
        IncidentReportData={IncidentReportData}
        getIncidentReport={getIncidentReportData}
        tab={props?.tab}
        backDetail={backDetail}
        getDivisionData={getDivisionData}
        divisions={divisionList}
        locationOfRecordData={incident?.locationOfRecord}
      />
    </>
  );
};

export default IncidentsContainer;
