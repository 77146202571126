import React, { useEffect, useState } from "react";
import JhaSetControls from "../component/jha/jhaSetControls";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as jhaAction from "../../../redux/actions/jhaAction";
import { API_STATUS, PAGE_SIZE, USER_ID } from "../../../constant/constant";
import { useSelector } from "react-redux";
import { ADMIN_JHA_EDIT_CONTROLS } from "../../../constant/routeContant";

const JhaSetControlsConatiner = ({
  activityName,
  hazardId,
  hazardName,
  setshowControlPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  // const hazardName = location?.state?.hazardName;
  // const hazardId = location?.state?.hazardId;
  // const activityName = location?.state?.activityName;

  const controlListData = useSelector((state) => state.getJhaControlList);
  const controlTypeListData = useSelector(
    (state) => state.getJhaControlTypeList
  );
  const getJhaControlList = (data) => {
    dispatch(jhaAction.getJhaControlList(data));
  };
  const getJhaControlTypeList = (data) => {
    dispatch(jhaAction.getJhaControlTypeList(data));
  };
  useEffect(() => {
    getJhaControlList({
      search: "",
      page: 1,
      pageSize: PAGE_SIZE,
      hazardId: hazardId,
      status:
        filterFormValue.isActive === 1
          ? true
          : filterFormValue.isActive === 2
          ? false
          : "",
    });
    getJhaControlTypeList({
      search: "",
      page: 1,
      pageSize: PAGE_SIZE,
    });
  }, [hazardId]);

  const [controlTypeList, setcontrolTypeList] = useState([]);
  function mapControlTypeKeyValue() {
    let arr = [];
    controlTypeListData?.data?.result?.itemList.map((i) =>
      arr.push({
        key: i.id,
        value: i.controlTypeName,
      })
    );
    setcontrolTypeList(arr);
  }

  useEffect(() => {
    if (
      controlTypeListData.status === API_STATUS.SUCCESS &&
      controlTypeListData?.isLoading === false
      // clearalert
    ) {
      mapControlTypeKeyValue();
    }
  }, [controlTypeListData]);

  const isLoading = useSelector((state) => state.getJhaControlList?.isLoading);
  // add
  const [formValue, setformValue] = useState({
    controlsName: "",
    isGlobal: false,
    hazardId: 0,
    controlTypeId: 0,
    createdBy: parseInt(USER_ID()),
  });

  const [filterFormValue, setFilterFormValue] = useState({
    isActive: "",
  });

  const addJhaControlData = useSelector((state) => state?.addJhaControl);
  function addJhaControl(data) {
    dispatch(jhaAction.addJhaControl(formValue));
    setformValue({
      ...formValue,
      controlsName: "",
      controlTypeId: 0,
    });
  }
  useEffect(() => {
    if (
      addJhaControlData.status === API_STATUS.SUCCESS &&
      addJhaControlData?.isLoading === false
      // clearalert
    ) {
      // toast("Control Added successfully");
      getJhaControlList({
        search: "",
        page: 1,
        pageSize: PAGE_SIZE,
        hazardId: hazardId,
        status:
          filterFormValue.isActive === 1
            ? true
            : filterFormValue.isActive === 2
            ? false
            : "",
      });
    }
  }, [addJhaControlData]);

  // edit
  const updateJhaControlData = useSelector((state) => state?.updateJhaControl);
  function handleStatusChange(data) {
    let newData = {
      controlsName: data.controlsName,
      isGlobal: false,
      controlId: data.id,
      controlTypeId: data.controlTypeId,
      isActive: data.isActive,
      modifiedBy: parseInt(USER_ID()),
    };
    dispatch(jhaAction.updateJhaControl(newData));
  }

  function handleEditOnClick(data) {
    navigate(ADMIN_JHA_EDIT_CONTROLS, {
      state: {
        data: data,
      },
    });
  }

  useEffect(() => {
    if (
      updateJhaControlData.status === API_STATUS.SUCCESS &&
      updateJhaControlData?.isLoading === false
      // clearalert
    ) {
      // toast("Hazard Updated successfully");
      getJhaControlList({
        search: "",
        page: 1,
        pageSize: PAGE_SIZE,
        hazardId: hazardId,
        status:
          filterFormValue.isActive === 1
            ? true
            : filterFormValue.isActive === 2
            ? false
            : "",
      });
    }
  }, [updateJhaControlData]);

  return (
    <div>
      <JhaSetControls
        hazardName={hazardName}
        hazardId={hazardId}
        activityName={activityName}
        controlListData={controlListData?.data?.result?.itemList ?? []}
        itemsCount={controlListData?.data?.result?.itemsCount ?? 0}
        isLoading={isLoading}
        formValue={formValue}
        setformValue={setformValue}
        addControl={addJhaControl}
        handleStatusChange={handleStatusChange}
        handleEditOnClick={handleEditOnClick}
        getJhaControlList={getJhaControlList}
        controlTypeList={controlTypeList}
        setshowControlPage={setshowControlPage}
        filterFormValue={filterFormValue}
        setFilterFormValue={setFilterFormValue}
      />
    </div>
  );
};

export default JhaSetControlsConatiner;
