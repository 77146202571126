import React, { useEffect, useState } from "react";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import AddSubCategory from "../component/inspection/addSubCategory";
import { ToastContainer, toast } from "react-toastify";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";

const InspectionSubCategoriesContainer = () => {
  const dispatch = useDispatch();
  const defaultData = {
    searchText: "",
    organisationId: ORG_ID(),
    page: 1,
    pageSize: PAGE_SIZE,
    allowPaging: true,
  };
  const getSubCategoriesList = useSelector(
    (state) => state.getSubCategoriesList
  );
  const getSubCategoriesListData = (data) => {
    dispatch(incpectionAction.getSubCategoriesList(data));
  };

  const adminAddUpdateSubcategory = useSelector(
    (state) => state.adminAddUpdateSubcategory
  );

  const isLoading = useSelector(
    (state) => state.getSubCategoriesList?.isLoading
  );

  const showLoader = useSelector(
    (state) => state.adminAddUpdateSubcategory?.isLoading
  );
  const [clearCategory, setClearCategory] = useState(false);

  const onSubmit = (data) => {
    setClearCategory(true);
    dispatch(incpectionAction.adminAddUpdateSubcategory(data));
  };

  useEffect(() => {
    dispatch(incpectionAction.clearAdminAddUpdateSubcategory());
    getSubCategoriesListData(defaultData);
  }, []);

  useEffect(() => {
    if (
      adminAddUpdateSubcategory?.status === "SUCCESS" &&
      adminAddUpdateSubcategory?.isLoading === false &&
      clearCategory
    ) {
      toast("Sub Category Added successfully");
    }
  }, [adminAddUpdateSubcategory]);

  return (
    <>
      {showLoader && <Loader />}

      <AddSubCategory
        getSubCategoriesList={
          isLoading ? [] : getSubCategoriesList?.data?.keyValues
        }
        recordsCount={getSubCategoriesList?.data?.recordsCount}
        onSubmit={onSubmit}
        getSubCategoriesListData={getSubCategoriesListData}
        adminAddUpdateSubcategory={adminAddUpdateSubcategory}
        isLoading={isLoading}
      />
      <ToastContainer />
    </>
  );
};

export default InspectionSubCategoriesContainer;
