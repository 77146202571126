import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FromToDatePicker from "../../../library/custom/datePicker/DatePicker";
import {
  Card,
  CardContent,
  Box,
  CardActions,
  FormControl,
} from "@mui/material";
import Buttons from "../../../library/custom/button/Button";
import Grid from "@mui/material/Grid";
import { getFormatedDate } from "../../../utils/helper";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";

import {
  INCIDENT_STATUS,
  INCIDENT_STATUS_Key_VALUE,
  INCIDENT_STATUS_VALUE,
} from "../../../constant/constant";
import commonImages from "../../../assets";
import "./FilterIncidentList.scss";
const FilterIncidentList = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleSearch,
  filterType,
  resetFilterAPI,
  setAnchorEl,
  singleSelect = false,
  singleCheckbox = true,
}) => {
  const [filter, setFilter] = useState({
    jobsite: "",
    startDate: "",
    endDate: "",
    searchType: "",
    incidentType: "",
    status: "",
  });

  const resetFilter = () => {
    setFilter({
      jobsite: "",
      startDate: null,
      endDate: null,
      searchType: "",
      incidentType: "",
      status: "",
    });
    setAnchorEl(null);
    resetFilterAPI();
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases filter_mui_1">
          <CardContent>
            <Grid container spacing={2}>
              {singleCheckbox && (
                <Grid item md={singleSelect ? 12 : 4}>
                  <Box>
                    <FormGroup>
                      {filterType &&
                        filterType.map((item) => (
                          <FormControlLabel
                            key={item.id}
                            control={
                              <img
                                className="filterListCheckbox"
                                checked={
                                  item.id == filter.incidentType ? true : false
                                }
                                alt=""
                                src={
                                  item.id == filter.incidentType
                                    ? commonImages.check
                                    : commonImages.noCheck
                                }
                                onClick={() =>
                                  setFilter((prevState) => ({
                                    ...prevState,
                                    incidentType: item.id,
                                  }))
                                }
                              />
                            }
                            label={item?.title}
                          />
                        ))}
                    </FormGroup>
                  </Box>
                </Grid>
              )}

              <Grid item md={singleSelect ? 12 : 7}>
                <Box className="" sx={{ pb: 2 }}>
                  <Typography className="filterLabel" gutterBottom variant="h5">
                    Time Period
                  </Typography>
                  <Box className="datePicker_row">
                    <div>
                      <Typography variant="subtitle1">From</Typography>
                      <FromToDatePicker
                        value={filter.startDate}
                        onChangeHandler={(value) =>
                          setFilter((prevState) => ({
                            ...prevState,
                            startDate: getFormatedDate(value),
                          }))
                        }
                      />
                    </div>
                    <div>
                      <Typography variant="subtitle1">To</Typography>
                      <FromToDatePicker
                        value={filter.endDate}
                        onChangeHandler={(value) =>
                          setFilter((prevState) => ({
                            ...prevState,
                            endDate: getFormatedDate(value),
                          }))
                        }
                      />
                    </div>
                  </Box>
                </Box>
                {singleSelect && (
                  <Box className="" sx={{ pb: 2 }}>
                    <FormControl fullWidth>
                      <FormLabels label="Incident Type" isRequired={false} />
                      <SelectMenu
                        placeholder="Please Select"
                        listData={filterType}
                        value={filter.incidentType}
                        onchangehandler={(e) =>
                          setFilter((prevState) => ({
                            ...prevState,
                            incidentType: e.target.value,
                          }))
                        }
                      />
                    </FormControl>
                  </Box>
                )}

                <Box className="" sx={{ pb: 2 }}>
                  {singleSelect && (
                    <FormControl fullWidth>
                      <FormLabels label="Status" isRequired={false} />
                      <SelectMenu
                        placeholder="Please Select"
                        listData={INCIDENT_STATUS_VALUE}
                        value={filter.status}
                        onchangehandler={(e) =>
                          setFilter((prevState) => ({
                            ...prevState,
                            status: e.target.value,
                          }))
                        }
                      />
                    </FormControl>
                  )}

                  {singleCheckbox && (
                    <FormGroup>
                      {INCIDENT_STATUS_Key_VALUE.map((filterItem, index) => (
                        <FormControlLabel
                          key={filterItem.Value}
                          control={
                            <img
                              className="filterListCheckbox"
                              checked={
                                filter.status == filterItem.value ? true : false
                              }
                              alt=""
                              src={
                                filter.status == filterItem.value
                                  ? commonImages.check
                                  : commonImages.noCheck
                              }
                              onClick={() =>
                                setFilter((prevState) => ({
                                  ...prevState,
                                  status: filterItem.value,
                                }))
                              }
                            />
                          }
                          label={filterItem.key}
                        />
                      ))}
                    </FormGroup>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Buttons primary={true} label={"Reset"} onClick={resetFilter} />
            <Buttons
              primary={true}
              label={"Apply"}
              onClick={(e) => handleSearch(e, filter)}
            />
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export default FilterIncidentList;
