import React from "react";
import Grid from "@mui/material/Grid";
import "./backBtn.scss";
import { useNavigate } from "react-router-dom";
import commonImages from "../../../assets";

const BackBtn = (props) => {
  const navigate = useNavigate();

  return (
    <Grid
      className="backBtn"
      onClick={() =>
        navigate(props?.dataBack?.route, {
          state: props?.dataBack?.state
            ? props?.dataBack?.state
            : {
                backTabId: props?.dataBack?.backTabId ?? "",
                projectName: props?.dataBack?.projectName,
                projectNumber: props?.dataBack?.projectNumber,
                projectid: props?.dataBack?.projectid,
                customerName: props?.dataBack?.customerName,
                editMode: props?.dataBack?.editMode
                  ? props?.dataBack?.editMode
                  : false,
                islesson: props?.dataBack?.islesson,
                iscourseNavigatePage:
                  props?.dataBack?.iscourseNavigatePage ?? null,
              },
        })
      }
    >
      <Grid className="backImg">
        <img src={commonImages?.backBtn} alt="" />
      </Grid>
      <Grid className="backTitle">{props?.dataBack?.title}</Grid>
    </Grid>
  );
};

export default BackBtn;
