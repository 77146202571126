import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./customTable.scss";
import { TableLoader } from "../../../library/common/Loader";
import { PAGE_SIZE } from "../../../constant/constant";
import NORow from "../../../library/custom/noRow/noRow";

const ControltableData = (props) => {
  const {
    getRowId,
    rows,
    columns,
    page,
    onPageChange,
    rowCount,
    isLoading,
    handleOnCellClick,
  } = props;

  return (
    <>
      <div className="tableData">
        <DataGrid
          getRowId={getRowId}
          rows={rows ?? []}
          columns={columns}
          page={page - 1}
          pageSize={PAGE_SIZE}
          onPageChange={onPageChange}
          paginationMode="server"
          rowCount={rowCount}
          IsReadOnly="True"
          backIconButtonProps={{ disabled: false }}
          loading={isLoading}
          getRowClassName={(params) => "customrowTable"}
          getColumnClassName={(params) => "customheaderTable"}
          headerClassName={"hideRightSeparator"}
          getEstimatedRowHeight={() => 100}
          getRowHeight={() => "auto"}
          onCellClick={handleOnCellClick}
          components={{
            LoadingOverlay: TableLoader,
            NoRowsOverlay: NORow,
          }}
          disableSelectionOnClick={true}
          disableColumnSelector={true}
          disableColumnFilter={true}
          disableColumnMenu={true}
        />
      </div>
    </>
  );
};

export default ControltableData;
