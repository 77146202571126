import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../library/common/Loader";
import { useLocation } from "react-router-dom";
import ViewOpenItemReport from "./viewOpenItemReport";
import * as viewPersonalInjuryAction from "../../redux/actions/perosnalInjuryAction";
import * as viewNearMissAction from "../../redux/actions/nearMissesAction";
import * as viewVehicleAccidentAction from "../../redux/actions/vehicalAccidentAction";
import * as viewStolenPropertyAction from "../../redux/actions/stolenPropertyAction";
import * as viewUtilityDamageAction from "../../redux/actions/utilityDamageAction";
import * as observationAction from "../../redux/actions/observationAction";
import * as reprimandAction from "../../redux/actions/reprimandAction";
import * as incidentAction from "../../redux/actions/incidentAction";
import * as incpectionAction from "../../redux/actions/incpectionAction";
import SuccessScreen from "../../library/custom/success/successScreen";
import { OPEN_ITEMS_LIST } from "../../constant/routeContant";
import { GET_ITEM_LOG } from "../../constant/constant";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "../../../src/feature/observation/view-observation-report/viewObservationReport.scss";

const ViewOpenItemReportContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const origin = location?.state?.origin;
  const pageTitle = location?.state?.title;
  const actionCaseId = location?.state?.rootId;
  const actionIncidentId = location?.state?.subRootId;
  const actionItemTypeId = location?.state?.itemTypeId;
  const actionReportId = location?.state?.id;
  const actionStatus = location?.state?.status;
  const itemType = location?.state?.itemType;
  const addedById = location?.state?.addedById;
  const involvedPartyName = location?.state?.involvedPartyName;
  const involvedPartyId = location?.state?.involvedPartyId;
 
  const observationInvolvedPartyID = location?.state?.assignedTo;
  const reprimandId = location?.state?.id;
  const inspectionId = location?.state?.id;
  const kudosStatus = location?.state?.status;
  const reprimandDetails = useSelector(
    (state) =>
      state?.reprimand?.editReprimandData?.inspectionAndReprimandDetails
  );
  const observationId = location?.state?.id;
  const observationType = location?.state?.itemType;
  const backRoute = location?.state?.backRoute;
  const backRouteText = location?.state?.backRouteText;
  const addedBy = location?.state?.addedBy;
  const statusReport = location?.state?.status;

  const ReprimandViolationCount = useSelector((state) => state?.reprimandCount);
  const stolenPropertyData = useSelector(
    (state) => state.getLossPropertyReport?.data
  );
  const InspectionOpenItemDetail = useSelector(
    (state) => state?.inspectionOpenItemDetail?.data
  );

  const CloseInspectionOpenItem = useSelector(
    (state) => state?.closeInspectionOpenItem
  );
  const observationStatusLog = location?.state?.status;

  const inspectionReprimandDetails = {
    id: reprimandId,
    type: GET_ITEM_LOG.Reprimand,
  };

  const inspectionKudosDetails = {
    id: reprimandId,
    type: GET_ITEM_LOG.Kudos,
  };

  const inspectionActionDetails = {
    id: reprimandId,
    type: GET_ITEM_LOG.Action,
  };

  const inspectionCorrectiveDetails = {
    id: inspectionId,
    type: GET_ITEM_LOG.Corrective,
  };

  function closeAction(data) {
    setIsSuccess(true);
    dispatch(incidentAction.closeActionItem(data));
  }

  function getCount(data) {
    dispatch(incpectionAction.getInspectionViolationCounts(data));
  }

  function closeInspectionCorrective(data) {
    setIsSuccess(true);
    dispatch(incpectionAction.CloseInspectionOpenItem(data));
  }

  function closeInspectionReprimand(data) {
    setIsSuccess(true);
    dispatch(incpectionAction.CloseInspectionOpenItem(data));
  }

  function closeReprimandReprimand(data) {
    setIsSuccess(true);
    dispatch(incpectionAction.postReprimandToClose(data));
  }

  const updateReprimandToClose = useSelector(
    (state) => state?.updateReprimandToClose
  );

  const inspectionViolationCounts = useSelector(
    (state) => state.getInspectionViolationCounts
  );

  function closeInspectionAction(data) {
    setIsSuccess(true);
    dispatch(incpectionAction.CloseInspectionOpenItem(data));
  }

  const openItemUpdatedStatus = useSelector(
    (state) => state.closeActionItem.status
  );

  const reprimandToCloseStatus = useSelector(
    (state) => state?.updateReprimandToClose?.status
  );

  const inspectionOpenItemStatus = useSelector(
    (state) => state.closeInspectionOpenItem.status
  );
  const [successScreens, setIsSuccess] = useState(false);
  const nearMissData = useSelector((state) => state.getNearMissReport?.data);
  const closeOpenItem = useSelector((state) => state.closeActionItem);
  const updateViolation = useSelector((state) => state?.updateObservation);
  const personalReportData = useSelector(
    (state) => state?.getPersonalReport?.data
  );

  const vehicleAccidentData = useSelector(
    (state) => state.getAccidentReport?.data
  );

  const utilityDamageData = useSelector(
    (state) => state.getUtilityDamageReport?.data
  );

  const observationReportData = useSelector(
    (state) => state?.getObservationReport?.data
  );

  function closeViolation(data) {
    setIsSuccess(true);
    dispatch(observationAction.getObservationUpdatePending(data));
  }
  const violationUpdatedStatus = useSelector(
    (state) => state.updateObservation.status
  );
  useEffect(() => {
    if (origin === "Incidents") {
      if (actionItemTypeId === 1) {
        dispatch(
          viewPersonalInjuryAction.getPersonalReportDetails(
            actionIncidentId,
            actionCaseId
          )
        );
      }
      if (actionItemTypeId === 4) {
        dispatch(
          viewUtilityDamageAction.getUtilityDamageDetails(
            actionIncidentId,
            actionCaseId
          )
        );
      }

      if (actionItemTypeId === 2) {
        dispatch(
          viewVehicleAccidentAction.getVehicalAccidentDetails(
            actionIncidentId,
            actionCaseId
          )
        );
      }
      if (actionItemTypeId === 3) {
        dispatch(
          viewStolenPropertyAction.getStolenProperty(
            actionIncidentId,
            actionCaseId
          )
        );
      }
      if (actionItemTypeId === 5) {
        dispatch(
          viewNearMissAction.getNearMisses(actionIncidentId, actionCaseId)
        );
      }
    }
    if (observationId && observationType && origin === "Observations") {
      dispatch(observationAction.getObservtionDetails(observationId));
    }

    if (
      reprimandId &&
      origin === "Reprimand" &&
      observationType === "Reprimand"
    ) {
      dispatch(reprimandAction.getReprimandDetails(reprimandId));
    }
    if (
      reprimandId &&
      origin === "Inspections" &&
      origin === "Inspections" &&
      observationType === "Kudos"
    ) {
      dispatch(
        incpectionAction.getInspectionOpenItemDetail(inspectionKudosDetails)
      );
    }
    if (observationType === "Action" && origin === "Inspection") {
      dispatch(
        incpectionAction.getInspectionOpenItemDetail(inspectionActionDetails)
      );
    }
    if (
      reprimandId &&
      origin === "Inspection" &&
      observationType === "Reprimand"
    ) {
      dispatch(
        incpectionAction.getInspectionOpenItemDetail(inspectionReprimandDetails)
      );
    }
    if (
      inspectionId &&
      origin === "Inspection" &&
      observationType === "Corrective"
    ) {
      dispatch(
        incpectionAction.getInspectionOpenItemDetail(
          inspectionCorrectiveDetails
        )
      );
    }

    if (
      inspectionId &&
      origin === "Inspections" &&
      observationType === "Corrective"
    ) {
      dispatch(
        incpectionAction.getInspectionOpenItemDetail(
          inspectionCorrectiveDetails
        )
      );
    }
  }, [dispatch]);

  const showLoader = useSelector(
    (state) =>
      state?.reprimand?.isLoading ||
      state?.getObservationReport.isLoading ||
      state?.getPersonalReport?.isLoading ||
      state?.getAccidentReport?.isLoading ||
      state?.getUtilityDamageReport?.isLoading ||
      state?.getNearMissReport?.isLoading ||
      state?.getLossPropertyReport?.isLoading ||
      state?.closeActionItem?.isLoading ||
      state?.updateObservation?.isLoading ||
      state?.inspectionOpenItemDetail?.isLoading ||
      state?.closeInspectionOpenItem?.isLoading ||
      state?.reprimandCount?.isLoading ||
      state?.getInspectionViolationCounts?.isLoading ||
      state?.updateReprimandToClose?.isLoading
  );

  const successScreen = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={"Open Item Closed Successfully"}
        route={OPEN_ITEMS_LIST}
        label1=" "
        label3=" "
        label2=" "
      ></SuccessScreen>
    );
  };
  const successScreen1 = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={"Open Item Closed Successfully"}
        route={OPEN_ITEMS_LIST}
        label1=" "
        label3=" "
        label2=" "
      ></SuccessScreen>
    );
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "View Open Item Report",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
  });
  return (
    <>
      {showLoader && <Loader />}
      <div className="viewOpenItemConainer">
        <div className="titleViewOpenItem">View Open Item</div>
        <div className="DownloadButtonViewItem">
          <Button
            onClick={handlePrint}
            variant="contained"
            startIcon={<DownloadIcon className="downloadIcon" />}
            className="downloadpdf"
          >
            Download as PDF
          </Button>
        </div>
      </div>
      {openItemUpdatedStatus === "SUCCESS" &&
      closeOpenItem &&
      successScreens ? (
        successScreen(closeOpenItem?.actionId)
      ) : violationUpdatedStatus === "SUCCESS" &&
        updateViolation &&
        successScreens ? (
        successScreen1(updateViolation?.data?.id)
      ) : inspectionOpenItemStatus === "SUCCESS" &&
        CloseInspectionOpenItem &&
        successScreens ? (
        successScreen1(CloseInspectionOpenItem?.data?.id)
      ) : reprimandToCloseStatus === "SUCCESS" &&
        updateReprimandToClose &&
        successScreens ? (
        successScreen1(updateReprimandToClose?.data?.id)
      ) : (
        <div ref={componentRef} className="overideView">
          <ViewOpenItemReport
            actionCaseId={actionCaseId}
            actionIncidentId={actionIncidentId}
            actionItemTypeId={actionItemTypeId}
            actionReportId={actionReportId}
            stolenPropertyData={stolenPropertyData}
            nearMissData={nearMissData}
            personalReportData={personalReportData}
            vehicleAccidentData={vehicleAccidentData}
            utilityDamageData={utilityDamageData}
            reprimandDetails={reprimandDetails}
            reprimandId={reprimandId}
            observationType={observationType}
            observationReportData={observationReportData}
            ReprimandViolationCount={ReprimandViolationCount}
            observationID={observationId}
            pageTitle={pageTitle}
            closeAction={closeAction}
            actionStatus={actionStatus}
            observationStatusLog={observationStatusLog}
            kudosStatus={kudosStatus}
            closeViolation={closeViolation}
            origin={origin}
            InspectionOpenItemDetail={
              InspectionOpenItemDetail?.inspectionAndReprimandDetails
            }
            itemType={itemType}
            closeInspectionCorrective={closeInspectionCorrective}
            closeInspectionAction={closeInspectionAction}
            closeInspectionReprimand={closeInspectionReprimand}
            inspectionViolationCounts={inspectionViolationCounts}
            addedById={addedById}
            closeReprimandReprimand={closeReprimandReprimand}
            inspectionId={inspectionId}
            getCount={getCount}
            observationInvolvedPartyID={observationInvolvedPartyID}
            involvedPartyName={involvedPartyName}
            involvedPartyId={involvedPartyId}
            backRoute={backRoute}
            backRouteText={backRouteText}
            addedBy={addedBy}
            statusReport={statusReport}
          />
        </div>
      )}
    </>
  );
};

export default ViewOpenItemReportContainer;
