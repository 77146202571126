import React from "react";
import { Grid } from "@mui/material";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import Card from "../../../../library/custom/cardList/card";
import { USERSEARCHMANAGEMENT } from "../../../../constant/constant";

function UserSerchMangementList({ onClick }) {
  return (
    <>
      <Grid container className="CardListContaner">
        {checkPrivileges([12, 148]) && (
          <>
            <Card
              iconShow={false}
              onClick={onClick}
              imgShow={true}
              cardDetail={USERSEARCHMANAGEMENT[0]}
            />

            <Card
              iconShow={false}
              onClick={onClick}
              imgShow={true}
              cardDetail={USERSEARCHMANAGEMENT[1]}
            />
          </>
        )}
      </Grid>
    </>
  );
}

export default UserSerchMangementList;
