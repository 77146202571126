import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import { GET_ITEM_LOG, PAGE_SIZE_100 } from "../../../../constant/constant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import DialogBox from "../../../../library/common/DialogBox";
import {
  downloadExcel,
  downloadPDF,
  getAssetTypeValue,
  getFilesForValue,
  useInfiniteScroll,
} from "../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import {
  REPORTS_ANALYTICS_DASHBOARD,
  REPORTS_ANALYTICS_DASHBOARD_Detail,
  VIEW_OPEN_ITEMS_DETAILS,
} from "../../../../constant/routeContant";
import commonImages from "../../../../assets";
import { Popover } from "@mui/material";

const Items = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("asc");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const assetType = props?.propsData?.drillDetail?.apiData?.assetType;

  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (props?.items) {
      setData((prevData) => [...prevData, ...props.items]);
    }
  }, [props?.items]);

  const fetchMoreData = async (pageNumber) => {
    const data = {
      userId: props?.userId ?? 0,
      search: "",
      page: pageNumber,
      pageSize: PAGE_SIZE_100,
      projectId: props?.propsData?.assetId ?? "",
      divisionId: 0,
      itemTypes: props?.propsData?.itemLog
        ? props?.propsData?.itemLog
        : GET_ITEM_LOG.All,
      statusIds: "",
      fromDate:
        props?.propsData?.previous === "previous"
          ? props?.propsData?.drillDetail?.apiData?.previousStartDate
          : props?.propsData?.drillDetail?.apiData?.startDate,
      toDate:
        props?.propsData?.previous === "previous"
          ? props?.propsData?.drillDetail?.apiData?.previousEndDate
          : props?.propsData?.drillDetail?.apiData?.endDate,
      status: "",
      assetType: props?.propsData?.drillDetail?.apiData?.assetType,
      reportDetails: props?.propsData?.drillDetail?.reportDetails,
      categoryId: props?.propsData?.categoryId ?? "",
      subCategoryId: props?.propsData?.subCategoryId ?? "",
      origin: props?.propsData?.origin ?? "",
      assetCatId: props?.propsData?.drillDetail?.apiData?.assetCatId ?? "",
    };
    try {
      const response = await props.getItemLogAllData(data);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    const moreData = await fetchMoreData(pageNumber);
    setPage(pageNumber);
    setData((prevData) => [...prevData, ...moreData]);
  };

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };

  const handleOnClick = (id, origin, rootId) => {
    setOpenModal(true);
    const data = {
      rootId: rootId,
      id: id,
      filesFor: getFilesForValue(origin),
    };
    props?.getOpenItemPhotosData(data);
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleViewOnClickCorrective = (row) => {
    if (row?.itemType === "Corrective") {
      navigate(VIEW_OPEN_ITEMS_DETAILS, {
        state: {
          id: row?.id,
          itemType: row?.itemType,
          title: row?.title,
          status: row?.status,
          origin: row?.origin,
          addedById: row?.addedById,
          addedBy: row?.addedBy,
          assignedTo: row?.assignedTo,
          involvedPartyName: row?.involvedPartyName,
          involvedPartyId: row?.involvedPartyId,
          backRoute: -1,
          backRouteText: "Back to Reports & Analytics",
        },
      });
    } else {
      navigate(VIEW_OPEN_ITEMS_DETAILS, {
        state: {
          id: row?.id,
          itemType: row?.itemType,
          title: row?.title,
          origin: row?.origin,
          status: row?.status,
          addedById: row?.addedById,
          addedBy: row?.addedBy,
          involvedPartyName: row?.involvedPartyName,
          involvedPartyId: row?.involvedPartyId,
          backRoute: -1,
          backRouteText: "Back to Reports & Analytics",
        },
      });
    }
  };

  useEffect(() => {
    setData([]);
  }, []);

  const columns = [
    {
      id: "id",
      label: "Item ID",
      sortable: true,
      isSorted: sortColumn === "id",
      sortDirection,
    },
    { id: "blank1", label: "View Photo" },
    {
      id: "jobsite",
      label:
        assetType === 2
          ? "Make And Model And Serial Number"
          : `${getAssetTypeValue(assetType)} Name`,
      sortable: true,
      isSorted: sortColumn === "jobsite",
      sortDirection,
    },
    {
      id: "origin",
      label: "Origin",
      sortable: true,
      isSorted: sortColumn === "origin",
      sortDirection,
    },
    {
      id: "status",
      label: "Item Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
    },
    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
    },
    { id: "blank2", label: "" },
  ];

  const rows = data.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset.id}</td>
      <td>
        <span
          className="link"
          onClick={() => handleOnClick(asset.id, asset.origin, asset.rootId)}
        >
          {"View Photo"}
        </span>
      </td>
      <td>{asset.jobsite}</td>
      <td>{asset.origin}</td>
      <td>{asset.status}</td>
      <td>{asset.dateOfEntry}</td>
      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClickCorrective(asset)}
          viewLabel={`View ${asset.itemType}`}
        />
      </td>
    </tr>
  ));

  const rowsPdf = data.map((asset) => [
    asset.id,
    asset.jobsite,
    asset.origin,
    asset.status,
    asset.dateOfEntry,
  ]);

  const columnsPdf = [
    {
      id: "id",
      label: "Item ID",
    },
    {
      id: "jobsite",
      label: `${getAssetTypeValue(assetType)} Name`,
    },
    {
      id: "origin",
      label: "Origin",
    },
    {
      id: "status",
      label: "Item Status",
    },
    {
      id: "dateOfEntry",
      label: "Date of Entry",
    },
  ];

  const dataBack = {
    title: "Back",
    route: props?.propsData?.page === "dashboardIns"
      ? REPORTS_ANALYTICS_DASHBOARD
      : REPORTS_ANALYTICS_DASHBOARD_Detail,
    state: {
      page: props?.propsData?.drillDetail?.page,
      apiData: props?.propsData?.drillDetail?.apiData,
      reportDetails: props?.propsData?.drillDetail?.reportDetails,
      alldata: props?.propsData?.drillDetail?.alldata,
      trendPassData: props?.propsData?.drillDetail?.trendData,
      tab: props?.propsData?.drillDetail?.tab,
      backpage: "backpage",
    },
  };

  return (
    <>
      <div>
        <BackBtn dataBack={dataBack} />
      </div>

      <div className="topHeaderDrill">
        <div className="topHeaderDrillLeft">
          <div className="title">
            Number of {props?.propsData?.itemTypeValue} Items
          </div>
        </div>
        <div className="topHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadPDF(
                        rowsPdf,
                        columnsPdf.map((col) => col.label),
                        `${props?.propsData?.itemTypeValue}.pdf`
                      )
                    }
                  >
                    <img src={commonImages.pdfFill} alt={""} />
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadExcel(
                        data,
                        `${props?.propsData?.itemTypeValue}.xlsx`
                      )
                    }
                  >
                    <img src={commonImages.excelDownload} alt={""} />
                    <span>Download .xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>

      {openModal && (
        <DialogBox
          open={openModal}
          rows={[]}
          view
          deleteView={false}
          photoView={true}
          handleCloseModal={handleCloseModal}
          photos={props?.getOpenItemPhotos?.files}
        />
      )}

      <div className="customTableWrapper">
        <Table
          columns={columns}
          rows={rows}
          tableRef={tableRef}
          onSort={handleSort}
        />
      </div>
    </>
  );
};

export default Items;
