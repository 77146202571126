import React, { useEffect } from "react";
import ItemIncidents from "../component/itemIncidents";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as incidentAction from "../../../../redux/actions/incidentAction";
import { PAGE_SIZE_100 } from "../../../../constant/constant";
import Loader from "../../../../library/common/Loader";

const ItemsIncidentContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const propsItemsData = location?.state;

  const incidentReportInjuredBodyPartList = useSelector(
    (state) => state?.incidentReportInjBodyPartList
  );
  const incidentReportMostCommonInjuryList = useSelector(
    (state) => state?.incidentReportMostCommonInjList?.data?.result
  );

  const incUserMostIncidentLists = useSelector(
    (state) => state?.incUserMostIncidentList?.data?.result
  );
  const incUsrMostActionItemLists = useSelector(
    (state) => state?.incUsrMostActionItemList?.data?.result
  );
  const projectwithmostincidentsLists = useSelector(
    (state) => state?.projectwithmostincidentsList?.data?.result
  );
  const vehiclewithmostincidentsLists = useSelector(
    (state) => state?.vehiclewithmostincidentsList?.data?.result
  );
  useEffect(() => {
    let data = {
      page: 1,
      pageSize: PAGE_SIZE_100,
      divisionId: propsItemsData?.drillDetail?.divisionId,
      locationId: propsItemsData?.drillDetail?.locationId,
      fromDate: propsItemsData?.drillDetail?.startDate,
      toDate: propsItemsData?.drillDetail?.endDate,
      //incidentType: propsItemsData?.drillDetail?.incidentType,
      incidentType:
        propsItemsData?.drillDetail?.incidentType > 0
          ? propsItemsData?.drillDetail?.incidentType
          : 0,
    };
    if (propsItemsData?.drillDetailFull?.page === "most_injured_body_part") {
      data.bodyPartId = propsItemsData?.bodyPartId;
      getinjuryBodyPartList(data);
    } else if (propsItemsData?.drillDetailFull?.page === "most_common_injury") {
      data.InjuryId = propsItemsData?.InjuryId;
      getMostCommonInjuryList(data);
    } else if (propsItemsData?.itemTypeValue === "vehicle_most_incident") {
      data.VehicleId = propsItemsData?.VehicleId;
      getVehicleMostIncidentList(data);
    } else if (propsItemsData?.itemTypeValue === "project_most_incident") {
      data.ProjectId = propsItemsData?.ProjectId;
      getProjectMostIncidentList(data);
    } else if (propsItemsData?.itemTypeValue === "user_most_incident") {
      data.userId = propsItemsData?.userId;
      getUserwithMostIncidentList(data);
    } else if (propsItemsData?.itemTypeValue === "user_most_action") {
      data.userId = propsItemsData?.userId;
      getUserwithMostItemList(data);
    }
  }, []);

  const getinjuryBodyPartList = (data) => {
    dispatch(incidentAction.getIncidentReportInjuredBodyPartList(data));
  };
  const getMostCommonInjuryList = (data) => {
    dispatch(incidentAction.getIncidentReportMostCommonInjuryList(data));
  };

  const getUserwithMostIncidentList = (data) => {
    dispatch(incidentAction.getIncidentUserWithMostNoOfIncidentList(data));
  };
  const getUserwithMostItemList = (data) => {
    dispatch(incidentAction.getIncidentUserWithMostNoOfActionItemList(data));
  };
  const getProjectMostIncidentList = (data) => {
    dispatch(incidentAction.getProjectWithmostIncidentsList(data));
  };
  const getVehicleMostIncidentList = (data) => {
    dispatch(incidentAction.getVehicleWithmostIncidentsList(data));
  };
  const showLoader = useSelector(
    (state) =>
      incidentReportInjuredBodyPartList?.isLoading ||
      state?.incidentReportMostCommonInjList?.isLoading ||
      state?.incUserMostIncidentList?.isLoading ||
      state?.incUsrMostActionItemList?.isLoading ||
      state?.projectwithmostincidentsList?.isLoading ||
      state?.vehiclewithmostincidentsList?.isLoading
  );
  return (
    <>
      {showLoader && <Loader />}

      <ItemIncidents
        incidentReportInjuredBodyPartList={incidentReportInjuredBodyPartList}
        getinjuryBodyPartList={getinjuryBodyPartList}
        incidentReportMostCommonInjuryList={incidentReportMostCommonInjuryList}
        getMostCommonInjuryList={getMostCommonInjuryList}
        incUserMostIncidentLists={incUserMostIncidentLists}
        getUserwithMostIncidentList={getUserwithMostIncidentList}
        incUsrMostActionItemLists={incUsrMostActionItemLists}
        getUserwithMostItemList={getUserwithMostItemList}
        projectwithmostincidentsLists={projectwithmostincidentsLists}
        getProjectMostIncidentList={getProjectMostIncidentList}
        vehiclewithmostincidentsLists={vehiclewithmostincidentsLists}
        getVehicleMostIncidentList={getVehicleMostIncidentList}
        propsItemsData={propsItemsData}
      />
    </>
  );
};

export default ItemsIncidentContainer;
