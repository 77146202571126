import React, { useEffect, useState } from "react";
import Profile from "../component/profile/profile";
import * as resourceAction from "../../../redux/actions/resourceAction";
import { useDispatch, useSelector } from "react-redux";
import {
  API_STATUS,
  LOGIN_ORG_ID,
  PAGE_SIZE,
  USER_ID,
} from "../../../constant/constant";
import * as userAction from "../../../redux/actions/userAction";
import * as credentialAction from "../../../redux/actions/credentialAction";
import * as lmsUserAction from "../../../redux/actions/lmsUserAction";
import * as projectAction from "../../../redux/actions/projectAction";
import * as equipmentsAction from "../../../redux/actions/equipmentsAction";
import * as vehiclesAction from "../../../redux/actions/vehiclesAction";
import * as assetsAction from "../../../redux/actions/assetsAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { fileUpload } from "../../../services/fileUploadService";
import Loader from "../../../library/common/Loader";
import * as clientAction from "../../../redux/actions/clientAction";
import * as contractorAction from "../../../redux/actions/contractorAction";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import { showRighSideMenu } from "../../../utils/rolesHelper";
import { getDivisionByServiceType, validateFileSize } from "../../../utils/helper";

export default function ProfileContainer() {
  const dispatch = useDispatch();
  const PAGE_SIZE_SIX = 6;
  const userId = localStorage.getItem("userId");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isClient = userInfo?.isClient;
  const isContractor = userInfo?.isContractor;

  const UserApi = isClient === false && isContractor === false;
  const ClientApi = isClient === true && isContractor === false;
  const ContractorApi = isClient === false && isContractor === true;

  const contractorId = userInfo?.contractorId;
  const Organisationid = userInfo?.organizationId;
  const divisions = getDivisionByServiceType();
  const showMenu = showRighSideMenu();
  const [isLoader, setIsLoader] = useState(false);
  const division = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const roles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.roles
  );
  const jobTitles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.jobTitles
  );

  const resourceCategory = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.resourceCategories
  );
  const resourceType = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.resourceTypes
  );

  const getDropdownDataKeyValueData = {
    orgId: LOGIN_ORG_ID(),
    flags: "5,6,12,14,15,20",
  };

  const getUserListDetail = () => {
    const data = {
      userId: userId,
      orgId: LOGIN_ORG_ID(),
    };
    dispatch(userAction.getUserListDetsils(data));
  };

  const getClientDetsils = () => {
    dispatch(clientAction.getClientDetsils(Organisationid));
  };

  const getContactDetail = () => {
    dispatch(contractorAction.getContractorDetails(contractorId));
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
    if (userId && UserApi) {
      getUserListDetail();
    }
    if (contractorId && ContractorApi) {
      getContactDetail();
    } else if (Organisationid && ClientApi) {
      getClientDetsils();
    }
  }, []);

  const defaultProjectData = {
    organisationId: LOGIN_ORG_ID(),
    divisionId: divisions[0]?.key,
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: USER_ID(),
  };

  const projectsListData = useSelector((state) => state.projectList);
  const isProjectLoading = useSelector((state) => state.projectList?.isLoading);
  const getProjectListData = (data) => {
    dispatch(projectAction.getProjectList(data));
  };

  const defaultEquipmentData = {
    organisationId: LOGIN_ORG_ID(),
    divisionId: divisions[0]?.key,
    search: "",
    page: 1,
    statusIds: "",
    pageSize: PAGE_SIZE,
    userId: USER_ID(),
  };
  const equipmentList = useSelector((state) => state.equipmentList);
  const isEquipmentLoading = useSelector(
    (state) => state.equipmentList?.isLoading
  );

  const getEquipmentList = (data) => {
    dispatch(equipmentsAction.getEquipmentList(data));
  };

  const defaultVehicleData = {
    organisationId: LOGIN_ORG_ID(),
    divisionId: divisions[0]?.key,
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: USER_ID(),
  };

  const vehicleList = useSelector((state) => state.vehicleList);
  const isVehicleLoading = useSelector((state) => state.vehicleList?.isLoading);
  const getVehicleList = (data) => {
    dispatch(vehiclesAction.getVehicleList(data));
  };

  const defaultAssetData = {
    organisationId: LOGIN_ORG_ID(),
    divisionId: divisions[0]?.key,
    search: "",
    page: 1,
    statusIds: "",
    pageSize: PAGE_SIZE,
    userId: USER_ID(),
  };
  const assetList = useSelector((state) => state.assetList);
  const isAssetLoading = useSelector((state) => state.assetList?.isLoading);
  const getAssetList = (data) => {
    dispatch(assetsAction.getAssetList(data));
  };

  const tabClick = (id) => {
    if (id === "4") {
      getAssetList(defaultAssetData);
    }
    if (id === "3") {
      getVehicleList(defaultVehicleData);
    }
    if (id === "2") {
      getEquipmentList(defaultEquipmentData);
    }
  };

  const defaultData = {
    page: 1,
    pageSize: PAGE_SIZE,
    resourceTypes: "",
    startDate: "",
    endDate: "",
    catId: "",
    search: "",
    userId: USER_ID(),
    orgId: LOGIN_ORG_ID(),
  };
  const resources = useSelector((state) => state.resourceList);
  const getResourceListData = (data) => {
    dispatch(resourceAction.getResourceList(data));
  };

  const userGroupdata = {
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    userId: USER_ID(),
    orgId: LOGIN_ORG_ID(),
  };

  const defaultCredentialData = {
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: USER_ID(),
    orgId: LOGIN_ORG_ID(),
    credentialType: "",
    userType: 0,
  };

  const credentialListData = useSelector(
    (state) => state.getCredentialUserListData
  );
  const getCredentials = (defaultCredentialData) => {
    dispatch(credentialAction.getUserCredentialList(defaultCredentialData));
  };
  const data = {
    page: 1,
    pageSize: PAGE_SIZE,
    search: "",
    userId: USER_ID(),
    orgId: LOGIN_ORG_ID(),
  };

  const tabProfileClick = (id) => {
    if (id === "6") {
      getUserGroupListsData(data);
    }
    if (id === "5") {
      getCoursesUserListData(defaultCoursesData);
    }
    if (id === "4") {
      getResourceListData(defaultData);
    }
    if (id === "3") {
      getCredentials(defaultCredentialData);
    }
    if (id === "2" && showMenu?.isSafety) {
      getProjectListData(defaultProjectData);
    }
  };

  const defaultCoursesData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE_SIX,
    search: "",
    catIds: "",
    statusId: "",
    userId: USER_ID(),
    orgId: LOGIN_ORG_ID(),
  };
  const coursesUserListData = useSelector((state) => state.getUserCoursesList);
  const isCoursesLoading = useSelector(
    (state) => state.getUserCoursesList?.isLoading
  );
  const getCoursesUserListData = (data) => {
    dispatch(lmsUserAction.getUserCoursesList(data));
  };

  const defaultLessonData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE_SIX,
    search: "",
    catIds: "",
    statusId: "",
    userId: USER_ID(),
    orgId: LOGIN_ORG_ID(),
  };
  const lessionUserListData = useSelector((state) => state.getUserLessonsList);
  const getLessionUserListData = (data) => {
    dispatch(lmsUserAction.getUserLessonsList(data));
  };
  const isLessonLoading = useSelector(
    (state) => state.getUserLessonsList?.isLoading
  );

  const getUserListDetsilsDatas = useSelector(
    (state) => state.getUserListDetsilsData
  );

  const getClientDetsilsData = useSelector(
    (state) => state.getClientDetsilsData
  );

  const viewContractors = useSelector((state) => state.viewContractor);

  const showLoader = useSelector(
    (state) =>
      state?.getUserListDetsilsData?.isLoading ||
      state?.updateUserListData?.isLoading ||
      state?.getClientDetsilsData?.isLoading ||
      state?.viewContractor?.isLoading ||
      state?.updateContractor?.isLoading ||
      state?.updateContractor?.isLoading ||
      state?.updateClientData?.isLoading
  );

  const isProfileSuccess = useSelector(
    (state) => state.getUserListDetsilsData.status === API_STATUS.SUCCESS
  );

  const isContractorProfileSuccess = useSelector(
    (state) => state.viewContractor.status === API_STATUS.SUCCESS
  );

  const isClientProfileSuccess = useSelector(
    (state) => state.getClientDetsilsData.status === API_STATUS.SUCCESS
  );
  const isProfileSignatureLoading = useSelector(
    (state) => state.fileUpload.isLoading
  );

  const isClinetProfileSignatureLoading = useSelector(
    (state) => state.fileUpload.isLoading
  );
  const isContractorProfileSignatureLoading = useSelector(
    (state) => state.fileUpload.isLoading
  );

  const [isProfileSignature, setIsProfileSignature] = useState(false);
  const [isClinetProfileSignature, setIsClientProfileSignature] =
    useState(false);
  const [isContractorProfileSignature, setIsContractorProfileSignature] =
    useState(false);
  const [contractorProfileError, setContractorProfileError] = useState(false);
  const [contractorSignatureError, setContractorSignatureError] =
    useState(false);
  const [fileUploadedData, setFileUploadedData] = useState("");
  const [profileImageError, setProfileImageError] = useState(false);
  const [signatureImageError, setSignatureImageError] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [clientProfileError, setClientProfileError] = useState(false);
  const [clientSignatureError, setClientSignatureError] = useState(false);
  const uploadProfileFile = (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileErrorMessage(validation.errorMessage);
      setProfileImageError(true);

      return;
    }
    setIsProfileSignature(false);
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadedData(files);
        setProfileImageError(false);
      })
      .catch((error) => {
        setFileErrorMessage("Error while uploading files.");
        setProfileImageError(true);
      });
  };

  const uploadSignatureFile = (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileErrorMessage(validation.errorMessage);
      setSignatureImageError(true);
      return;
    }
    setIsProfileSignature(true);
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadedData(files);
        setSignatureImageError(false);
      })
      .catch((error) => {
        setFileErrorMessage("Error while uploading files.");
        setSignatureImageError(true);
      });
  };

  const uploadClientProfileFile = (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileErrorMessage(validation.errorMessage);
      setClientProfileError(true);
      return;
    }
    setIsClientProfileSignature(false);
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadedData(files);
        setClientProfileError(false);
      })
      .catch((error) => {
        setFileErrorMessage("Error while uploading files.");
        setClientProfileError(true);
      });
  };

  const uploadClientSignatureFile = (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileErrorMessage(validation.errorMessage);
      setClientSignatureError(true);
      return;
    }
    setIsClientProfileSignature(true);
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadedData(files);
        setClientSignatureError(false);
      })
      .catch((error) => {
        setFileErrorMessage("Error while uploading files.");
        setClientSignatureError(true);
      });
  };

  const uploadContractorProfileFile = (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileErrorMessage(validation.errorMessage);
      setContractorProfileError(true);
      return;
    }
    setIsContractorProfileSignature(false);
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadedData(files);
        setContractorProfileError(false);
      })
      .catch((error) => {
        setFileErrorMessage("Error while uploading files.");
        setContractorProfileError(true);
      });
  };
  const uploadContractorSignatureFile = (data) => {
    const validation = validateFileSize(data);
    if (!validation.isValid) {
      setFileErrorMessage(validation.errorMessage);
      setContractorSignatureError(true);
      return;
    }
    setIsContractorProfileSignature(true);
    setIsLoader(true);
    fileUpload(data)
      .then((res) => {
        setIsLoader(false);
        const files = { files: res };
        setFileUploadedData(files);
        setContractorSignatureError(false);
      })
      .catch((error) => {
        setFileErrorMessage("Error while uploading files.");
        setContractorSignatureError(true);
      });
  };

  const leaveGroupDatas = useSelector((state) => state.leaveGroupData);
  const isUserLoading = useSelector(
    (state) =>
      state.leaveGroupDatas?.isLoading ||
      state.individualUserGroupData?.isLoading
  );

  const leaveGroups = (groupId) => {
    dispatch(userAction.leaveGroup(groupId));
  };

  const individualUserGroupData = useSelector(
    (state) => state.individualUserGroupData
  );

  const getUserGroupListsData = (data) => {
    dispatch(userAction.getUserGroupListsData(data));
  };

  useEffect(() => {
    if (leaveGroupDatas.status === API_STATUS.SUCCESS) {
      getUserGroupListsData(data);
    }
  }, [leaveGroupDatas]);
  const tabtraingClick = (id) => {
    if (id === "1") {
    }
    if (id === "2") {
      getLessionUserListData(defaultLessonData);
    }
    if (id === "3") {
      getToolBoxTalkListData(defaultTrainingData);
    }
  };
  const updateUserListData = useSelector((state) => state.updateUserListData);
  const updateClientData = useSelector((state) => state.updateClientData);
  const updateContractorData = useSelector((state) => state.updateContractor);

  const updateClient = (data) => {
    dispatch(clientAction.updateClient(data));
  };

  const updateContractors = (data) => {
    dispatch(contractorAction.updateContractor(data));
  };

  const adduserLists = (data) => {
    dispatch(userAction.updateUserList(data));
  };

  const categoryListData = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.toolboxCategories
  );

  const getToolBoxTalkListResult = useSelector(
    (state) => state.getToolBoxTalkList?.data
  );

  const defaultTrainingData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    search: "",
    catIds: "",
    userId: USER_ID(),
    status: "",
    tabId: 1,
  };

  const getToolBoxTalkListData = (data) => {
    dispatch(toolboxAction.clearUserDetailTalk(data));
    dispatch(toolboxAction.getToolBoxTalkList(data));
  };

  return (
    <>
      {(showLoader || isLoader) && <Loader />}
      <Profile
        isLoadingResources={resources?.isLoading}
        resources={resources?.isLoading ? [] : resources?.data}
        getResourceListData={getResourceListData}
        tabProfileClick={tabProfileClick}
        individualUserGroupData={isUserLoading ? [] : individualUserGroupData}
        getUserGroupListsData={getUserGroupListsData}
        isloadinguserGroup={isUserLoading}
        credentialListData={
          credentialListData?.isLoading ? [] : credentialListData?.data?.result
        }
        getCredentials={getCredentials}
        isLoadingCredential={
          credentialListData?.isLoading || leaveGroupDatas.isLoading
        }
        tabtraingClick={tabtraingClick}
        coursesUserListData={isCoursesLoading ? [] : coursesUserListData?.data}
        getCoursesUserListData={getCoursesUserListData}
        isCoursesLoading={isCoursesLoading}
        lessionUserListData={isLessonLoading ? [] : lessionUserListData.data}
        getLessionUserListData={getLessionUserListData}
        isLessonLoading={isLessonLoading}
        projectsListData={isProjectLoading ? [] : projectsListData?.data}
        getProjectListData={getProjectListData}
        isProjectLoading={isProjectLoading}
        equipmentList={isEquipmentLoading ? [] : equipmentList?.data}
        getEquipmentList={getEquipmentList}
        isEquipmentLoading={isEquipmentLoading}
        vehicles={isVehicleLoading ? [] : vehicleList?.data}
        getVehicleList={getVehicleList}
        isVehicleLoading={isVehicleLoading}
        assetList={isAssetLoading ? [] : assetList?.data}
        isAssetLoading={isAssetLoading}
        getAssetListData={getAssetList}
        tabClick={tabClick}
        divisions={division}
        roles={roles}
        jobTitles={jobTitles}
        getUserListDetsilsData={getUserListDetsilsDatas?.data}
        uploadProfileFile={uploadProfileFile}
        uploadSignatureFile={uploadSignatureFile}
        isProfileSuccess={isProfileSuccess}
        adduserLists={adduserLists}
        updateUserListData={updateUserListData}
        isProfileSignature={isProfileSignature}
        isProfileSignatureLoading={isProfileSignatureLoading}
        leaveGroup={leaveGroups}
        resourceCategory={resourceCategory}
        resourceType={resourceType}
        getClientDetsilsData={getClientDetsilsData}
        viewContractors={viewContractors}
        updateClient={updateClient}
        updateClientData={updateClientData}
        updateContractors={updateContractors}
        updateContractorData={updateContractorData}
        uploadClientProfileFile={uploadClientProfileFile}
        uploadClientSignatureFile={uploadClientSignatureFile}
        isClinetProfileSignature={isClinetProfileSignature}
        isClientProfileSuccess={isClientProfileSuccess}
        isClinetProfileSignatureLoading={isClinetProfileSignatureLoading}
        uploadContractorProfileFile={uploadContractorProfileFile}
        uploadContractorSignatureFile={uploadContractorSignatureFile}
        isContractorProfileSuccess={isContractorProfileSuccess}
        isContractorProfileSignatureLoading={
          isContractorProfileSignatureLoading
        }
        isContractorProfileSignature={isContractorProfileSignature}
        isClient={isClient}
        divisionDropdown={divisions}
        categoryListData={categoryListData}
        getToolBoxTalkListResult={getToolBoxTalkListResult}
        getToolBoxTalkListData={getToolBoxTalkListData}
        fileUploadedData={fileUploadedData}
        profileImageError={profileImageError}
        fileErrorMessage={fileErrorMessage}
        signatureImageError={signatureImageError}
        clientProfileError={clientProfileError}
        contractorProfileError={contractorProfileError}
        contractorSignatureError={contractorSignatureError}
        clientSignatureError={clientSignatureError}
      />
    </>
  );
}
