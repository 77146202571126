import React, { useEffect, useState } from "react";
import imageCompression from "browser-image-compression";
import { INSPECTION_STATUS } from "../../../../constant/constant";
import parse from "html-react-parser";
import Loader from "../../../../library/common/Loader";

const PreviousOpenItem = ({ filterPreviousData }) => {
  const [compressedImages, setCompressedImages] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchImageAsFile = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], "image.jpg", { type: blob.type });
  };

  useEffect(() => {
    const compressImages = async () => {
      setLoading(true);
      const imagesByLocation = {};
      await Promise.all(
        filterPreviousData?.flatMap(async (itemPrev, locationIndex) => {
          const files = await Promise.all(
            itemPrev?.files?.map(async (img) => {
              const file = await fetchImageAsFile(img.filePath);
              const maxSizeKB = 100;
              const maxSizeMB = maxSizeKB / 1024;

              const compressedFile = await imageCompression(file, {
                maxSizeMB: maxSizeMB,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
              });
              return {
                filePath: URL.createObjectURL(compressedFile),
                fileId: img.fileId,
              };
            })
          );
          imagesByLocation[locationIndex] = files;
        })
      );
      setCompressedImages(imagesByLocation);
      setLoading(false);
    };

    compressImages();
  }, [filterPreviousData]);

  return (
    <>
      {loading && <Loader />}

      {filterPreviousData?.map((itemPrev, locationIndex) => {
        return (
          <tr className="tableBody" key={locationIndex}>
            <td>{itemPrev?.location}</td>
            <td>
              <div className="itemFondText">
                <span>
                  {itemPrev?.item}
                  {itemPrev?.itemDetail && <>{" -"}</>}
                </span>
                {itemPrev?.itemDetail && (
                  <span>{parse(unescape(itemPrev?.itemDetail))}</span>
                )}
              </div>
            </td>
            <td>
              {compressedImages[locationIndex]?.map((img) => (
                <img
                  src={img.filePath}
                  alt=""
                  className="imgViewReport"
                  key={img.fileId}
                />
              ))}
            </td>
            <td>
              <div className="statuss">
                {itemPrev?.status === INSPECTION_STATUS?.Open ? (
                  <div className="open">Open</div>
                ) : (
                  <div className="closed">Closed</div>
                )}
              </div>
            </td>

            <td className="swinspectiondownloadprint">
              {itemPrev?.status === INSPECTION_STATUS?.Open ? (
                <>
                  {itemPrev?.comment?.map((item, index) => {
                    return <div key={index}>{item?.comment}</div>;
                  })}
                </>
              ) : (
                <>
                  {itemPrev?.actionTaken
                    ? itemPrev?.actionTaken
                    : itemPrev?.closedAction}
                </>
              )}
            </td>
            <td className="swinspectiondownloadprint">
              {itemPrev?.status === INSPECTION_STATUS?.Open ? (
                <>
                  {itemPrev?.comment?.map((item, index) => {
                    return <div key={index}>{item?.addedOn}</div>;
                  })}
                </>
              ) : (
                <>{itemPrev?.dateClosed}</>
              )}
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default PreviousOpenItem;
