import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import "./viewObservationReport.scss";
import * as observationAction from "../../../redux/actions/observationAction";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import ViewObservationReport from "./viewObservationReport";
import { useLocation } from "react-router-dom";
import { VIEW_OBSERVATION_LISTS } from "../../../constant/routeContant";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { GET_COUNT_TYPE, ORG_ID } from "../../../constant/constant";

const ViewObservationReportContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [successScreens, setIsSuccess] = useState(false);
  const observationID = location?.state?.insObservationId;
  const observationType = location?.state?.observedType;
  const updateViolation = useSelector((state) => state?.updateObservation);
  const observationStatus = location?.state?.status;

  const violationObservedEmployee = location?.state?.observedEmployee;

  const dataIns = {
    id: violationObservedEmployee,
    type: GET_COUNT_TYPE?.VIOLATION,
    organisationId: ORG_ID(),
  };

  const inspectionViolationCounts = useSelector(
    (state) => state.getInspectionViolationCounts
  );

  const showLoader = useSelector(
    (state) =>
      state?.updateObservation?.isLoading ||
      state?.getObservationReport.isLoading ||
      state?.reprimandCount?.isLoading ||
      state?.getInspectionViolationCounts?.isLoading
  );
  const violationUpdatedStatus = useSelector(
    (state) => state.updateObservation.status
  );
  const observationReportData = useSelector(
    (state) => state?.getObservationReport?.data
  );

  function closeViolation(data) {
    setIsSuccess(true);
    dispatch(observationAction.getObservationUpdatePending(data));
  }

  useEffect(() => {
    dispatch(observationAction.getObservtionDetails(observationID));

    dispatch(incpectionAction.getInspectionViolationCounts(dataIns));
  }, [dispatch]);

  const dataBack = {
    title: "Back to Observation",
    route: VIEW_OBSERVATION_LISTS,
  };

  useEffect(() => {
    setIsSuccess(false);
  }, []);
  const successScreen = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={"Corrective Closed Successfully"}
        route={VIEW_OBSERVATION_LISTS}
        // label1=" "
        // label3=" "
      ></SuccessScreen>
    );
  };

  return (
    <>
      {showLoader && <Loader />}
      <div className="page-title">
        <p>View Observation</p>
      </div>
      {violationUpdatedStatus === "SUCCESS" &&
      updateViolation &&
      successScreens ? (
        successScreen(updateViolation?.data?.id)
      ) : (
        <div className="overideView">
          <ViewObservationReport
            observationReportData={observationReportData}
            dataBack={dataBack}
            observationType={observationType}
            closeViolation={closeViolation}
            observationID={observationID}
            observationStatus={observationStatus}
            inspectionViolationCounts={inspectionViolationCounts}
          />
        </div>
      )}
    </>
  );
};
export default ViewObservationReportContainer;
