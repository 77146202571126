import React, { useState, useRef } from "react";
import { Grid, Card } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import RadioGroup from "@mui/material/RadioGroup";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import Buttons from "../../../library/custom/button/Button";
import { ORG_ID, USER_TYPE } from "../../../constant/constant";
import BackBtn from "../../../library/custom/backBtn/backBtn";
import { SETUP_CREDENTIAL } from "../../../constant/routeContant";
import UsersChip from "../../../library/custom/usersChip/UsersChip";

export default function AssignCredential({
  allUsers,
  userGroups,
  isLoading,
  onSearch,
  getCredentials,
  credentialListData,
  assignCredential,
  handleAllGroupSearch,
}) {
  const intialState = {
    userType: 0,
    userGroups: 1,

    individualUserIds: [],
  };

  const [issueBody, SetIssueBody] = useState("");
  const [credentialsName, SetcredentialsName] = useState("");
  const [formValue, setFormValue] = useState(intialState);
  const [credentialNameId, setcredentialNameId] = useState();
  const [credentialsNameError, SetcredentialsNameError] = useState(false);
  const [issueBodyError, SetIssueBodyError] = useState(false);
  const [formValueError, setFormValueError] = useState(false);
  const autoComp = useRef(null);

  const dataBack = {
    title: "Back to Credentials",
    route: SETUP_CREDENTIAL,
  };

  const onChangeIssuingBody = (event) => {
    SetIssueBody(event.target.value);
  };

  const onChangeCredential = (event, data) => {
    const defaultData = {
      search: event.target.value,
      organisationId: ORG_ID(),
    };
    getCredentials(defaultData);
  };

  const onSubmitData = () => {
    let isFieldEmpty = false;
    if (!credentialsName) {
      SetcredentialsNameError(true);
      isFieldEmpty = true;
    }
    if (!issueBody) {
      SetIssueBodyError(true);
      isFieldEmpty = true;
    }

    if (formValue.userType === 0) {
      if (individualUsersList?.length <= 0) {
        setFormValueError(true);
        isFieldEmpty = true;
      }
    } else {
      if (userGroupList?.length <= 0) {
        setFormValueError(true);
        isFieldEmpty = true;
      }
    }
    if (!isFieldEmpty) {
      const sendData1 =
        formValue.userType === 0 ? individualUsersList : userGroupList;
      const sendObj = sendData1?.map((individual) => {
        return {
          userType: formValue?.userType,
          usertTypeId: individual.key,
        };
      });
      const sendData = {
        credentialNameId: credentialNameId,
        credentialTypeId: credentialTypeId,
        issuingBodyId: issueBody,
        organisationId: ORG_ID(),
        createdBy: localStorage.getItem("userId"),
        assignBodies: sendObj,
      };

      assignCredential(sendData);
    }
  };

  const [users, setUsers] = useState(1);

  const [individualUsersList, setIndividualUsersList] = useState([]);
  const [individualUsers, setIndividualUsers] = useState("");
  const [individualUsersIds, setIndividualUsersIds] = useState([]);
  const deleteIndividualUsersData = (id) => {
    const updateIndividualUsersList = individualUsersList.filter(
      (list) => list.key !== id
    );
    const updateindividualUsersIds = individualUsersIds.filter(
      (list) => list.key !== id
    );
    setIndividualUsersList(updateIndividualUsersList);
    setIndividualUsersIds(updateindividualUsersIds);
  };
  const addIndiVidualUserData = () => {
    setFormValueError(false);
    if (!individualUsers.key) return;
    const index = individualUsersList.findIndex(
      (object) => object.key === individualUsers.key
    );
    if (index === -1) {
      setIndividualUsersIds([...individualUsersIds, individualUsers.key]);
      setIndividualUsersList([...individualUsersList, individualUsers]);
      const ele = autoComp.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  // User Group List

  const [groupUsersIds, setGroupUsersIds] = useState([]);
  const [userGroupList, setUsersGroupList] = useState([]);
  const [usersGroup, setUsersGroup] = useState("");

  const addGroupUserData = () => {
    setFormValueError(false);

    const index = userGroupList?.findIndex(
      (obect) => obect.key === usersGroup.key
    );
    if (index === -1 && usersGroup) {
      setUsersGroup("");
      setUsersGroupList([...userGroupList, usersGroup]);
      setGroupUsersIds([...groupUsersIds, usersGroup.key]);
      const ele = autoComp.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  const deleteUsersGroup = (row) => {
    const updateList = userGroupList.filter((list) => list.key !== row);
    setUsersGroupList(updateList);
    setGroupUsersIds(updateList?.map((ids) => ids.key));
  };

  const onChangeUsersGroup = (value) => {
    setUsersGroup(value);
  };

  const [issuingBodys, setissuingBodys] = useState([]);
  const [credentialTypeId, setCredentialTypeId] = useState();
  const [credentialsNameValue, SetcredentialsNameValue] = useState();

  const onSelectSearchCredential = (v1, v2) => {
    SetcredentialsNameValue({ key: v2?.key ?? "", value: v2?.value ?? "" });
    SetcredentialsName(v2?.value);
    setcredentialNameId(v2?.key);
    setissuingBodys(v2?.issuingBodies);
    setCredentialTypeId(v2?.credentialTypeId);
  };

  return (
    <div className="overidePersonal">
      <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
        <div className="heading_style">Assign Credential</div>
      </Grid>

      <Grid item container mt={3} spacing={2} pl={4}>
        <Grid lg={12} className="addShadowBox">
          <Grid item container>
            <Grid item lg={5} xs={12} sm={12} md={4} mt={3} pl={2}>
              <FormControl fullWidth>
                <FormLabels label={"Search Credential"} isRequired={true} />

                <SearchAutoComplete
                  name="credentials"
                  optionsData={
                    isLoading ? [] : credentialListData?.data?.result ?? []
                  }
                  typeOnchange={(event, value) =>
                    onChangeCredential(event, value)
                  }
                  isKeyValuePair={true}
                  value={credentialsNameValue}
                  onChange={(event, value) =>
                    onSelectSearchCredential(event, value)
                  }
                  isLoading={isLoading}
                  noOptionsTextMsg="No credential"
                />
              </FormControl>
              {credentialsNameError && !credentialsName && (
                <div className="errorMsg">Please enter this required field</div>
              )}
            </Grid>

            <Grid
              item
              lg={5}
              xs={12}
              sm={12}
              md={4}
              mt={3}
              pl={2}
              className="SelectMenuValue"
            >
              <FormControl fullWidth>
                <FormLabels label="Issuing Body" isRequired={true} />
                <SelectMenu
                  customClass={"dropCustom"}
                  placeholder={"Please Select"}
                  listData={issuingBodys}
                  value={issueBody}
                  onchangehandler={(e) => onChangeIssuingBody(e)}
                />
                {issueBodyError && !issueBody && (
                  <div className="errorMsg">
                    Selection is required for this field
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid container mt={4}>
              <Grid item lg={4} xs={12} sm={12} md={4} pl={2}>
                <FormLabels label="Select User Type" isRequired={true} />
                <RadioGroup row name="radio-user-type" className="radioGroup">
                  <RadioButton
                    checked={formValue.userType === 0}
                    value={0}
                    label="Individual"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        userType: parseInt(e.target.value),
                      })
                    }
                  ></RadioButton>
                  <RadioButton
                    checked={formValue.userType === 1}
                    value={1}
                    label="User Group"
                    onChange={(e) =>
                      setFormValue({
                        ...formValue,
                        userType: parseInt(e.target.value),
                      })
                    }
                  ></RadioButton>
                </RadioGroup>
              </Grid>
            </Grid>

            {formValue.userType === 0 && (
              <Grid item container mt={3} spacing={2} pl={2}>
                <Grid item lg={4} xs={12} sm={12} md={4}>
                  <FormLabels label="Assign to" isRequired={true} />
                  <SearchAutoComplete
                    optionsData={isLoading ? [] : allUsers?.data?.users ?? []}
                    isKeyValuePair={true}
                    value={formValue.employeeName}
                    onChange={(e, value) => setIndividualUsers(value)}
                    typeOnchange={(event, value) =>
                      onSearch(event.target.value, USER_TYPE.NONPRIVILEGED)
                    }
                    isLoading={isLoading}
                    autoCompClear={autoComp}
                  />
                  {formValueError && individualUsersList?.length <= 0 && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
                <Grid
                  className="smallAddBtn"
                  item
                  lg={1.2}
                  xs={12}
                  sm={12}
                  mt={4}
                  md={4}
                >
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Add"}
                    onClick={() => addIndiVidualUserData()}
                    id="btnAddEquipment"
                    varientAddIconBlue={true}
                  ></Buttons>
                </Grid>
                <Grid pl={2} mt={3} container gap={1}>
                  <UsersChip
                    userList={individualUsersList ?? []}
                    handleDelete={(e) => deleteIndividualUsersData(e)}
                  />
                </Grid>
              </Grid>
            )}

            {formValue.userType === 1 && (
              <Grid item container mt={3} spacing={2} pl={2}>
                <Grid item lg={4} xs={12} sm={12} md={4}>
                  <FormLabels label="Add User Group" isRequired={true} />
                  <SearchAutoComplete
                    name="Add User Group"
                    optionsData={isLoading ? [] : userGroups?.groupList ?? []}
                    typeOnchange={(event) =>
                      handleAllGroupSearch(event.target.value, 0, true)
                    }
                    isKeyValuePair={true}
                    value={usersGroup}
                    onChange={(e, value) => onChangeUsersGroup(value)}
                    isLoading={isLoading}
                    autoCompClear={autoComp}
                  />
                  {formValueError && userGroupList?.length <= 0 && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
                <Grid
                  className="smallAddBtn"
                  item
                  lg={1.5}
                  xs={12}
                  sm={12}
                  mt={4}
                  md={4}
                >
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Add"}
                    onClick={() => addGroupUserData()}
                    id="btnAddEquipment"
                    varientAddIconBlue={true}
                  ></Buttons>
                </Grid>
                <Grid pl={2} mt={3} container gap={1}>
                  <UsersChip
                    userList={userGroupList}
                    handleDelete={(e) => deleteUsersGroup(e)}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container lg={12} mb={5} mt={1} spacing={2} pl={2} pr={2}>
              <Grid lg={6} md={6} xs={12} sm={6}>
                <div className="save_btn_with_back">
                  <div className="">
                    <BackBtn dataBack={dataBack} />
                  </div>
                </div>
              </Grid>

              <Grid
                lg={6}
                md={6}
                xs={12}
                sm={6}
                className="assignrightbtn"
                mt={2}
              >
                <div className="rightSideBtn">
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={"Assign"}
                    onClick={() => onSubmitData()}
                    varientContained={true}
                  ></Buttons>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
