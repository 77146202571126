import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Buttons from "../../../../library/custom/button/Button";
import { InputField } from "../../../../library/custom/textBox/InputField";
import {
  API_STATUS,
  ORG_ID,
  USER_ID,
} from "../../../../constant/constant";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { useNavigate } from "react-router-dom";
import { INSPECTION_ADMIN } from "../../../../constant/routeContant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import AddNewCategory from "./addNewCategory";
import {
  getQestSetDetail,
  getQuestionsList,
} from "../../../../services/incpectionService";
import { ASSETTYPE } from "../../../../constant/inspectionConstant";
import Loader from "../../../../library/common/Loader";

const SetConfiguration = ({
  onSubmit,
  onSetSubmit,
  questionSetList,
  getCategoriesList,
  getSubCategoriesList,
  adminAddUpdateInspection,
  questionSetListData,
  questsetId,
  assetTypeId,
  editMode,
  onCategorySubmit,
  onSubCategorySubmit,
  onQuestionSubmit,
  addUpdateQuestions,
}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [questionSetValue, setQuestionSetValue] = useState();
  const navigate = useNavigate();
  const [assetTypeValue, setAssetTypeValue] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  const intialState = {
    inspeciontype: "",
  };

  const [formValue, setFormValue] = useState(intialState);

  const [isInspeciontype, setIsInspeciontype] = useState(false);
  const [subCategoryFindValues, setSubCategoryFindValues] = useState();
  const [categoryes, setCategoryes] = useState([]);
  const [selectIns, setSelectIns] = useState();
  const [selectCategory, setSelectCategory] = useState(false);
  const [selectCategoryMsg, setSelectCategoryMsg] = useState(false);
  const [selectSubCategory, setSelectSubCategory] = useState(false);
  const [selectSubCategoryMsg, setSelectSubCategoryMsg] = useState(false);

  const handleOnclickCategory = (event) => {
    event.stopPropagation();
    let errors = false;
    if (!questionSetValue) {
      setSelectIns(true);
      errors = true;
    }
    if (!categorySetValue) {
      setSelectCategory(true);
      setSelectCategoryMsg("Category is required");
      errors = true;
    }
    if (errors) {
      // setShowLoader(true);
      return false;
    }
    const newCategoryes = [...categoryes];

    const index = newCategoryes?.findIndex(
      (obect) => obect.key === categoryFindValues?.key
    );
    if (index === -1) {
      setShowLoader(false);
      setSelectIns(false);
      setSelectCategory(false);
      newCategoryes.push({
        key: categoryFindValues?.key,
        value: categoryFindValues?.value,
        categoryId: categoryFindValues?.key,
        subcategories: [],
      });
      setCategoryes(newCategoryes);
    } else {
      setSelectCategory(true);
      setSelectCategoryMsg("Category already added.");
    }
  };

  const handleOnChangeCategoryForm = (current, data, singleSubCat, type) => {
    let elementId = "";
    let value = "";

    value = current.target.value;
    elementId = current.target.id;

    const findSubCategoryValue = getSubCategoriesList.find(
      (item) => item.key === current.target.value
    );
    setSubCategoryFindValues(findSubCategoryValue);

    let updatedCategoryes = [...categoryes];
    updatedCategoryes.forEach((categoryess) => {
      if (categoryess.categoryId === data.categoryId) {
        if (elementId === "categorySetValue") {
          categoryess.categorySetValue = value;
        } else if (type === "subCategorySetValue") {
          categoryess.subCategorySetValue = value;
          setSelectSubCategory(false);
        }
      }
      if (singleSubCat) {
        categoryess.subcategories.forEach((subCategory) => {
          if (subCategory.subCategoryId === singleSubCat.subCategoryId) {
            if (elementId === "subCategorySetValue") {
              subCategory.subCategorySetValue = value;
            } else if (type === "questionValue") {
              subCategory.questionValue = value;
            }
          }
        });
      }
    });

    setCategoryes(updatedCategoryes);
  };

  const cancelCategory = (sinlgeCate) => {
    const removeCategory = categoryes.filter(
      (category) => category.key !== sinlgeCate.key
    );
    setCategoryes(removeCategory);
  };
  const [subCatAdd, setSubCatAdd] = useState();

  const addSubCategory = (singleSubCat) => {
    let updatedSubCategory = [...categoryes];
    updatedSubCategory.forEach(async (sinlgeSubCat) => {
      const index = sinlgeSubCat.subcategories?.findIndex(
        (obect) => obect.key === subCategoryFindValues?.key
      );
      if (index === -1) {
        setShowLoader(true);
        if (sinlgeSubCat.key === singleSubCat.key) {
          const data = {
            subCategories: [subCategoryFindValues?.key],
            searchText: "",
            organisationId: ORG_ID(),
            allowPaging: false,
          };
          const keyValueQues = [];
          const questionsData = await getQuestionsList({ data });
          if (questionsData?.status === 200) {
            setShowLoader(false);
            setSelectSubCategory(false);
          }
          setSubCatAdd(questionsData);
          questionsData?.data?.keyValues
            .filter((x) => x.isActive)
            .forEach((item) => {
              const quesSet = {
                key: item.key,
                value: item.value,
                isActive: item.isActive,
                isSelected: false,
              };
              keyValueQues.push(quesSet);
            });
          sinlgeSubCat.subcategories.push({
            key: subCategoryFindValues?.key,
            value: subCategoryFindValues?.value,
            subCategoryId: subCategoryFindValues?.key,
            questions: keyValueQues,
          });
        }
        setCategoryes(updatedSubCategory);
        setShowLoader(false);
      } else {
        setSelectSubCategory(true);
        setSelectSubCategoryMsg("Sub Category already added.");
      }
    });
  };

  const cancelSubCategory = (singQue, signAws) => {
    const cloneList = [...categoryes];
    cloneList.forEach((des) => {
      if (singQue.key === des.key) {
        let filterAns = des.subcategories.filter(
          (te) => te.key !== signAws.key
        );
        des.subcategories = filterAns;
      }
    });
    setCategoryes(cloneList);
  };
  const [errorsMsgShowActive, seterrorsMsgShowActive] = useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();

  function mappedData() {
    let errors = false;
    if (formValue?.inspeciontype?.trim() === "") {
      setFormValue({
        ...formValue,
        inspeciontype: "",
      });
      seterrorsMsgShowActive(false);
      setIsInspeciontype(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    const index = questionSetList?.findIndex(
      (obect) =>
        obect.value.toUpperCase() === formValue?.inspeciontype.toUpperCase()
    );
    if (index === -1) {
      seterrorsMsgShowActive(false);
      const data = {
        questionSetId: 0,
        imgUrl: "",
        isDeleted: false,
        value: formValue?.inspeciontype,
        createdBy: parseInt(USER_ID()),
        organisationId: ORG_ID(),
        assetTypeId: null,
      };
      return data;
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Inspection name already added");
    }
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  const handleQuestionSetChange = async (e) => {
    setSelectIns(false);
    setQuestionSetValue(e.target.value);
    setShowLoader(true);
    const data = {
      questSetId: e.target.value,
      organisationId: ORG_ID(),
    };
    const categoryData = await getQestSetDetail({ data });
    if (categoryData?.status === 200) {
      setShowLoader(false);
    }
    const addQuesSet = JSON.parse(
      JSON.stringify(categoryData?.data?.categories)
    );
    addQuesSet.forEach((qes) => {
      qes.categoryId = qes.key;
      qes.subcategories.forEach((seq) => {
        seq.subCategoryId = seq.key;
      });
    });
    setCategoryes(addQuesSet);
  };

  const [categorySetValue, setCategorySetValue] = useState();
  const [categoryFindValues, setCategoryFindValues] = useState();
  const handleCategorySetChange = (e) => {
    setSelectCategory(false);
    const findCategoryValue = getCategoriesList.find(
      (item) => item.key === e.target.value
    );
    setCategoryFindValues(findCategoryValue);
    setCategorySetValue({
      categorySetValue: e.target.value,
    });
  };
  const handleQuestionCheck = (
    singlesubcategories,
    quesId,
    singleCategoryes
  ) => {
    const addQuesSet = JSON.parse(JSON.stringify(categoryes));
    addQuesSet.forEach((qes) => {
      if (qes.categoryId == singlesubcategories.categoryId) {
        qes.subcategories.forEach((seq) => {
          if (seq.subCategoryId === quesId.subCategoryId) {
            seq.questions.forEach((swq) => {
              if (swq.key == singleCategoryes.key) {
                swq.isSelected = !swq.isSelected;
              }
            });
          }
        });
      }
    });
    setCategoryes(addQuesSet);
  };

  const handleQuestionAdd = (singlesubcategories, quesId, singleCategoryes) => {
    const addQuesSet = JSON.parse(JSON.stringify(categoryes));
    addQuesSet.forEach((qes) => {
      if (qes.categoryId == singlesubcategories.categoryId) {
        qes.subcategories.forEach((seq) => {
          if (seq.subCategoryId === quesId.subCategoryId) {
            seq.show = !seq.show;
          }
        });
      }
    });
    setCategoryes(addQuesSet);
  };
  const [singlesubcategoriesId, setSinglesubcategoriesId] = useState();
  const [singlecategoriesId, setSinglecategoriesId] = useState();
  const [questionValueCheck, setQuestionValueCheck] = useState();
  const submitQuestionData = async (singlesubcategories, singleCategoryes) => {
    let existData = singlesubcategories?.questions.filter(
      (items) => items.value === singlesubcategories.questionValue
    );
    setShowLoader(true);
    setQuestionValueCheck(singlesubcategories.questionValue);

    if (existData.length >= 1) {
      const addQuesSet = JSON.parse(JSON.stringify(categoryes));
      addQuesSet.forEach((qes) => {
        if (qes.categoryId == singleCategoryes.categoryId) {
          qes.subcategories.forEach((seq) => {
            if (seq.subCategoryId === singlesubcategories.subCategoryId) {
              seq.errorTextField = true;
            }
          });
        }
      });
      setShowLoader(false);

      setCategoryes(addQuesSet);
    } else {
      const addQuesSet = JSON.parse(JSON.stringify(categoryes));
      addQuesSet.forEach((qes) => {
        if (qes.categoryId == singleCategoryes.categoryId) {
          qes.subcategories.forEach((seq) => {
            if (seq.subCategoryId === singlesubcategories.subCategoryId) {
              seq.errorTextField = false;
              seq.questionValue = "";
            }
          });
        }
      });
      setCategoryes(addQuesSet);
      setSinglesubcategoriesId(singlesubcategories);
      setSinglecategoriesId(singleCategoryes);

      const questions = [
        {
          key: 0,
          subcategoryId: singlesubcategories?.key,
          question: singlesubcategories?.questionValue,
          isActive: true,
          createdBy: parseInt(USER_ID()),
        },
      ];
      onQuestionSubmit({
        questions: questions,
        organisationId: ORG_ID(),
      });
    }
  };

  const getQuestionSetData = async () => {
    const data = {
      subCategories: [singlesubcategoriesId?.key],
      searchText: "",
      organisationId: ORG_ID(),
      allowPaging: false,
    };
    const questionsData = await getQuestionsList({ data });
    const cloneList = [...categoryes];
    cloneList.forEach((des) => {
      if (singlecategoriesId.key === des.key) {
        des.subcategories.forEach((subSeq) => {
          if (subSeq.key === singlesubcategoriesId.key) {
            subSeq.questions.push(questionsData?.data?.keyValues[0]);
          }
        });
      }
    });
    setCategoryes(cloneList);
  };

  useEffect(() => {
    if (addUpdateQuestions?.status === API_STATUS?.SUCCESS) {
      setShowLoader(false);
      getQuestionSetData();
    } else {
    }
  }, [addUpdateQuestions]);

  function mappedSetData() {
    let errors = false;
    if (!questionSetValue) {
      setSelectIns(true);
      errors = true;
    }

    if (errors) {
      return false;
    }

    const data = {
      questSetId: questionSetValue,
      createdBy: parseInt(USER_ID()),
      organisationId: ORG_ID(),
      assetTypeId: assetTypeValue,
      categories: categoryes,
    };
    return data;
  }

  const submitUpdateData = () => {
    const data = mappedSetData();
    if (!data) {
    } else {
      setIsSubmit(true);
      const newCategoryes = JSON.parse(JSON.stringify(data.categories));
      const sendData = newCategoryes?.map((x, indexCategories) => {
        delete x.value;
        delete x.id;
        x.sequenceNumber = indexCategories + 1;
        x.subcategories?.map((y,indexSubCategories) => {
          const questionSetIds = [];
          y.questions.forEach((items, indexQuestion) => {
            if (items.isSelected) {
              const reerr = {
                questionId: items.key,
                sequenceNumber: indexQuestion + 1,
              };
              questionSetIds.push(reerr);
            }
          });
          delete y.value;
          delete y.id;
          y.sequenceNumber = indexSubCategories + 1;
          y.questions = questionSetIds;
          return y;
        });
        return x;
      });
      data.categories = sendData;
      onSetSubmit(data);
    }
  };
  const dataBack = {
    title: "Inspection Configuration",
    route: INSPECTION_ADMIN,
  };

  useEffect(() => {
    if (
      adminAddUpdateInspection?.status === "SUCCESS" &&
      adminAddUpdateInspection?.isLoading === false
    ) {
      setIsSubmit(false);
      setIsInspeciontype(false);
      setFormValue({
        ...formValue,
        inspeciontype: "",
      });

      const defaultQuestionSetData = {
        organisationId: ORG_ID(),
        isAdmin: true,
      };
      questionSetListData(defaultQuestionSetData);
    }
  }, [adminAddUpdateInspection]);

  const editInspection = async () => {
    const data = {
      questSetId: questsetId,
      organisationId: ORG_ID(),
    };
    const categoryData = await getQestSetDetail({ data });
    if (categoryData?.status === 200) {
      setShowLoader(false);
    }
    const addQuesSet = JSON.parse(
      JSON.stringify(categoryData?.data?.categories)
    );
    addQuesSet.forEach((qes) => {
      qes.categoryId = qes.key;
      qes.subcategories.forEach((seq) => {
        seq.subCategoryId = seq.key;
        
      });
    });
    setCategoryes(addQuesSet);
  };

  useEffect(() => {
    if (questsetId) {
      setShowLoader(true);
      editInspection();
      setQuestionSetValue(questsetId);
      setAssetTypeValue(assetTypeId);
    }
  }, [editMode]);

  const categoryIntialState = {
    categoriesValue: "",
  };
  const [addCategoryBox, setAddCategoryBox] = useState(false);
  const [categoryFormValue, setCategoryFormValue] =
    useState(categoryIntialState);
  const [isCategoryName, setIsCategoryName] = useState(false);
  const [errorsCategoryMsgShowActive, setErrorsCategoryMsgShowActive] =
    useState(false);
  const [errorsCategoryMsgShow, setErrorsCategoryMsgShow] = useState(false);
  const [isCategorySubmit, setIsCategorySubmit] = useState(false);

  const handleOnclickAddCategory = () => {
    setAddCategoryBox(true);
  };

  function mappedCategoryData() {
    let errors = false;
    if (categoryFormValue?.categoriesValue.trim() === "") {
      setFormValue({
        ...categoryFormValue,
        categoriesValue: "",
      });
      setErrorsCategoryMsgShowActive(false);
      setIsCategoryName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }

    const index = getCategoriesList?.findIndex(
      (obect) =>
        obect.value?.toUpperCase() ===
        categoryFormValue?.categoriesValue?.toUpperCase()
    );
    if (index === -1) {
      setErrorsCategoryMsgShowActive(false);
      const categories = [
        {
          key: 0,
          value: categoryFormValue?.categoriesValue,
          isActive: true,
          createdBy: parseInt(USER_ID()),
        },
      ];
      return { categories, organisationId: ORG_ID()};
    } else {
      setErrorsCategoryMsgShowActive(true);
      setErrorsCategoryMsgShow("Category Name already added");
    }
  }

  const submitCategoryData = () => {
    const data = mappedCategoryData();
    if (!data) {
    } else {
      setCategoryFormValue({
        ...categoryFormValue,
        categoriesValue: "",
      });
      setIsCategorySubmit(true);
      onCategorySubmit(data);
    }
  };
  const handleAssetChange = (e) => {
    setAssetTypeValue(e.target.value);
  };

  return (
    <>
      <>{showLoader && <Loader />}</>

      <Grid className="inspectionOverRide">
        <BackBtn dataBack={dataBack} />
        {editMode ? (
          ""
        ) : (
          <Grid container mt={2}>
            <Grid item md={4} xs={4} mb={2}>
              <InputField
                type="text"
                isRequired={true}
                value={formValue?.inspeciontype}
                onChange={(e) =>
                  setFormValue({
                    ...formValue,
                    inspeciontype: e.target.value,
                  })
                }
                label="Inspection Name"
                placeholder="Inspection Name Here..."
                isError={isInspeciontype}
                errorMsg={isInspeciontype ? "Inspection Name is Required" : ""}
              />
              {errorsMsgShowActive && (
                <div className="errorMsg">{errorsMsgShow}</div>
              )}
            </Grid>
            <Grid
              className="smallAddBtn"
              item
              lg={1}
              md={2}
              xs={3}
              ml={2}
              mt={4}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                id="btnAddEquipment"
                varientAddIconBlue={true}
                disabled={isSubmit ? true : false}
                onClick={submitData}
              ></Buttons>
            </Grid>
          </Grid>
        )}

        <Grid container mt={5}>
          <Grid item md={6} xs={12} mb={2}>
            <FormControl fullWidth>
              <FormLabels label="Select Inspection Name" isRequired={true} />
              <SelectMenu
                placeholder="Please Select"
                listData={questionSetList ?? []}
                value={questionSetValue ?? ""}
                disabled={editMode ? true : false}
                onchangehandler={(e) => handleQuestionSetChange(e)}
              />
              {selectIns && (
                <div className="errorMsg">{"Inspection Name is required"}</div>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container mt={2} mb={2}>
          <Grid item lg={5} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels label="Asset Type" isRequired={true} />
              <SelectMenu
                listData={ASSETTYPE}
                value={assetTypeValue}
                onchangehandler={(e) => handleAssetChange(e)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={5}>
          {/* <Grid>
            <Button
              varientTextGreen={true}
              label="Add Category"
              onClick={handleOnclickCategory}
            />
          </Grid> */}
          <Grid container item>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <FormLabels label="Add Category" />
                <SelectMenu
                  placeholder="Please Select"
                  listData={getCategoriesList ?? []}
                  value={categorySetValue}
                  onchangehandler={(e) => handleCategorySetChange(e)}
                />
                {selectCategory && (
                  <div className="errorMsg">{selectCategoryMsg}</div>
                )}
              </FormControl>
            </Grid>
            <Grid
              className="smallAddBtn"
              item
              lg={1.5}
              md={2}
              xs={3}
              ml={2}
              mt={4}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                id="btnAddEquipment"
                varientAddIconBlue={true}
                onClick={handleOnclickCategory}
              ></Buttons>
            </Grid>

            <Grid
              item
              lg={3}
              md={2}
              xs={3}
              ml={1}
              mt={4}
              className="variantOutlineBorderSmall"
            >
              <Buttons
                aria-describedby="dd"
                variant="variantOutlineBorder"
                type="button"
                name="btn"
                label={"Create New"}
                id="btnAddEquipment"
                variantOutlineBorder={true}
                onClick={handleOnclickAddCategory}
              ></Buttons>
            </Grid>
          </Grid>
        </Grid>

        {addCategoryBox && (
          <Grid item container md={8} className="CategoriesBox">
            <Grid item md={8} xs={8} mb={2}>
              <InputField
                type="text"
                isRequired={true}
                value={categoryFormValue?.categoriesValue}
                onChange={(e) =>
                  setCategoryFormValue({
                    ...categoryFormValue,
                    categoriesValue: e.target.value,
                  })
                }
                label="Create New Category"
                placeholder="Create Category Here..."
                isError={isCategoryName}
                errorMsg={isCategoryName ? "Category Name is Required" : ""}
              />
              {errorsCategoryMsgShowActive && (
                <div className="errorMsg">{errorsCategoryMsgShow}</div>
              )}
            </Grid>
            <Grid
              className="smallAddBtn"
              item
              lg={1.5}
              md={3}
              xs={3}
              ml={3}
              mt={4}
            >
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Create"}
                id="btnAddEquipment"
                varientAddIconBlue={true}
                disabled={false}
                onClick={submitCategoryData}
              ></Buttons>
            </Grid>
          </Grid>
        )}

        <Grid container mt={2}>
          {categoryes?.map((singleCategoryes, index) => (
            <AddNewCategory
              getSubCategoriesList={getSubCategoriesList}
              singleCategoryes={singleCategoryes}
              handleOnChangeCategoryForm={handleOnChangeCategoryForm}
              addSubCategory={addSubCategory}
              cancelCategory={(id) => cancelCategory(id)}
              cancelSubCategory={cancelSubCategory}
              handleQuestionCheck={handleQuestionCheck}
              onSubCategorySubmit={onSubCategorySubmit}
              handleQuestionAdd={handleQuestionAdd}
              submitQuestionData={submitQuestionData}
              selectSubCategory={selectSubCategory}
              selectSubCategoryMsg={selectSubCategoryMsg}
              key={index}
            />
          ))}
        </Grid>

        <Grid container mt={7}>
          <Grid item lg={2} xs={12} sm={3} md={3}>
            <Buttons
              varientText={true}
              label="Cancel"
              onClick={() => navigate(-1)}
            />
          </Grid>
          <Grid item lg={2} xs={12} sm={3} md={3}>
            <Buttons
              varientContained={true}
              label={"Save"}
              onClick={submitUpdateData}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SetConfiguration;
