import React, { useEffect } from "react";
import "./reports.scss";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import { useState } from "react";
import {
  GET_ITEM_LOG,
  LOGIN_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
} from "../../../constant/constant";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import Buttons from "../../../library/custom/button/Button";
import DatePicker from "../../../library/custom/datePicker/DatePicker";
import Cards from "./Cards";
import {
  getCurrentDate,
  getDateWithDayPast,
  getFormatedDate,
} from "../../../utils/helper";
import ReportSafetylogListContainer from "../containers/reportSafetylogListContainer";

function ReportSafetyData({
  itemLogCountData,
  divisons,
  projectList,
  getProjectSearch,
  getItemLogData,
}) {
  const [divison, setDivison] = useState("");
  const [project, setProject] = useState("");
  const [days, setDays] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [incSearchText, setIncSearchText] = useState("");

  const onChangeDivision = (e) => {
    setProject("");
    setDivison(e.target.value);
    settabId("");

    getItemLogData({
      page: 1,
      pageSize: PAGE_SIZE,
      search: incSearchText,
      userId: 0,
      itemTypes: GET_ITEM_LOG.All,
      organizationId: ORG_ID(),
      divisionId: e.target.value,
      projectId: 0,
      fromDate: startDate,
      toDate: endDate,
      status: "",
      statusIds: "",
    });
    getProjectSearch({
      search: "",
      divisionId: e.target.value,
    });
  };
  const onChangeSelectProject = (e) => {
    setProject(e.target.value);
    getItemLogData({
      page: 1,
      pageSize: PAGE_SIZE,
      search: incSearchText,
      userId: 0,
      itemTypes: GET_ITEM_LOG.All,
      organizationId: ORG_ID(),
      divisionId: divison,
      projectId: e.target.value,
      status: "",
      statusIds: "",
    });
  };
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Incident Report",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
  });

  const filterDays = [
    {
      key: 0,
      value: "Today",
    },
    {
      key: 7,
      value: "7 days",
    },
    {
      key: 14,
      value: "14 days",
    },
    {
      key: 30,
      value: "30 days",
    },
    {
      key: 12,
      value: "Custom",
    },

    {
      key: 13,
      value: "Reset",
    },
  ];

  const resetFilterData = [
    {
      key: 0,
      value: "Reset",
    },
  ];

  const cardOnClick = (value) => {
    if (days.key === value.key) {
      setDays("");
      setStartDate("");
      setEndDate("");
      const defaultData = {
        page: 1,
        pageSize: PAGE_SIZE,
        search: incSearchText,
        userId: 0,
        itemTypes: GET_ITEM_LOG.All,
        organizationId: ORG_ID(),
        divisionId: divison,
        projectId: project,
        fromDate: "",
        toDate: "",
        status: "",
        statusIds: "",
      };
      getItemLogData(defaultData);
    } else {
      if (value.key !== 12) {
        let dateObj = getDateWithDayPast(value.key);
        const defaultData = {
          page: 1,
          pageSize: PAGE_SIZE,
          search: incSearchText,
          userId: 0,
          itemTypes: GET_ITEM_LOG.All,
          organizationId: ORG_ID(),
          divisionId: divison,
          projectId: project,
          fromDate: dateObj.pastData,
          toDate: dateObj.getCurrentDate,
          status: "",
          statusIds: "",
        };
        getItemLogData(defaultData);
        setStartDate(dateObj.pastData);
        setEndDate(dateObj.getCurrentDate);
      }
      if (value.key === 13) {
        settabId("");
        setStartDate("");
        setEndDate("");
        setDivison("");
        setProject("");
        setIncSearchText("");
        setDays("");
        const defaultData = {
          page: 1,
          pageSize: PAGE_SIZE,
          search: incSearchText,
          userId: 0,
          itemTypes: GET_ITEM_LOG.All,
          organizationId: ORG_ID(),
          divisionId: "",
          projectId: "",
          fromDate: "",
          toDate: "",
          status: "",
          statusIds: "",
        };
        getItemLogData(defaultData);
      }
      setDays(days.key === 13 ? "" : value);
    }
  };

  const onchangeEnddatePicker = (e) => {
    const defaultData = {
      page: 1,
      pageSize: PAGE_SIZE,
      search: incSearchText,
      userId: 0,
      itemTypes: GET_ITEM_LOG.All,
      organizationId: ORG_ID(),
      divisionId: divison,
      projectId: project,
      fromDate: getFormatedDate(
        startDate === "" ? getCurrentDate() : startDate
      ),
      toDate: getFormatedDate(e),
      status: "",
      statusIds: "",
    };
    setEndDate(e);
    getItemLogData(defaultData);
  };
  const [tabId, settabId] = useState("0");

  const isClient = ORG_ID() !== LOGIN_ORG_ID();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const clientLogo = localStorage.getItem("orgLogo");
  const clientName = localStorage.getItem("orgName");
  const logoCheck = isClient ? clientLogo : userInfo?.logo;
  console.log(tabId, "tabId");
  return (
    <div ref={componentRef} className="innerReportAndAnalyticsPdf">
      <section className="grid_main_sec">
        <Grid item md={12} className="clientPdfDetail">
          <Grid className="clientName">
            {isClient ? clientName : userInfo?.clientName}
          </Grid>
          <Grid className="clientLogo">
            {logoCheck && <img src={logoCheck} alt="client Log" />}
          </Grid>
        </Grid>
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style repotPdfHeading">
            {/*todo Safety Data Reports */}
          </div>

          <div className="filter_btn removeElement">
            <Buttons
              primary={true}
              id="btnAddClient"
              label={"Download Report"}
              onClick={handlePrint}
            ></Buttons>
          </div>
        </div>
      </section>

      <Grid container mt={6} className="removeElement">
        <Grid item lg={4} xs={12} sm={12} md={4}>
          <FormControl fullWidth>
            <FormLabels label="Select Division" isRequired={false} />
            <SelectMenu
              placeholder={"All Divisions"}
              disabledDefault={false}
              listData={divisons}
              value={divison}
              onchangehandler={(e) => onChangeDivision(e)}
              isgroupplaceholder={true}
            />
          </FormControl>
        </Grid>
      </Grid>
      {divison !== "" && (
        <Grid container mt={2} className="removeElement">
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels label="Select Project" isRequired={false} />
              <SelectMenu
                placeholder="All Projects"
                listData={projectList}
                value={project}
                onchangehandler={(e) => onChangeSelectProject(e)}
                disabledDefault={false}
                isgroupplaceholder={true}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}

      <Grid container mt={2} item lg={6} xs={12} sm={12} md={8} className="removeElement">
        <Grid item lg={12}>
          <FormLabels label={"Select Time Period"} />
        </Grid>
        <Cards
          staticCardData={true}
          cardData={filterDays}
          selectedVehicleAccident={days}
          cardClickHandler={cardOnClick}
        />
      </Grid>
      {days?.value === "Custom" ? (
        <>
          <Grid item container mt={1} spacing={1} className="removeElement">
            <Grid item lg={4} xs={12} sm={12}>
              <FormLabels label={"Custom Time Period"} isRequired={false} />
            </Grid>
          </Grid>

          <Grid item container spacing={2} mb={2} className="removeElement">
            <Grid item lg={3} xs={12} sm={12} md={4}>
              <FormLabels label={"From"} isRequired={false} />
              <DatePicker
                maxDateEnable={true}
                value={startDate === "" ? getCurrentDate() : startDate}
                disabled={false}
                onChangeHandler={(e) => setStartDate(e)}
              />
            </Grid>
            <Grid item lg={3} xs={12} sm={12} md={4}>
              <FormLabels label={"To"} isRequired={false} />

              <DatePicker
                maxDateEnable={true}
                minDate={startDate}
                value={endDate === "" ? getCurrentDate() : endDate}
                disabled={false}
                onChangeHandler={(e) => onchangeEnddatePicker(e)}
              />
            </Grid>
          </Grid>
        </>
      ) : null}
      <div className="reportBody">
        <div
          className={tabId === "0" ? "reportCardBody active" : "reportCardBody"}
          onClick={() => {
            settabId("0");
            console.log(startDate);
            getItemLogData({
              page: 1,
              pageSize: PAGE_SIZE,
              search: incSearchText,
              userId: 0,
              itemTypes: GET_ITEM_LOG.All,
              organizationId: ORG_ID(),
              divisionId: divison,
              projectId: project,
              fromDate:
                startDate.length > 0 ? getFormatedDate(startDate) : startDate,
              toDate: endDate.length > 0 ? getFormatedDate(endDate) : endDate,
              status: "",
              statusIds: "",
            });
          }}
        >
          <div className="text">Total Items</div>
          <div className="number">{itemLogCountData?.totalnoOfRecords}</div>
        </div>
        <div
          className={tabId === "1" ? "reportCardBody active" : "reportCardBody"}
          onClick={() => {
            getItemLogData({
              page: 1,
              search: incSearchText,
              userId: 0,
              itemTypes: GET_ITEM_LOG.All,
              divisionId: divison,
              projectId: project,
              fromDate:
                startDate.length > 0 ? getFormatedDate(startDate) : startDate,
              toDate: endDate.length > 0 ? getFormatedDate(endDate) : endDate,
              status: "",
              statusIds: "",
            });
            settabId("1");
          }}
        >
          <div className="text">Open Items</div>
          <div className="number">{itemLogCountData?.openItemCount}</div>
        </div>
        <div
          className={tabId === "2" ? "reportCardBody active" : "reportCardBody"}
          onClick={() => {
            getItemLogData({
              page: 1,
              pageSize: PAGE_SIZE,
              search: incSearchText,
              userId: 0,
              itemTypes: GET_ITEM_LOG.All,
              organizationId: ORG_ID(),
              divisionId: divison,
              projectId: project,
              fromDate:
                startDate.length > 0 ? getFormatedDate(startDate) : startDate,
              toDate: endDate.length > 0 ? getFormatedDate(endDate) : endDate,
              status: "",
              statusIds: "",
            });
            settabId("2");
          }}
        >
          <div className="text">Closed Items</div>
          <div className="number">{itemLogCountData?.closeItemCount}</div>
        </div>
        {/* todo 
        <div
          className="reportCardBody"
          onClick={() => {
            getItemLogData({
              page: 1,
              pageSize: PAGE_SIZE,
              search: incSearchText,
              userId: 0,
              itemTypes: GET_ITEM_LOG.Kudos,
              organizationId: ORG_ID(),
              divisionId: divison,
              projectId: project,
              fromDate:
                startDate.length > 0 ? getFormatedDate(startDate) : startDate,
              toDate: endDate.length > 0 ? getFormatedDate(endDate) : endDate,
            });
            settabId("3");
          }}
        >
          <div className="text">Kudos</div>
          <div className="number">{itemLogCountData?.noOfKudos}</div>
        </div> */}

        <div
          className={tabId === "4" ? "reportCardBody active" : "reportCardBody"}
          onClick={() => {
            getItemLogData({
              page: 1,
              pageSize: PAGE_SIZE,
              search: incSearchText,
              userId: 0,
              itemTypes: GET_ITEM_LOG.Corrective,
              organizationId: ORG_ID(),
              divisionId: divison,
              projectId: project,
              fromDate:
                startDate.length > 0 ? getFormatedDate(startDate) : startDate,
              toDate: endDate.length > 0 ? getFormatedDate(endDate) : endDate,
              status: "",
              statusIds: "",
            });
            settabId("4");
          }}
        >
          <div className="text">Correctives</div>
          <div className="number">{itemLogCountData?.noOfViolation}</div>
        </div>

        <div
          className={tabId === "5" ? "reportCardBody active" : "reportCardBody"}
          onClick={() => {
            getItemLogData({
              page: 1,
              pageSize: PAGE_SIZE,
              search: incSearchText,
              userId: 0,
              itemTypes: GET_ITEM_LOG.Reprimand,
              organizationId: ORG_ID(),
              divisionId: divison,
              projectId: project,
              fromDate:
                startDate.length > 0 ? getFormatedDate(startDate) : startDate,
              toDate: endDate.length > 0 ? getFormatedDate(endDate) : endDate,
              status: "",
              statusIds: "",
            });

            settabId("5");
          }}
        >
          <div className="text">Reprimands</div>
          <div className="number">{itemLogCountData?.noOfReprimand}</div>
        </div>

        <div
          className={tabId === "6" ? "reportCardBody active" : "reportCardBody"}
          onClick={() => {
            getItemLogData({
              page: 1,
              pageSize: PAGE_SIZE,
              search: incSearchText,
              userId: 0,
              itemTypes: GET_ITEM_LOG.Action,
              organizationId: ORG_ID(),
              divisionId: divison,
              projectId: project,
              fromDate:
                startDate.length > 0 ? getFormatedDate(startDate) : startDate,
              toDate: endDate.length > 0 ? getFormatedDate(endDate) : endDate,
              status: "",
              statusIds: "",
            });
            settabId("6");
          }}
        >
          <div className="text">Actions</div>
          <div className="number">{itemLogCountData?.noOfAction}</div>
        </div>
      </div>
      {tabId.length > 0 && (
        <Grid>
          <ReportSafetylogListContainer
            tabId={tabId}
            startDate={startDate}
            endDate={endDate}
            project={project}
            divison={divison}
            getItemLogData={getItemLogData}
            incSearchText={incSearchText}
            setIncSearchText={setIncSearchText}
          />
        </Grid>
      )}
    </div>
  );
}

export default ReportSafetyData;
