import React from "react";
import {
  LOGIN_ORG_ID,
  ORG_ID,
  USERMANAGEMENT,
} from "../../../../constant/constant";
import Card from "../../../../library/custom/cardList/card";
import { Grid } from "@mui/material";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const UserManagementList = ({ onClick }) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const isProcoreClient = userInfo?.isProcoreConnected;
  const ProcoreClientId = localStorage.getItem("IsProcoreConnected");
  const isClientSwitch = parseInt(ORG_ID()) !== parseInt(LOGIN_ORG_ID());

  let ShowCard = false;

  if (isClientSwitch) {
    if (ProcoreClientId === "true") {
      ShowCard = true;
    }
  } else {
    if (isProcoreClient) {
      ShowCard = true;
    }
  }

  return (
    <>
      <Grid container className="CardListContaner">
        {checkPrivileges([12, 64, 65, 66]) && (
          <>
            <Card
              iconShow={false}
              onClick={onClick}
              imgShow={true}
              cardDetail={USERMANAGEMENT[0]}
            />

            <Card
              iconShow={false}
              onClick={onClick}
              imgShow={true}
              cardDetail={USERMANAGEMENT[3]}
            />

            <Card
              iconShow={false}
              onClick={onClick}
              imgShow={true}
              cardDetail={USERMANAGEMENT[4]}
            />
          </>
        )}
        {checkPrivileges([12, 55, 56, 57]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={USERMANAGEMENT[1]}
          />
        )}
        {checkPrivileges([12, 14, 76, 77]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={USERMANAGEMENT[2]}
          />
        )}

        {checkPrivileges([12, 148]) && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={USERMANAGEMENT[5]}
          />
        )}
        {ShowCard && (
          <Card
            iconShow={false}
            onClick={onClick}
            imgShow={true}
            cardDetail={USERMANAGEMENT[6]}
          />
        )}
      </Grid>
    </>
  );
};

export default UserManagementList;
