import React from "react";
import "../../../incidents/cases/addCaseList.scss";
import Buttons from "../../../../library/custom/button/Button";
import AssetTable from "./assetTable";
import { checkPrivileges } from "../../../../utils/rolesHelper";

export default function AssetList({
  isAssetLoading,
  assetList,
  addContact,
  getAssetListData,
  divisionDropdown,
  assetCategory,
}) {
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Asset Type</div>
          <div>
            {checkPrivileges([37, 38, 12]) && (
              <Buttons
                id="btnAddAsset"
                label={"Add Asset"}
                varientAddIconBlue={true}
                onClick={() => addContact()}
              />
            )}
          </div>
        </div>
        <AssetTable
          assetList={assetList}
          isAssetLoading={isAssetLoading}
          getAssetListData={getAssetListData}
          filterShow={true}
          divisionDropdown={divisionDropdown}
          assetCategory={assetCategory}
        />
      </section>
    </>
  );
}
