import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { get, post, deletes } from "./axiosRequest";

export const getAssetList = async ({ data }) => {
  const {
    pageSize,
    divisionId = 0,
    page,
    statusIds = "",
    search,
    userId,
    organisationId,
    assetType = 0,
  } = data;
  const url = `${getApiUrl().adminApiUrl}/Asset/GetAssetsList?${
    organisationId ? `organisationId=${organisationId}` : ""
  }${divisionId ? `&divisionId=${divisionId}` : ""}${
    page ? `&page=${page}` : ""
  }${pageSize ? `&pageSize=${pageSize}` : ""}${
    search ? `&search=${search}` : ""
  }${
    userId ? `&userId=${userId}` : ""
  }&assetType=${assetType}&statusIds=${statusIds}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: organisationId,
      },
    });
  } catch (err) {
    return err;
  }
};

export const postAddAssest = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Asset/AddAsset`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const deleteAsset = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/Asset/DeleteAsset/${
    payload ? payload : ""
  }`;
  try {
    return await deletes(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getAssetDetails = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Asset/GetAssetDetails?assetId=${data}&orgId=${ORG_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateAsset = async ({ payload }) => {
  const url = `${getApiUrl().adminApiUrl}/Asset/UpdateAsset`;
  try {
    return await post(url, payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// git assetlist dynamo Db
export const getAssetListDynamo = async (data) => {
  const { searchText, type, orgId, assetCatId = 0, divisionId = 0 } = data.data;
  if (searchText?.trim()) {
    const url = `${getApiUrl().contactSearchListUrl}/getassetlist?orgId=${
      orgId ? orgId : ORG_ID()
    }&search=${
      searchText ? searchText?.trim().toLowerCase() : ""
    }&assetType=${type}&assetCatId=${assetCatId}&divisionId=${divisionId}`;

    try {
      return await get(
        url,
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        },
        true
      );
    } catch (err) {
      return err;
    }
  }
};
// git assetuserlist  ==============================
export const getUserAssetList = async (data) => {
  const { userId, type } = data?.data;

  const url = `${
    getApiUrl().adminApiUrl
  }/User/GetUserAssetList?assetType=${type}&userId=${userId}`;

  try {
    const res = await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });

    return res;
  } catch (err) {
    return err;
  }
};
