import { FormControlLabel, FormGroup, Grid } from "@mui/material";
import React, { useState } from "react";
import Checkbox from "../../../../library/checkbox/checkbox";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import Buttons from "../../../../library/custom/button/Button";
import Button from "../../../../library/custom/button/Button";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import { checkPrivileges } from "../../../../utils/rolesHelper";

import { useEffect } from "react";
import {
  HBNEXT_ORG_ID,
  ISwitch,
  LOGIN_ORG_ID,
  ORG_ID,
  USER_ID,
} from "../../../../constant/constant";
import {
  ITEM_FOUND_RESPONSES,
  LINK_QUESTION_INSPECTION,
  SW_CATEGORIES,
  SW_INSPECTION_CONFIGURATION,
  SW_SUB_CATEGORIES,
} from "../../../../constant/routeContant";
import { useNavigate, useLocation } from "react-router-dom";
import ToolBar from "../../../../library/storm-water/toolBar";
const InspectionGenerator = ({
  inspectionTypeFields,
  stateData,
  getInTyFeByInTyListData,
  getInTyFeByInTyList,
  updateInspectionTypeFieldsMapData,
  updateInspectionTypeFieldsMap,
  getSwReference,
  getInTyFeByInTyLists,
  getInspectionTypeFieldsListData,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dataBack = {
    title: "Back to Inspection Configuration",
    route: -1,
  };
  const [inspectionTypeFieldsLists, setInspectionTypeFieldsLists] = useState();
  const [inspectionType, setInspectionType] = useState("");
  const [isInspectionType, setIsInspectionType] = useState(false);
  const [isStateValue, setIsStateValue] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [checked, setChecked] = useState(false);
  const [insTypeList, setInsTypeList] = useState(null);

  useEffect(() => {
    if (getSwReference) {
      const newInsTypeList = getSwReference?.filter((item) => item.key !== 3);
      setInsTypeList(newInsTypeList);
    }
  }, [getSwReference]);

  const handleChangeDefault = () => {
    setChecked(!checked);
    const data = {
      inspectionTypeId: inspectionType,
      stateId: stateValue,
      userId: parseInt(USER_ID()),
      listInspectionTypeFields: null,
    };
    updateInspectionTypeFieldsMapData(data);
  };

  useEffect(() => {
    if (getInTyFeByInTyLists?.isGlobalHeader == true) {
      setChecked(true);
    } else if (getInTyFeByInTyLists?.isGlobalHeader == false) {
      setChecked(false);
    }
  }, [getInTyFeByInTyList]);

  const [stateValue, setStateValue] = useState();
  const handleStateChange = (e) => {
    setStateValue(e.target.value);
    const data = {
      stateId: e.target.value,
    };
  };
  const handleInspectionTypeChange = (e) => {
    setInspectionType(e.target.value);
    const data = {
      inspectionTypeId: e.target.value ? e.target.value : "",
      stateId: stateValue ? stateValue : "",
      projectId: 0,
    };
    getInTyFeByInTyListData(data);
  };

  useEffect(() => {
    if (stateValue) {
      setInspectionType("");
      getInspectionTypeFieldsListData();
    }
  }, [stateValue]);

  useEffect(() => {
    if (inspectionTypeFields) {
      setInspectionTypeFieldsLists(inspectionTypeFields);
    }
  }, [inspectionTypeFields]);

  useEffect(() => {
    if (getInTyFeByInTyList) {
      setInspectionTypeFieldsLists(getInTyFeByInTyList);
    }
  }, [getInTyFeByInTyList]);

  const handleMandatoryChange = (item) => {
    const newCloneList = [...inspectionTypeFieldsLists];
    newCloneList.forEach((lists) => {
      if (lists.key == item.key) {
        lists.isMandatory = !lists.isMandatory;
        lists.key = item?.key;
        lists.value = item?.value;
        lists.isDefault = item?.isDefault;
      }
    });
    setInspectionTypeFieldsLists(newCloneList);
  };

  const handleDefaultChange = (item) => {
    const newCloneList = [...inspectionTypeFieldsLists];
    newCloneList.forEach((lists) => {
      if (lists.key == item.key) {
        lists.isDefault = !lists.isDefault;
        lists.key = item?.key;
        lists.value = item?.value;
        lists.isMandatory = item?.isMandatory;
      }
    });
    setInspectionTypeFieldsLists(newCloneList);
  };

  const handleChange = (item) => {
    const newCloneList = [...inspectionTypeFieldsLists];
    newCloneList.forEach((lists) => {
      if (lists.key == item.key) {
        lists.isSelected = !lists.isSelected;
        lists.isDefault = false;
        lists.isMandatory = false;
        lists.key = item?.key;
        lists.value = item?.value;
      }
    });
    setInspectionTypeFieldsLists(newCloneList);
  };

  function mappedData() {
    let errors = false;
    if (!inspectionType) {
      setIsInspectionType(true);
      errors = true;
    }
    if (!stateValue) {
      setIsStateValue(true);
      errors = true;
    }

    if (errors) {
      return false;
    }
    const returndata = [...inspectionTypeFieldsLists];
    returndata.forEach((lists) => {
      lists.isMandatory = lists.isMandatory ? lists.isMandatory : false;
      lists.isSelected = lists.isSelected ? lists.isSelected : false;
      lists.isDefault = lists.isDefault ? lists.isDefault : false;
    });
    const data = {
      inspectionTypeId: inspectionType,
      stateId: stateValue,
      userId: parseInt(USER_ID()),
      listInspectionTypeFields: returndata,
    };
    return data;
  }

  const submitData = () => {
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      updateInspectionTypeFieldsMapData(data);
    }
  };

  useEffect(() => {
    if (
      updateInspectionTypeFieldsMap?.status === "SUCCESS" &&
      updateInspectionTypeFieldsMap?.isLoading === false
    ) {
      const data = {
        inspectionTypeId: inspectionType ? inspectionType : 0,
        stateId: stateValue ? stateValue : 0,
        projectId: 0,
      };
      getInTyFeByInTyListData(data);
      setIsSubmit(false);
    }
  }, [updateInspectionTypeFieldsMap]);

  const disbleSelectHandler = (item) => {
    let isSelectValue = false;

    if (
      parseInt(ORG_ID()) !== parseInt(HBNEXT_ORG_ID) &&
      item?.isDefault === true
    ) {
      isSelectValue = true;
    } else {
      isSelectValue = false;
    }
    return isSelectValue;
  };

  const checkBoxHandler = (item) => {
    let isValue = false;
    if (
      parseInt(ORG_ID()) !== parseInt(HBNEXT_ORG_ID) &&
      item?.isMandatory &&
      item?.isDefault &&
      item?.key !== 4
    ) {
      isValue = true;
    } else if (item?.isSelected && item?.key === 4) {
      isValue = false;
    } else if (!item?.isSelected) {
      isValue = true;
    } else {
      isValue = false;
    }
    return isValue;
  };

  useEffect(() => {
    if (inspectionTypeFields) {
      const updatedFormData = inspectionTypeFields?.map((item) => ({
        ...item,
        isSelected: false,
      }));
      setInspectionTypeFieldsLists(updatedFormData);
    }
  }, [location, inspectionTypeFields]);

  const toolBarData = {
    title: "Inspection Form Configuration",
    list: [
      {
        id: 5,
        title: "Inspection Header",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
        activeClass: "active",
      },
      {
        id: 1,
        title: "Category Configuration",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 2,
        title: "Sub Category Configuration",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 3,
        title: "Questions Configuration",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 4,
        title: "Item Found Responses",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
    ],
  };

  return (
    <>
      <Grid className="inspectionOverRide stromwater">
        <div className="heading_style">Inspection Generator</div>
        <Grid container item md={12} xs={12} className="addShadowBox">
          <Grid container>
            <Grid item md={8} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={12} xs={12} mb={3}>
                  <FormControl fullWidth>
                    <FormLabels label="Select State" isRequired={true} />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={stateData ?? []}
                      value={stateValue ?? ""}
                      onchangehandler={(e) => handleStateChange(e)}
                      isError={isStateValue}
                      errorMsg={isStateValue ? "State is Required" : ""}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={12} xs={12} mb={3}>
                  <FormControl fullWidth>
                    <FormLabels
                      label="Select Inspection Type"
                      isRequired={true}
                    />
                    <SelectMenu
                      placeholder="Please Select"
                      listData={insTypeList ?? []}
                      value={inspectionType}
                      onchangehandler={(e) => handleInspectionTypeChange(e)}
                      isError={isInspectionType}
                      disabled={!stateValue ? true : false}
                      errorMsg={
                        isInspectionType ? "Inspection Type is Required" : ""
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid md={12} xs={12}>
                  <div className="insTyF">
                    <div className="insTyFLeft">Select Field</div>
                    <div className="insTyFRight">Mandatory</div>
                    {parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && (
                      <div className="insTyFRight">Default</div>
                    )}
                  </div>
                  <div className="insTyBox">
                    {inspectionTypeFieldsLists &&
                      inspectionTypeFieldsLists?.map((item) => {
                        return (
                          <div className="insTyList" key={item?.key}>
                            <div className="insTyFLeft">
                              <Checkbox
                                checked={item?.isSelected ? true : false}
                                onChange={() => handleChange(item)}
                                label={item?.value}
                                // disabled={item?.isDefault ? true : false}
                                disabled={disbleSelectHandler(item)}
                              />
                            </div>
                            <div className="insTyFRight">
                              <Checkbox
                                checked={
                                  item?.isSelected && item?.isMandatory
                                    ? true
                                    : false
                                }
                                onChange={() => handleMandatoryChange(item)}
                                label={""}
                                disabled={checkBoxHandler(item)}
                              />
                            </div>
                            {parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && (
                              <div className="insTyFRight">
                                <Checkbox
                                  checked={
                                    item?.isSelected && item?.isDefault
                                      ? true
                                      : false
                                  }
                                  onChange={() => handleDefaultChange(item)}
                                  label={""}
                                  disabled={
                                    !item?.isSelected ||
                                    parseInt(LOGIN_ORG_ID()) !==
                                      parseInt(HBNEXT_ORG_ID)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </Grid>
                {inspectionType && ORG_ID() != 1 && (
                  <Grid container spacing={2} pl={1} justifyContent="flex-end">
                    <Grid item lg={3.7} md={12} xs={12} mt={3}>
                      <Grid className="catListBox">
                        <Grid className="closeIcon">
                          <FormControl component="fieldset" variant="standard">
                            <FormGroup className="isActiveBox swins cursorDefault">
                              <FormControlLabel
                                className="cursorActive"
                                control={
                                  <ISwitch
                                    checked={checked}
                                    onChange={handleChangeDefault}
                                    name="status"
                                    disabled={
                                      getInTyFeByInTyLists?.isGlobalHeader ==
                                      true
                                    }
                                  />
                                }
                              />
                              <div>
                                {checked ? "Default Header" : "Custom Header"}
                              </div>
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid container mt={10} md={11} className="insGenBt">
                  <Grid item lg={6} md={12} xs={12}>
                    <div className="insBackBtn">
                      <BackBtn dataBack={dataBack} />
                    </div>
                  </Grid>
                  <Grid item lg={6} md={12} xs={12}>
                    <Grid
                      container
                      item
                      lg={12}
                      xs={12}
                      className="divisionBtnBox"
                    >
                      <Grid lg={6} xs={12} sm={6}>
                        <Buttons
                          onClick={() => navigate(SW_INSPECTION_CONFIGURATION)}
                          varientText={true}
                          label="Cancel"
                        />
                      </Grid>
                      <Grid lg={6} xs={12} sm={6}>
                        <Button
                          varientContained={true}
                          label={"Save"}
                          disabled={isSubmit ? true : false}
                          onClick={submitData}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid lg={4} md={12} xs={12} item className="inspectionTool">
              <div className="toolBarBox">
                <ToolBar
                  toolBarData={toolBarData}
                  handleClick={(e) => {
                    if (e.id === 1) {
                      navigate(SW_CATEGORIES);
                    }
                    if (e.id === 2) {
                      navigate(SW_SUB_CATEGORIES);
                    }
                    if (e.id === 3) {
                      navigate(LINK_QUESTION_INSPECTION);
                    }
                    if (e.id === 4) {
                      navigate(ITEM_FOUND_RESPONSES);
                    }
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InspectionGenerator;
