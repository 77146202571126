export const incidentType = {
  GET_INCIDENT: "[REQUEST] Get Incident Data",
  GET_INCIDENT_SUCCESS: "[RESPONSE] Get Incident Data Successfull",
  GET_INCIDENT_FAILURE: "[RESPONSE] Get Incident Data Failed",
  INCIDENT_PAGE: "InicidentPage",
  CASES_PAGE: "CasesPage",
  CASEVIEW_PAGE: "case view page",

  // list of incidents
  DELETE_INCIDENT: "DELETE_INCIDENT",
  DELETE_INCIDENT_SUCCESS: "DELETE_INCIDENT_SUCCESS",
  DELETE_INCIDENT_FAILED: "DELETE_INCIDENT_FAILED",

  //GetIncidentList

  GET_INCIDENT_LIST: "[GET_INCIDENT_LIST] Get Incident List Data",
  GET_INCIDENT_LIST_SUCCESS:
    "[GET_INCIDENT_LIST_SUCCESS] Get Incident List Data Successfull",
  GET_INCIDENT_LIST_FAILURE:
    "[GET_INCIDENT_LIST_FAILURE] Get Incident List Data Failed",

  GET_CASES_LIST: "[GET_CASES_LIST] Get Cases List Data",
  GET_CASES_LIST_SUCCESS:
    "[GET_CASES_LIST_SUCCESS] Get Cases List Data Successfull",
  GET_CASES_LIST_FAILURE: "[GET_CASES_LIST_FAILURE] Get Cases List Data Failed",

  GET_CASE_DETAILS_PENDING: "[GET_CASE_DETAILS_PENDING] Get Cases Detail Data",
  GET_CASE_DETAILS_SUCCESS:
    "[GET_CASES_LIST_SUCCESS] Get Cases Detail Data Successfull",
  GET_CASE_DETAILS_FAILED:
    "[GET_CASES_LIST_FAILURE] Get Cases Detail Data Failed",

  GET_ACTION_LIST: "[GET_ACTION_LIST] Get Action List Data",
  GET_ACTION_LIST_SUCCESS:
    "[GET_ACTION_LIST_SUCCESS] Get Action List Data Successfull",
  GET_ACTION_LIST_FAILURE:
    "[GET_ACTION_LIST_FAILURE] Get Action List Data Failed",

  CLOSE_ACTION_ITEM: "[UPDATE_ACTION_LIST] Get Action List Data",
  CLOSE_ACTION_ITEM_SUCCESS:
    "[UPDATE_ACTION_LIST_SUCCESS] Get Action List Data Successfull",
  CLOSE_ACTION_ITEM_FAILURE:
    "[UPDATE_ACTION_LIST_FAILURE] Get Action List Data Failed",

  CLOSE_INCIDENT: "[CLOSE_INCIDENT] CLOSE_INCIDENT Data",
  CLOSE_INCIDENT_SUCCESS:
    "[CLOSE_INCIDENT_SUCCESS] CLOSE_INCIDENT Data Successfull",
  CLOSE_INCIDENT_FAILURE: "[CLOSE_INCIDENT_FAILURE] CLOSE_INCIDENT Data Failed",

  DELETE_CASE: "DELETE_CASE",
  DELETE_CASE_SUCCESS: "DELETE_CASE_SUCCESS",
  DELETE_CASE_FAILED: "DELETE_CASE_FAILED",
  REPORT_INCIDENT_PAGE: "REPORT_INCIDENT_PAGE",
  GET_INCIDENT_REPORT: "[GET_INCIDENT_REPORT] Get Incident Report",
  GET_INCIDENT_REPORT_SUCCESS:
    "[GET_INCIDENT_REPORT_SUCCESS] Get Incident Report Successfull",
  GET_INCIDENT_REPORT_FAILURE:
    "[GET_INCIDENT_REPORT_FAILURE] Get Incident Report Failed",

  GET_INCIDENT_REPORT_INJURED_BODYPART:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART] Get Incident Injured body part Report",
  GET_INCIDENT_REPORT_INJURED_BODYPART_SUCCESS:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART_SUCCESS] Get Incident Injured body part Report Successfull",
  GET_INCIDENT_REPORT_INJURED_BODYPART_FAILURE:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART_FAILURE] Get Incident Injured body part Report Failed",

  GET_INCIDENT_REPORT_INJURED_BODYPART_LIST:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART_LIST] Get Incident Injured body lIST part Report",
  GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_SUCCESS:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_SUCCESS] Get Incident Injured body lIST part Report Successfull",
  GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_FAILURE:
    "[GET_INCIDENT_REPORT_INJURED_BODYPART_LIST_FAILURE] Get Incident Injured body lIST part Report Failed",

  GET_INCIDENT_REPORT_MOST_COMMON_INJURY:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY] Get Most common Injury Type Report",
  GET_INCIDENT_REPORT_MOST_COMMON_INJURY_SUCCESS:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY_SUCCESS] Get Most common Injury Type Report Successfull",
  GET_INCIDENT_REPORT_MOST_COMMON_INJURY_FAILURE:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY_FAILURE] Get Most common Injury Type Report Failed",

  GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST] Get Most common Injury lIST Type Report",
  GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_SUCCESS:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_SUCCESS] Get Most common Injury lIST Type Report Successfull",
  GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_FAILURE:
    "[GET_INCIDENT_REPORT_MOST_COMMON_INJURY_LIST_FAILURE] Get Most common Injury lIST Type Report Failed",

  GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY:
    "[GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY] Get Most common day Injury",
  GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_SUCCESS:
    "[GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_SUCCESS] Get Most common day Injury Successfull",
  GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_FAILURE:
    "[GET_INCIDENT_REPORT_MOST_COMMON_DAY_OF_INJURY_FAILURE] Get Most common day Injury Failed",
  // iNCIDENT/action items  WITH USER
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS] Get USER WITH MOST incidents",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_SUCCESS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_SUCCESS] Get USER WITH MOST incidents Successfull",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_FAILURE:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_FAILURE] Get USER WITH MOST incidents Failed",

  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST] Get USER WITH MOST incidents LIST",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_SUCCESS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_SUCCESS] Get USER WITH MOST incidents LIST Successfull",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_FAILURE:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_INCIDENTS_LIST_FAILURE] Get USER WITH MOST incidents LIST Failed",

  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS] Get USER WITH MOST ACTION ITEMS",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_SUCCESS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_SUCCESS]  Get USER WITH MOST ACTION ITEMS Successfull",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_FAILURE:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_FAILURE]  Get USER WITH MOST ACTION ITEMS Failed",

  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST] Get USER WITH MOST ACTION ITEMS lIST",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_SUCCESS:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_SUCCESS] Get USER WITH MOST ACTION ITEMS lIST Successfull",
  GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_FAILURE:
    "[GET_INCIDENT_REPORT_USER_WITH_MOST_NO_OF_ACTION_ITEMS_LIST_FAILURE] Get USER WITH MOST ACTION ITEMS lIST Failed",

  PROJECT_WITH_MOST_INCIDENT:
    "[PROJECT_WITH_MOST_INCIDENT] Get Project With Most Incident",
  PROJECT_WITH_MOST_INCIDENT_SUCCESS:
    "[PROJECT_WITH_MOST_INCIDENT_SUCCESS] Get Project With Most Incident Successfull",
  PROJECT_WITH_MOST_INCIDENT_FAILURE:
    "[PROJECT_WITH_MOST_INCIDENT_FAILURE] Get Project With Most Incident Failed",

  PROJECT_WITH_MOST_INCIDENT_LIST:
    "[PROJECT_WITH_MOST_INCIDENT_LIST] Project WithMostIncident lIST",
  PROJECT_WITH_MOST_INCIDENT_LIST_SUCCESS:
    "[PROJECT_WITH_MOST_INCIDENT_LIST_SUCCESS] Get Project With Most Incident lIST Successfull",
  PROJECT_WITH_MOST_INCIDENT_LIST_FAILURE:
    "[PROJECT_WITH_MOST_INCIDENT_LIST_FAILURE] Get Project With Most Incident lIST Failed",

  VEHICLE_WITH_MOST_INCIDENT:
    "[VEHICLE_WITH_MOST_INCIDENT] Get VEHICLE With Most Incident",
  VEHICLE_WITH_MOST_INCIDENT_SUCCESS:
    "[VEHICLE_WITH_MOST_INCIDENT_SUCCESS] Get VEHICLE With Most Incident Successfull",
  VEHICLE_WITH_MOST_INCIDENT_FAILURE:
    "[VEHICLE_WITH_MOST_INCIDENT_FAILURE] Get VEHICLE With Most Incident Failed",

  VEHICLE_WITH_MOST_INCIDENT_LIST:
    "[VEHICLE_WITH_MOST_INCIDENT_LIST] VEHICLE WithMostIncident lIST",
  VEHICLE_WITH_MOST_INCIDENT_LIST_SUCCESS:
    "[VEHICLE_WITH_MOST_INCIDENT_LIST_SUCCESS] Get VEHICLE With Most Incident lIST Successfull",
  VEHICLE_WITH_MOST_INCIDENT_LIST_FAILURE:
    "[VEHICLE_WITH_MOST_INCIDENT_LIST_FAILURE] Get VEHICLE With Most Incident lIST Failed",
};
