import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewcCasetable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import { useNavigate } from "react-router-dom";
import UserFilterList from "../../shared/UserFilterList ";
import { TableLoader } from "../../../../library/common/Loader";
import {
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import {
  ADD_ADMIN_NONPRIVILEGE_USER,
  ADMIN_SAFETY_LOG,
} from "../../../../constant/routeContant";
import DialogBox from "../../../../library/common/DialogBox";
import NORow from "../../../../library/custom/noRow/noRow";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { encodeSearchText } from "../../../../utils/helper";

export default function NonPrivilegeUserList({
  NavigateSingleUserMethode,
  loading,
  jobTitles,
  deleteNonPrivilegeLists,
  isDivision = true,
  divisiondata,
  NonPrivilegeuserListData,
  getNonPrivilegeLists,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const [search, setSearch] = useState("");
  const [jobTitle, setjobTitle] = useState("");
  const [divisionfilter, setDivisionFilter] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [userListApiData, setUserListApiData] = useState({
    organisationId: ORG_ID(),
    page: 1,
    pageSize: pageSize,
    search: "",
    orgId: ORG_ID(),
    titleId: jobTitle ? jobTitle : "",
    divisionId: divisionfilter ? divisionfilter : "",
  });
  const [isTableLoading, setisTableLoading] = useState(false);

  const navigate = useNavigate();

  const deletehandle = () => {
    setOpenModal(false);
    deleteNonPrivilegeLists(selectedRow.userid);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openAdd = Boolean(anchorElAdd);
  const idAdd = openAdd ? "simple-popover" : undefined;

  const handleClickAdd = (event) => {
    NavigateSingleUserMethode();
  };

  const handleautoSearch = (event) => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      page: 1,
      pageSize: pageSize,
      titleId: jobTitle ? jobTitle : "",
      divisionId: divisionfilter ? divisionfilter : "",
      search: searchText ? encodeSearchText(searchText) : "",
    };
    setUserListApiData({
      page: 1,
      pageSize: pageSize,
      titleId: jobTitle ? jobTitle : "",
      divisionId: divisionfilter ? divisionfilter : "",
      search: searchText ? encodeSearchText(searchText) : "",
    });
    getNonPrivilegeLists(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      ...userListApiData,
      page: newPage + 1,
      pageSize: pageSize,
      search: search ? encodeSearchText(search) : "",
      orgId: ORG_ID(),
      titleId: jobTitle ? jobTitle : "",
      divisionId: divisionfilter ? divisionfilter : "",
    };
    setUserListApiData({
      ...userListApiData,
      page: newPage + 1,
      pageSize: pageSize,
      search: search ? encodeSearchText(search) : "",
      titleId: jobTitle ? jobTitle : "",
      divisionId: divisionfilter ? divisionfilter : "",
    });
    getNonPrivilegeLists(data);
  };

  const handleViewOnClick = (rows) => {
    navigate(ADMIN_SAFETY_LOG, {
      state: { userId: rows.row.userid, userSafetyLog: true },
    });
  };

  const handleOnclick = (rows) => {
    navigate(ADD_ADMIN_NONPRIVILEGE_USER, {
      state: { userId: rows.row.userid, editMode: true },
    });
  };
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteIncidents = (rows) => {
    setOpenModal(true);
    setSelectedRow(rows);
  };

  const columns = [
    { field: "userid", headerName: "User ID", flex: 2 },
    {
      field: "fullName",
      headerName: "Name",
      flex: 3,
      valueGetter: (params) =>
        `${params.getValue(params.id, "firstname") || ""} ${
          params.getValue(params.id, "lastname") || ""
        }`,
    },
    { field: "jobTitlename", headerName: "Job Title", flex: 3 },
    isDivision && { field: "divisionname", headerName: "Division", flex: 3 },
    isDivision && {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={NonPrivilegeuserListData?.itemsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={NonPrivilegeuserListData?.userData ?? []}
          fileNameXL="Non Privileged Users List"
        />
      ),
      flex: 1,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return isDivision ? (
          <ReportRowManipulte
            rows={rows}
            safetyLogOnClick={() => handleViewOnClick(rows)}
            editOnClick={() => handleOnclick(rows)}
            deleteOnClick={() => deleteIncidents(rows?.row)}
            edit={checkPrivileges([64, 65, 12])}
            editLabel="Edit User"
            safetyLog={checkPrivileges([64, 65, 66, 12])}
            safetyLogLabel="Safety Log"
            deleted={checkPrivileges([64, 12])}
            deleteLabel="Delete User"
          />
        ) : (
          ""
        );
      },
    },
  ];

  const jobtitleOnChanges = (e) => {
    setjobTitle(e.target.value);
  };

  const onRowClick = (e) => {
    console.log("ROW CLICK");
  };

  const handleSearch = (data) => {
    setAnchorEl(null);
    getNonPrivilegeLists(data);
  };
  const handleClear = () => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    setjobTitle("");
    setDivisionFilter("");
    setSearch("");
    const data = {
      page: 1,
      pageSize: pageSize,
      search: "",
      titleId: "",
      orgId: ORG_ID(),
      divisionId: "",
    };
    handleSearch(data);
  };
  const handleApply = () => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    setUserListApiData({
      ...userListApiData,
      titleId: jobTitle ? jobTitle : "",
      divisionId: divisionfilter ? divisionfilter : "",
    });
    setAnchorEl(null);
    const data = {
      ...userListApiData,
      page: 1,
      pageSize: pageSize,
      search: search ? encodeSearchText(search) : "",
      orgId: ORG_ID(),
      titleId: jobTitle ? jobTitle : "",
      divisionId: divisionfilter ? divisionfilter : "",
    };
    getNonPrivilegeLists(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      ...userListApiData,
      page: 1,
      pageSize: newPazeSize,
      search: search ? encodeSearchText(search) : "",
      orgId: ORG_ID(),
      titleId: jobTitle ? jobTitle : "",
      divisionId: divisionfilter ? divisionfilter : "",
    };
    getNonPrivilegeLists(data);
  };

  const divisionFilterOnChanges = (e) => {
    setDivisionFilter(e.target.value);
  };

  return (
    <>
      <section className="grid_main_sec">
        {isDivision && (
          <div className="grid_main_header two_grid">
            <div className="grid_heading_style">Non-Privileged Users List</div>
            {openModal && (
              <DialogBox
                open={openModal}
                rows={[]}
                header={"Confirm Deletion"}
                deletehandle={deletehandle}
                handleCloseModal={() => setOpenModal(false)}
                content={"Are you sure you want to delete this User?"}
                view
              />
            )}
            <div>
              <div className="filter_btn">
                {checkPrivileges([12, 64, 65]) && (
                  <Buttons
                    id="btnAddUsers"
                    aria-describedby={idAdd}
                    varientAddIconBlue={true}
                    label={"Add User"}
                    onClick={(e) => handleClickAdd(e)}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>

            {isDivision && (
              <div className="filter_btn">
                <button
                  aria-describedby={id}
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClick(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>
                <UserFilterList
                  secondLabel="Job Title"
                  firstLabel="Division"
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  handleSearch={handleSearch}
                  handleApply={handleApply}
                  secondLebelFilter={jobTitles}
                  resetBtn={true}
                  handleClear={handleClear}
                  secondLebelOnChanges={jobtitleOnChanges}
                  secondLebelValue={jobTitle}
                  firstLebelOnChanges={divisionFilterOnChanges}
                  firstLebelValue={divisionfilter}
                  firstLebelFilter={divisiondata}
                  isShow={true}
                />
              </div>
            )}
          </div>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <ViewcCasetable
                  getRowId={(r) => r.userid}
                  rows={NonPrivilegeuserListData?.userData || []}
                  rowCount={NonPrivilegeuserListData?.itemsCount}
                  onRowClick={onRowClick}
                  columns={columns}
                  loading={loading}
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                  checkboxSelection={false}
                  page={page}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
