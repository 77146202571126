import React, { useState } from "react";
import Buttons from "../../../../library/custom/button/Button";
import { Grid } from "@mui/material";
import {
  CLIENT_SATATUS_FILTER,
  ISwitch,
  PAGE_SIZE,
  USER_ID,
} from "../../../../constant/constant";
import Loader, { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import CustomTable from "../../../../library/custom/customTable/customTable";
import "./jha.scss";
import TextEditor from "../../../../library/custom/textEditor/TextEditor";
import commonImages from "../../../../assets";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import ServiceeFilterList from "../jha/serviceListFilter";
import FilterListIcon from "@mui/icons-material/FilterList";

const JhaSetControls = ({
  handleEditOnClick,
  controlListData = [],
  isLoading,
  hazardName,
  hazardId,
  activityName,
  formValue,
  setformValue,
  addControl,
  handleStatusChange,
  getJhaControlList,
  itemsCount,
  controlTypeList,
  setshowControlPage,
  setFilterFormValue,
  filterFormValue,
}) => {
  const dataBack = {
    title: "Back to Hazards",
    route: -1,
  };

  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const openFilter = Boolean(anchorElFilter);
  const id = openFilter ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const [selectLabel, setSelectLabel] = useState("No control Type");
  const columns = [
    {
      field: "controlsName",
      headerName: "All Controls",
      flex: 3,
      renderCell: (rows) => (
        <Grid container item lg={12} className="">
          <Grid
            style={{
              display: "flex",
              textAlign: "left",
              overflow: "scroll",
              width: "100%,",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            item
            lg={12}
            className="richTextControl"
          >
            {/* {parse(rows.row.controlsName)} */}
            <div
              dangerouslySetInnerHTML={{
                __html: rows.row.controlsName,
              }}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      ),
    },
    { field: "controlType", headerName: "Control Type", flex: 1 },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox">
                {checkPrivileges([58, 12]) && (
                  <FormControlLabel
                    control={
                      <ISwitch
                        checked={
                          rows?.row.isActive ? rows?.row.isActive : false
                        }
                        onChange={() => handleChange(rows?.row)}
                        name="status"
                      />
                    }
                  />
                )}
                <div>{rows?.row?.isActive ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      flex: 1,
      field: "icon",
      renderHeader: () => "",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            editOnClick={() => {
              handleEditOnClick(rows.row);
            }}
            edit={checkPrivileges([58, 59, 12])}
            editLabel="Edit Control"
          />
        );
      },
    },
  ];

  const handleChange = (hazardsSetItem) => {
    const newCatList = [...controlListData];
    newCatList.forEach((lists) => {
      if (lists.id === hazardsSetItem.id) {
        lists.isGlobal = false;
        lists.isActive = !lists.isActive;
        lists.listsmodifiedBy = parseInt(USER_ID());
      }
    });
    setpage(1);
    handleStatusChange(hazardsSetItem);
  };
  const [page, setpage] = useState(1);
  const [isControlName, setisControlName] = useState(false);

  const [isAlreadyExists, setIsAlreadyExists] = useState(false);
  const [isExistsErrorMsg, setisExistsErrorMsg] = useState("");

  function submitData() {
    let error = false;
    if (formValue.controlsName === "") {
      setisControlName(true);
      error = true;
    }
    if (formValue.controlTypeId === "0") {
      setformValue({
        ...formValue,
        controlTypeId: 0,
      });
    }
    const index = controlListData?.findIndex(
      (object) =>
        object.controlsName.toUpperCase() ===
        formValue?.controlsName.toUpperCase()
    );
    if (index >= 0) {
      setIsAlreadyExists(true);
      setisExistsErrorMsg("Control Already Exists");
      error = true;
      return;
    }
    if (!error) {
      addControl();
      setSelectLabel("Please Select");
    }
  }

  const handlePagignation = (newPage) => {
    setpage(newPage + 1);
    const data = {
      search: "",
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      hazardId: hazardId,
      status:
        filterFormValue.isActive === 1
          ? true
          : filterFormValue.isActive === 2
          ? false
          : "",
    };
    getJhaControlList(data);
  };

  const [isTableLoading, setisTableLoading] = useState(false);
  return (
    <>
      <Grid className="overrideadmincategory">
        <Grid container className="confBox">
          <Grid item md={8.5} xs={12}>
            <div className="confTitile">Set Controls</div>
          </Grid>
        </Grid>
        <Grid container className="confBox">
          <Grid item md={8.5} xs={12} mt={4}>
            <div className="confSubTitle">{`${activityName} - ${hazardName}`}</div>
          </Grid>
        </Grid>
        {checkPrivileges([58, 59, 12]) && (
          <Grid
            item
            container
            justifyContent={"space-between"}
            mt={1.5}
            lg={11.6}
          >
            <Grid mt={3} lg={6} xs={12} sm={12} md={6} item>
              <FormControl fullWidth>
                <FormLabels label="Select Control Type (optional)" />
                <SelectMenu
                  listData={controlTypeList ?? []}
                  value={formValue.controlTypeId}
                  placeholder={selectLabel}
                  onchangehandler={(e) => {
                    setSelectLabel("No control Type");
                    setformValue({
                      ...formValue,
                      controlTypeId: e.target.value,
                    });
                  }}
                  isgroupplaceholder={true}
                />
              </FormControl>
            </Grid>

            <Grid className="smallAddBtn" item mt={6} lg={1.1}></Grid>
          </Grid>
        )}
        {checkPrivileges([58, 59, 12]) && (
          <Grid container mt={4} mb={3}>
            <Grid item lg={12} md={12} xs={12}>
              <FormLabels label="Add Control" isRequired={true} />
              <TextEditor
                value={formValue.controlsName ?? ""}
                placeholderText={"Enter Here..."}
                onChange={(e) => {
                  setformValue({
                    ...formValue,
                    controlsName: e,
                    hazardId: hazardId,
                  });
                  setisExistsErrorMsg("");
                  setisControlName(false);
                  setIsAlreadyExists(false);
                }}
                isError={isControlName}
                errorMsg={isControlName ? "Control name is required" : ""}
              />
            </Grid>
          </Grid>
        )}
        {checkPrivileges([58, 59, 12]) && (
          <Grid
            item
            container
            justifyContent={"space-between"}
            mt={1.5}
            lg={11.6}
          >
            <Grid mt={3} lg={6} xs={12} sm={12} md={6} item></Grid>

            <Grid className="smallAddBtn" item mt={2} lg={1.1}>
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={"Add"}
                onClick={(e) => {
                  submitData();
                }}
                id="btnAddEquipment"
                varientAddIconBlue={true}
              ></Buttons>
            </Grid>
            <Grid md={12} item>
              {isAlreadyExists && (
                <p className="errorMsg">{isExistsErrorMsg}</p>
              )}
            </Grid>
          </Grid>
        )}

        <Grid item container md={12} className="CategoriesBox" mt={3}>
          <Grid item md={12} xs={8}>
            <div className="filter_of_caselist filter_of_caselist_right">
              <div className="filter_btn">
                <button
                  aria-describedby={id}
                  variant="contained"
                  type="button"
                  name="btn"
                  onClick={(e) => handleClick(e)}
                >
                  <FilterListIcon id="icn" /> Filters
                </button>
              </div>
            </div>

            <ServiceeFilterList
              id={id}
              open={openFilter}
              anchorEl={anchorElFilter}
              handleClose={() => {
                setAnchorElFilter(null);
                window.close();
              }}
              handleApply={() => {
                setpage(1);
                const data = {
                  search: "",
                  page: 1,
                  pageSize: PAGE_SIZE,
                  hazardId: hazardId,
                  status:
                    filterFormValue.isActive === 1
                      ? true
                      : filterFormValue.isActive === 2
                      ? false
                      : "",
                };
                setisTableLoading(true);
                setTimeout(() => {
                  setisTableLoading(false);
                }, 300);
                getJhaControlList(data);
                setAnchorElFilter(null);
                window.close();
              }}
              secondLabel="Status"
              secondLebelFilter={CLIENT_SATATUS_FILTER}
              secondLebelValue={filterFormValue.isActive}
              firstLebelOnChanges={(e) =>
                setFilterFormValue({
                  ...filterFormValue,
                })
              }
              secondLebelOnChanges={(e) =>
                setFilterFormValue({
                  ...filterFormValue,
                  isActive: e.target.value,
                })
              }
              handleClear={() => {
                setFilterFormValue({
                  assetType: "",
                  isActive: "",
                });

                setpage(1);
                const data = {
                  search: "",
                  page: 1,
                  pageSize: PAGE_SIZE,
                  hazardId: hazardId,
                  status: "",
                };
                setisTableLoading(true);
                setTimeout(() => {
                  setisTableLoading(false);
                }, 300);
                getJhaControlList(data);
                setAnchorElFilter(null);
                window.close();
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item md={12} xs={12}>
            <Grid item md={12} className="CategoriesBox" mt={4}>
              {isTableLoading ? (
                <Loader />
              ) : (
                <div className="desc_box row_uniq">
                  <div className="table_style_ui inspectionSet">
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <CustomTable
                        getRowId={(r) => r.id}
                        rows={controlListData ?? []}
                        rowCount={itemsCount ?? 0}
                        columns={columns}
                        loading={isLoading}
                        checkboxSelection={false}
                        page={page}
                        pagination={true}
                        pageSize={PAGE_SIZE}
                        rowsPerPageOptions={[PAGE_SIZE]}
                        backIconButtonProps={{ disabled: false }}
                        disableSelectionOnClick
                        components={{
                          LoadingOverlay: TableLoader,
                          NoRowsOverlay: NORow,
                        }}
                        onPageChange={(e) => handlePagignation(e)}
                      />
                    )}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div>
          <Grid className="backBtn" onClick={() => setshowControlPage(false)}>
            <Grid className="backImg">
              <img src={commonImages?.backBtn} alt="" />
            </Grid>
            <Grid className="backTitle">{dataBack?.title}</Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default JhaSetControls;
