import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormControl, Grid } from "@mui/material";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";
import Table from "../../../../library/custom/table/table";
import {
  associatedIncidentType1,
  PAGE_SIZE_100,
} from "../../../../constant/constant";
import {
  REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
  VIEW_INCIDENT_REPORT,
} from "../../../../constant/routeContant";
import {
  downloadExcel,
  downloadPDF,
  useInfiniteScroll,
} from "../../../../utils/helper";
import commonImages from "../../../../assets";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import * as incidentAction from "../../../../redux/actions/incidentAction";

const TotalIncidents = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const [page, setPage] = useState(1);
  const [isreset, setIsreset] = useState(false);
  const [incvalue, setIncvalue] = useState(
    props?.drillDetail?.apiData?.incidentType > 0
      ? props?.drillDetail?.apiData?.incidentType
      : -1
  );
  const pageSize = PAGE_SIZE_100;

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);

  const getIncidentListData = (data) => {
    dispatch(incidentAction.getIncidentList(data));
  };

  useEffect(() => {
    if (props?.incidentList && !isreset) {
      setData((prevData) => [...prevData, ...props?.incidentList]);
    } else {
      setData(props?.incidentList);
      setIsreset(false);
    }
  }, [props?.incidentList, isreset]);

  const fetchMoreData = async (pageNumber) => {
    const { startDate, endDate, incidentType, divisionId, locationId } =
      props.drillDetail?.apiData;
    const data = {
      pageNumber: pageNumber,
      pageSize: PAGE_SIZE_100,
      incidentType: incvalue > 0 ? incvalue : 0,
      startDate: startDate,
      endDate: endDate,
      divisionId: divisionId,
      locationId: locationId,
    };
    try {
      const response = await props?.getIncidentListData(data);
      return response?.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    setPage(pageNumber);
    const moreData = await fetchMoreData(pageNumber);
    setData((prevData) => [...prevData, ...moreData]);
  };

  useEffect(() => {
    setData([]);
  }, []);

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    console.log("sortedData", sortedData);
    setData(sortedData);
  };
  const columns = [
    {
      id: "reportId",
      label: `Report ID`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
      className: "inc_report-id",
    },
    {
      id: "caseId",
      label: `Case ID`,
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
      className: "inc_case-id",
    },
    {
      id: "dateOfEntry",
      label: `Date of Entry`,
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
      className: "inc_dateOfEntry",
    },
    {
      id: "incidentType",
      label: `Incident Type`,
      sortable: true,
      isSorted: sortColumn === "incidentType",
      sortDirection,
    },
    {
      id: "assetName",
      label: "Asset Name",
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },
    {
      id: "enteredBy",
      label: `Entered by`,
      sortable: true,
      isSorted: sortColumn === "enteredBy",
      sortDirection,
    },

    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
    },
    { id: "blank2", label: "", className: "inc_blank" },
  ];
  function navigations(navigationPath, incidentId, caseId, incidentType) {
    navigate(navigationPath, {
      state: {
        caseId: caseId,
        incidentId: incidentId,
        editMode: true,
        isDirect: true,
        incidentType: incidentType,
        apiData: props?.drillDetail,
        isIncident: true, // TODO
        page: "report_totalIncidents", // TODO
        reportDetails: 0, // TODO
        incidentURL: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
        tab: props?.tab,
      },
    });
  }
  const handleViewOnClick = (rows) => {
    if (
      rows?.incidentId === 2 ||
      rows?.incidentId === 1 ||
      rows?.incidentId === 3 ||
      rows?.incidentId === 4 ||
      rows?.incidentId === 5
    ) {
      navigations(
        VIEW_INCIDENT_REPORT,
        rows?.reportId,
        rows?.caseId,
        rows?.incidentId
      );
    }
  };

  const rows = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td className="inc_row_reportId">{asset?.reportId}</td>
      <td className="inc_row_caseid">{asset?.caseId}</td>
      <td className="inc_row_dateOfEntry">{asset?.dateOfEntry}</td>
      <td>{asset?.incidentType}</td>
      <td>{asset?.assetName}</td>
      <td>{asset?.enteredBy}</td>
      <td>{asset?.status}</td>
      <td className="inc_row_blank">
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident"}
        />
      </td>
    </tr>
  ));
  const handleIncidentTypeChange = (e) => {
    setData([]);

    setIncvalue(e?.target?.value);
    setPage(1);
    const data = {
      pageNumber: 1,
      incidentType: e?.target?.value > 0 ? e?.target?.value : 0,
      pageSize: pageSize,
      startDate: props?.drillDetail?.apiData?.startDate,
      endDate: props?.drillDetail?.apiData?.endDate,
      divisionId: props?.drillDetail?.apiData?.divisionId,
      locationId: props?.drillDetail?.apiData?.locationId,
    };
    getIncidentListData(data);
  };

  const rowsPdf = data.map((asset) => [
    asset?.reportId,
    asset?.caseId,
    asset?.dateOfEntry,
    asset?.incidentType,
    asset?.assetName,
    asset?.enteredBy,
    asset?.status,
  ]);
  return (
    <>
      <div className="topHeaderDrill">
        <div className="topHeaderDrillLeft">
          <div className="title">{"Total Incidents"}</div>
        </div>
        <div className="topHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() =>
                      downloadPDF(
                        rowsPdf,
                        columns.map((col) => col.label),
                        "Incidents.pdf"
                      )
                    }
                  >
                    <img src={commonImages.pdfFill} alt={""} />
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>

              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={() => downloadExcel(data, "Incidents.xlsx")}
                  >
                    <img src={commonImages.excelDownload} alt="" />
                    <span>Download.xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <Grid container mb={2}>
        <Grid md={4}>
          <FormControl fullWidth>
            <FormLabels label="Incident Type" isRequired={true} />
            <SelectMenu
              listData={associatedIncidentType1}
              value={incvalue}
              onchangehandler={(e) => handleIncidentTypeChange(e)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container>
        {" "}
        <Grid md={12} xs={12}>
          <div className="customTableWrapper">
            <Table
              columns={columns}
              rows={rows}
              tableRef={tableRef}
              onSort={handleSort}
              className="totalinc_cls"
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default TotalIncidents;
