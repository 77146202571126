import React from "react";
import Buttons from "../button/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import "./lmsFilterList.scss";
import commonImages from "../../../assets";
import { ORG_ID, USER_ID } from "../../../constant/constant";
import SelectMenu from "../selectMenu/SelectMenu";
import { CardActions } from "@mui/material";

const LmsFilterList = ({
  filterType,
  handleSearch,
  tabvalue,
  inputsearch,
  onChangeType,
  filter,
  PAGE_SIZE,
  selectedValue,
  isFilterTypeList = true,
  handleClear,
  setisTableLoading,

}) => {
  const handleApply = (e) => {

    const data = {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      search: inputsearch ? inputsearch : "",
      catIds: isFilterTypeList
        ? filter?.searchType[0]?.key
          ? filter?.searchType[0]?.key
          : ""
        : selectedValue
          ? selectedValue
          : "",
      statusId:
        parseInt(tabvalue) === 0 ? 2 : parseInt(tabvalue) === 2 ? "" : 1,
      userId: USER_ID(),
      orgId: ORG_ID(),
      organisationId: ORG_ID(),
      status: tabvalue ? tabvalue : "",
    };
    handleSearch(data);
    //   setPage(1);
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
  };
  return (
    <>
      <Box className="filterResult">
        <div className="filterTitle">Category</div>
        <div className="checkboxWrap">
          <FormGroup className="labelList">
            {isFilterTypeList ? (
              <>
                {filterType &&
                  filterType.map((item) => (
                    <FormControlLabel
                      key={item.key}
                      control={
                        <img
                          className="filterCheckbox"
                          title={
                            filter.searchType[0]?.key === item.key
                              ? "tickCheckbox"
                              : "untickCheckbox"
                          }
                          alt=""
                          src={
                            filter.searchType[0]?.key === item.key
                              ? commonImages.check
                              : commonImages.noCheck
                          }
                          onClick={() => onChangeType(item)}
                        />
                      }
                      label={item?.value}
                      className="checkboxText"
                    />
                  ))}
              </>
            ) : (
              <>
                <SelectMenu
                  name="Please select"
                  listData={filterType ?? []}
                  onchangehandler={(e) => onChangeType(e.target.value)}
                  value={selectedValue}
                  placeholder="Please select"
                />
              </>
            )}
          </FormGroup>
        </div>
        <div className="filterBottom">
          <CardActions>
            <Buttons
              primary={true}
              label={"Reset"}
              onClick={(e) => handleClear(e)}
              size="small"
            />
            <Buttons
              primary={true}
              label={"Apply"}
              onClick={(e) => handleApply(e)}
              size="small"
            />
          </CardActions>
        </div>
      </Box>
    </>
  );
};

export default LmsFilterList;
