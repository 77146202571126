import React, { useEffect } from "react";
import ObservationList from "./observationList";
import * as observationAction from "../../../redux/actions/observationAction";
import { useDispatch, useSelector } from "react-redux";
import {
  VIEW_OBSERVATION_DETAILS,
  OBSERVATION,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import { PAGE_SIZE } from "../../../constant/constant";

const ObservationListContainer = () => {
  const disptch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.observationList?.isLoading);
  const observationList = useSelector((state) => state.observationList);

  useEffect(() => {
    const data = {
      pageSize: PAGE_SIZE,
      page: 1,
      searchText: "",
      fromdate: "",
      todate: "",
      location: "",
      Status: "",
      itemTypes: "",
    };
    getObservationListData(data);
  }, []);

  const getObservationListData = (data) => {
    disptch(observationAction.getListOfObservationPending(data));
  };

  const handleViewOnClick = (
    insObservationId,
    observedType,
    observedEmployee,
    status
  ) => {
    navigate(VIEW_OBSERVATION_DETAILS, {
      state: {
        insObservationId: insObservationId,
        observedType: observedType,
        observedEmployee: observedEmployee,
        status:status,
      },
    });
  };

  const addObservation = () => {
    navigate(OBSERVATION);
  };

  return (
    <>
      <ObservationList
        observationList={observationList.data?.result}
        getObservationListData={getObservationListData}
        isLoading={isLoading}
        handleViewOnClick={handleViewOnClick}
        addObservation={addObservation}
      />
    </>
  );
};

export default ObservationListContainer;
