import React, { useEffect } from "react";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import UserInfo from "../component/userInfo/userInfo";
import Loader from "../../../library/common/Loader";
import { checkPrivileges } from "../../../utils/rolesHelper";
import { ACCESS_DENIED } from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";

const UserInfoContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkUserInfo = useSelector((state) => state.checkUserInfo);
  const showLoader = useSelector((state) => state.checkUserInfo?.isLoading);

  const onSubmit = (data) => {
    dispatch(userAction.checkUserInfo(data));
  };

  useEffect(() => {
    const data = {
      phonenumber: "",
      email: "",
    };
    dispatch(userAction.checkUserInfo(data));
  }, []);
  
  return (
    <>
      {checkPrivileges([12, 64, 65, 66, 148]) ? (
        <>
          {showLoader && <Loader />}
          <div className="dashTitle">User Information Check</div>
          <UserInfo userInfo={checkUserInfo?.data} onSubmit={onSubmit} />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
};

export default UserInfoContainer;
