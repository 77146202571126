import { Button, Checkbox, FormControl, Grid } from "@mui/material";
import React, { useEffect } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import "./adminOpenTicket.scss";
import AddIcon from "@mui/icons-material/Add";
import Buttons from "../../../../library/custom/button/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../../library/common/Loader";
import TextArea from "../../../../library/custom/textArea/TextArea";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import * as supportAction from "../../../../redux/actions/supportAction";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ViewImages from "../../../incidents/view-incident-report/common/viewImages/viewImages";
import SentimentNeutralSharpIcon from "@mui/icons-material/SentimentNeutralSharp";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import SentimentDissatisfiedSharpIcon from "@mui/icons-material/SentimentDissatisfiedSharp";
import UpdateIcon from "@mui/icons-material/Update";
import { useSelector } from "react-redux";
import { ORG_ID } from "../../../../constant/constant";
import { useNavigate } from "react-router-dom";
import { CREATE_TICKET } from "../../../../constant/routeContant";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const AdminOpenTicket = ({
  ticketDetails,
  articleCatSubcategory,
  handleTicketReply,
  fileUploadData,
  uploadFile,
  allUsers,
  isLoading,
  handleAllUserSearch,
  handleResolutionRequest,
  feedbackDesk = false,
  getTicketApiData,
  handleTicketDetails,
  isLoader
}) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const navigate = useNavigate();
  useEffect(() => {
    fileUploadData = "";
  }, [ticketDetails?.replyList]);
  const [employeeNameValue, setEmployeeNameValue] = useState("");
  const [ticketReplyData, setticketReplyData] = useState({
    createdBy: localStorage.getItem("userId"),
    ticketId: "",
    message: "",
    isUserMessage: false,
    fileIds: [],
    orgId: ORG_ID(),
  });
  const [postUserAssignData, setpostUserAssignData] = useState({
    ticketId: "",
    userId: "",
    notes: "",
    categoryId: ticketDetails?.articleCategorySubcategoryId,
    orgId: ORG_ID(),
  });
  const [postUpdateSeverityData, setPostUpdateSeverityData] = useState({
    ticketId: "",
    severityTypeId: "",
    orgId: ORG_ID(),
  });
  const [formValue, setFormValue] = useState({
    message: "",
    employeeName: "",
    department: "",
    notes: "",
    severity: "",
    assignee: "",
  });
  function handleReplySubmit(e) {
    e.preventDefault();
    if (
      ticketReplyData.message.length > 0 ||
      ticketReplyData.fileIds.length > 0
    ) {
      handleTicketReply(ticketReplyData, ticketDetails);
      setticketReplyData({
        ...ticketReplyData,
        message: "",
        ticketId: ticketDetails.ticketId,
        fileIds: [],
      });
      setselectedFile();
      setisFilePicked(false);
    }
    if (closeticket) {
      const closeTicketData = {
        ticketId: ticketDetails?.ticketId,
        isClosed: true,
        userId: localStorage.getItem("userId"),
        orgId: ORG_ID(),
      };
      dispatch(supportAction.postClosedTicket(closeTicketData));
    }
  }
  const [closeticket, setCloseticket] = useState(false);
  const [severitystate, setSeveritystate] = useState({
    high: false,
    medium: false,
    low: false,
  });
  const [selectedFile, setselectedFile] = useState();
  const [isFilePicked, setisFilePicked] = useState(false);

  const handleFile = (event) => {
    setselectedFile(event.target.files[0]);
    setisFilePicked(true);
    const fileUploaded = event.target.files[0];
    const fileName = fileUploaded.name;
    uploadFile(fileUploaded, fileName);
  };
  useEffect(() => {
    if (fileUploadData) {
      setticketReplyData({
        ...ticketReplyData,
        ticketId: ticketDetails?.ticketId,
        fileIds: [fileUploadData?.files[0].id],
      });
    }
  }, [fileUploadData]);

  const imageSize = {
    size: "large",
  };
  const fileUploadStatus = useSelector((state) => state.fileUpload.isLoading);

  const [error, setError] = useState(false);
  const [showReplierName, setshowReplierName] = useState(null);

  useEffect(() => {
    if (showReplierName !== null) {
      setTimeout(() => {
        setshowReplierName(null);
      }, 10000);
    }
  }, [showReplierName]);

  return (
    <>
      {ticketDetails ? (
        <div className="overrideAdminOpenTicket">
          <div className="mainContainer">
            <Grid container className="topDiv">
              <button
                onClick={() => {
                  history(-1);
                }}
                className="backBtn"
              >
                <ArrowBackIosNewIcon fontSize="small" />
                Back to Support Tickets
              </button>

              <Grid item container lg={5} gap={2}>
                <Grid
                  onClick={() => {
                    handleTicketDetails(ticketDetails.ticketId);
                  }}
                  container
                  item
                  className="updateIcon"
                  lg={5.8}
                  gap={1}
                >
                  <UpdateIcon />
                  <h1>Refresh Chat</h1>
                </Grid>
                <Buttons
                  startIcon={<AddIcon />}
                  primary={true}
                  label={"New Ticket"}
                  onClick={() => {
                    navigate(CREATE_TICKET);
                  }}
                />
              </Grid>
            </Grid>
            <Grid mt={3} md={5} className="ticketContainer">
              <Grid className="idPrioirtyDiv">
                <Grid className="id">
                  <span
                    className={
                      ticketDetails.status === 1
                        ? "iconClosed"
                        : `icon${ticketDetails.priority}`
                    }
                  >
                    <DescriptionIcon />
                  </span>
                  Ticket Id - {ticketDetails.ticketId}
                </Grid>
                <Grid className="id">
                  {ticketDetails.status !== 1 &&
                  ticketDetails.replyList.length === 0
                    ? "Open"
                    : ticketDetails.status !== 1 &&
                      ticketDetails.replyList.length > 0
                    ? " In Progress"
                    : ""}
                </Grid>

                <Grid className="id">
                  Created By: {ticketDetails.createByNameTicket}
                </Grid>

                <Grid className="id">
                  Assigned To: {ticketDetails.assignedUserName}
                </Grid>
                <Grid
                  className={
                    ticketDetails.status === 1
                      ? "priorityClosed"
                      : `priority${ticketDetails.priority}`
                  }
                >
                  {ticketDetails.status === 1
                    ? "Closed"
                    : ticketDetails.priority}
                </Grid>
              </Grid>
              <Grid className="ticketDetails">
                <Grid mt={2} className="question">
                  {ticketDetails.title}
                </Grid>
                <Grid mt={2} className="answer">
                  {ticketDetails?.description?.split("\n").map((str, ind) => (
                    <p key={ind}>{str}</p>
                  ))}
                </Grid>
                {ticketDetails.files.length > 0 && (
                  <div>
                    <ViewImages
                      image={ticketDetails.files}
                      imageSize={imageSize}
                    />
                  </div>
                )}
              </Grid>
              {ticketDetails.replyList ? (
                <Grid className="chatsContainer">
                  {ticketDetails.replyList.map((ele, ind) => {
                    return (
                      <Grid mt={2} className="chat" key={ind}>
                        <Grid
                          className={
                            ele.isUserMessage ? "userReplies" : "adminReplies"
                          }
                          key={ind}
                        >
                          {ele.message && (
                            <Grid
                              className="reply"
                              onClick={() => setshowReplierName(ele.replyId)}
                            >
                              {ele.message.split("\n").map((str, ind) => (
                                <p key={ind}>{str}</p>
                              ))}
                            </Grid>
                          )}
                          {ele.filePath && (
                            <div
                              onClick={() => setshowReplierName(ele.replyId)}
                            >
                              <ViewImages
                                image={[ele.filePath]}
                                imageSize={imageSize}
                              />
                            </div>
                          )}
                          {ele.replyId === showReplierName && (
                            <p className="createdBy">{ele?.createdByName}</p>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Loader />
              )}
              {ticketDetails.feedbackData.feedback && (
                <Grid mt={4} className="feedBackmsg">
                  {ticketDetails.feedbackData.userSatisfectionId === 0 && (
                    <SentimentDissatisfiedSharpIcon className="feedbackIconSad" />
                  )}
                  {ticketDetails.feedbackData.userSatisfectionId === 2 && (
                    <SentimentNeutralSharpIcon className="feedbackIconNeu" />
                  )}
                  {ticketDetails.feedbackData.userSatisfectionId === 1 && (
                    <EmojiEmotionsIcon className="feedbackIconHappy" />
                  )}
                  <h1>User Feedback</h1>
                  <p>{ticketDetails.feedbackData.feedback}</p>
                </Grid>
              )}
              {ticketDetails.status === 1 ? (
                ""
              ) : (
                <Grid className="resolutionDiv" item container mt={4} mb={3}>
                  <Grid lg={9} xs={12}>
                    <FormControl fullWidth>
                      <FormLabels label="Provide Response" />
                      <TextArea
                        placeholder={"Enter Here"}
                        value={ticketReplyData.message}
                        onChange={(e) =>
                          setticketReplyData({
                            ...ticketReplyData,
                            message: e.target.value,
                            ticketId: ticketDetails.ticketId,
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid className="resolutionActionDiv" lg={9} xs={12}>
                    <div>
                      <Checkbox
                        checked={closeticket}
                        onChange={(event) => {
                          setCloseticket(event.target.checked);
                        }}
                      />
                      Close Ticket
                    </div>
                    <div className="resolutionBtnDiv">
                      <Grid className="fileUploadDiv" container item>
                        <Button variant="text" component="label">
                          <AttachFileIcon className="btnColor" />
                          <input
                            hidden
                            multiple
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                              handleFile(event);
                            }}
                          />
                        </Button>
                        {isFilePicked && (
                          <div className="nameTxt">
                            {" "}
                            Image:{" "}
                            <span className="name">{selectedFile?.name}</span>
                          </div>
                        )}
                      </Grid>
                      <Buttons
                        varientContained={true}
                        disabled={isLoader ? true : false}
                        label={
                          isLoader === true
                            ? "Uploading"
                            : ticketReplyData.message.length > 0 || isFilePicked
                            ? "Send"
                            : "Reply"
                        }
                        className="cancelBtn"
                        onClick={(e) => {
                          handleReplySubmit(e);
                          setticketReplyData({
                            ...ticketReplyData,
                            message: "",
                            fileIds: [],
                          });
                          setselectedFile();
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid container item mt={4}>
                    <Grid>
                      <Grid className="question">Escalation Notes</Grid>
                    </Grid>
                    <Grid container>
                      <Grid mt={1} className="answer">
                        {ticketDetails?.notes?.split("\n").map((str, ind) => (
                          <p key={ind}>{str}</p>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  {checkPrivileges([7, 12]) && (
                    <Grid container lg={9} spacing={2} mt={3}>
                      <Grid item lg={6} xs={12} sm={12} md={4}>
                        <FormControl fullWidth>
                          <FormLabels label="Select Category" />
                          <SelectMenu
                            listData={articleCatSubcategory}
                            value={formValue.department}
                            onchangehandler={(e) => {
                              setFormValue({
                                ...formValue,
                                department: e.target.value,
                              });
                              setpostUserAssignData({
                                ...postUserAssignData,
                                categoryId: e.target.value,
                              });
                            }}
                            // isKeyValue={false}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} xs={12} sm={12} md={4}>
                        <FormControl fullWidth>
                          <FormLabels label="Add Assignee" isRequired={true} />
                          <SearchAutoComplete
                            optionsData={isLoading ? [] : allUsers?.users ?? []}
                            isKeyValuePair={true}
                            value={employeeNameValue}
                            isLoading={isLoading}
                            typeOnchange={(event, value = 3) =>
                              handleAllUserSearch(event, value)
                            }
                            onChange={(event, value) => {
                              setError(false);
                              setFormValue({
                                ...formValue,
                                employeeName: `${value?.value}`,
                              });
                              setEmployeeNameValue({
                                key: value?.value ?? "",
                                value: value?.value ?? "",
                              });
                              setpostUserAssignData({
                                ...postUserAssignData,
                                userId: value?.key,
                                ticketId: ticketDetails.ticketId,
                              });
                            }}
                            errorMsg={error ? "Please select user" : ""}
                            isError={error}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  {checkPrivileges([7, 12]) && (
                    <Grid item container spacing={2} mt={3}>
                      <Grid item container lg={6} xs={12} sm={12}>
                        <FormControl fullWidth>
                          <FormLabels label="Select Severity" />

                          <div className="chipGroup">
                            <Button
                              className={
                                severitystate.low ? "sevLow" : "outlinedButton"
                              }
                              variantOutline={true}
                              onClick={() => {
                                setSeveritystate({
                                  ...severitystate,
                                  low: true,
                                  medium: false,
                                  high: false,
                                });
                                setPostUpdateSeverityData({
                                  ...postUpdateSeverityData,
                                  severityTypeId: 3,
                                  ticketId: ticketDetails?.ticketId,
                                });
                              }}
                            >
                              Low
                            </Button>

                            <Button
                              className={
                                severitystate.medium
                                  ? "sevMedium"
                                  : "outlinedButton"
                              }
                              variantOutline={true}
                              label="Medium"
                              onClick={() => {
                                setSeveritystate({
                                  ...severitystate,
                                  low: false,
                                  medium: true,
                                  high: false,
                                });

                                setPostUpdateSeverityData({
                                  ...postUpdateSeverityData,
                                  severityTypeId: 2,
                                  ticketId: ticketDetails?.ticketId,
                                });
                              }}
                            >
                              Medium
                            </Button>
                            <Button
                              className={
                                severitystate.high
                                  ? "sevHigh"
                                  : "outlinedButton"
                              }
                              label="High"
                              onClick={() => {
                                setSeveritystate({
                                  ...severitystate,
                                  low: false,
                                  medium: false,
                                  high: true,
                                });
                                setPostUpdateSeverityData({
                                  ...postUpdateSeverityData,
                                  severityTypeId: 1,
                                  ticketId: ticketDetails?.ticketId,
                                });
                              }}
                            >
                              High
                            </Button>
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {checkPrivileges([7, 12]) && (
                    <Grid mt={4} lg={9} xs={12}>
                      <FormControl fullWidth>
                        <FormLabels label="Add Escalation Notes" />
                        <TextArea
                          placeholder={"Enter Here"}
                          value={formValue.notes}
                          onChange={(e) => {
                            setFormValue({
                              ...formValue,
                              notes: e.target.value,
                            });
                            setpostUserAssignData({
                              ...postUserAssignData,
                              notes: e.target.value,
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {checkPrivileges([7, 12]) && (
                    <Grid className="resolutionReqDiv" lg={12} xs={12} mt={3}>
                      <div className="resolutionReqDiv">
                        <Buttons
                          primary={true}
                          label="Assign Ticket"
                          onClick={() => {
                            if (postUserAssignData.userId === "")
                              setError(true);
                            else
                              handleResolutionRequest(
                                postUserAssignData,
                                postUpdateSeverityData
                              );
                          }}
                        />
                      </div>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AdminOpenTicket;
