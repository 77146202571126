import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateNews from "../component/news/createNews";
import * as newsAction from "../../../redux/actions/newsAction";
import { useLocation } from "react-router-dom";
import SuccessScreencommon from "../../../library/custom/success/successScreen";
import { DASHBOARD } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { API_STATUS } from "../../../constant/constant";

const AddNewsContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const editMode = location?.state?.editMode;

  const newsIds = location?.state?.newsId;
  const createNews = useSelector((state) => state?.newsAdd);
  const cid = createNews?.data?.id;

  const statuss = createNews?.status;
  const [successScreens, setSuccessScreens] = useState(false);
  const editNewsData = useSelector((state) => state.getNewsDetails);
  useEffect(() => {
    setSuccessScreens(false);
  }, []);
  useEffect(() => {
    if (newsIds || editMode) {
      dispatch(newsAction.getNewsDetails(newsIds));
    }
  }, []);

  const onSubmit = (data) => {
    setSuccessScreens(true);
    if (editMode) {
      dispatch(newsAction.updateNews(data));
    } else dispatch(newsAction.postcreateNews(data));
  };

  const successScree = (id, message, labelmsg) => {
    return (
      <SuccessScreencommon
        isUpdate={message}
        route={DASHBOARD}
        id={id}
        label2={labelmsg}
        label1="News ID"
        label4="News in "
      ></SuccessScreencommon>
    );
  };
  const showLoader = useSelector(
    (state) =>
      state?.newsAdd?.isLoading ||
      state?.updateNews?.isLoading ||
      state?.getNewsDetails?.isLoading
  );
  return (
    <>
      {showLoader && <Loader />}

      {statuss === API_STATUS.SUCCESS && !editMode && successScreens ? (
        successScree(cid, "News Created Successfully", "")
      ) : editNewsData?.status === API_STATUS.SUCCESS &&
        editMode === true &&
        successScreens ? (
        successScree(newsIds, "News Updated Successfully", "has been updated")
      ) : (
        <CreateNews
          onSubmit={onSubmit}
          editNewsData={editMode ? editNewsData : ""}
          editMode={editMode}
          newsId={newsIds}
        />
      )}
    </>
  );
};

export default AddNewsContainer;
