import { getApiUrl } from "../config";
import { ORG_ID } from "../constant/constant";
import { PRODUCT_ID } from "../constant/storm-water/constant";
import { post, get } from "./axiosRequest";

export const addcategory = async ({ data }) => {
const url = `${getApiUrl().adminApiUrl}/CategoryConfiguration/AddCategory`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const addSupportcategory = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/CategoryConfiguration/AddSupportCategory`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};

export const getCategoryLists = async ({ data }) => {
  const { orgId, flags } = data;
  const page = 1;
  const pageSize = 10000;
  const url = `${
    getApiUrl().adminApiUrl
  }/CategoryConfiguration/GetCategoriesList?organisationId=${orgId}&flag=${flags}&page=${page}&pageSize=${pageSize}&serviceType=${PRODUCT_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const activeCategory = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/CategoryConfiguration/ActiveCategory`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const activeSupportCategory = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/CategoryConfiguration/ActiveSupportCategory`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

//activeSupportCategory
export const getSupportCategoryLists = async ({ data }) => {
  const { orgId, flags } = data;
  const page = 1;
  const pageSize = 10000;
  const url = `${
    getApiUrl().adminApiUrl
  }/CategoryConfiguration/GetSupportCategoriesList?organisationId=${orgId}&flag=${flags}&page=${page}&pageSize=${pageSize}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getUtilityIncidentCategoryLists = async ({ data }) => {
  console.log("isAll================", data);
  const url = `${getApiUrl().incidentApiUrl}/GetReferenceData?isAll=${
    data?.isAll
  }`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const addIncidentcategory = async ({ data }) => {
  const url = `${getApiUrl().incidentApiUrl}/AddInsCategory`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (error) {
    return error;
  }
};
export const activeIncidentCategory = async ({ data }) => {
  const { incRefDataId, isActive } = data;
  const url = `${
    getApiUrl().incidentApiUrl
  }/InActiveInsCategory?incRefDataId=${incRefDataId}&isActive=${isActive}`;

  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateCategoryConfigurationCategory = async (data) => {
  const url = `${getApiUrl().adminApiUrl}/CategoryConfiguration/UpdateCategory`;

  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
export const updateSupportCategoryConfigurationCategory = async (data) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/CategoryConfiguration/UpdateSupportCategory`;

  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
//updateIncidentCategoryConfigurationCategory

export const updateIncidentCategoryConfigurationCategory = async (data) => {
  const url = `${getApiUrl().incidentApiUrl}/UpdateInsCategory`;

  try {
    return await post(url, data.payload, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
