export const loginTypes = {
  GET_LOGIN: "GET_LOGIN",
  GET_LOGIN_SUCCESS: "GET_LOGIN_SUCCESS",
  GET_LOGIN_FAILURE: "GET_LOGIN_FAILURE",
  POST_LOGIN_PENDING: "[LOGIN][REQUEST] Try To Login",
  POST_LOGIN_FAILURE: "[LOGIN][REQUEST] Login Failed",
  POST_LOGIN_SUCCESS: "[LOGIN][REQUEST] Login Successfull",
  CLEAR_ERRORS: "[LOGIN][REQUEST] CLEAR_ERRORS",
  LOGOUT_SESSION: "LOGOUT_SESSION",
  LOGINPAGE: "LoginPage",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  lOGIN_LOGOUT: "lOGIN_LOGOUT",
  POST_REDIRECT_URL_PENDING: "[URL][REQUEST] Try To URL",
  POST_REDIRECT_URL_PENDINGSUCCESS: "GET_URL_SUCCESS",

  POST_NOTIFICATION_LOGIN_PENDING:
    "[NOTIFICATION_LOGIN][REQUEST] Create Notification Login",
  POST_NOTIFICATION_LOGIN_SUCCESS:
    "[NOTIFICATION_LOGIN][RESPONSE] Notification Login Created Successfull",
  POST_NOTIFICATION_LOGIN_FAILURE:
    "[NOTIFICATION_LOGIN][RESPONSE] Notification Login Creation Failed",

  POST_NOTIFICATION_LOGOUT_PENDING:
    "[NOTIFICATION_LOGOUT][REQUEST] Create Notification Logout",
  POST_NOTIFICATION_LOGOUT_SUCCESS:
    "[NOTIFICATION_LOGOUT][RESPONSE] Notification Logout Created Successfull",
  POST_NOTIFICATION_LOGOUT_FAILURE:
    "[NOTIFICATION_LOGOUT][RESPONSE] Notification  Logout Creation Failed",

  GET_NOTIFICATION_LIST_PENDING:
    "[GET_NOTIFICATION_LIST][REQUEST] Get Notification List",
  GET_NOTIFICATION_LIST_SUCCESS:
    "[GET_NOTIFICATION_LIST][RESPONSE] Notification List  Successfull",
  GET_NOTIFICATION_LIST_FAILURE:
    "[GET_NOTIFICATION_LIST][RESPONSE] Notification  List  Failed",

  POST_UPDATE_NOTIFICATION_LIST_PENDING:
    "[POST_UPDATE_NOTIFICATION_LIST][REQUEST] Notification Update Notification List",
  POST_UPDATE_NOTIFICATION_LIST_SUCCESS:
    "[POST_UPDATE_NOTIFICATION_LIST][RESPONSE] Notification Update List  Successfull",
  POST_UPDATE_NOTIFICATION_LIST_FAILURE:
    "[POST_UPDATE_NOTIFICATION_LIST][RESPONSE]  Notification Update  List  Failed",

  GET_NOTIFICATION_COUNT_PENDING:
    "[GET_NOTIFICATION_COUNT][REQUEST] Get Notification Count",
  GET_NOTIFICATION_COUNT_SUCCESS:
    "[GET_NOTIFICATION_COUNT][RESPONSE] Notification Count Successfull",
  GET_NOTIFICATION_COUNT_FAILURE:
    "[GET_NOTIFICATION_COUNT][RESPONSE] Notification  Count Failed",

  POST_UPDATE_NOTIFICATION_COUNT_PENDING:
    "[POST_UPDATE_NOTIFICATION_COUNT][REQUEST] Notification Update Notification Count",
  POST_UPDATE_NOTIFICATION_COUNT_SUCCESS:
    "[POST_UPDATE_NOTIFICATION_COUNT][RESPONSE] Notification Update Count  Successfull",
  POST_UPDATE_NOTIFICATION_COUNT_FAILURE:
    "[POST_UPDATE_NOTIFICATION_COUNT][RESPONSE]  Notification Update  Count  Failed",

  GET_LOGIN_OTP_PENDING: "[GET_LOGIN_OTP][REQUEST] LOGIN OTP PENDING",
  GET_LOGIN_OTP_SUCCESS: "[GET_LOGIN_OTP][RESPONSE] LOGIN OTP  Successfull",
  GET_LOGIN_OTP_FAILURE: "[GET_LOGIN_OTP][RESPONSE]  LOGIN OTP  Failed",

  GET_LOGIN_OTP_USER_VERIFY_PENDING:
    "[GET_LOGIN_OTP_USER_VERIFY][REQUEST] LOGIN OTP User verify PENDING",
  GET_LOGIN_OTP_USER_VERIFY_SUCCESS:
    "[GET_LOGIN_OTP_USER_VERIFY][RESPONSE] LOGIN OTP User Verify Successfull",
  GET_LOGIN_OTP_USER_VERIFY_FAILURE:
    "[GET_LOGIN_OTP_USER_VERIFY][RESPONSE]  LOGIN OTP User Verify Failed",
};
