import React, { useEffect } from "react";
import ToolBoxTalkUserList from "../component/toolBoxTalkList/toolBoxTalkUserList";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE, ORG_ID, USER_ID } from "../../../constant/constant";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
const ToolboxTalkUserContainer = () => {
  const dispatch = useDispatch();

  const getToolBoxTalkListResult = useSelector(
    (state) => state.getToolBoxTalkList?.data
  );

  const getDropdownDataKeyValue = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.toolboxCategories
  );
  // const dataHeader = {
  //   title: "Safety Meetings",
  //   btnText: "Create Safety Meeting",
  //   btnLink: TOOL_BOX_CREATE,
  //   menu: [],
  // };
  const defaultData = {
    pageNumber: 1,
    pageSize: PAGE_SIZE,
    search: "",
    catIds: "",
    userId: USER_ID(),
    status: "",
    tabId: 1,
  };

  useEffect(() => {
    getToolBoxTalkListData(defaultData);
  }, []);
  const getToolBoxTalkListData = (data) => {
    dispatch(toolboxAction.clearUserDetailTalk(data));
    dispatch(toolboxAction.getToolBoxTalkList(data));
  };

  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: [20],
  };

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);
  return (
    <>
      {/* {lmsAccess.isFullAccess &&
      lmsAccess.isWriteAccess &&
      lmsAccess.isReadAccess ? (
        <LmsHeader dataHeader={dataHeader} />
      ) : (
        ""
      )} */}

      <ToolBoxTalkUserList
        categoryListData={getDropdownDataKeyValue}
        getToolBoxTalkListResult={getToolBoxTalkListResult}
        getToolBoxTalkListData={getToolBoxTalkListData}
      />
    </>
  );
};

export default ToolboxTalkUserContainer;
