import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import UserPool from "../UserPool";
import { ORG_ID } from "../../src/constant/constant";
import { getApiUrl } from "../../../safety_cloud_ui/src/config";
import { post, get } from "../../src/services/axiosRequest";
import { result } from "lodash";
let currentCognitoUser = {};
let sessionUserAttributes = "";
function asyncAuthenticateUser(authenticationDetails, userData) {
  currentCognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  return new Promise(function (resolve, reject) {
    currentCognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: resolve,
      onFailure: reject,
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        delete userAttributes.phone_number_verified;
        delete userAttributes.email_verified;
        delete userAttributes.name;
        delete userAttributes.phone_number;
        delete userAttributes.email;

        sessionUserAttributes = userAttributes;
        reject({
          code: "NEW_PASSWORD_REQUIRED",
          message: "New Password is required",
        });
      },
    });
  });
}

export const login = async (userName, password) => {
  let userData = {
    Username: userName,
    Pool: UserPool,
  };
  let authenticationData = {
    mobile: userName,
    Password: password,
  };
  let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
  );
  try {
    let result = await asyncAuthenticateUser(authenticationDetails, userData);
    let idToken = result.getIdToken().jwtToken;
    let accessToken = result.getAccessToken().jwtToken;
    AWS.config.region = "us-east-1";
    return { idToken, accessToken };
  } catch (error) {
    return { error };
  }
};

// Force Change Password
export const changePassword = async (password) => {
  try {
    return new Promise((resolve, reject) => {
      currentCognitoUser.completeNewPasswordChallenge(
        password,
        sessionUserAttributes,
        {
          onSuccess(result) {
            resolve(result);
          },
          onFailure(err) {
            reject(err);
          },
        }
      );
    });
  } catch (error) {
    return error;
  }
};

// sign out
export const signOut = () => {
  try {
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      return "success";
    } else {
      return "success";
    }
  } catch (error) {
    return "error";
  }
};

export const NotifiactionLogin = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Notification/LoginUserInApp`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const NotifiactionLogout = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Notification/LogOutUserInApp`;
  console.log("NotifiactionLogout" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const NotificationList = async ({ data }) => {
  const { pageSize, page, search, userId, fromDate, toDate, status } = data;

  const url = `${
    getApiUrl().adminApiUrl
  }/Notification/NotificationList?userId=${userId}&search=${search}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&pageSize=${pageSize}
    `;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateNotifiactionList = async ({ data }) => {
  const url = `${
    getApiUrl().adminApiUrl
  }/Notification/UpdateNotificationStatus`;
  console.log("NotifiactionLogin" + url);
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const NotificationCount = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Notification/NotificationCount`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const updateNotifiactionCount = async ({ data }) => {
  const url = `${getApiUrl().adminApiUrl}/Notification/UpdateNotificationCount`;
  try {
    return await post(url, null, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

export const getLoginOTP = async ({ data }) => {
  console.log(data)
  const COGNITO_BASE_URL = "https://cognito-idp.us-east-1.amazonaws.com/";
  const body = {
    AuthParameters: { USERNAME: data },
    AuthFlow: "CUSTOM_AUTH",
    ClientId: UserPool.getClientId(),
  };
  try {
    return await post(COGNITO_BASE_URL, body, {
      headers: {
        "Content-Type": "application/x-amz-json-1.1",
        "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
      },
    });
  } catch (err) {
    return err;
  }
};

export const sendLoginOTP = async (userName, password, Session) => {
  const COGNITO_BASE_URL = "https://cognito-idp.us-east-1.amazonaws.com/";
  const body = {
    ChallengeName: "CUSTOM_CHALLENGE",
    ClientId: UserPool.getClientId(),
    ChallengeResponses: { USERNAME: userName, ANSWER: password },
    Session: Session,
  };
  const result = await post(COGNITO_BASE_URL, body, {
    headers: {
      "Content-Type": "application/x-amz-json-1.1",
      "X-Amz-Target":
        "AWSCognitoIdentityProviderService.RespondToAuthChallenge",
    },
  });
  try {
    if (result.data.AuthenticationResult.IdToken) {
      let idToken = result.data.AuthenticationResult.IdToken;
      let accessToken = result.data.AuthenticationResult.AccessToken;

      // AWS.config.region = "us-east-1";
      return { idToken, accessToken };
    } else return { result };
  } catch (err) {
    return result;
  }
};
