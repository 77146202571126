import { Grid, RadioGroup } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import RadioButton from "../../../../library/custom/radioButton/RadioButton";
import SearchAutoComplete from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import FormControl from "@mui/material/FormControl";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import { InputField } from "../../../../library/custom/textBox/InputField";
import Buttons from "../../../../library/custom/button/Button";
import UsersChip from "../../../../library/custom/usersChip/UsersChip";
import DatePicker from "../../../../library/custom/datePicker/DatePicker";
import SearchAutoComplete2 from "../../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import {
  ASSETTYPE,
  ASSETTYPE_SW,
} from "../../../../constant/inspectionConstant";
import Checkbox from "../../../../library/checkbox/checkbox";

import {
  ORG_ID,
  RESOURCETYPES,
  USER_ID,
  HBNEXT_ORG_ID,
  LOGIN_ORG_ID,
} from "../../../../constant/constant";
import EditResource from "./editResource";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { ADMIN_RESOURCE } from "../../../../constant/routeContant";
import { getCurrentDate, getFormatedDate } from "../../../../utils/helper";
import FormDropZone from "../../../../library/dropZone/FormDropZone";
import { checkPrivileges } from "../../../../utils/rolesHelper";
export default function CreateResource({
  onSubmit,
  allUsers,
  isLoading,
  editMode,
  viewResourceData,
  resourcesId,
  resourceTypesId,
  addMode,
  viewEditResourceData,
  isResViewLoading,
  resourceType,
  resourceCategory,
  handleAllUserSearch,
  allGroupsUser,
  isUserGropLoading,
  handleAllGroupSearch,
  searchAsset,
  assetOption,
  searchClient,
  searchAllClientsReducerData,
  autoSearchDataLoading,
  setClients,
  clients,
  setchangesearchauto,
  changesearchauto,
}) {
  const [checkBox, setcheckBox] = useState(false);

  const intialState = {
    resourcesId: 0,
    title: "",
    resourceType: 1,
    categoryId: "",
    fileUrls: "",
    linkUrls: "",
    individualUserIds: [],
    createdBy: parseInt(USER_ID()),
    organisationId: ORG_ID(),
    userType: 1,
    isPublic: false,
    expirationDate: "",
    resourceAssigneeTypeId: 0,
    assetTypeId: PRODUCT_ID() == 2 ? 5 : 1,
    assetsIds: [],
  };

  const [individualUsersIds, setIndividualUsersIds] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [users, setUsers] = useState("");
  const [expirationDate, setExpirationDate] = useState();
  const [individualAssetsIds, setndividualAssetsIds] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [assets, setAssets] = useState("");
  const autoCompAsset = useRef(null);

  const onchangeExpirationdatePicker = (e) => {
    setExpirationDate(e);
  };

  const autoComp = useRef(null);
  const [serviceType, setServiceType] = useState([]);
  const addIndiVidualUserData = () => {
    const index = userList?.findIndex((obect) => obect.key === users.key);
    if (index === -1 && users) {
      setUsers("");
      setUsersList([...userList, users]);
      setIndividualUsersIds([...individualUsersIds, users.key]);

      const ele = autoComp.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  const addAssetsData = () => {
    const index = assetsList?.findIndex((obect) => obect?.key === assets?.key);
    if (index === -1 && assets) {
      setAssets("");
      setAssetsList([...assetsList, assets]);
      setndividualAssetsIds([...individualAssetsIds, assets.key]);

      const ele = autoCompAsset?.current?.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  const deleteUsers = (row) => {
    const updateList = userList.filter((list) => list.key !== row);
    setUsersList(updateList);
    setIndividualUsersIds(updateList?.map((ids) => ids.key));
  };
  const deleteAssets = (row) => {
    const updateList = assetsList.filter((list) => list.key !== row);
    setAssetsList(updateList);
    setndividualAssetsIds(updateList?.map((ids) => ids.key));
  };

  const onChangeUsers = (value) => {
    setIsindividualUsersIds(false);
    setUsers(value);
  };

  const dataBack = {
    title: "Back to Resources",
    route: ADMIN_RESOURCE,
  };
  // User Group List

  const [groupUsersIds, setGroupUsersIds] = useState([]);
  const [userGroupList, setUsersGroupList] = useState([]);
  const [usersGroup, setUsersGroup] = useState("");

  const addGroupUserData = () => {
    const index = userGroupList?.findIndex(
      (obect) => obect?.key === usersGroup?.key
    );
    if (index === -1 && usersGroup) {
      setUsersGroup("");
      setUsersGroupList([...userGroupList, usersGroup]);
      setGroupUsersIds([...groupUsersIds, usersGroup.key]);
      const ele = autoComp.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) ele.click();
    }
  };

  const deleteUsersGroup = (row) => {
    const updateList = userGroupList.filter((list) => list.key !== row);
    setUsersGroupList(updateList);
    setGroupUsersIds(updateList?.map((ids) => ids.key));
  };

  const onChangeUsersGroup = (value) => {
    setIsGroupIds(false);
    setUsersGroup(value);
  };

  const [formValue, setFormValue] = useState(intialState);
  const [showDocs, setShowDocuemnts] = useState(1);
  const handleResourceType = (value) => {
    setLinkUrlsError(false);
    if (value === 1) {
      setShowDocuemnts(value);
      setFormValue({
        ...formValue,
        linkUrls: " ",
      });
    }
    if (value === 2) {
      setShowDocuemnts(value);
      setFormValue({
        ...formValue,
        fileUrls: "",
      });
    }
    setFormValue({
      ...formValue,
      resourceType: value,
    });
  };
  const [resourceFilesData, setResourceFilesData] = useState("");

  const handleFileChange = (files, single) => {
    setResourceFilesData(files);
  };
  useEffect(() => {
    formValue.fileUrls = resourceFilesData;
    setFormValue({ ...formValue });
  }, [resourceFilesData]);

  const handleDeleteImageURL = () => {
    setFormValue({
      ...formValue,
      fileUrls: "",
    });
  };
  const [isSubmit, setIsSubmit] = useState(false);

  const [isTitle, setisTitle] = useState(false);
  const [isResourceCategory, setIsResourceCategory] = useState(false);
  const [islinkUrls, setIslinkUrls] = useState(false);
  const [islinkUrlsError, setLinkUrlsError] = useState(false);
  const [dropboxError, setDropboxError] = useState(false);
  const [isindividualUsersIds, setIsindividualUsersIds] = useState(false);
  const [isGroupIds, setIsGroupIds] = useState(false);
  const [isassignAssets, setIsassignAssets] = useState(false);

  function mappedData() {
    let errors = false;
    if (formValue?.title.trim() === "") {
      setFormValue({
        ...formValue,
        title: "",
      });
      setisTitle(true);
      errors = true;
    }
    if (formValue?.categoryId === "") {
      setIsResourceCategory(true);
      errors = true;
    }
    if (formValue?.linkUrls) {
      const res = formValue?.linkUrls?.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      if (res === null && formValue.resourceType === 2) {
        setIslinkUrls(true);
        errors = true;
      } else {
        setIslinkUrls(false);
      }
    }
    if (
      individualUsersIds?.length === 0 &&
      formValue?.resourceAssigneeTypeId === 0 &&
      formValue?.userType === 1
    ) {
      errors = true;
      setIsindividualUsersIds(true);
    }
    if (
      groupUsersIds?.length === 0 &&
      formValue?.resourceAssigneeTypeId === 0 &&
      formValue?.userType === 2
    ) {
      errors = true;
      setIsGroupIds(true);
    }
    if (
      individualAssetsIds?.length === 0 &&
      formValue?.resourceAssigneeTypeId === 1
    ) {
      errors = true;
      setIsassignAssets(true);
    }
    if (formValue?.linkUrls == "" && formValue.resourceType === 2) {
      setLinkUrlsError(true);
      errors = true;
    }

    if (formValue?.fileUrls == "" && formValue.resourceType === 1) {
      setDropboxError(true);
      errors = true;
    }
    if (errors) {
      return false;
    }

    let data;
    if (editMode) {
      data = {
        title: formValue?.title,
        resourceType: formValue?.resourceType,
        userType:
          formValue?.resourceAssigneeTypeId == 1 ? null : formValue?.userType,
        categoryId: formValue?.categoryId,
        fileUrls: formValue?.fileUrls,
        linkUrls: formValue?.linkUrls,
        individualUserIds: individualUsersIds,
        userGroupIds: groupUsersIds,
        organisationId: clients ? clients : parseInt(ORG_ID()),
        isPublic: formValue?.isPublic,
        resourceId: resourcesId ? resourcesId : formValue?.resourcesId,
        modifiedBy: parseInt(USER_ID()),
        expirationDate: expirationDate && getFormatedDate(expirationDate),
        resourceAssigneeTypeId: formValue?.resourceAssigneeTypeId,
        assetTypeId:
          formValue?.resourceAssigneeTypeId > 0
            ? PRODUCT_ID() == 2
              ? 5
              : formValue?.assetTypeId
            : 0,

        assetsIds: individualAssetsIds,
        sendNotification: checkBox ? false : true,
        productIds: serviceType,
        client: clients ? clients : parseInt(ORG_ID()),
      };
    } else {
      data = {
        title: formValue?.title,
        resourceType: formValue?.resourceType,
        userType:
          formValue?.resourceAssigneeTypeId == 1 ? null : formValue?.userType,
        categoryId: formValue?.categoryId,
        fileUrls: formValue?.fileUrls,
        linkUrls: formValue?.linkUrls,
        individualUserIds: individualUsersIds,
        userGroupIds: groupUsersIds,
        organisationId: clients ? clients : parseInt(ORG_ID()),
        isPublic: formValue?.isPublic,
        resourceId: resourcesId ? resourcesId : formValue?.resourcesId,
        createdBy: parseInt(USER_ID()),
        expirationDate: expirationDate && getFormatedDate(expirationDate),
        resourceAssigneeTypeId: formValue?.resourceAssigneeTypeId,
        assetTypeId:
          formValue?.resourceAssigneeTypeId > 0
            ? PRODUCT_ID() == 2
              ? 5
              : formValue?.assetTypeId
            : 0,

        assetsIds: individualAssetsIds,
        sendNotification: checkBox ? false : true,
        productIds: [parseInt(PRODUCT_ID())],
        client: clients ? clients : parseInt(ORG_ID()),
      };
    }
    return data;
  }

  const submitData = () => {
    const data = mappedData();

    if (!data) {
    } else {
      setIsSubmit(true);

      onSubmit(data);
    }
  };

  useEffect(() => {
    if (viewResourceData?.resourceId) {
      setFormValue({
        title: viewResourceData?.resourceTitle,
        categoryId: viewResourceData?.categoryId,
        userType: viewResourceData?.userType,
        resourceType: viewResourceData?.resourceTypeId,
        linkUrls: viewResourceData?.linkUrls[0],
        expirationDate: viewResourceData?.expirationDate,
        resourceAssigneeTypeId: viewResourceData?.resourceAssigneeType,
        assetTypeId: viewResourceData?.assetTypeId,
      });
      setcheckBox(viewResourceData?.sendNotification == true ? false : true);
      setExpirationDate(viewResourceData?.expirationDate);
      setShowDocuemnts(viewResourceData?.resourceTypeId);
      setServiceType(viewResourceData?.productIds?.map((item) => item.key));
    }
  }, [viewResourceData]);
  useEffect(() => {
    if (viewResourceData?.resourceId) {
      setIndividualUsersIds(
        viewResourceData?.individualUserIds?.map((data) => data.key)
      );

      setUsersList(viewResourceData?.individualUserIds);

      setGroupUsersIds(viewResourceData?.userGroupIds?.map((data) => data.key));

      setUsersGroupList(viewResourceData?.userGroupIds);
      setndividualAssetsIds(
        viewResourceData?.assetIds?.map((data) => data?.key)
      );

      setAssetsList(viewResourceData?.assetIds);
    }

    if (parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID)) {
      setClients(changesearchauto ? changesearchauto?.key : "");
    }
  }, [viewResourceData]);

  const addEditLink = resourceTypesId === 2 || addMode === true;

  const onChangeAssets = (e, value) => {
    setIsassignAssets(false);
    setAssets(value);
  };

  useEffect(() => {
    const ele = autoCompAsset?.current?.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    if (ele) ele.click();
  }, [formValue?.assetTypeId]);
  const blankstate = () => {
    setndividualAssetsIds([]);
    setAssetsList([]);

    setIndividualUsersIds([]);
    setUsersList([]);

    setGroupUsersIds([]);
    setUsersGroupList([]);

    setAssets("");

    if (!editMode) {
      setExpirationDate("");
    }
  };
  const onChnageResourseassignee = (e, val) => {
    setFormValue({
      ...formValue,
      resourceAssigneeTypeId: parseInt(e?.target?.value),
      isPublic: false,
    });
    blankstate();
  };
  const onChangeAssetTypeId = (e, val) => {
    setFormValue({
      ...formValue,
      assetTypeId: parseInt(e?.target?.value),
      isPublic: false,
    });
    blankstate();
  };
  const onChangeUserType = (e, val) => {
    setFormValue({
      ...formValue,
      userType: parseInt(e.target.value),
      isPublic: false,
    });
    blankstate();
    setIsindividualUsersIds(false);
    setIsGroupIds(false);
  };
  const [assetTypeData, setAssetTypeData] = useState(
    parseInt(PRODUCT_ID()) === 2 ? ASSETTYPE_SW : ASSETTYPE
  );
  useEffect(() => {
    if (PRODUCT_ID() == 2) {
      var stormAssetTypeData = ASSETTYPE_SW?.filter((val) => {
        return val.key == 5;
      });
      setAssetTypeData(stormAssetTypeData);
    }
  }, []);
  const typeOnchange = (event) => {
    searchClient(event.target.value.trim());
  };

  const onSelectSearchCustomer = (e, value, s) => {
    setchangesearchauto({ key: value?.key ?? "", value: value?.value ?? "" });
    setClients(value?.key);
  };

  useEffect(() => {
    // change client blank all fields
    setFormValue(intialState);
    setExpirationDate("");

    setndividualAssetsIds([]);
    setAssetsList([]);

    setIndividualUsersIds([]);
    setUsersList([]);

    setGroupUsersIds([]);
    setUsersGroupList([]);

    setAssets("");
  }, [changesearchauto]);
  return (
    <>
      {addEditLink && (
        <div className="overidePersonal">
          <Grid item lg={6} xs={12} sm={12} md={6} textAlign="left">
            <div className="heading_style">
              {editMode ? "Edit Resource Library" : "Add Resource Library"}
            </div>
          </Grid>
          <Grid container item md={9} xs={9} className="addShadowBox">
            {ORG_ID() !== LOGIN_ORG_ID() && parseInt(PRODUCT_ID()) === 2 ? (
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Client" isRequired={false} />
                  <InputField
                    isRequired={false}
                    type="text"
                    placeholder={"Add Client Name Here..."}
                    value={changesearchauto ? changesearchauto?.value : ""}
                    disabled={ORG_ID() !== 1}
                  />
                </FormControl>
              </Grid>
            ) : parseInt(PRODUCT_ID()) === 2 &&
              parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) &&
              checkPrivileges([12]) ? (
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Client Name" isRequired={false} />

                  <SearchAutoComplete
                    name="Customer"
                    optionsData={searchAllClientsReducerData?.data ?? []}
                    typeOnchange={(event, value) => typeOnchange(event)}
                    isKeyValuePair={true}
                    value={changesearchauto ? changesearchauto : ""}
                    onChange={(event, value, key) =>
                      onSelectSearchCustomer(event, value, key)
                    }
                    isLoading={autoSearchDataLoading}
                    noOptionsTextMsg="No Client"
                    disabled={
                      parseInt(LOGIN_ORG_ID()) !== parseInt(HBNEXT_ORG_ID) ||
                      editMode
                    }
                  />
                </FormControl>
              </Grid>
            ) : (
              ""
            )}

            <Grid item container mt={3} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <InputField
                  type="text"
                  isRequired={true}
                  label="Resource Title:"
                  placeholder="Add Resource Title Here..."
                  disabled={editMode ? true : false}
                  value={formValue?.title}
                  onChange={(e) =>
                    setFormValue({ ...formValue, title: e.target.value })
                  }
                  isError={isTitle}
                  errorMsg={isTitle ? "Resource Title is Required" : ""}
                />
              </Grid>

              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Resource Category:" isRequired={true} />
                  <SelectMenu
                    placeholder="Please Select"
                    listData={resourceCategory}
                    value={formValue?.categoryId}
                    onchangehandler={(e) =>
                      setFormValue({
                        ...formValue,
                        categoryId: e.target.value,
                      })
                    }
                    isError={isResourceCategory}
                    errorMsg={
                      isResourceCategory ? "Resource Category is Required" : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container mt={4}>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <FormLabels label="Assign Resource To" />
                <RadioGroup row name="radio-user-type" className="radioGroup">
                  <RadioButton
                    checked={formValue?.resourceAssigneeTypeId === 0}
                    value={0}
                    label="Users"
                    onChange={(e, value) => onChnageResourseassignee(e, value)}
                    disabled={
                      editMode && formValue?.resourceAssigneeTypeId == 1
                        ? true
                        : false
                    }
                  ></RadioButton>
                  <RadioButton
                    checked={formValue?.resourceAssigneeTypeId === 1}
                    value={1}
                    label="Assets"
                    onChange={(e, value) => onChnageResourseassignee(e, value)}
                    disabled={
                      editMode && formValue?.resourceAssigneeTypeId == 0
                        ? true
                        : false
                    }
                  ></RadioButton>
                </RadioGroup>
              </Grid>
            </Grid>
            {formValue?.resourceAssigneeTypeId == 1 && (
              <Grid container mt={3}>
                <Grid item lg={12} xs={12} sm={12} md={12}>
                  <FormLabels label="Asset Type" />
                  <RadioGroup row name="radio-user-type" className="radioGroup">
                    {assetTypeData?.map((val, index) => {
                      return (
                        <RadioButton
                          key={index}
                          checked={formValue?.assetTypeId === val?.key}
                          value={val?.key}
                          label={val?.value}
                          onChange={(e, value) => onChangeAssetTypeId(e, value)}
                          disabled={
                            editMode && formValue?.resourceAssigneeTypeId == 1
                              ? true
                              : false
                          }
                        ></RadioButton>
                      );
                    })}
                  </RadioGroup>
                </Grid>
              </Grid>
            )}
            {parseInt(formValue?.resourceAssigneeTypeId) === 0 && (
              <Grid container mt={4}>
                <Grid item lg={12} xs={12} sm={12} md={12}>
                  <FormLabels label="Select User Type" />
                  <RadioGroup row name="radio-user-type" className="radioGroup">
                    <RadioButton
                      checked={formValue?.userType === 1}
                      value={1}
                      label="Individual"
                      onChange={(e, value) => onChangeUserType(e, value)}
                    ></RadioButton>
                    <RadioButton
                      checked={formValue?.userType === 2}
                      value={2}
                      label="User Group"
                      onChange={(e, value) => onChangeUserType(e, value)}
                    ></RadioButton>

                    <RadioButton
                      checked={formValue?.userType === 3}
                      value={3}
                      label="All User"
                      onChange={(e, value) => onChangeUserType(e, value)}
                    ></RadioButton>
                  </RadioGroup>
                </Grid>
              </Grid>
            )}

            {formValue?.userType === 1 &&
              parseInt(formValue?.resourceAssigneeTypeId) == 0 && (
                <Grid item container mt={0} spacing={2}>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormLabels label="Assign to" isRequired={true} />
                    <SearchAutoComplete
                      name="Add Individual Users"
                      optionsData={allUsers?.users ?? []}
                      typeOnchange={(event, value) =>
                        handleAllUserSearch(event?.target?.value.trim())
                      }
                      isKeyValuePair={true}
                      value={users}
                      onChange={(e, value) => onChangeUsers(value)}
                      isLoading={isLoading}
                      autoCompClear={autoComp}
                    />
                    {isindividualUsersIds && (
                      <div className="errorMsg">
                        Please enter this required field
                      </div>
                    )}
                  </Grid>
                  <Grid
                    className="smallAddBtn"
                    item
                    lg={1.6}
                    xs={12}
                    sm={12}
                    mt={3.4}
                    md={4}
                  >
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={"Add"}
                      onClick={() => addIndiVidualUserData()}
                      id="btnAddEquipment"
                      varientAddIconBlue={true}
                    ></Buttons>
                  </Grid>
                  <Grid pl={2} mt={3} mb={1} container gap={1}>
                    <UsersChip
                      userList={userList}
                      handleDelete={(e) => deleteUsers(e)}
                    />
                  </Grid>
                </Grid>
              )}

            {formValue?.userType === 2 &&
              formValue?.resourceAssigneeTypeId == 0 && (
                <Grid item container mt={0} spacing={2}>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormLabels label="Add User Group" isRequired={true} />
                    <SearchAutoComplete
                      name="Add User Group"
                      optionsData={allGroupsUser?.groupList ?? []}
                      typeOnchange={(event, value) =>
                        handleAllGroupSearch(event.target.value, 0, true)
                      }
                      isKeyValuePair={true}
                      value={usersGroup}
                      onChange={(e, value) => onChangeUsersGroup(value)}
                      isLoading={isUserGropLoading}
                      autoCompClear={autoComp}
                    />
                    {isGroupIds && (
                      <div className="errorMsg">
                        Please enter this required field
                      </div>
                    )}
                  </Grid>
                  <Grid
                    className="smallAddBtn"
                    item
                    lg={1.5}
                    xs={12}
                    sm={12}
                    mt={3.4}
                    md={4}
                  >
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={"Add"}
                      onClick={() => addGroupUserData()}
                      id="btnAddEquipment"
                      varientAddIconBlue={true}
                    ></Buttons>
                  </Grid>
                  <Grid pl={2} mt={3} container gap={1}>
                    <UsersChip
                      userList={userGroupList}
                      handleDelete={(e) => deleteUsersGroup(e)}
                    />
                  </Grid>
                </Grid>
              )}

            {(formValue?.resourceAssigneeTypeId == 1 && !editMode) ||
            (editMode &&
              (PRODUCT_ID() == 1 || PRODUCT_ID() == 2) &&
              formValue?.resourceAssigneeTypeId == 1) ? (
              <>
                <Grid item container mt={0} spacing={2}>
                  <Grid item lg={6} xs={12} sm={12} md={6}>
                    <FormLabels
                      label={editMode ? "Asset Name" : "Add Asset Name"}
                      isRequired={true}
                    />
                    <SearchAutoComplete2
                      name="Add Asset Name"
                      optionsData={assetOption ?? []}
                      isKeyValuePair={true}
                      value={editMode ? assetsList[0] : assets}
                      typeOnchange={(event) =>
                        searchAsset(
                          event.target.value,
                          PRODUCT_ID() == 2 ? 5 : formValue?.assetTypeId
                        )
                      }
                      onChange={(event, value) => onChangeAssets(event, value)}
                      isLoading={isLoading}
                      autoCompClear={autoCompAsset}
                      disabled={editMode ? true : false}
                    />

                    {isassignAssets && (
                      <div className="errorMsg">
                        Please enter this required field
                      </div>
                    )}
                  </Grid>
                  <Grid
                    className="smallAddBtn"
                    item
                    lg={1.6}
                    xs={12}
                    sm={12}
                    mt={3.4}
                    md={4}
                  >
                    {editMode &&
                    (PRODUCT_ID() == 2 || PRODUCT_ID() == 1) &&
                    viewResourceData?.resourceAssigneeType == 1 ? (
                      ""
                    ) : (
                      <Buttons
                        aria-describedby="dd"
                        variant="contained"
                        type="button"
                        name="btn"
                        label={"Add"}
                        onClick={() => addAssetsData()}
                        id="btnAddEquipment"
                        varientAddIconBlue={true}
                        disabled={
                          editMode &&
                          (PRODUCT_ID() == 2 || PRODUCT_ID() == 1) &&
                          viewResourceData?.resourceAssigneeType == 1
                            ? true
                            : false
                        }
                      ></Buttons>
                    )}
                  </Grid>
                  <Grid pl={2} mt={3} mb={2} container gap={1}>
                    {editMode &&
                    (PRODUCT_ID() == 2 || PRODUCT_ID() == 1) &&
                    viewResourceData?.resourceAssigneeType == 1 ? (
                      ""
                    ) : (
                      <UsersChip
                        userList={assetsList}
                        handleDelete={
                          editMode &&
                          PRODUCT_ID() == 2 &&
                          viewResourceData?.resourceAssigneeType == 1
                            ? ""
                            : (e) => deleteAssets(e)
                        }
                        isiconDel={
                          editMode &&
                          (PRODUCT_ID() == 2 || PRODUCT_ID() == 1) &&
                          viewResourceData?.resourceAssigneeType == 1
                            ? false
                            : true
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container item>
                <Grid pl={2} mt={1} mb={2} container gap={1}>
                  <UsersChip
                    userList={assetsList}
                    handleDelete={
                      editMode &&
                      PRODUCT_ID() == 2 &&
                      viewResourceData?.resourceAssigneeType == 1
                        ? ""
                        : (e) => deleteAssets(e)
                    }
                    isiconDel={
                      editMode &&
                      PRODUCT_ID() == 2 &&
                      viewResourceData?.resourceAssigneeType == 1
                        ? false
                        : true
                    }
                  />
                </Grid>
              </Grid>
            )}
            <Grid item container spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormLabels label={"Expiration Date"} isRequired={false} />
                <DatePicker
                  value={expirationDate ? expirationDate : null}
                  maxDateEnable={true}
                  minDate={getCurrentDate()}
                  onChangeHandler={(e) => onchangeExpirationdatePicker(e)}
                  // disabled={editMode ? true : false}
                />
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={4} mt={4}>
                <Checkbox
                  checked={checkBox}
                  onChange={() => setcheckBox(!checkBox)}
                  label={"Do not notify users about updation"}
                />
              </Grid>
            </Grid>

            <Grid item container mt={0} spacing={2}>
              <Grid item lg={6} xs={12} sm={12} md={6}>
                <FormControl fullWidth>
                  <FormLabels label="Resource Type:" />
                  <SelectMenu
                    listData={RESOURCETYPES}
                    value={formValue?.resourceType}
                    onchangehandler={(e) => handleResourceType(e.target.value)}
                    disabled={editMode ? true : false}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container mt={3}>
              <Grid item lg={12} xs={12} sm={12} md={12}>
                {showDocs === 1 && (
                  <>
                    <FormLabels label="Add Resource" isRequired={true} />
                    <FormDropZone
                      paraText1={"Drop the files here ..."}
                      paraText2={"Drop the files here, "}
                      paraText3={"or Browse"}
                      handleFileChange={handleFileChange}
                      fileType={10}
                      imageUrl={formValue?.fileUrls}
                      deleteImageURL={(url) => handleDeleteImageURL()}
                      id="addResources"
                      key="addResources"
                      isMuliple={false}
                      isDoc={true}
                      isImage={false}
                      allFiles={true}
                    />
                    <p>
                      Supported file types: doc, docx, ppt, pptx, xls, xlsx,
                      zip,images, or pdf
                    </p>
                    {!formValue?.fileUrls && dropboxError && (
                      <div className="errorMsg">Add Resource is Required</div>
                    )}
                  </>
                )}

                {showDocs === 2 && (
                  <>
                    <FormLabels label="Add Link:" />
                    <InputField
                      fullWidth={true}
                      type="url"
                      value={formValue?.linkUrls}
                      onChange={(e) =>
                        setFormValue({ ...formValue, linkUrls: e.target.value })
                      }
                    />
                    {islinkUrls && (
                      <div className="error">Please add proper link</div>
                    )}
                    {islinkUrlsError && (
                      <div className="error">Add Link is Required</div>
                    )}
                  </>
                )}
              </Grid>
            </Grid>

            <Grid container mt={3} spacing={10} alignItems="center">
              <Grid item lg={6} xs={12} sm={6} md={6}>
                <BackBtn dataBack={dataBack} />
              </Grid>
              <Grid item lg={6} xs={12} sm={6} md={6} textAlign="right">
                <Buttons
                  varientContained={true}
                  label="Upload Resource"
                  disabled={isSubmit ? true : false}
                  onClick={submitData}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}

      {resourceTypesId === 1 && (
        <>
          <EditResource
            isResViewLoading={isResViewLoading}
            viewResourceData={viewEditResourceData}
            allUsers={allUsers}
            isLoading={isLoading}
            onSubmit={onSubmit}
            handleAllUserSearch={handleAllUserSearch}
            allGroupsUser={allGroupsUser}
            isUserGropLoading={isUserGropLoading}
            handleAllGroupSearch={handleAllGroupSearch}
            searchAsset={searchAsset}
            assetOption={assetOption}
            isEdit={true}
            clients={clients}
          />
        </>
      )}
    </>
  );
}
