import React, { useEffect, useState } from "react";
import OpenItemList from "./openItemList";
import { useDispatch, useSelector } from "react-redux";
import * as incpectionAction from "../../redux/actions/incpectionAction";
import { USER_ID, GET_ITEM_LOG, PAGE_SIZE } from "../../constant/constant";
import { VIEW_OPEN_ITEMS_DETAILS } from "../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import Loader from "../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { checkPrivileges } from "../../utils/rolesHelper";

const OpenItemListContainer = ({ title = "Item Management" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fixedCount, setFixedCount] = useState(1);

  const itemListData = useSelector((state) => state.getItemList?.data);
  const userId = checkPrivileges([12, 117]) ? 0 : parseInt(USER_ID());
  const [statusIds, setStatusIds] = useState(2);
  const defaultData = {
    userId: userId,
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
    projectId: 0,
    divisionId: 0,
    itemTypes: GET_ITEM_LOG.All,
    statusIds: parseInt(statusIds) === 1 ? "" : parseInt(statusIds),
    fromDate: "",
    toDate: "",
    status: "",
  };

  const getItemLogAllData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };

  const getItemLogCorrectiveData = (data) => {
    dispatch(incpectionAction.getItemList(data));
  };

  const getExportItemListData = (data) => {
    setIsSuccess(true);
    dispatch(incpectionAction.getExportItemList(data));
  };

  useEffect(() => {
    if (activeTab === 0) {
      getItemLogAllData(defaultData);
    }
  }, [activeTab]);

  const handleViewOnClick = (
    id,
    itemType,
    title,
    origin,
    status,
    itemTypeId,
    rootId,
    subRootId,
    assignedPartyId,
    addedById,
    assignedTo,
    involvedPartyName,
    involvedPartyId
  ) => {
    navigate(VIEW_OPEN_ITEMS_DETAILS, {
      state: {
        id: id,
        itemType: itemType,
        title: title,
        origin: origin,
        status: status,
        itemTypeId: itemTypeId,
        rootId: rootId,
        subRootId: subRootId,
        assignedPartyId: assignedPartyId,
        addedById: addedById,
        assignedTo: assignedTo,
        involvedPartyName:involvedPartyName,
        involvedPartyId:involvedPartyId
      },
    });
  };

  const isGetItemLogLoading = useSelector(
    (state) => state.getItemList?.isLoading
  );

  const getExportItemListDatass = useSelector(
    (state) => state.getExportItemListDatas
  );

  useEffect(() => {
    if (
      getExportItemListDatass?.data?.responseMessages?.responseMessage ==
      "Request was successful"
    ) {
      setIsSuccess(false);
      toast("Export Details has been sent to registered mail ID");
      setTimeout(() => {
        window.location.reload();
      }, 3800);
    }
  }, [getExportItemListDatass]);

  const openItemIntialCount = {
    correctivecount: "",
    reprimandscount: "",
    kudoscount: "",
    actionscount: "",
    openItemCountt: "",
  };
  const [openItemCount, setOpenItemCount] = useState();

  useEffect(() => {
    if (
      parseInt(activeTab) === 0 &&
      itemListData &&
      parseInt(statusIds) === 2 &&
      fixedCount === 1
    ) {
      setOpenItemCount({
        correctivecount: itemListData?.result?.noOfViolation,
        reprimandscount: itemListData?.result?.noOfReprimand,
        kudoscount: itemListData?.result?.noOfKudos,
        actionscount: itemListData?.result?.noOfAction,
        totalopenitems: itemListData?.result?.openItemCount,
        data: itemListData,
      });
    }
  }, [fixedCount, activeTab, itemListData, statusIds]);
  const showLoader = useSelector(
    (state) => state.getExportItemListDatas?.isLoading || isGetItemLogLoading
  );
  return (
    <>
        {showLoader && <Loader />}
         <div className="openItemoverride">
            <div className="insi-stitle">
              <p>{title}</p>
            </div>
          </div>
          <div>
            <ToastContainer />
          </div>
          <OpenItemList
            userId={userId}
            itemListData={isGetItemLogLoading ? [] : itemListData}
            isGetItemLogLoading={isGetItemLogLoading}
            getItemLogCorrectiveData={getItemLogCorrectiveData}
            title={title}
            filterShow
            activeTab={activeTab}
            getItemLogAllData={getItemLogAllData}
            handleViewOnClick={handleViewOnClick}
            statusIds={statusIds}
            setStatusIds={setStatusIds}
            getExportItemListData={getExportItemListData}
            getExportItemListDatass={getExportItemListDatass}
            openItemCount={openItemCount}
            setActiveTab={setActiveTab}
            fixedCount={fixedCount}
            setFixedCount={setFixedCount}
          />
        </>
      );
};

export default OpenItemListContainer;