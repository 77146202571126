import React from "react";
import Popover from "@mui/material/Popover";
import { Card, CardContent, Box, CardActions } from "@mui/material";
import Buttons from "../../../library/custom/button/Button";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import "./FilterCommonStatusSelect.scss";

const UserFilterList = ({
  id,
  open,
  anchorEl,
  handleClose,
  firstLebelFilter,
  secondLebelFilter,
  firstLabel,
  secondLabel,
  firstLebelOnChanges,
  secondLebelOnChanges,
  firstLebelValue,
  secondLebelValue,
  resetBtn = false,
  handleClear,
  handleApply,
  isShow = false,
  expirationType,
  expirationTypeOnChanges,
  expirationTypeList = null,
}) => {
  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases">
          <CardContent>
            {secondLebelFilter && (
              <Box className="custom_filter_box" sx={{ pb: 2 }}>
                <FormLabels label={secondLabel} isRequired={false} />
                <SelectMenu
                  name={secondLabel}
                  listData={secondLebelFilter ?? []}
                  onchangehandler={(e) => secondLebelOnChanges(e)}
                  value={secondLebelValue}
                  placeholder="Please select"
                />
              </Box>
            )}
            {firstLebelFilter && isShow && (
              <Box className="">
                <FormLabels label={firstLabel} isRequired={false} />
                <SelectMenu
                  name={firstLabel}
                  listData={firstLebelFilter}
                  onchangehandler={(e) => firstLebelOnChanges(e)}
                  value={firstLebelValue}
                  placeholder="Please select"
                />
              </Box>
            )}
            {expirationTypeList && (
              <Box className="" sx={{ pb: 2, pt: 2 }}>
                <FormLabels label={"Expiry"} isRequired={false} />
                <SelectMenu
                  name={"expirationType"}
                  listData={expirationTypeList}
                  onchangehandler={(e) => expirationTypeOnChanges(e)}
                  value={expirationType}
                  placeholder="Please select"
                />
              </Box>
            )}
          </CardContent>
          <CardActions>
            {resetBtn && (
              <Buttons
                primary={true}
                label={"Reset"}
                onClick={(e) => handleClear(e)}
                size="small"
              />
            )}

            {/* <Buttons
              primary={true}
              label={"Reset"}
              onClick={(e) => resetFilter(e)}
              size="small"
            /> */}
            <Buttons
              primary={true}
              label={"Apply"}
              onClick={(e) => handleApply(e)}
              size="small"
            />
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export default UserFilterList;
