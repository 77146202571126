import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Buttons from "../../../../library/custom/button/Button";
import { InputField } from "../../../../library/custom/textBox/InputField";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import {
  ORG_ID,
  USERID,
  ISwitch,
  PAGE_SIZE,
  USER_ID,
  HBNEXT_ORG_ID,
} from "../../../../constant/constant";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import {
  INSPECTION_ADMIN,
  INSPECTION_GENERATOR,
  ITEM_FOUND_RESPONSES,
  LINK_QUESTION_INSPECTION,
  SW_CATEGORIES,
} from "../../../../constant/routeContant";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import NORow from "../../../../library/custom/noRow/noRow";
import { TableLoader } from "../../../../library/common/Loader";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../../admin/shared/ReportRowManipulate";
import { getFormatedDate } from "../../../../utils/helper";
import ToolBar from "../../../../library/storm-water/toolBar";
import { useNavigate } from "react-router-dom";
import commonImages from "../../../../assets";
import { checkPrivileges } from "../../../../utils/rolesHelper";

const AddSubCategory = ({
  onSubmit,
  getSubCategoriesList,
  getSubCategoriesListData,
  createSwSubCategory,
  updateSwSubCategoryData,
  isLoading,
  recordsCount,
  updateSwSubCategory,
}) => {
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = useState();
  const handleClose = () => setOpen(false);
  const [addCategoryBox, setAddCategoryBox] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [isGlobal, setIsGlobal] = useState(false);

  const navigate = useNavigate();

  const addCategoryHandler = () => {
    setIsSubCategoryName(false);
    setAddCategoryBox(!addCategoryBox);
  };

  const handleautoSearch = (event, state) => {
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      search: searchText ? searchText : "",
      page: page,
      pageSize: PAGE_SIZE,
    };
    getSubCategoriesListData(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    getSubCategoriesListData({
      page: newPage + 1,
      pageSize: PAGE_SIZE,
      search: search ? search : "",
    });
  };

  const [isSubmit, setIsSubmit] = useState(false);

  const intialState = {
    value: "",
  };
  const intialEditState = {
    value: "",
  };
  const [formValue, setFormValue] = useState(intialState);
  const [isSubCategoryName, setIsSubCategoryName] = useState(false);
  const [editFormValue, setEditFormValue] = useState(intialEditState);
  const [isEditSubCategoryName, setIsEditSubCategoryName] = useState(false);

  const handleChange = (categorieItem) => {
    const newCatList = [...getSubCategoriesList];
    newCatList.forEach((lists) => {
      if (lists.key == categorieItem.key) {
        lists.status = !lists.status;
        lists.checked = !lists.checked;
        lists.createdBy = parseInt(USERID);

        updateSwSubCategoryData({
          key: categorieItem?.key,
          value: categorieItem?.value,
          status: categorieItem?.status,
          isGlobal: categorieItem?.isGlobal,
          modifedBy: parseInt(USER_ID()),
        });
      }
    });
  };

  const handleGlobalChange = (categorieItem) => {
    const newCatList = [...getSubCategoriesList];
    newCatList.forEach((lists) => {
      if (lists.key == categorieItem.key) {
        lists.isGlobal = !lists.isGlobal;
        lists.createdBy = parseInt(USERID);
        updateSwSubCategoryData({
          key: categorieItem?.key,
          value: categorieItem?.value,
          status: categorieItem?.status,
          isGlobal: categorieItem?.isGlobal,
          modifedBy: parseInt(USER_ID()),
        });
      }
    });
  };

  const [errorsMsgShowActive, seterrorsMsgShowActive] = useState(false);
  const [errorsMsgShow, setErrorsMsgShow] = useState();

  function mappedData() {
    let errors = false;
    if (formValue?.value.trim() === "") {
      setFormValue({
        ...formValue,
        value: "",
      });
      seterrorsMsgShowActive(false);
      setIsSubCategoryName(true);
      errors = true;
    }
    if (errors) {
      return false;
    }

    const index = getSubCategoriesList?.findIndex(
      (obect) =>
        obect.value?.trim().toUpperCase() ===
        formValue?.value?.trim()?.toUpperCase()
    );
    if (index === -1) {
      seterrorsMsgShowActive(false);
      const list = [
        {
          value: formValue?.value,
          isGlobal: isGlobal,
        },
      ];
      return { list, userId: parseInt(USER_ID()) };
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Category Name already added");
    }
  }

  const submitData = () => {
    setSearch("");
    const data = mappedData();
    if (!data) {
    } else {
      setIsSubmit(true);
      onSubmit(data);
    }
  };

  const handleEditOnClick = (modalData) => {
    setAddCategoryBox(!addCategoryBox);
    setOpen(true);
    setModalData(modalData);
    setEditFormValue({
      value: modalData?.value,
    });
  };

  const submitUpdateData = () => {
    const index = getSubCategoriesList?.findIndex(
      (obect) =>
        obect.value?.toUpperCase() === editFormValue?.value?.toUpperCase()
    );

    if (editFormValue?.value.trim() === "") {
      setEditFormValue({
        ...editFormValue,
        value: "",
      });
      seterrorsMsgShowActive(false);
      setIsEditSubCategoryName(true);
    } else if (index === -1) {
      setOpen(false);
      setEditFormValue({
        ...editFormValue,
        value: "",
      });

      updateSwSubCategoryData({
        key: modalData?.key,
        value: editFormValue?.value,
        status: modalData?.status,
        isGlobal: modalData?.isGlobal,
        modifedBy: parseInt(USER_ID()),
      });
    } else {
      seterrorsMsgShowActive(true);
      setErrorsMsgShow("Sub Category Name already added");
    }
  };

  const dataBack = {
    title: "Back to Inspection Configuration",
    route: -1,
  };

  const [getSubCategoriesLists, setGetSubCategoriesLists] = useState([]);
  useEffect(() => {
    setGetSubCategoriesLists(getSubCategoriesList);
  }, [getSubCategoriesList]);
  useEffect(() => {
    if (
      createSwSubCategory?.status === "SUCCESS" &&
      createSwSubCategory?.isLoading === false
    ) {
      setIsSubmit(false);
      setFormValue({
        ...formValue,
        value: "",
      });
      setIsSubCategoryName(false);
      const data = {
        search: "",
        page: 1,
        pageSize: PAGE_SIZE,
      };
      getSubCategoriesListData(data);
      setIsGlobal(false);
    }
  }, [createSwSubCategory]);

  const columns = [
    { field: "value", headerName: "Sub Category Name", flex: 2 },
    // {
    //   field: "lastUpdated",
    //   headerName: "Last Updated",
    //   flex: 1,
    //   renderCell: (rows) => <>{getFormatedDate(rows?.row?.lastUpdated)}</>,
    // },
    {
      flex: 1,
      field: "status",
      headerName: "Status",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox swins">
                {checkPrivileges([12, 93]) && (
                  <FormControlLabel
                    control={
                      <ISwitch
                        checked={rows?.row.status ? rows?.row.status : false}
                        onChange={() => handleChange(rows?.row)}
                        name="status"
                      />
                    }
                  />
                )}
                <div>{rows?.row?.status ? "Active" : "Inactive"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      flex: 1,
      field: "isGlobal",
      headerName: "Global",
      renderCell: (rows) => (
        <Grid className="catListBox">
          <Grid className="closeIcon">
            <FormControl component="fieldset" variant="standard">
              <FormGroup className="isActiveBox swins">
                {checkPrivileges([12, 93]) &&
                  parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && (
                    <FormControlLabel
                      control={
                        <ISwitch
                          checked={
                            rows?.row.isGlobal ? rows?.row.isGlobal : false
                          }
                          onChange={() => handleGlobalChange(rows?.row)}
                          name="isGlobal"
                        />
                      }
                    />
                  )}

                <div>{rows?.row?.isGlobal ? "Global" : "N/A"}</div>
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      ),
    },
    {
      flex: 1,
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={recordsCount ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={getSubCategoriesLists ?? []}
          fileNameXL={"categoryList"}
        />
      ),
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <>
            {(parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) ||
              !rows?.row?.isGlobal) && (
              <ReportRowManipulte
                rows={rows}
                editOnClick={() => handleEditOnClick(rows?.row)}
                edit={checkPrivileges([12, 93, 94])}
                editLabel="Edit Sub Category"
              />
            )}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    if (
      updateSwSubCategory?.status === "SUCCESS" &&
      updateSwSubCategory?.isLoading === false
    ) {
      getSubCategoriesListData({
        page: page,
        pageSize: PAGE_SIZE,
        search: search ? search : "",
      });
    }
  }, [updateSwSubCategory]);

  const toolBarData = {
    title: "Inspection Form Configuration",
    list: [
      {
        id: 1,
        title: "Inspection Header",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 2,
        title: "Category Configuration",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 5,
        title: "Sub Category Configuration",
        link: "",
        activeClass: "active",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 3,
        title: "Questions Configuration",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
      {
        id: 4,
        title: "Item Found Responses",
        link: "",
        isshow: checkPrivileges([12, 93, 94, 95]),
      },
    ],
  };

  return (
    <>
      <Grid className="inspectionOverRide">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid className="modalContainer">
            <Box className="modalBox">
              <div className="modalTitle">Edit Sub Category</div>

              <div className="modalInputBox">
                <InputField
                  type="text"
                  isRequired={true}
                  value={editFormValue?.value}
                  onChange={(e) =>
                    setEditFormValue({
                      ...editFormValue,
                      value: e.target.value,
                    })
                  }
                  label="Edit Sub Category"
                  placeholder="Edit Sub Category Here..."
                  isError={isEditSubCategoryName}
                  errorMsg={
                    isEditSubCategoryName ? "Category Sub Name is Required" : ""
                  }
                />
                {errorsMsgShowActive && (
                  <div className="errorMsg">{errorsMsgShow}</div>
                )}
              </div>
              <Grid container className="modalFooterBox">
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientText={true}
                    label="Cancel"
                    onClick={handleClose}
                  />
                </Grid>
                <Grid item lg={2} xs={12} sm={3} md={3}>
                  <Buttons
                    varientContained={true}
                    label={"Save"}
                    onClick={submitUpdateData}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Modal>

        <Grid container item md={12} xs={12} className="addShadowBox">
          <Grid container className="confBox">
            <Grid item md={8} xs={12}>
              <div className="confTitile">Sub Category Configuration</div>
            </Grid>
            <Grid item md={4} xs={12}>
              {checkPrivileges([12, 93, 94]) && (
                <Buttons
                  id="btnAddCategory"
                  label={"Add Sub Category"}
                  varientAddIconBlue={true}
                  onClick={() => addCategoryHandler()}
                  iconToggle={addCategoryBox}
                />
              )}
            </Grid>
          </Grid>

          <Grid container md={12} xs={12} item>
            <Grid container item md={12} xs={12}>
              {addCategoryBox && (
                <Grid item container md={10} className="CategoriesBox">
                  <Grid item md={6} xs={6} mb={2}>
                    <InputField
                      type="text"
                      isRequired={true}
                      value={formValue?.value}
                      onChange={(e) =>
                        setFormValue({
                          ...formValue,
                          value: e.target.value,
                        })
                      }
                      label="Create Sub Category"
                      placeholder="Create Sub Category Here..."
                      isError={isSubCategoryName}
                      errorMsg={
                        isSubCategoryName ? "Sub Category Name is Required" : ""
                      }
                    />
                    {errorsMsgShowActive && (
                      <div className="errorMsg">{errorsMsgShow}</div>
                    )}
                  </Grid>

                  <Grid
                    className="smallAddBtn"
                    item
                    lg={1.5}
                    md={3}
                    xs={3}
                    ml={3}
                    mt={4}
                  >
                    <Buttons
                      aria-describedby="dd"
                      variant="contained"
                      type="button"
                      name="btn"
                      label={"Create"}
                      id="btnAddEquipment"
                      varientAddIconBlue={true}
                      disabled={isSubmit ? true : false}
                      onClick={submitData}
                    ></Buttons>
                  </Grid>
                </Grid>
              )}

              <Grid item container md={12} className="CategoriesBox" mt={3}>
                <Grid item md={5} xs={8} mb={3}>
                  <div className="searchcaseid">
                    <label htmlFor="search">
                      <span>
                        <SearchIcon />
                      </span>
                      <div>
                        <input
                          id="search"
                          placeholder="Search"
                          type="text"
                          value={search}
                          className=""
                          onChange={handleautoSearch}
                          autoComplete="off"
                        />
                      </div>
                    </label>
                  </div>
                </Grid>
              </Grid>

              <Grid item container>
                <Grid item md={8} className="CategoriesBox" mt={3}>
                  <div className="desc_box row_uniq">
                    <div className="table_style_ui">
                      <GridTable
                        getRowId={(r) => r.key}
                        rows={getSubCategoriesLists ?? []}
                        rowCount={recordsCount ?? 0}
                        columns={columns}
                        loading={isLoading}
                        checkboxSelection={false}
                        page={page}
                        pagination
                        pageSize={PAGE_SIZE}
                        rowsPerPageOptions={[PAGE_SIZE]}
                        onPageChange={handlePagignation}
                        backIconButtonProps={{ disabled: false }}
                        disableSelectionOnClick
                        components={{
                          LoadingOverlay: TableLoader,
                          NoRowsOverlay: NORow,
                        }}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid lg={4} md={12} xs={12} item className="inspectionTool">
                  <div className="toolBarBox">
                    <ToolBar
                      toolBarData={toolBarData}
                      handleClick={(e) => {
                        if (e.id === 1) {
                          navigate(INSPECTION_GENERATOR);
                        }
                        if (e.id === 2) {
                          navigate(SW_CATEGORIES);
                        }
                        if (e.id === 3) {
                          navigate(LINK_QUESTION_INSPECTION);
                        }
                        if (e.id === 4) {
                          navigate(ITEM_FOUND_RESPONSES);
                        }
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div className="insBackBtn">
            <BackBtn dataBack={dataBack} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AddSubCategory;
