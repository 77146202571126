import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as userAction from "../../../redux/actions/userAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";

import ContractorList from "../component/contractor/contractorList";
import { useNavigate } from "react-router-dom";
import {
  ADD_ADMIN_GROUP,
  ADMIN_CONTRACTOR,
} from "../../../constant/routeContant";
import * as contractorAction from "../../../redux/actions/contractorAction";
import { API_STATUS, ORG_ID } from "../../../constant/constant";

const ContractorContainer = () => {
  const dispatch = useDispatch();
  const addUsers = () => {};
  const navigate = useNavigate();

  const NavigateSingleUserMethode = () => {
    navigate(ADMIN_CONTRACTOR);
  };
  const NavigateUserGroupMethode = () => {
    navigate(ADD_ADMIN_GROUP);
  };
  const contractorGrades = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.contractorGrades
  );
  const contractorTypes = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.contractorTypes
  );

  const getuserLists = () => {
    dispatch(userAction.getUserList());
  };

  const deleteContractors = useSelector((state) => state.deleteContractor);
  const deleteList = (id) => {
    dispatch(contractorAction.deleteContractor(id));
  };

  useEffect(() => {
    getuserLists();
  }, []);

  const defaultData = {
    page: 1,
    pageSize: 10,
    search: "",
    gradeId: "",
    divisionId: 0,
  };

  const ContractorListData = useSelector((state) => state.getContractorList);
  const getDropdownDataKeyValue = useSelector(
    (state) => state.getDropdownDataKeyValue
  );

  const getContractorListData = (data) => {
    dispatch(contractorAction.getContractorList(data));
  };
  const getDropdownDataKeyValueData = {
    orgId: ORG_ID(),
    flags: "3,6,2",
  };
  useEffect(() => {
    if (deleteContractors.status === API_STATUS.SUCCESS) {
      getContractorListData(defaultData);
    }
  }, [deleteContractors]);

  useEffect(() => {
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
    getContractorListData(defaultData);
  }, []);

  return (
    <>
      <ContractorList
        addUsers={addUsers}
        loading={ContractorListData?.isLoading || deleteContractors.isLoading}
        contractorListData={ContractorListData?.data}
        getContractorListData={getContractorListData}
        contractorGrades={contractorGrades ?? []}
        NavigateSingleUser={NavigateSingleUserMethode}
        NavigateUserGroup={NavigateUserGroupMethode}
        deleteList={deleteList}
        contractorTypes={contractorTypes ?? []}
      />
    </>
  );
};

export default ContractorContainer;
