import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";
import { PAGE_SIZE_100 } from "../../../../constant/constant";
import { REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item } from "../../../../constant/routeContant";
import commonImages from "../../../../assets";
import {
  downloadExcel,
  downloadPDF,
  useInfiniteScroll,
} from "../../../../utils/helper";

const UserMostIncidentActionItems = (props) => {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const [data, setData] = useState([]);
  const [dataAction, setDataAction] = useState([]);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);

  useEffect(() => {
    if (props?.incUserMostIncidentData) {
      setData((prevData) => [...prevData, ...props?.incUserMostIncidentData]);
    }
    if (props?.incUserMostActionList) {
      setDataAction((prevData) => [
        ...prevData,
        ...props?.incUserMostActionList,
      ]);
    }
  }, [props?.incUserMostIncidentData, props?.incUserMostActionList]);
  useEffect(() => {
    setData([]);
    setDataAction([]);
  }, []);
  const fetchMoreData = async (pageNumber) => {
    const { startDate, endDate, incidentType, divisionId, locationId } =
      props?.drillDetail;

    const data = {
      page: pageNumber,
      pageSize: PAGE_SIZE_100,
      incidentType: incidentType > 0 ? incidentType : 0,
      fromDate: startDate,
      toDate: endDate,
      divisionId: divisionId,
      locationId: locationId,
    };
    try {
      let response;
      if (props?.drillDetailFull?.page === "user_most_action") {
        response = await props?.getIncidentUserWithMostNoOfActionItemData(data);
      } else if (props?.drillDetailFull?.page === "user_most_incident") {
        response = await props.gettUserMostIncidentData(data);
      }

      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    const moreData = await fetchMoreData(pageNumber);
    setPage(pageNumber);
    setData((prevData) => [...prevData, ...moreData]);
  };

  const columns = [
    {
      id: "user",
      label: `Name`,
      sortable: true,
      isSorted: sortColumn === "injuryTypeId",
      sortDirection,
    },

    {
      id: "incidents",
      label: "Number of Incidents",
      sortable: true,
      isSorted: sortColumn === "incidents",
      sortDirection,
    },
  ];
  const columnsAction = [
    {
      id: "user",
      label: `Name`,
      sortable: true,
      isSorted: sortColumn === "injuryTypeId",
      sortDirection,
    },

    {
      id: "actionItem",
      label: "Number of Action Items",
      sortable: true,
      isSorted: sortColumn === "actionItem",
      sortDirection,
    },
  ];
  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };
  const handleSortAction = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...dataAction].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setDataAction(sortedData);
  };
  const handleOnClickActionUser = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item, {
      state: {
        userId: id,
        drillDetail: props?.drillDetail,
        drillDetailFull: props?.drillDetailFull,
        itemTypeValue: "user_most_action",
      },
    });
  };
  const rowsAction = dataAction?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.user}</td>
      <td>
        <span
          className="link"
          onClick={() => handleOnClickActionUser(asset?.userId)}
        >
          {asset?.actionItem}
        </span>
      </td>
    </tr>
  ));
  const handleOnClickUserMostInc = (id) => {
    navigate(REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item, {
      state: {
        userId: id,
        drillDetail: props?.drillDetail,
        drillDetailFull: props?.drillDetailFull,
        itemTypeValue: "user_most_incident",
      },
    });
  };
  const rows = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.user}</td>
      <td>
        <span
          className="link"
          onClick={() => handleOnClickUserMostInc(asset?.userId)}
        >
          {asset?.incidents}
        </span>
      </td>
    </tr>
  ));
  const rowsPdf = data.map((asset) => [asset.user, asset.incidents]);
  const rowsActionPdf = dataAction.map((asset) => [
    asset?.user,
    asset?.actionItem,
  ]);

  return (
    <>
      <div className="topHeaderDrill">
        <div className="topHeaderDrillLeft">
          <div className="title">
            {props?.ispage === "user_most_incident"
              ? "User Incidents"
              : "Users with Most Number of Open Action Items"}
          </div>
        </div>
        <div className="topHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={
                      props?.drillDetailFull?.page === "user_most_incident"
                        ? () =>
                            downloadPDF(
                              rowsPdf,
                              columns.map((col) => col.label),
                              "User-most-incidents.pdf"
                            )
                        : props?.drillDetailFull?.page === "user_most_action"
                        ? () =>
                            downloadPDF(
                              rowsActionPdf,
                              columns.map((col) => col.label),
                              "User-most-actionItems.pdf"
                            )
                        : ""
                    }
                  >
                    <img src={commonImages.pdfFill} alt={""} />
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>

              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={
                      props?.drillDetailFull?.page === "user_most_incident"
                        ? () => downloadExcel(data, "User-most-incidents.xlsx")
                        : props?.drillDetailFull?.page === "user_most_action"
                        ? () =>
                            downloadExcel(
                              dataAction,
                              "User-most-Action-Item.xlsx"
                            )
                        : ""
                    }
                  >
                    <img src={commonImages.excelDownload} alt="" />
                    <span>Download.xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="customTableWrapper">
        {props?.ispage === "user_most_incident" ? (
          <Table
            columns={columns}
            rows={rows}
            tableRef={tableRef}
            onSort={handleSort}
          />
        ) : (
          <Table
            columns={columnsAction}
            rows={rowsAction}
            tableRef={tableRef}
            onSort={handleSortAction}
          />
        )}
      </div>
    </>
  );
};

export default UserMostIncidentActionItems;
