import React from "react";
import "../../../incidents/cases/addCaseList.scss";
import Buttons from "../../../../library/custom/button/Button";
import EquipmentTable from "./equipmentTable";
import { checkPrivileges } from "../../../../utils/rolesHelper";

export default function EquipmentList({
  equipmentList,
  addEquipment,
  getEquipmentList,
  isLoading,
  handleDeleteOnClick,
  handleEditOnClick,
  divisionDropdown,
  equipmentCategoryData,
}) {
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Equipment</div>
          <div>
            {checkPrivileges([52, 53, 12]) && (
              <Buttons
                label={"Add Equipment"}
                id="btnAddEquipment"
                varientAddIconBlue={true}
                onClick={() => addEquipment()}
              />
            )}
          </div>
        </div>

        <EquipmentTable
          equipmentList={equipmentList}
          getEquipmentList={getEquipmentList}
          isLoading={isLoading}
          filterShow
          handleDeleteOnClick={handleDeleteOnClick}
          handleEditOnClick={handleEditOnClick}
          divisionDropdown={divisionDropdown}
          equipmentCategoryData={equipmentCategoryData}
        />
      </section>
    </>
  );
}
