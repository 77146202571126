import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../../library/custom/button/Button";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import {
  CLIENT_PRODUCT_FILTER,
  CLIENT_SATATUS_FILTER,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import { TableLoader } from "../../../../library/common/Loader";
import NORow from "../../../../library/custom/noRow/noRow";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import {
  ADMIN_ADD_CLIENT,
  ADMIN_VIEW_CLIENT,
} from "../../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import UserFilterList from "../../shared/UserFilterList ";
import FilterListIcon from "@mui/icons-material/FilterList";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";

export default function ClientList({
  NavigateAddClient,
  clientListsData,
  isLoading,
  state,
  industryType,
  getClients,
}) {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusId, setStatusId] = useState("");
  const [productTypes, setProductTypes] = useState("");
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [clientListApiData, setClientListApiData] = useState({
    organisationId: ORG_ID(),
    page: 1,
    pageSize: pageSize,
    productId: PRODUCT_ID(),
    search: "",
    statusIds: "",
  });
  const [isTableLoading, setisTableLoading] = useState(false);

  const handleSearch = (data) => {
    setAnchorEl(null);
    getClients(data);
  };

  const handleApply = (state) => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    setClientListApiData({
      ...clientListApiData,
      productId: productTypes
        ? productTypes === 3
          ? 0
          : productTypes
        : PRODUCT_ID(),
      statusIds: statusId ? (statusId === 2 ? 0 : statusId) : "",
      search: search ? search : "",
    });
    setAnchorEl(null);
    const data = {
      ...clientListApiData,
      page: 1,
      pageSize: pageSize,
      productId: productTypes
        ? productTypes === 3
          ? 0
          : productTypes
        : PRODUCT_ID(),
      search: search ? search : "",
      statusIds: statusId ? (statusId === 2 ? 0 : statusId) : "",
      organisationId: ORG_ID(),
    };
    getClients(data);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      ...clientListApiData,
      page: newPage + 1,
      pageSize: pageSize,
      productId: productTypes
        ? productTypes === 3
          ? 0
          : productTypes
        : PRODUCT_ID(),
      search: search ? search : "",
      statusIds: statusId ? (statusId === 2 ? 0 : statusId) : "",
      organisationId: ORG_ID(),
    };
    setClientListApiData({
      ...clientListApiData,
      page: newPage + 1,
      productId: productTypes
        ? productTypes === 3
          ? 0
          : productTypes
        : PRODUCT_ID(),
      statusIds: statusId ? (statusId === 2 ? 0 : statusId) : "",
      search: search ? search : "",
    });
    getClients(data);
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    const data = {
      ...clientListApiData,
      page: 1,
      pageSize: newPazeSize,
      productId: productTypes
        ? productTypes === 3
          ? 0
          : productTypes
        : PRODUCT_ID(),
      search: search ? search : "",
      statusIds: statusId ? (statusId === 2 ? 0 : statusId) : "",
      organisationId: ORG_ID(),
    };
    setClientListApiData(data);
    getClients(data);
  };

  const handleautoSearch = (event) => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...clientListApiData,
      search: searchText ? searchText : "",
    };
    setClientListApiData({
      ...clientListApiData,
      search: searchText ? searchText : "",
    });
    getClients(data);
  };

  const handleClear = () => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    setProductTypes("");
    setStatusId("");
    setSearch("");
    const data = {
      page: 1,
      pageSize: pageSize,
      productId: PRODUCT_ID(),
      search: "",
      statusIds: "",
    };
    handleSearch(data);
  };

  const handleOnEditclick = (rows) => {
    navigate(ADMIN_ADD_CLIENT, {
      state: { Organisationid: rows.row.organisationId, editMode: true },
    });
  };
  const handleOnViewclick = (rows) => {
    navigate(ADMIN_VIEW_CLIENT, {
      state: {
        Organisationid: rows.row.organisationId,
        OrganisationDetails: rows.row,
        editMode: true,
      },
    });
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      navigate(ADMIN_VIEW_CLIENT, {
        state: {
          Organisationid: rows.row.organisationId,
          OrganisationDetails: rows.row,
          editMode: true,
        },
      });
    }
  };

  const credcloumns = [
    { field: "organisationId", headerName: " Client ID", flex: 2.8 },
    { field: "fullName", headerName: "Client Name", flex: 4 },
    { field: "shortName", headerName: " Client Short Name", flex: 4 },
    { field: "industrytype", headerName: "Industry Type", flex: 4 },
    { field: "stateName", headerName: "State", flex: 3.5 },
    {
      field: "procoreCompanyId",
      headerName: "Procore Status",
      flex: 3.5,
      renderCell: (rows) => {
        return rows?.row?.isProcoreConnected ? "Connected" : "Disconnected";
      },
    },
    { field: "noOfEmployees", headerName: "Employees", flex: 3.4 },
    { field: "status", headerName: "Status", flex: 2.5 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={clientListsData?.data?.result?.noOfRecords ?? 0}
          numberOfItemsPerPage={pageSize}
          incidentLists={clientListsData?.data?.result?.organisationData ?? []}
          fileNameXL={"ClientList"}
        />
      ),
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            credentialLists={true}
            editOnClick={() => handleOnEditclick(rows)}
            viewOnClick={() => handleOnViewclick(rows)}
            edit={checkPrivileges([12, 67, 68])}
            editLabel="Edit Client"
            download={false}
            view={checkPrivileges([12, 67, 68, 69])}
            viewLabel="View Client"
          />
        );
      },
    },
  ];

  const productTypeOnChange = (e) => {
    setProductTypes(e.target.value);
  };

  const statusOnChanges = (e) => {
    setStatusId(e.target.value);
  };

  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Clients</div>
          <div>
            <div className="filter_btn">
              {checkPrivileges([12, 67, 68]) && (
                <Buttons
                  id="btnAddClient"
                  label={"Add Client"}
                  varientAddIconBlue={true}
                  onClick={NavigateAddClient}
                ></Buttons>
              )}
            </div>
          </div>
        </div>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>

            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              <UserFilterList
                firstLabel="Status"
                secondLabel="Product"
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleApply={handleApply}
                handleClear={handleClear}
                firstLebelFilter={CLIENT_SATATUS_FILTER}
                secondLebelFilter={CLIENT_PRODUCT_FILTER}
                firstLebelOnChanges={statusOnChanges}
                secondLebelOnChanges={productTypeOnChange}
                firstLebelValue={statusId}
                secondLebelValue={productTypes}
                resetBtn={true}
                isShow={true}
              />
            </div>
          </div>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) =>
                    `${r.organisationId}|${r.fullName}|${
                      r.email || ""
                    }|${Date.now()}|${Math.random().toString(36).substr(2, 9)}`
                  }
                  rows={clientListsData?.data?.result?.organisationData ?? []}
                  rowCount={clientListsData?.data?.result?.noOfRecords ?? 0}
                  columns={credcloumns}
                  loading={isLoading}
                  checkboxSelection={false}
                  page={page}
                  pagination
                  pageSize={pageSize}
                  onClick={(rows) => onRowClick(rows)}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
