import React, { useEffect } from "react";
import AddCategory from "../component/inspection/addCategory";
import * as incpectionAction from "../../../redux/actions/incpectionAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../library/common/Loader";
import { ToastContainer, toast } from "react-toastify";
import { ORG_ID, PAGE_SIZE } from "../../../constant/constant";
import { useState } from "react";

const InspectionCategoriesContainer = () => {
  const dispatch = useDispatch();

  const defaultData = {
    searchText: "",
    organisationId: ORG_ID(),
    page: 1,
    pageSize: PAGE_SIZE,
    allowPaging: true,
  };

  const getCategoriesList = useSelector((state) => state.getCategoriesList);

  const getCategoriesListData = (data) => {
    dispatch(incpectionAction.getCategoriesList(data));
  };
  const adminAddUpdateCategory = useSelector(
    (state) => state.adminAddUpdateCategory
  );
  const isLoading = useSelector((state) => state.getCategoriesList?.isLoading);
  const showLoader = useSelector(
    (state) => state.adminAddUpdateCategory?.isLoading
  );
  const [clearCategory, setClearCategory] = useState(false);

  const onSubmit = (data) => {
    setClearCategory(true);
    dispatch(incpectionAction.adminAddUpdateCategory(data));
  };
  useEffect(() => {
    if (
      adminAddUpdateCategory?.status === "SUCCESS" &&
      adminAddUpdateCategory?.isLoading === false &&
      clearCategory
    ) {
      toast("Category Added successfully");
    }
  }, [adminAddUpdateCategory]);

  useEffect(() => {
    getCategoriesListData(defaultData);
  }, []);

  return (
    <>
      {showLoader && <Loader />}

      <AddCategory
        getCategoriesList={isLoading ? [] : getCategoriesList?.data?.keyValues}
        recordsCount={getCategoriesList?.data?.recordsCount}
        getCategoriesListData={getCategoriesListData}
        onSubmit={onSubmit}
        adminAddUpdateCategory={adminAddUpdateCategory}
        isLoading={isLoading}
      />
      <ToastContainer />
    </>
  );
};

export default InspectionCategoriesContainer;
