import { Button, FormControl, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import "./openTicketStyle.scss";
import AddIcon from "@mui/icons-material/Add";
import Buttons from "../../../../library/custom/button/Button";
import ReplyIcon from "@mui/icons-material/Reply";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useState } from "react";
import Loader from "../../../../library/common/Loader";
import TextArea from "../../../../library/custom/textArea/TextArea";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";
import FileUploadInput from "../../common/fileUploadInput";
import SentimentNeutralSharpIcon from "@mui/icons-material/SentimentNeutralSharp";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import SentimentDissatisfiedSharpIcon from "@mui/icons-material/SentimentDissatisfiedSharp";
import ViewImages from "../../../incidents/view-incident-report/common/viewImages/viewImages";
import UpdateIcon from "@mui/icons-material/Update";
import { ORG_ID } from "../../../../constant/constant";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CREATE_TICKET } from "../../../../constant/routeContant";
const OpenTicket = ({
  handleSubmit,
  handleUserFeedback,
  handleTicketReply,
  uploadFile,
  fileUploadData,
  ticketDetails,
  handleTicketDetails,
  isLoader,
}) => {
  console.log(isLoader)
  const navigate = useNavigate();
  useEffect(() => {
    // fileUploadData = "";
  }, [ticketDetails?.replyList]);

  const [showInputField, setShowInputField] = useState(false);
  const [showuserFeedbackInputFeild, setshowuserFeedbackInputFeild] =
    useState(false);
  const [userFeedback, setuserFeedback] = useState({
    ticketId: "",
    feedback: "",
    userSatisfection: "",
    orgId: ORG_ID(),
  });
  const [ticketReplyData, setticketReplyData] = useState({
    createdBy: localStorage.getItem("userId"),
    ticketId: null,
    message: "",
    isUserMessage: true,
    fileIds: [],
    orgId: ORG_ID(),
  });
  const handleUploadFileMethod = (fileUploaded, fileName, data) => {
    // const formData = new FormData();
    // formData.append("", fileUploaded);
    uploadFile(fileUploaded);
  };
  function handleReplySubmit(e) {
    e.preventDefault();
    if (
      ticketReplyData.message.length > 0 ||
      ticketReplyData.fileIds.length > 0
    ) {
      setticketReplyData({ ...ticketReplyData, message: "", fileIds: [] });
      handleTicketReply(ticketReplyData, ticketDetails);
      setreplyListState(null);
      fileUploadData = "";
    }
  }

  useEffect(() => {
    setreplyListState(ticketDetails?.replyList);
  }, [ticketDetails]);
  useEffect(() => {
    if (fileUploadData) {
      setticketReplyData({
        ...ticketReplyData,
        isUserMessage: true,
        ticketId: ticketDetails?.ticketId,
        fileIds: [fileUploadData?.files[0].id],
      });
    }
  }, [fileUploadData]);
  const [replyListState, setreplyListState] = useState(
    ticketDetails?.replyList
  );

  const imageSize = {
    size: "large",
  };

  const fileUploadStatus = useSelector((state) => state.fileUpload.isLoading);
  return (
    <>
      {ticketDetails ? (
        <div className="overrideOpenTicket">
          <div className="mainContainer">
            <Grid container className="topDiv">
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="backBtn"
              >
                <ArrowBackIosNewIcon fontSize="small" />
                Back to Support Tickets
              </button>
              <Grid item container lg={5} gap={2}>
                <Grid
                  onClick={() => {
                    // setticketDetails(null);
                    handleTicketDetails(ticketDetails.ticketId);
                  }}
                  container
                  item
                  className="updateIcon"
                  lg={5.8}
                  xs={6}
                  gap={1}
                >
                  <UpdateIcon />
                  <h1>Refresh Chat</h1>
                </Grid>
                <Buttons
                  startIcon={<AddIcon />}
                  primary={true}
                  label={"New Ticket"}
                  onClick={() => {
                    navigate(CREATE_TICKET);
                  }}
                />
              </Grid>
            </Grid>
            <Grid mt={3} className="ticketContainer">
              <Grid className="idPrioirtyDiv">
                <Typography className="id">
                  <span
                    className={
                      ticketDetails.status === 1
                        ? "iconClosed"
                        : `icon${ticketDetails.priority}`
                    }
                  >
                    <DescriptionIcon />
                  </span>
                  Ticket Id - {ticketDetails.ticketId}
                </Typography>
                <Typography className="id">
                  {ticketDetails.status !== 1 &&
                  ticketDetails.replyList.length === 0
                    ? "Open"
                    : ticketDetails.status !== 1 &&
                      ticketDetails.replyList.length > 0
                    ? " In Progress"
                    : ""}
                </Typography>
                <Grid
                  className={
                    ticketDetails.status === 1
                      ? "priorityClosed"
                      : `priority${ticketDetails.priority}`
                  }
                >
                  {ticketDetails.status === 1
                    ? "Closed"
                    : ticketDetails.priority}
                </Grid>
              </Grid>
              <Grid className="ticketDetails">
                <Typography mt={2} className="question">
                  {ticketDetails.title}
                </Typography>
                <Typography mt={2} className="answer">
                  {ticketDetails?.description?.split("\n").map((str, ind) => (
                    <p key={ind}>{str}</p>
                  ))}
                </Typography>
                {ticketDetails.files.length > 0 && (
                  <div>
                    <ViewImages
                      image={ticketDetails.files}
                      imageSize={imageSize}
                    />
                  </div>
                )}
              </Grid>
              {replyListState ? (
                <Grid className="chatsContainer">
                  {ticketDetails.replyList.map((ele, ind) => {
                    return (
                      <Grid mt={2} className="chat" key={ind}>
                        <Grid
                          className={
                            ele.isUserMessage ? "userReplies" : "adminReplies"
                          }
                        >
                          {ele.message && (
                            <Typography className="reply">
                              {ele.message}
                            </Typography>
                          )}
                          {ele.filePath && (
                            <div>
                              <ViewImages
                                image={[ele.filePath]}
                                imageSize={imageSize}
                              />
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Loader />
              )}
              {ticketDetails.feedbackData.feedback && (
                <Grid mt={4} className="feedBackmsg">
                  <div>
                    {ticketDetails.feedbackData.userSatisfectionId === 0 && (
                      <SentimentDissatisfiedSharpIcon className="feedbackIconSad" />
                    )}
                    {ticketDetails.feedbackData.userSatisfectionId === 2 && (
                      <SentimentNeutralSharpIcon className="feedbackIconNeu" />
                    )}
                    {ticketDetails.feedbackData.userSatisfectionId === 1 && (
                      <EmojiEmotionsIcon className="feedbackIconHappy" />
                    )}
                  </div>
                  <h1>User Feedback</h1>
                  <p>{ticketDetails.feedbackData.feedback}</p>
                </Grid>
              )}
              {ticketDetails.status === 1 ? (
                ""
              ) : (
                <Grid mt={2} className="replyBtnGrid">
                  {showInputField && (
                    <div>
                      <FileUploadInput
                        setticketReplyData={setticketReplyData}
                        ticketReplyData={ticketReplyData}
                        handleUploadFile={handleUploadFileMethod}
                        ticketId={ticketDetails.ticketId}
                        isLoader={isLoader}
                      />
                    </div>
                  )}
                  <Buttons
                    startIcon={<ReplyIcon />}
                    primary={true}
                    disabled={isLoader ? true : false}
                    label={
                      isLoader === true
                        ? "Uploading"
                        : ticketReplyData.message.length > 0 ||
                          ticketReplyData.fileIds.length > 0
                        ? "Send"
                        : "Reply"
                    }
                    onClick={(e) => {
                      handleReplySubmit(e);
                      setShowInputField(!showInputField);
                    }}
                  />
                </Grid>
              )}
              {ticketDetails.status === 1 && (
                <Grid className="reviewDiv" mt={2} textAlign={"left"}>
                  <SentimentNeutralSharpIcon
                    className="feedbackIconNeu"
                    onClick={() => {
                      setshowuserFeedbackInputFeild(true);
                      setuserFeedback({
                        ...userFeedback,
                        userSatisfection: 2,
                      });
                    }}
                  />
                  <EmojiEmotionsIcon
                    className="feedbackIconHappy"
                    onClick={() => {
                      setshowuserFeedbackInputFeild(true);
                      setuserFeedback({
                        ...userFeedback,
                        userSatisfection: 1,
                      });
                    }}
                  />
                  <SentimentDissatisfiedSharpIcon
                    className="feedbackIconSad"
                    onClick={() => {
                      setshowuserFeedbackInputFeild(true);
                      setuserFeedback({
                        ...userFeedback,
                        userSatisfection: 0,
                      });
                    }}
                  />
                  {showuserFeedbackInputFeild ? (
                    <Grid item container mt={2}>
                      <Grid item lg={8} md={12} xs={12}>
                        <Grid>
                          <FormControl className="formControl" fullWidth>
                            <FormLabels
                              className="formControl"
                              label="How can we help improve your experience?"
                            />
                            <TextArea
                              placeholder={"Enter Here"}
                              value={userFeedback.feedback}
                              onChange={(e) =>
                                setuserFeedback({
                                  ...userFeedback,
                                  feedback: e.target.value,
                                  ticketId: ticketDetails.ticketId,
                                })
                              }
                            />
                          </FormControl>
                        </Grid>

                        <Grid
                          className="reviewBtnGrid"
                          container
                          mt={1}
                          spacing={10}
                        >
                          <Button
                            varientText={true}
                            label="Cancel"
                            onClick={() => setshowuserFeedbackInputFeild(false)}
                          >
                            Cancel
                          </Button>
                          <Grid lg={4} xs={12} sm={6} md={6} textAlign="right">
                            <Buttons
                              varientContained={true}
                              label="Submit Feedback"
                              onClick={() => {
                                handleUserFeedback(userFeedback);
                                setuserFeedback({
                                  ...userFeedback,
                                  feedback: "",
                                });
                                setshowuserFeedbackInputFeild(false);
                              }}
                            >
                              Submit Feedback
                            </Buttons>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default OpenTicket;
