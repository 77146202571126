import React from "react";
import MostInjuredBodyPart from "./mostInjuredBodyPart";
import MostCommonInjury from "./mostCommonInjury";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { REPORTS_ANALYTICS_DASHBOARD } from "../../../../constant/routeContant";
import MostCommonDayofweek from "./mostCommonDayofweek";
import ProjectWithMostIncident from "./projectWithMostIncident";
import VehicleWithMostIncident from "./vehicleWithMostIncident";
import TotalIncidents from "./totalIncidents";
import OpenCases from "./openCases";
import UserMostIncidentActionItems from "./userMostIncidentActionItems";

const drillDownIncident = (props) => {
  const dataBack = {
    title: "Back to Dashboard",
    route: REPORTS_ANALYTICS_DASHBOARD,
    state: {
      apiData: props?.drillDetail?.apiData,
      backpageInc: "backpageInc",
      tab: props?.drillDetail?.tab,
    },
  };
  return (
    <>
      <div>
        <BackBtn dataBack={dataBack} />
      </div>

      {props?.drillDetail?.page === "report_personaInjury" ||
      props?.drillDetail?.page === "report_VehicleAccident" ||
      props?.drillDetail?.page === "report_StolenProperty" ||
      props?.drillDetail?.page === "report_UtilityDamage" ||
      props?.drillDetail?.page === "report_NearMiss" ||
      props?.drillDetail?.page === "report_totalIncidents" ? (
        <TotalIncidents
          incidentList={props?.incidentList}
          getIncidentListData={props?.getIncidentListData}
          drillDetail={props?.drillDetail}
          tab={props?.drillDetail?.tab}
        />
      ) : props?.drillDetail?.page === "totalcases" ? (
        <OpenCases
          caseList={props?.casesList}
          getCasesListData={props?.getCasesListData}
          drillDetail={props?.drillDetail}
          statusId={props?.drillDetail?.statusId}
          startdateFormated={props?.startdateFormated}
          enddateFormated={props?.enddateFormated}
        />
      ) : props?.drillDetail?.page === "most_injured_body_part" ? (
        <MostInjuredBodyPart
          InjuredBodyPartLists={
            props?.InjuredBodyPartListData?.result?.injuries
          }
          getInjuredBodyPartLists={props?.getInjuredBodyPartData}
          drillDetail={props?.drillDetail?.apiData}
          drillDetailFull={props?.drillDetail}
        />
      ) : props?.drillDetail?.page === "most_common_injury" ? (
        <MostCommonInjury
          mostCommonInjuryListData={
            props?.mostCommonInjuryListData?.result?.records
          }
          getMostCommonInjury={props?.getMostCommonInjuryData}
          drillDetail={props?.drillDetail?.apiData}
          drillDetailFull={props?.drillDetail}
        />
      ) : props?.drillDetail?.page === "most_common_day_week" ? (
        <MostCommonDayofweek
          incReportMostCommonDayOfInjuryData={
            props?.incReportMostCommonDayOfInjuryData?.result?.records
          }
          getMostCommnDayOfInjury={props?.getMostCommnDayOfInjury}
          drillDetail={props?.drillDetail?.apiData}
          drillDetailFull={props?.drillDetail}
        />
      ) : props?.drillDetail?.page === "project_most_incident" ? (
        <ProjectWithMostIncident
          projectwithmostincidentsList={props?.projectwithmostincidentsList}
          getProjectWithmostIncidentsData={
            props?.getProjectWithmostIncidentsData
          }
          drillDetail={props?.drillDetail?.apiData}
          drillDetailFull={props?.drillDetail}
        />
      ) : props?.drillDetail?.page === "vehicle_most_incident" ? (
        <VehicleWithMostIncident
          getVehicleWithmostIncidentsData={
            props?.getVehicleWithmostIncidentsData
          }
          vehiclewithmostincidentsList={props?.vehiclewithmostincidentsList}
          drillDetail={props?.drillDetail?.apiData}
          drillDetailFull={props?.drillDetail}
        />
      ) : props?.drillDetail?.page === "user_most_incident" ? (
        <UserMostIncidentActionItems
          incUserMostIncidentData={props?.incUserMostIncidentData}
          gettUserMostIncidentData={props?.gettUserMostIncidentData}
          drillDetail={props?.drillDetail?.apiData}
          ispage={props?.drillDetail?.page}
          drillDetailFull={props?.drillDetail}
        />
      ) : props?.drillDetail?.page === "user_most_action" ? (
        <UserMostIncidentActionItems
          drillDetail={props?.drillDetail?.apiData}
          incUserMostActionList={props?.incUserMostActionList}
          getIncidentUserWithMostNoOfActionItemData={
            props?.getIncidentUserWithMostNoOfActionItemData
          }
          ispage={props?.drillDetail?.page}
          drillDetailFull={props?.drillDetail}
        />
      ) : null}
    </>
  );
};

export default drillDownIncident;
