import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import MainRouter from "./router/";

import { useDispatch } from "react-redux";
import { setCurrentUserAction } from "./redux/actions/loginActions";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga4";
import { getGATRackingId } from "./config";
const gaID = getGATRackingId();

function App() {
  const themeValue = localStorage.getItem("theme");
  const dispatch = useDispatch();
  useEffect(() => {
    ReactGA.initialize(gaID);
  }, [])
  
  const { access_token, id_token } = JSON.parse(JSON.stringify(localStorage));
  if (access_token && id_token) {
    dispatch(
      setCurrentUserAction({
        idToken: id_token,
        accessToken: access_token,
        tokenType: "id",
      })
    );
  }
  return (
    <div className="App" data-theme={themeValue ? themeValue : "light"}>
      <Router>
        <MainRouter />
      </Router>
    </div>
  );
}

export default App;
