import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FromToDatePicker from "../../library/custom/datePicker/DatePicker";
import {
  Card,
  CardContent,
  Box,
  CardActions,
  FormControl,
} from "@mui/material";
import Buttons from "../../library/custom/button/Button";
import Grid from "@mui/material/Grid";
import { getFormatedDate } from "../../utils/helper";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import {
  INCIDENT_STATUS,
  INCIDENT_STATUS_VALUE,
  ITEM_STATUS_NEW_VALUE,
  ITEM_STATUS_VALUE,
  ITEM_TYPE,
  ORIGIN,
  USER_ID,
} from "../../constant/constant";
import commonImages from "../../assets";
import "./FilterIncidentList.scss";
import SelectMenu from "../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../library/custom/formLabel/FormLabels";
import { ASSETTYPE } from "../../constant/inspectionConstant";
const FilterIncidentList = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleSearch,
  filterType,
  resetFilterAPI,
  label2,
  FromToDate,
  label1 = " Time Period",
  PermitFilter = false,
  openItemFilter = false,
  firstLabel,
  firstLebelFilter,
  firstLebelOnChanges,
  firstLebelValue,
  assetType = false,
  assetTypeId,
  typeOnAssetChanges,
  itemTypesShow = false,
  itemTypesId,
  typeOnItemTypeChanges,
  origin = false,
  originId,
  typeOnOriginChanges,
  statusIds,
  fromDate,
  toDate,
  singleCheckbox = true,
  singleSelect = false,
}) => {
  const [filter, setFilter] = useState({
    startDate: fromDate ? fromDate : "",
    endDate: toDate ? toDate : "",
    startDate1: "",
    endDate1: "",
    status: parseInt(statusIds) === 1 ? "" : "2",
  });

  const resetFilter = () => {
    setFilter({
      startDate: "",
      endDate: "",
      startDate1: "",
      endDate1: "",
      status: "2",
      userId: parseInt(USER_ID()),
    });
    resetFilterAPI();
  };

  return (
    <>
      {openItemFilter && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Card className="filterlist_of_cases filter_mui_1">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Box className="" sx={{ pb: 2 }}>
                    <Typography
                      className="filterLabel"
                      gutterBottom
                      variant="h5"
                    >
                      {label1}
                    </Typography>
                    <Box className="datePicker_row">
                      <div>
                        <Typography variant="subtitle1">From</Typography>
                        <FromToDatePicker
                          value={filter.startDate}
                          onChangeHandler={(value) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              startDate: getFormatedDate(value),
                            }))
                          }
                        />
                      </div>
                      <div>
                        <Typography variant="subtitle1">To</Typography>
                        <FromToDatePicker
                          value={filter.endDate}
                          onChangeHandler={(value) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              endDate: getFormatedDate(value),
                            }))
                          }
                        />
                      </div>
                    </Box>
                  </Box>
                  {FromToDate && (
                    <Box className="" sx={{ pb: 2 }}>
                      <Typography
                        className="filterLabel"
                        gutterBottom
                        variant="h5"
                      >
                        {label2}
                      </Typography>
                      <Box className="datePicker_row">
                        <div>
                          <Typography variant="subtitle1">From</Typography>
                          <FromToDatePicker
                            value={filter.startDate}
                            onChangeHandler={(value) =>
                              setFilter((prevState) => ({
                                ...prevState,
                                startDate: getFormatedDate(value),
                              }))
                            }
                          />
                        </div>
                        <div>
                          <Typography variant="subtitle1">To</Typography>
                          <FromToDatePicker
                            value={filter.endDate}
                            onChangeHandler={(value) =>
                              setFilter((prevState) => ({
                                ...prevState,
                                endDate: getFormatedDate(value),
                              }))
                            }
                          />
                        </div>
                      </Box>
                    </Box>
                  )}
                  {singleSelect && (
                    <>
                      <Box className="singleSelect" sx={{ pb: 0.5 }}>
                        <FormControl fullWidth>
                          <FormLabels label="Status" isRequired={false} />
                          <SelectMenu
                            placeholder="Please Select"
                            listData={ITEM_STATUS_NEW_VALUE}
                            value={filter.status}
                            onchangehandler={(e) =>
                              setFilter((prevState) => ({
                                ...prevState,
                                status: e.target.value,
                              }))
                            }
                          />
                        </FormControl>
                      </Box>
                    </>
                  )}

                  {singleCheckbox && (
                    <Box className="" sx={{ pb: 2 }}>
                      <Typography
                        className="filterLabel"
                        gutterBottom
                        variant="h5"
                      >
                        Status
                      </Typography>
                      <Box>
                        <FormGroup>
                          <FormControlLabel
                            key={INCIDENT_STATUS.SUBMITTED}
                            control={
                              <img
                                className="filterListCheckbox"
                                checked={
                                  filter.status == INCIDENT_STATUS.SUBMITTED
                                    ? true
                                    : false
                                }
                                alt=""
                                src={
                                  filter.status == INCIDENT_STATUS.SUBMITTED
                                    ? commonImages.check
                                    : commonImages.noCheck
                                }
                                onClick={() =>
                                  setFilter((prevState) => ({
                                    ...prevState,
                                    status: INCIDENT_STATUS.SUBMITTED,
                                  }))
                                }
                              />
                            }
                            label={"Open"}
                          />
                          <FormControlLabel
                            key={INCIDENT_STATUS.CLOSED}
                            control={
                              <img
                                className="filterListCheckbox"
                                checked={
                                  filter.status == INCIDENT_STATUS.CLOSED
                                    ? true
                                    : false
                                }
                                alt=""
                                src={
                                  filter.status == INCIDENT_STATUS.CLOSED
                                    ? commonImages.check
                                    : commonImages.noCheck
                                }
                                onClick={() =>
                                  setFilter((prevState) => ({
                                    ...prevState,
                                    status: INCIDENT_STATUS.CLOSED,
                                  }))
                                }
                              />
                            }
                            label={"Closed"}
                          />
                          <FormControlLabel
                            key={INCIDENT_STATUS.BOTH}
                            control={
                              <img
                                className="filterListCheckbox"
                                checked={
                                  filter.status == INCIDENT_STATUS.BOTH
                                    ? true
                                    : false
                                }
                                alt=""
                                src={
                                  filter.status == INCIDENT_STATUS.BOTH
                                    ? commonImages.check
                                    : commonImages.noCheck
                                }
                                onClick={() =>
                                  setFilter((prevState) => ({
                                    ...prevState,
                                    status: INCIDENT_STATUS.BOTH,
                                  }))
                                }
                              />
                            }
                            label={"All"}
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  )}

                  {origin && (
                    <>
                      <Box className="" sx={{ pb: 0.5 }}>
                        <Typography
                          className="filterLabel"
                          gutterBottom
                          variant="h5"
                        >
                          Origin
                        </Typography>
                      </Box>
                      <Box className="" sx={{ pb: 2 }}>
                        <SelectMenu
                          name={"origin"}
                          listData={ORIGIN}
                          onchangehandler={(e) => typeOnOriginChanges(e)}
                          value={originId}
                          placeholder="Please select"
                        />
                      </Box>
                    </>
                  )}

                  {itemTypesShow && (
                    <>
                      <Box className="" sx={{ pb: 0.5 }}>
                        <Typography
                          className="filterLabel"
                          gutterBottom
                          variant="h5"
                        >
                          Item Types
                        </Typography>
                      </Box>
                      <Box className="" sx={{ pb: 2 }}>
                        <SelectMenu
                          name={"itemTypes"}
                          listData={ITEM_TYPE}
                          onchangehandler={(e) => typeOnItemTypeChanges(e)}
                          value={itemTypesId}
                          placeholder="Please select"
                        />
                      </Box>
                    </>
                  )}

                  {assetType && (
                    <>
                      <Box className="" sx={{ pb: 0.5 }}>
                        <Typography
                          className="filterLabel"
                          gutterBottom
                          variant="h5"
                        >
                          Asset Type
                        </Typography>
                      </Box>
                      <Box className="" sx={{ pb: 2 }}>
                        <SelectMenu
                          name={"assetType"}
                          listData={ASSETTYPE}
                          onchangehandler={(e) => typeOnAssetChanges(e)}
                          value={assetTypeId}
                          placeholder="Please select"
                        />
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Buttons primary={true} label={"Reset"} onClick={resetFilter} />
              <Buttons
                primary={true}
                label={"Apply"}
                onClick={(e) => handleSearch(e, filter)}
              />
            </CardActions>
          </Card>
        </Popover>
      )}

      {PermitFilter && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Card className="filterlist_of_cases filter_mui_1">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Box sx={{ pb: 2 }} md={4} className="permitTypeFilterBox">
                    <Grid md={6} xs={12}>
                      <FormLabels
                        className="permitteeLable"
                        label={firstLabel}
                        isRequired={false}
                      />
                      <SelectMenu
                        name={firstLabel}
                        listData={firstLebelFilter}
                        onchangehandler={(e) => firstLebelOnChanges(e)}
                        value={firstLebelValue}
                        placeholder="Please select"
                      />
                    </Grid>
                  </Box>
                  <Box sx={{ pb: 2, mt: 2 }} className="permitFilter">
                    <Typography gutterBottom variant="h5">
                      Approval Date
                    </Typography>
                    <Box className="datePicker_row permitFilterDate">
                      <div className="approvalDate">
                        <Typography variant="subtitle1">From</Typography>
                        <FromToDatePicker
                          maxDateEnable={true}
                          className="dateInput"
                          value={filter.startDate}
                          onChangeHandler={(value) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              startDate: getFormatedDate(value),
                            }))
                          }
                        />
                      </div>
                      <div className="approvalDate">
                        <Typography variant="subtitle1">To</Typography>
                        <FromToDatePicker
                          maxDateEnable={true}
                          value={filter.endDate}
                          onChangeHandler={(value) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              endDate: getFormatedDate(value),
                            }))
                          }
                        />
                      </div>
                    </Box>
                  </Box>

                  <Box sx={{ pb: 2 }} className="permitFilter">
                    <Typography gutterBottom variant="h5">
                      Project Permit Expiration Date
                    </Typography>
                    <Box className="datePicker_row permitFilterDate">
                      <div className="approvalDate">
                        <Typography variant="subtitle1">From</Typography>
                        <FromToDatePicker
                          maxDateEnable={true}
                          variant="subtitle1"
                          value={filter.startDate1}
                          onChangeHandler={(value) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              startDate1: getFormatedDate(value),
                            }))
                          }
                        />
                      </div>
                      <div className="approvalDate">
                        <Typography variant="subtitle1">To</Typography>
                        <FromToDatePicker
                          maxDateEnable={true}
                          value={filter.endDate1}
                          onChangeHandler={(value) =>
                            setFilter((prevState) => ({
                              ...prevState,
                              endDate1: getFormatedDate(value),
                            }))
                          }
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Buttons primary={true} label={"Reset"} onClick={resetFilter} />
              <Buttons
                primary={true}
                label={"Apply"}
                onClick={(e) => handleSearch(e, filter)}
              />
            </CardActions>
          </Card>
        </Popover>
      )}
    </>
  );
};

export default FilterIncidentList;
