import React from "react";
import UserSerchMangementList from "../component/userManagement/userSerchMangementList";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_DENIED,
  ADMIN_USER_INFO,
  ADMIN_USER_SEARCH_BY_ROLE,
} from "../../../constant/routeContant";
import { checkPrivileges } from "../../../utils/rolesHelper";

function UserSearchMangementContainer() {
  const navigate = useNavigate();

  const onClickHandler = (id) => {
    if (id === 1) {
      navigate(ADMIN_USER_INFO);
    } else if (id === 2) {
      navigate(ADMIN_USER_SEARCH_BY_ROLE);
    }
  };
  return (
    <>
      {checkPrivileges([12, 139, 64, 65, 66, 148]) ? (
        <>
          <div className="dashTitle">User Search Management</div>
          <UserSerchMangementList onClick={onClickHandler} />
        </>
      ) : (
        navigate(ACCESS_DENIED)
      )}
    </>
  );
}

export default UserSearchMangementContainer;
