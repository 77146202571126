import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Grid } from "@mui/material";
import Buttons from "../../../library/custom/button/Button";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import FormControl from "@mui/material/FormControl";
import {
  FILTERDAYS,
  LOGIN_ORG_ID,
  ORG_ID,
  USER_ID,
} from "../../../constant/constant";
import Cards from "./Cards";
import DatePicker from "../../../library/custom/datePicker/DatePicker";
import {
  getCurrentDate,
  getDateWithDayPast,
  getFormatedDate,
} from "../../../utils/helper";

import { PERSONALINJURYFACTORS } from "../../../constant/constant";
import { Settings } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const ReportPersonallInjury = ({
  divisons,
  getProjectSearch,
  projectSearchList,
  getPiIncidentReportCount,
  personalInjuryIncidentReportCount,
  getPiIncidentReportList,
  personalInjuryIncidentReportList,
  getSupervisorByProject,
  supervisorByProject,
}) => {
  const [divison, setDivison] = useState("");
  const [project, setProject] = useState("");
  const [days, setDays] = useState({ key: 30, value: "30 days" });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [factors, setFactors] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [factorTypeId, setFactorTypeId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tableData, setTableData] = useState();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeDivision = (e) => {
    setProject("");
    setDivison(e.target.value);
    const data = {
      divisionId: e.target.value,
    };
    getProjectSearch(data);
  };

  useEffect(() => {
    if(divison !== "")
    {
    const dataIncidentCount = {
      orgId: parseInt(ORG_ID()),
      userId: null,
      divisionId: parseInt(divison) === 0 ? null : parseInt(divison),
      projectId: parseInt(project) === 0 ? null : parseInt(project),
      supervisorId: parseInt(supervisor) === 0 ? null : parseInt(supervisor),
      employeeSelected: false,
      contractorSelected: false,
      factorType: parseInt(factors) ? parseInt(factors) : null,
      factorTypeId: parseInt(factorTypeId) ? parseInt(factorTypeId) : null,
      fromDate: startDate === null ? null : getFormatedDate(startDate),
      toDate: endDate === null ? null : getFormatedDate(endDate),
    };
    getPiIncidentReportCount(dataIncidentCount);}
  }, [divison,project]);


  const onChangeSelectSupervisor = (e) => {
    setSupervisor(e.target.value);
    const data = {
      orgId: parseInt(ORG_ID()),
      userId: null,
      divisionId: parseInt(divison) === 0 ? null : parseInt(divison),
      projectId: parseInt(project) === 0 ? null : parseInt(project),
      supervisorId:
        parseInt(e.target.value) === 0 ? null : parseInt(e.target.value),
      employeeSelected: false,
      contractorSelected: false,
      factorType: parseInt(factors) ? parseInt(factors) : null,
      factorTypeId: parseInt(factorTypeId) ? parseInt(factorTypeId) : null,
      fromDate: startDate === null ? null : getFormatedDate(startDate),
      toDate: endDate === null ? null : getFormatedDate(endDate),
    };
    getPiIncidentReportCount(data);
  };
  const onChangeSelectProject = (e) => {
    setProject(e.target.value);
    const data = {
      divisionId: divison,
      projectId: e.target.value,
    };
    getSupervisorByProject(data);
  };

  const onChangeSelectFactors = (e) => {
    setTableData([]);
    setFactors(e.target.value);
    const data = {
      orgId: parseInt(ORG_ID()),
      userId: null,
      divisionId: parseInt(divison) === 0 ? null : parseInt(divison),
      projectId: parseInt(project) === 0 ? null : parseInt(project),
      supervisorId: parseInt(supervisor) === 0 ? null : parseInt(supervisor),
      employeeSelected: false,
      contractorSelected: false,
      factorType: parseInt(e.target.value) ? parseInt(e.target.value) : null,
      factorTypeId: parseInt(factorTypeId) ? parseInt(factorTypeId) : null,
      fromDate: startDate === null ? null : getFormatedDate(startDate),
      toDate: endDate === null ? null : getFormatedDate(endDate),
    };
    getPiIncidentReportCount(data);
  };

  const cardOnClick = (value) => {
    let dateObj = getDateWithDayPast(value.key);
    if (days.key === value.key) {
      const defaultData = {
        orgId: parseInt(ORG_ID()),
        userId: null,
        divisionId: parseInt(divison) === 0 ? null : parseInt(divison),
        projectId: parseInt(project) === 0 ? null : parseInt(project),
        supervisorId: parseInt(supervisor) === 0 ? null : parseInt(supervisor),
        employeeSelected: false,
        contractorSelected: false,
        factorType: parseInt(factors) ? parseInt(factors) : null,
        factorTypeId: parseInt(factorTypeId) ? parseInt(factorTypeId) : null,
        fromDate: dateObj.pastData,
        toDate: dateObj.getCurrentDate,
      };
      getPiIncidentReportCount(defaultData);
      setDays({ key: 30, value: "30 days" });
      setStartDate(dateObj.pastData);
      setEndDate(dateObj.getCurrentDate);
    } else {
      if (value.key !== 12) {
        let dateObj = getDateWithDayPast(value.key);
        const defaultData = {
          orgId: parseInt(ORG_ID()),
          userId: null,
          divisionId: parseInt(divison) === 0 ? null : parseInt(divison),
          projectId: parseInt(project) === 0 ? null : parseInt(project),
          supervisorId:
            parseInt(supervisor) === 0 ? null : parseInt(supervisor),
          employeeSelected: false,
          contractorSelected: false,
          factorType: parseInt(factors) ? parseInt(factors) : null,
          factorTypeId: parseInt(factorTypeId) ? parseInt(factorTypeId) : null,
          fromDate: dateObj.pastData,
          toDate: dateObj.getCurrentDate,
        };
        getPiIncidentReportCount(defaultData);
        setStartDate(dateObj.pastData);
        setEndDate(dateObj.getCurrentDate);
      }
      if (value.key === 13) {
        let dateObj = getDateWithDayPast(30);
        const defaultData = {
          orgId: parseInt(ORG_ID()),
          userId: null,
          divisionId: parseInt(divison) === 0 ? null : parseInt(divison),
          projectId: parseInt(project) === 0 ? null : parseInt(project),
          supervisorId:
            parseInt(supervisor) === 0 ? null : parseInt(supervisor),
          employeeSelected: false,
          contractorSelected: false,
          factorType: parseInt(factors) ? parseInt(factors) : null,
          factorTypeId: parseInt(factorTypeId) ? parseInt(factorTypeId) : null,
          fromDate: null,
          toDate: null,
        };
        getPiIncidentReportCount(defaultData);
        setStartDate(null);
        setEndDate(null);
      }
      setDays(value);
    }
  };

  const onchangeEnddatePicker = (e) => {
    const defaultData = {
      orgId: parseInt(ORG_ID()),
      userId: null,
      divisionId: parseInt(divison) === 0 ? null : parseInt(divison),
      projectId: parseInt(project) === 0 ? null : parseInt(project),
      supervisorId: parseInt(supervisor) === 0 ? null : parseInt(supervisor),
      employeeSelected: false,
      contractorSelected: false,
      factorType: parseInt(factors) ? parseInt(factors) : null,
      factorTypeId: parseInt(factorTypeId) ? parseInt(factorTypeId) : null,
      fromDate: getFormatedDate(
        startDate === "" ? getCurrentDate() : startDate
      ),
      toDate: getFormatedDate(e),
    };
    setEndDate(e);
    getPiIncidentReportCount(defaultData);
  };

  const getTabListHandler = (factorTypeIds) => {
    setFactorTypeId(factorTypeIds);
    const data = {
      orgId: parseInt(ORG_ID()),
      userId: null,
      divisionId: parseInt(divison) === 0 ? null : parseInt(divison),
      projectId: parseInt(project) === 0 ? null : parseInt(project),
      supervisorId: parseInt(supervisor) === 0 ? null : parseInt(supervisor),
      employeeSelected: false,
      contractorSelected: false,
      factorType: parseInt(factors) ? parseInt(factors) : null,
      factorTypeId: parseInt(factorTypeIds) ? parseInt(factorTypeIds) : 0,
      fromDate: startDate === null ? null : getFormatedDate(startDate),
      toDate: endDate === null ? null : getFormatedDate(endDate),
    };
    getPiIncidentReportList(data);
  };

  useEffect(() => {
    let dateObj = getDateWithDayPast(30);
    setStartDate(dateObj.pastData);
    setEndDate(dateObj.getCurrentDate);
  }, []);

  useEffect(() => {
    if (personalInjuryIncidentReportList) {
      setTableData(personalInjuryIncidentReportList);
    }
  }, [personalInjuryIncidentReportList]);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Incident Report",
    fonts: ["Lato"],
    bodyClass: "bodyPdf",
  });

  const isClient = ORG_ID() !== LOGIN_ORG_ID();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const clientLogo = localStorage.getItem("orgLogo");
  const clientName = localStorage.getItem("orgName");
  const logoCheck = isClient ? clientLogo : userInfo?.logo;

  return (
    <>
      <div ref={componentRef} className="lmsReport innerReportAndAnalyticsPdf">
        <section className="grid_main_sec">
          <Grid container className="grid_main_header two_grid">
            <Grid item className="grid_heading_style repotPdfHeading" lg={7}>
              Personal Injury Incident Reports
            </Grid>
            <Grid item md={12} className="clientPdfDetail">
              <Grid className="clientName">
                {isClient ? clientName : userInfo?.clientName}
              </Grid>
              <Grid className="clientLogo">
                {logoCheck && <img src={logoCheck} alt="client Log" />}
              </Grid>
            </Grid>
            <Grid item lg={5} md={6} className="filter_btn removeElement">
              <Grid>
                <Buttons
                  primary={true}
                  id="btnAddClient"
                  label={"Download Report"}
                  onClick={handlePrint}
                ></Buttons>
              </Grid>
            </Grid>
          </Grid>
        </section>

        <Grid container mt={6} className="removeElement">
          <Grid item lg={4} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels label="Select Division" isRequired={false} />
              <SelectMenu
                placeholder={"All Divisions"}
                disabledDefault={false}
                listData={divisons}
                value={divison}
                onchangehandler={(e) => onChangeDivision(e)}
                isgroupplaceholder={true}
              />
            </FormControl>
          </Grid>
        </Grid>
        {divison !== "" && (
          <Grid container mt={2} className="removeElement">
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <FormLabels label="Select Project" isRequired={false} />
                <SelectMenu
                  placeholder="All Projects"
                  listData={projectSearchList}
                  value={project}
                  onchangehandler={(e) => onChangeSelectProject(e)}
                  disabledDefault={false}
                  isgroupplaceholder={true}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}

        {project !== "" && (
          <Grid container mt={2} className="removeElement">
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <FormLabels label="Select Supervisor" isRequired={false} />
                <SelectMenu
                  placeholder="All Supervisors"
                  listData={supervisorByProject?.data?.supervisors ?? []}
                  value={supervisor}
                  onchangehandler={(e) => onChangeSelectSupervisor(e)}
                  disabledDefault={false}
                  isgroupplaceholder={true}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}

        {supervisor !== "" && (
          <Grid container mt={2} item lg={12} xs={12} sm={12} md={8} className="removeElement">
            <Grid item lg={12}>
              <FormLabels label={"Select Time Period"} />
            </Grid>
            <Cards
              staticCardData={true}
              cardData={FILTERDAYS}
              selectedVehicleAccident={days}
              cardClickHandler={cardOnClick}
            />
          </Grid>
        )}
        {days?.value === "Custom" ? (
          <>
            <Grid item container mt={1} spacing={1} className="removeElement">
              <Grid item lg={4} xs={12} sm={12}>
                <FormLabels label={"Custom Time Period"} isRequired={false} />
              </Grid>
            </Grid>

            <Grid item container spacing={2} mb={2} className="removeElement">
              <Grid item lg={3} xs={12} sm={12} md={4}>
                <FormLabels label={"From"} isRequired={false} />
                <DatePicker
                  maxDateEnable={false}
                  value={startDate === "" ? getCurrentDate() : startDate}
                  disabled={false}
                  onChangeHandler={(e) => setStartDate(e)}
                />
              </Grid>
              <Grid item lg={3} xs={12} sm={12} md={4}>
                <FormLabels label={"To"} isRequired={false} />

                <DatePicker
                  maxDateEnable={false}
                  minDate={startDate}
                  value={endDate === "" ? getCurrentDate() : endDate}
                  disabled={false}
                  onChangeHandler={(e) => onchangeEnddatePicker(e)}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
        {supervisor !== "" && (
          <div className="reportBody">
            <div className="reportCardBody">
              <div className="text">Total Incidents</div>
              <div className="number">
                {
                  personalInjuryIncidentReportCount?.data?.incidentReportDetails
                    ?.totalIncident
                }
              </div>
            </div>
            <div className="reportCardBody">
              <div className="text">Employee Cases</div>
              <div className="number">
                {
                  personalInjuryIncidentReportCount?.data?.incidentReportDetails
                    ?.totalEmployeeCases
                }
              </div>
            </div>
            <div className="reportCardBody">
              <div className="text">Contractor Cases</div>
              <div className="number">
                {
                  personalInjuryIncidentReportCount?.data?.incidentReportDetails
                    ?.totalContractorCases
                }
              </div>
            </div>
          </div>
        )}

        {supervisor !== "" && (
          <Grid container mt={2}>
            <Grid item lg={4} xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <FormLabels
                  label="Personal Injury Factors"
                  isRequired={false}
                />
                <SelectMenu
                  placeholder="Please Select"
                  listData={PERSONALINJURYFACTORS}
                  value={factors}
                  onchangehandler={(e) => onChangeSelectFactors(e)}
                  disabledDefault={false}
                  isgroupplaceholder={true}
                />
              </FormControl>
            </Grid>
          </Grid>
        )}
        {factors !== "" && (
          <div className="reportBody">
            {personalInjuryIncidentReportCount?.data?.incidentReportDetails?.factorCounts?.map(
              (item) => {
                return (
                  <div
                    className={
                      factorTypeId === item?.key
                        ? "reportCardBody active"
                        : "reportCardBody"
                    }
                    onClick={() => getTabListHandler(item?.key)}
                    key={item?.key}
                  >
                    <div className="text">{item?.value}</div>
                    <div className="number">{item?.count}</div>
                  </div>
                );
              }
            )}
          </div>
        )}

        {factorTypeId !== "" && (
          <div className="reportTableRes">
            <table className="reportTable">
              <thead>
                <tr>
                  <th>Report ID</th>
                  <th>Date of Entry</th>
                  <th>Incident Type</th>
                  <th>Entered by</th>
                  <th>Status</th>
                  <th>
                    <Settings
                      onClick={handleClick}
                      className="settingIconReport"
                    />
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography
                        sx={{ py: 1, px: 2 }}
                        className="downloadList"
                      >
                        <CSVLink
                          filename={"personalInjuryReport"}
                          data={tableData?.data?.incidentReportList ?? []}
                        >
                          Download List
                        </CSVLink>
                      </Typography>
                    </Popover>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.data?.incidentReportList?.map((item) => {
                  return (
                    <tr key={item?.incidentId}>
                      <td>{item?.incidentId}</td>
                      <td>{item?.dateOfEntry}</td>
                      <td>{item?.incidentType}</td>
                      <td>{item?.enteredBy}</td>
                      <td>{item?.status}</td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default ReportPersonallInjury;
