import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FromToDatePicker from "../../../../library/custom/datePicker/DatePicker";
import {
  Card,
  CardContent,
  Box,
  CardActions,
  FormControl,
} from "@mui/material";
import Buttons from "../../../../library/custom/button/Button";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import SelectMenu from "../../../../library/custom/selectMenu/SelectMenu";
import FormLabels from "../../../../library/custom/formLabel/FormLabels";

const FilterList = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleSearch,
  filterType,
  typeSearchLabel,
  isTypeSearchLabel,
  isStatus,
  statusLabel,
  sattusListData,
  statusOfList,
  handleClear,
  singleSelect = false,
  singleCheckbox = true,
}) => {
  const [filter, setFilter] = useState({
    jobsite: "",
    startDate: "",
    endDate: "",
    searchType: [],
    statusType: [],
  });

  const searLocation = (event, value) => {};
  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };
  const onChangeTypeStatus = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const statusType = prevStates.statusType;
      const filter =
        statusType && statusType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.statusType = [item];
      } else {
        prevStates.statusType = [];
      }
      return prevStates;
    });
  };
  const onChangeSelectTypeStatus = (value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      statusType: [value],
    }));
  };
  const clearState = () => {
    setFilter({
      jobsite: "",
      startDate: "",
      endDate: "",
      searchType: [],
      statusType: [],
    });
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card className="filterlist_of_cases filter_mui_1">
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={singleSelect ? 12 : 6}>
                {isTypeSearchLabel && (
                  <>
                    <Grid item md={12}>
                      <Box className="">
                        <Typography
                          className="filterLabel"
                          gutterBottom
                          variant="h5"
                        >
                          {typeSearchLabel}
                        </Typography>
                      </Box>

                      <Box>
                        <FormGroup>
                          {sattusListData &&
                            sattusListData.map((item) => (
                              <FormControlLabel
                                key={item.key}
                                control={
                                  <Checkbox
                                    checked={
                                      filter.searchType[0]?.key === item.key
                                    }
                                    onChange={() => onChangeType(item)}
                                    name="gilad"
                                  />
                                }
                                label={item?.value}
                              />
                            ))}
                        </FormGroup>
                      </Box>
                    </Grid>
                  </>
                )}

                {isStatus && singleCheckbox && (
                  <>
                    <Grid item md={12} mt={3}>
                      <Box className="">
                        <Typography
                          className="filterLabel"
                          gutterBottom
                          variant="h5"
                        >
                          {statusLabel}
                        </Typography>
                      </Box>
                      <Box>
                        <FormGroup>
                          {statusOfList &&
                            statusOfList.map((item) => (
                              <FormControlLabel
                                key={item.key}
                                control={
                                  <Checkbox
                                    checked={
                                      filter.statusType[0]?.key === item.key
                                    }
                                    onChange={() => onChangeTypeStatus(item)}
                                    name="gilad"
                                  />
                                }
                                label={item?.value}
                              />
                            ))}
                        </FormGroup>
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item md={singleSelect ? 12 : 6}>
                <Box className="" sx={{ pb: 2 }}>
                  <Typography className="filterLabel" gutterBottom variant="h5">
                    Time Period
                  </Typography>

                  <Box className="datePicker_row">
                    <div>
                      <Typography variant="subtitle1">From</Typography>
                      <FromToDatePicker
                        value={filter.startDate}
                        onChangeHandler={(value) =>
                          setFilter((prevState) => ({
                            ...prevState,
                            startDate: value,
                          }))
                        }
                      />
                    </div>
                    <div>
                      <Typography variant="subtitle1">To</Typography>
                      <FromToDatePicker
                        value={filter.endDate}
                        maxDateEnable
                        minDate={filter.startDate}
                        onChangeHandler={(value) =>
                          setFilter((prevState) => ({
                            ...prevState,
                            endDate: value,
                          }))
                        }
                      />
                    </div>
                  </Box>
                </Box>
                {isStatus && singleSelect && (
                  <>
                    <FormControl fullWidth>
                      <FormLabels label={statusLabel} isRequired={false} />
                      <SelectMenu
                        placeholder="Please Select"
                        listData={statusOfList}
                        value={filter.statusType[0]}
                        onchangehandler={(e) =>
                          onChangeSelectTypeStatus(e?.target?.value)
                        }
                      />
                    </FormControl>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Buttons
              primary={true}
              label={"Reset"}
              onClick={(e) => {
                clearState();
                handleClear(e, filter);
              }}
            />
            <Buttons
              primary={true}
              label={"Apply"}
              onClick={(e) => {
                handleSearch(e, filter);
              }}
            />
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export default FilterList;
