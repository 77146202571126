import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CardBox from "../../../../library/custom/cardBox/cardBox";
import "./faqTopicStyle.scss";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Loader from "../../../../library/common/Loader";
import DialogBox from "../../../../library/common/DialogBox";
import ViewImages from "../../../incidents/view-incident-report/common/viewImages/viewImages";
import {} from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as supportAction from "../../../../redux/actions/supportAction";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { ORG_ID } from "../../../../constant/constant";
import { CREATE_FAQ } from "../../../../constant/routeContant";
import { checkPrivileges } from "../../../../utils/rolesHelper";
const DetailedFaqTopic = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const catSubcatId = location?.state?.catSubcatId;
  const articleId = location?.state?.articleId;
  const title = location?.state?.title;
  const description = location?.state?.description;
  const articlesDetails = useSelector((state) => state.getArticleDetails?.data);
  const deleteFaqData = useSelector((state) => state.deleteFAQ);
  console.log(deleteFaqData);
  function getArticleDetails(data) {
    dispatch(
      supportAction.getArticleDetails({
        catSubcatId: catSubcatId,
        articleId: articleId,
      })
    );
  }
  useEffect(() => {
    getArticleDetails();
  }, []);

  const initialState = {
    createdBy: parseInt(localStorage.getItem("userId")),
    isLikedDisLiked: false,
    artileID: articleId,
  };

  // articleDetails DATA
  const [likeActive, setLikeActive] = useState();
  const [disLikeActive, setDisLikeActive] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [form, setForm] = useState(initialState);
  const userID = parseInt(localStorage.getItem("userId"));

  function handleLike() {
    setForm({
      ...form,
      isLikedDisLiked: true,
      createdBy: userID,
    });
    setDisLikeActive(false);
    setLikeActive(true);
    handleSubmit(form);
  }

  function handleDislike() {
    setForm({
      ...form,
      isLikedDisLiked: false,
      createdBy: userID,
    });
    setDisLikeActive(true);
    setLikeActive(false);
    handleSubmit(form);
  }

  function handleSubmit(data) {
    dispatch(supportAction.addLikeDislikeArticle(data));
  }
  const imageSize = {
    size: "large",
  };

  const isLoading = useSelector(
    (state) => state.getArticleDetails?.isLoading || state.deleteFAQ.isLoading
  );
  function handleArticleDetails(newApiData) {
    dispatch(supportAction.getArticleDetails(newApiData));
  }
  useEffect(() => {
    if (
      deleteFaqData.status === "SUCCESS" &&
      deleteFaqData.isLoading === false
    ) {
      dispatch(supportAction.deleteFAQ());
      navigate(-1);
    }
  }, [deleteFaqData]);

  const deleteHandle = () => {
    setOpenModal(true);
    // setSelectedRow(rows);
  };

  function deleteFaq(id) {
    const data = {
      orgId: ORG_ID(),
      articleId: id,
    };
    dispatch(supportAction.deleteFAQ(data));
  }

  function editFaq(articleId) {
    navigate(CREATE_FAQ, {
      state: {
        articleId: articleId,
        categoryID: catSubcatId,
        editMode: true,
      },
    });
  }
  return (
    <div className="overrideDetailedFaq">
      {openModal && (
        <DialogBox
          open={openModal}
          rows={[]}
          header={"Confirm Deletion"}
          deletehandle={() =>
            deleteFaq(articlesDetails?.articleDetail?.articleId)
          }
          handleCloseModal={() => setOpenModal(false)}
          content={"Are you sure you want to delete this FAQ?"}
          view
        />
      )}
      {!isLoading ? (
        <Grid className="detailedFaqContainer">
          <Grid mb={3} className="backBtnGrid">
            <button
              className="btn"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIosNewIcon style={{ fontSize: "1rem" }} />
              Back
            </button>
          </Grid>
          <CardBox
            className="cardBox"
            editLabel="Edit FAQ"
            deleteLabel="Delete FAQ"
            category={title}
            details={description}
            popupButton={checkPrivileges([7, 12])}
            editOnClick={() =>
              editFaq(articlesDetails?.articleDetail?.articleId)
            }
            deleteOnClick={() => deleteHandle()}
          />
          <Grid mt={0.5} p={3} className="borderedGrid">
            <Grid className="detailsGrid">
              <Typography className="question">
                {articlesDetails?.articleDetail?.title}
              </Typography>
              <Typography className="answer">
                {articlesDetails?.articleDetail?.description
                  ?.split("\n")
                  .map((str, ind) => (
                    <p key={ind}>{str}</p>
                  ))}
              </Typography>
              {articlesDetails?.articleDetail?.imageUrl && (
                <div>
                  <ViewImages
                    image={[articlesDetails?.articleDetail?.imageUrl]}
                    imageSize={imageSize}
                  />
                </div>
              )}
            </Grid>
            <Grid mt={2} container gap={2} className="reviewGrid">
              <Typography className="typo">
                Was this article helpful to you?
              </Typography>
              <span className="spanIcon">
                <Typography className={likeActive ? "iconActive" : "icon"}>
                  <ThumbUpIcon onClick={handleLike} />
                </Typography>
              </span>
              <span className="spanIcon">
                <Typography className={disLikeActive ? "iconActive" : "icon"}>
                  <ThumbDownIcon onClick={handleDislike} />
                </Typography>
              </span>
            </Grid>
            <Grid mt={2} className="articlesGrid">
              <Typography className="containerHeading">
                Other Articles
              </Typography>
              {articlesDetails?.otherArticles.map((item, ind) => {
                return (
                  <Grid
                    key={ind}
                    mt={2}
                    className="otheraqArticles"
                    onClick={() => {
                      let newApiData = {
                        catSubcatId: catSubcatId,
                        articleId: item.articleId,
                      };
                      handleArticleDetails(newApiData);
                    }}
                  >
                    <h1>{item?.title}</h1>
                    <p>{item?.description.slice(0, 180)}...</p>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default DetailedFaqTopic;
