import React, { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";
import Table from "../../../../library/custom/table/table";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import {
  downloadExcel,
  downloadPDF,
  getFormatedDate,
  useInfiniteScroll,
} from "../../../../utils/helper";
import commonImages from "../../../../assets";
import {
  VIEW_OPEN_ITEMS_DETAILS,
  VIEW_INCIDENT_REPORT,
  REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item,
  REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
} from "../../../../constant/routeContant";
import { PAGE_SIZE_100 } from "../../../../constant/constant";

const ItemIncidents = (props) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const [page, setPage] = useState(1);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);

  useEffect(() => {
    if (props?.incidentReportInjuredBodyPartList?.data?.result?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.incidentReportInjuredBodyPartList?.data?.result?.records,
      ]);
    }
  }, [props?.incidentReportInjuredBodyPartList?.data?.result?.records]);
  useEffect(() => {
    if (props?.incidentReportMostCommonInjuryList?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.incidentReportMostCommonInjuryList?.records,
      ]);
    }
  }, [props?.incidentReportMostCommonInjuryList?.records]);
  useEffect(() => {
    if (props?.vehiclewithmostincidentsLists?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.vehiclewithmostincidentsLists?.records,
      ]);
    }
  }, [props?.vehiclewithmostincidentsLists?.records]);
  useEffect(() => {
    if (props?.projectwithmostincidentsLists?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.projectwithmostincidentsLists?.records,
      ]);
    }
  }, [props?.projectwithmostincidentsLists?.records]);

  useEffect(() => {
    if (props?.incUserMostIncidentLists?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.incUserMostIncidentLists?.records,
      ]);
    }
  }, [props?.incUserMostIncidentLists?.records]);

  useEffect(() => {
    if (props?.incUsrMostActionItemLists?.records) {
      setData((prevData) => [
        ...prevData,
        ...props?.incUsrMostActionItemLists?.records,
      ]);
    }
  }, [props?.incUsrMostActionItemLists?.records]);
  const fetchMoreData = async (pageNumber) => {
    const { locationId, divisionId, startDate, endDate, incidentType } =
      props.propsItemsData?.drillDetail;
    try {
      let response;
      let data = {
        page: pageNumber,
        pageSize: PAGE_SIZE_100,
        divisionId: locationId,
        locationId: divisionId,
        fromDate: startDate,
        toDate: endDate,
        incidentType: incidentType > 0 ? incidentType : 0,
      };
      if (
        props?.propsItemsData?.drillDetailFull?.page ===
        "most_injured_body_part"
      ) {
        data.bodyPartId = props?.propsItemsData?.bodyPartId;
        response = await props.getinjuryBodyPartList(data);
      } else if (
        props?.propsItemsData?.itemTypeValue === "mostCommonInjuryType"
      ) {
        data.InjuryId = props?.propsItemsData?.InjuryId;
        response = await props.getMostCommonInjuryList(data);
      } else if (
        props?.propsItemsData?.itemTypeValue === "vehicle_most_incident"
      ) {
        data.VehicleId = props?.propsItemsData?.VehicleId;
        response = await props.getVehicleMostIncidentList(data);
      } else if (
        props?.propsItemsData?.itemTypeValue === "project_most_incident"
      ) {
        data.ProjectId = props?.propsItemsData?.ProjectId;
        response = await props.getProjectMostIncidentList(data);
      } else if (
        props?.propsItemsData?.itemTypeValue === "user_most_incident"
      ) {
        data.userId = props?.propsItemsData?.userId;
        response = await props.getUserwithMostIncidentList(data);
      } else if (props?.propsItemsData?.itemTypeValue === "user_most_action") {
        data.userId = props?.propsItemsData?.userId;
        response = await props.getUserwithMostItemList(data);
      }
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    const moreData = await fetchMoreData(pageNumber);
    setPage(pageNumber);
    setData((prevData) => [...prevData, ...moreData]);
  };

  useEffect(() => {
    setData([]);
  }, []);

  const columns1 = [
    {
      id: "reportId",
      label: `Report ID`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
      className: "inc_report-id",
    },

    {
      id: "caseId",
      label: "Case ID",
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
      className: "inc_case-id",
    },
    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
      className: "inc_dateOfEntry",
    },
    {
      id: "assetName",
      label: "Asset Name",
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },
    {
      id: "userInjured",
      label: "User Injured",
      sortable: true,
      isSorted: sortColumn === "userInjured",
      sortDirection,
    },

    {
      id: "enteredBy",
      label: "Entered By",
      sortable: true,
      isSorted: sortColumn === "enteredBy",
      sortDirection,
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
    },

    { id: "blank2", label: "" },
  ];

  const columnsForInjuryType = columns1?.map((column) => {
    if (column.id === "dateOfEntry") {
      return {
        ...column,
        id: "createdOn",
        label: "Date of Entry",
        isSorted: sortColumn === "createdOn",
        sortDirection,
      };
    }
    return column;
  });

  const columns2 = [
    {
      id: "reportId",
      label: `Report ID`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
    },

    {
      id: "caseId",
      label: "Case ID",
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
    },
    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
    },
    {
      id: "assetName",
      label: "Asset Name",
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },

    {
      id: "enteredByName",
      label: "Entered By",
      sortable: true,
      isSorted: sortColumn === "enteredByName",
      sortDirection,
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
    },

    { id: "blank2", label: "" },
  ];
  const columns3 = [
    {
      id: "itemId",
      label: `Item ID`,
      sortable: true,
      isSorted: sortColumn === "itemId",
      sortDirection,
      className: "inc_report-id",
    },
    {
      id: "subRootId",
      label: "Case ID",
      sortable: true,
      isSorted: sortColumn === "subRootId",
      sortDirection,
      className: "inc_case-id",
    },

    {
      id: "rootId",
      label: `Incident ID`,
      sortable: true,
      isSorted: sortColumn === "rootId",
      sortDirection,
      className: "inc_report-id",
    },
    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
      className: "inc_dateOfEntry",
    },
    {
      id: "incidentType",
      label: `Incident Type`,
      sortable: true,
      isSorted: sortColumn === "incidentType",
      sortDirection,
    },

    {
      id: "assetName",
      label: "Asset Name",
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },

    {
      id: "enteredBy",
      label: "Entered By",
      sortable: true,
      isSorted: sortColumn === "enteredBy",
      sortDirection,
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
    },

    { id: "blank2", label: "" },
  ];
  const columns4 = [
    {
      id: "reportId",
      label: `Report ID`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
      className: "inc_report-id",
    },

    {
      id: "caseId",
      label: "Case ID",
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
    },

    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
    },
    {
      id: "incidentType",
      label: `Incident Type`,
      sortable: true,
      isSorted: sortColumn === "incidentType",
      sortDirection,
    },

    {
      id: "assetName",
      label: "Asset Name",
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },

    {
      id: "enteredByName",
      label: "Entered By",
      sortable: true,
      isSorted: sortColumn === "enteredByName",
      sortDirection,
    },

    { id: "blank2", label: "" },
  ];
  const columns5 = [
    //vehicle most incidents
    {
      id: "reportId",
      label: `Report ID`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
      className: "inc_report-id",
    },

    {
      id: "caseId",
      label: "Case ID",
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
      className: "inc_case-id",
    },

    {
      id: "dateOfEntry",
      label: "Date of Entry",
      sortable: true,
      isSorted: sortColumn === "dateOfEntry",
      sortDirection,
      className: "inc_dateOfEntry",
    },
    {
      id: "incidentType",
      label: `Incident Type`,
      sortable: true,
      isSorted: sortColumn === "incidentType",
      sortDirection,
    },

    {
      id: "unitNo",
      label: "Unit No.",
      sortable: true,
      isSorted: sortColumn === "unitNo",
      sortDirection,
    },

    {
      id: "enteredBy",
      label: "Entered By",
      sortable: true,
      isSorted: sortColumn === "enteredBy",
      sortDirection,
    },
    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
    },

    { id: "blank2", label: "" },
  ];

  function navigations(navigationPath, incidentId, caseId, incidentType) {
    navigate(navigationPath, {
      state: {
        caseId: caseId,
        incidentId: incidentId,
        editMode: true,
        isDirect: true,
        incidentType: incidentType,
        apiData: props?.propsItemsData,
        backRoute: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item,
        backRouteText: "Back to Reports & Analytics",
        incidentURL: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item,
      },
    });
  }
  const handleViewOnClick = (rows) => {
    if (
      rows?.incTypeId === 2 ||
      rows?.incTypeId === 1 ||
      rows?.incTypeId === 3 ||
      rows?.incTypeId === 4 ||
      rows?.incTypeId === 5
    ) {
      navigations(
        VIEW_INCIDENT_REPORT,
        rows?.reportId,
        rows?.caseId,
        rows?.incTypeId
      );
    }
  };
  const handleViewOnClickAction = (row) => {
    navigate(VIEW_OPEN_ITEMS_DETAILS, {
      state: {
        id: row?.itemId,
        itemTypeId: row?.itemTypeId,
        rootId: row?.rootId,
        subRootId: row?.subRootId,
        assignedPartyId: row?.assignedTo,
        status: row?.status,
        origin: row?.origin,
        itemType: row?.itemType,
        addedById: row?.enteredBy,
        involvedPartyName: row?.involvedPartyName,
        involvedPartyId: row?.involvedPartyId,
        incidentType: row?.incTypeId,
        apiData: props?.propsItemsData,
        backRoute: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail_item,
        backRouteText: "Back to Reports & Analytics",
      },
    });
  };
  // most injured and injurty type
  const row1 = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td className="inc_row_reportId">{asset?.reportId}</td>
      <td className="inc_row_caseid">{asset?.caseId}</td>
      <td className="inc_row_dateOfEntry">
        {props?.propsItemsData?.drillDetailFull?.page ===
        "most_injured_body_part"
          ? asset?.dateOfEntry
            ? getFormatedDate(asset?.dateOfEntry)
            : ""
          : props?.propsItemsData?.itemTypeValue === "mostCommonInjuryType"
          ? asset?.createdOn
            ? asset?.createdOn
            : ""
          : ""}
      </td>
      <td>{asset?.assetName}</td>
      <td>{asset?.userInjured}</td>
      <td>{asset?.enterByName}</td>
      <td>{asset?.status}</td>
      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident Report"}
        />
      </td>
    </tr>
  ));
  // user with most incidents
  const row2 = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.reportId}</td>
      <td>{asset?.caseId}</td>
      <td>{getFormatedDate(asset?.dateOfEntry)}</td>
      <td>{asset?.assetName}</td>
      <td>{asset?.enteredByName}</td>
      <td>{asset?.status}</td>
      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident Report"}
        />
      </td>
    </tr>
  ));
  // Users with Most no. of Open Action Items
  const row3 = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.itemId}</td>
      <td className="inc_row_caseid">{asset?.subRootId}</td>
      <td className="inc_row_reportId">{asset?.rootId}</td>
      <td>{getFormatedDate(asset?.dateOfEntry)}</td>
      <td>{asset?.incidentType}</td>
      <td>{asset?.assetName}</td>
      <td>{asset?.enteredByName}</td>
      <td>{asset?.status}</td>
      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClickAction(asset)}
          viewLabel={"View Action"}
        />
      </td>
    </tr>
  ));
  //project with most incident
  const row4 = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.reportId}</td>
      <td>{asset?.caseId}</td>
      <td>{getFormatedDate(asset?.dateOfEntry)}</td>
      <td>{asset?.incidentType}</td>
      <td>{asset?.assetName}</td>
      <td>{asset?.enteredByName}</td>

      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident Report"}
        />
      </td>
    </tr>
  ));
  // vehicle with most incident
  const row5 = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.reportId}</td>
      <td>{asset?.caseId}</td>

      <td>{getFormatedDate(asset?.dateOfEntry)}</td>
      <td>{asset?.incType}</td>
      <td>{asset?.unitNo}</td>
      <td>{asset?.enteredByName}</td>
      <td>{asset?.status}</td>
      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident Report"}
        />
      </td>
    </tr>
  ));
  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };
  const rowsPdf = data?.map((item) => [
    item?.reportId,
    item?.caseId,
    props?.propsItemsData?.drillDetailFull?.page === "most_injured_body_part"
      ? item?.dateOfEntry
        ? getFormatedDate(item?.dateOfEntry)
        : ""
      : props?.propsItemsData?.itemTypeValue === "mostCommonInjuryType"
      ? item?.createdOn
        ? item?.createdOn
        : ""
      : "",

    item?.assetName,
    item?.userInjured,
    item?.enterByName,
    item?.status,
  ]);

  const rowsPdf_userincident = data?.map((item) => [
    // pdf data for user action
    item?.reportId,
    item?.caseId,
    getFormatedDate(item?.dateOfEntry),
    item?.assetName,
    item?.enteredByName,
    item?.status,
  ]);

  const rowsPdf_userAction = data?.map((item) => [
    // pdf data for user action
    item?.itemId,
    item?.subRootId,
    item?.rootId,
    getFormatedDate(item?.dateOfEntry),
    item?.incidentType,
    item?.assetName,
    item?.enteredByName,
    item?.status,
  ]);

  const rowsPdf_project = data?.map((item) => [
    // pdf data for vehicle
    item?.reportId,
    item?.caseId,
    getFormatedDate(item?.dateOfEntry),
    item?.incidentType,
    item?.assetName,
    item?.enteredByName,
    item?.status,
  ]);
  const rowsPdf_vehicle = data?.map((item) => [
    // pdf data for vehicle
    item?.reportId,
    item?.caseId,
    getFormatedDate(item?.dateOfEntry),
    item?.incType,
    item?.unitNo,
    item?.enteredByName,
    item?.status,
  ]);

  const dataBack = {
    title: "Back",
    route: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
    state: props.propsItemsData?.drillDetailFull,
  };

  return (
    <>
      <div>
        <BackBtn dataBack={dataBack} />
      </div>
      <div className="topHeaderDrill">
        <div className="topHeaderDrillLeft">
          <div className="title">
            {props?.propsItemsData?.drillDetailFull?.page === "user_most_action"
              ? "View Action"
              : "View Incident Reports"}
          </div>
        </div>
        <div className="topHeaderDrillRight">
          <div
            className="downloadBox"
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div className="downloadIcon">
              <img src={commonImages?.downloadWhite} alt="Download" />
            </div>
            <div className="downloadText">Download Data</div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="downloadListBox">
              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    onClick={
                      props?.propsItemsData?.drillDetailFull?.page ===
                      "most_injured_body_part"
                        ? () =>
                            downloadPDF(
                              rowsPdf,
                              columns1.map((col) => col.label),
                              "mostInjuredBodyPart.pdf"
                            )
                        : props?.propsItemsData?.itemTypeValue ===
                          "mostCommonInjuryType"
                        ? () =>
                            downloadPDF(
                              rowsPdf,
                              columnsForInjuryType.map((col) => col.label),
                              "mostCommonInjuryType.pdf"
                            )
                        : props?.propsItemsData?.itemTypeValue ===
                          "user_most_incident"
                        ? () =>
                            downloadPDF(
                              rowsPdf_userincident,
                              columns2.map((col) => col.label),
                              "user_most_incident.pdf"
                            )
                        : props?.propsItemsData?.itemTypeValue ===
                          "vehicle_most_incident"
                        ? () =>
                            downloadPDF(
                              rowsPdf_vehicle,
                              columns5.map((col) => col.label),
                              "vehicle-most-incident.pdf"
                            )
                        : props?.propsItemsData?.itemTypeValue ===
                          "project_most_incident"
                        ? () =>
                            downloadPDF(
                              rowsPdf_project,
                              columns4.map((col) => col.label),
                              "project-most-incident.pdf"
                            )
                        : props?.propsItemsData?.itemTypeValue ===
                          "user_most_action"
                        ? () =>
                            downloadPDF(
                              rowsPdf_userAction,
                              columns3.map((col) => col.label),
                              "user_most_action.pdf"
                            )
                        : ""
                    }
                  >
                    <img src={commonImages.pdfFill} alt={""} />
                    <span>Download PDF</span>
                  </div>
                </div>
              </div>

              <div className="downloadList">
                <div className="downloads">
                  <div
                    className="downloadListIcon"
                    //  onClick={() => downloadExcel(data, "All-items.xlsx")}
                    onClick={() =>
                      downloadExcel(
                        data,
                        `${props?.propsItemsData?.itemTypeValue}.xlsx`
                      )
                    }
                  >
                    <img src={commonImages.excelDownload} alt="" />
                    <span>Download.xlsx</span>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>

      <div className="customTableWrapper">
        <Table
          columns={
            props?.propsItemsData?.drillDetailFull?.page ===
            "most_injured_body_part"
              ? columns1
              : props?.propsItemsData?.itemTypeValue === "mostCommonInjuryType"
              ? columnsForInjuryType
              : props?.propsItemsData?.itemTypeValue === "user_most_incident"
              ? columns2
              : props?.propsItemsData?.itemTypeValue === "user_most_action"
              ? columns3
              : props?.propsItemsData?.itemTypeValue === "project_most_incident"
              ? columns4
              : props?.propsItemsData?.itemTypeValue === "vehicle_most_incident"
              ? columns5
              : ""
          }
          rows={
            props?.propsItemsData?.drillDetailFull?.page ===
              "most_injured_body_part" ||
            props?.propsItemsData?.itemTypeValue === "mostCommonInjuryType"
              ? row1
              : props?.propsItemsData?.itemTypeValue === "user_most_incident"
              ? row2
              : props?.propsItemsData?.itemTypeValue === "user_most_action"
              ? row3
              : props?.propsItemsData?.itemTypeValue === "project_most_incident"
              ? row4
              : props?.propsItemsData?.itemTypeValue === "vehicle_most_incident"
              ? row5
              : ""
          }
          tableRef={tableRef}
          onSort={handleSort}
        />
      </div>
    </>
  );
};

export default ItemIncidents;
