import React, { useState } from "react";
import * as equipmentsAction from "../../../redux/actions/equipmentsAction";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_SIZE, ORG_ID, LOGIN_ORG_ID } from "../../../constant/constant";
import {
  ADMIN_ADD_EQUIPMENTS,
  ADMIN_EDIT_EQUIPMENT,
} from "../../../constant/routeContant";
import { useNavigate } from "react-router-dom";
import EquipmentList from "../component/equipments/equipmentList";
import { API_STATUS } from "../../../constant/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";
export default function EquipmentsContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const equipmentList = useSelector((state) => state.equipmentList);
  const isLoading = useSelector(
    (state) =>
      state.equipmentList?.isLoading || state.deleteEquipment?.isLoading
  );

  const clientDivision = useSelector((state) => state.getDropdownDataKeyValue);

  const equipmentCategoryData = useSelector(
    (state) => state?.getDropdownDataKeyValue?.data?.equipmentCategories
  );
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const divisions = userInfo?.divisions?.filter(
    (i) => i?.serviceType == PRODUCT_ID()
  );
  const isClient = userInfo?.isClient;

  const [clientDivisions, setClientDivisions] = useState();
  const valueCheck = clientDivisions || divisions;
  const clientSwitch = isClient || ORG_ID() !== LOGIN_ORG_ID();

  useEffect(() => {
    if (clientSwitch) {
      if (clientDivision?.status === API_STATUS.SUCCESS) {
        const divisions = clientDivision?.data?.divisons && [
          ...clientDivision?.data?.divisons,
        ];
        divisions?.unshift({ key: 1, value: "All Divisions" });
        setClientDivisions(divisions);
      }
    }
  }, [clientDivision, clientSwitch]);

  const defaultData = {
    organisationId: ORG_ID(),
    divisionId: clientSwitch && valueCheck ? 0 : divisions[0]?.key,
    search: "",
    page: 1,
    pageSize: PAGE_SIZE,
    userId: 0,
    statusIds: "",
  };

  const deleteEquipment = useSelector((state) => state.deleteEquipment);
  useEffect(() => {
    getEquipmentList(defaultData);
  }, []);
  useEffect(() => {
    if (deleteEquipment?.status === API_STATUS.SUCCESS) {
      getEquipmentList(defaultData);
    }
  }, [deleteEquipment]);

  const getEquipmentList = (data) => {
    dispatch(equipmentsAction.getEquipmentList(data));
  };

  const handleDeleteOnClick = (EquipmentId) => {
    dispatch(equipmentsAction.deleteEquipmentAction(EquipmentId));
  };

  const handleEditOnClick = (rows) => {
    navigate(ADMIN_EDIT_EQUIPMENT, {
      state: { id: rows.equipmentId, editMode: true },
    });
  };

  const addEquipment = () => {
    navigate(ADMIN_ADD_EQUIPMENTS);
  };

  useEffect(() => {
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: [6, 10],
      productId: PRODUCT_ID(),
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  return (
    <>
      <EquipmentList
        equipmentList={isLoading ? [] : equipmentList?.data}
        getEquipmentList={getEquipmentList}
        isLoading={isLoading}
        addEquipment={addEquipment}
        handleDeleteOnClick={handleDeleteOnClick}
        handleEditOnClick={handleEditOnClick}
        divisionDropdown={
          clientSwitch && valueCheck ? clientDivisions : divisions
        }
        equipmentCategoryData={equipmentCategoryData}
      />
    </>
  );
}
