import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import BackBtn from "../../../../library/custom/backBtn/backBtn";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import ProjectTable from "./projectTable";
import "./viewProcoreProject.scss";
import { ORG_ID, PAGE_SIZE } from "../../../../constant/constant";
import * as adminAction from "../../../../redux/actions/adminCommonAction";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import { useDispatch } from "react-redux";

function ViewProcoreProject({
  dataBack,
  location,
  projectsListData,
  getProjectListData,
  isLoading,
  deleteProject,
  isProjectsLoading,
  divisionDropdown,
  getSyncProcoreProjectsLists,
  syncProcoreProjectsListData,
  clientSwitch,
  valueCheck,
  divisions,
  setIsSuccess,
  isSuccess,
}) {
  const data = location?.state?.projectsDetails;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [projectid, setProjectid] = useState("");
  const [isdisabled, setIsDisabled] = useState(true);
  const [accessUserId, setAccessUserId] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    setIsDisabled(!(projectid || accessUserId.length > 0));
  }, [projectid, accessUserId]);

  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "60%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: 550,
    bgcolor: "background.paper",
    boxShadow: 24,
    border: "1px solid #000",
    p: 1,
  };
  const OnClickMapProject = () => {
    setOpen(true);
    const defaultData = {
      organisationId: ORG_ID(),
      divisionId: clientSwitch && valueCheck ? 0 : divisions[0]?.key,
      search: "",
      page: 1,
      pageSize: PAGE_SIZE,
      userId: 0,
    };
    getProjectListData(defaultData);
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: "6",
      productId: PRODUCT_ID(),
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  };

  const onClickConnectProject = () => {
    let array = accessUserId;
    let integerValue = array[0];
    const defaultData = {
      orgId: ORG_ID(),
      projectId: projectid ? projectid : integerValue,
      procoreProjectId: data?.id,
    };
    getSyncProcoreProjectsLists(defaultData);
    setIsSuccess(true);
  };

  useEffect(() => {
    if (syncProcoreProjectsListData.status === "SUCCESS" && isSuccess) {
      setOpen(false);
      setProjectid("");
    }
  }, [syncProcoreProjectsListData]);

  const onRowClick = (rows) => {
    setProjectid(rows?.row?.id);
    setResults(rows?.row?.id);
  };

  return (
    <>
      <div className="viewOverideClient">
        <BackBtn dataBack={dataBack} />
      </div>
      <div className="viewOverideContact">
        <div className="commonOveride">
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Grid mt={1}>
                  <div
                    style={{
                      fontFamily: "lato",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                  >
                    SequenceXT Projects List
                  </div>
                </Grid>
                <Grid item container lg={12}>
                  <Grid lg={12} className="procoreProjectTable">
                    <div className="desc_box row_uniq">
                      <div className="table_style_ui">
                        <ProjectTable
                          projectsListData={projectsListData}
                          getProjectListData={getProjectListData}
                          isLoading={isLoading}
                          filterShow={true}
                          deleteProject={deleteProject}
                          isProjectsLoading={isProjectsLoading}
                          divisionDropdown={divisionDropdown}
                          checkbox={true}
                          onRowClick={(rows) => onRowClick(rows)}
                          hideFooterSelectedRowCount
                          selectionModel={accessUserId}
                          onSelectionModelChange={(selection) => {
                            if (selection.length > 1) {
                              const selectionSet = new Set(accessUserId);
                              const result = selection.filter(
                                (s) => !selectionSet.has(s)
                              );
                              setAccessUserId(result);
                            } else {
                              setAccessUserId(selection);
                            }
                          }}
                          multipleSelection={false}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid item container lg={12} mt={16}>
                  <Grid lg={6}></Grid>
                  <Grid lg={6}>
                    <Button
                      variant="contained"
                      style={{
                        height: "44px",
                        backgroundColor: isdisabled ? "#E0E0E0" : "#34A853",
                        color: isdisabled ? "#9E9E9E" : "white",
                        borderRadius: "100px",
                        textTransform: "none",
                        boxShadow: "none",
                        transition: "none",
                        fontFamily: "Lato",
                        fontSize: "14px",
                        fontWeight: "600",
                        padding: "0px 38px",
                      }}
                      disabled={isdisabled}
                      onClick={onClickConnectProject}
                    >
                      Connect with SequenceXT Project
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
          </div>
          <Box className="commoninfoWrap">
            <Box className="commoninfoBox">
              <Box className="commoninfoleft">
                <Typography className="title">
                  {" "}
                  Procore Project Details:
                </Typography>
                <Box className="columns">
                  <Typography className="listView">
                    Project ID:
                    <span className="font-w400">{data?.id}</span>
                  </Typography>
                  <Typography className="listView">
                    Project ID:
                    <span className="font-w400">{data?.name}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Project Type:
                    <span className="font-w400">{data?.type_name}</span>
                  </Typography>
                  <Typography className="listView">
                    Project Status:
                    <span className="font-w400">{data?.status_name}</span>
                  </Typography>
                </Box>

                <Box className="columns">
                  <Typography className="listView">
                    Country Code:
                    <span className="font-w400">
                      {data?.address?.country_code}
                    </span>
                  </Typography>
                  <Typography className="listView">
                    city:
                    <span className="font-w400">{data?.address?.city}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Address1:
                    <span className="font-w400">{data?.address?.street}</span>
                  </Typography>
                  <Typography className="listView">
                    Zip:
                    <span className="font-w400">{data?.address?.zip}</span>
                  </Typography>
                </Box>
                <Box className="columns">
                  <Typography className="listView">
                    Email:
                    <span className="font-w400">{data?.created_by?.email}</span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
      <Grid mt={4}>
        <Button
          variant="contained"
          style={{
            height: "44px",
            backgroundColor: "#34A853",
            color: "white",
            borderRadius: "100px",
            textTransform: "none",
            boxShadow: "none",
            transition: "none",
            fontFamily: "Lato",
            fontSize: "14px",
            fontWeight: "600",
            padding: "0px 38px",
          }}
          onClick={OnClickMapProject}
        >
          {"Map with SequenceXT Project"}
        </Button>
      </Grid>
    </>
  );
}

export default ViewProcoreProject;
