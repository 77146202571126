import React, { useEffect, useState, useRef } from "react";
import Table from "../../../../library/custom/table/table";
import { useNavigate } from "react-router-dom";
import Popover from "@mui/material/Popover";
import "jspdf-autotable";
import {
  downloadExcel,
  downloadPDF,
  getFormatedDate,
  useInfiniteScroll,
  getDayNameAndDate,
} from "../../../../utils/helper";
import commonImages from "../../../../assets";
import ReportRowManipulte from "../../../incidents/incidentList/ReportRowManipulte";
import BarChart from "../../../../library/custom/charts/barchart";
import { Grid } from "@mui/material";
import Buttons from "../../../../library/custom/button/Button";
import { useSelector } from "react-redux";
import { COLORS, PAGE_SIZE_100 } from "../../../../constant/constant";
import { useDispatch } from "react-redux";
import { getIncMostCommonDayOfInjury } from "../../../../redux/actions/incidentAction";
import {
  REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
  VIEW_INCIDENT_REPORT,
} from "../../../../constant/routeContant";
import Loader from "../../../../library/common/Loader";

function MostCommonDayofweek(props) {
  const navigate = useNavigate();
  const [sortColumn, setSortColumn] = useState();
  const [sortDirection, setSortDirection] = useState("asc");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const tableRef = useInfiniteScroll(() => fetchData(page + 1), [page]);
  const [barDate, setBarDate] = useState();
  const incReportMostCommonDayOfInjuryData = useSelector(
    (state) => state.incReportMostCommonDayOfInjury
  );

  useEffect(() => {
    if (props?.incReportMostCommonDayOfInjuryData) {
      setData((prevData) => [
        ...prevData,
        ...props?.incReportMostCommonDayOfInjuryData,
      ]);
    }
  }, [props?.incReportMostCommonDayOfInjuryData]);

  const fetchMoreData = async (pageNumber) => {
    const data = {
      page: pageNumber,
      pageSize: PAGE_SIZE_100,
      fromDate: props?.drillDetailFull?.startDate,
      toDate: props?.drillDetailFull?.endDate,
      divisionId: props?.drillDetail?.divisionId,
      locationId: props?.drillDetail?.locationId,
      Day: props?.drillDetail?.Day,
    };
    try {
      const response = await props?.getMostCommnDayOfInjury(data);
      return response.data || [];
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const fetchData = async (pageNumber) => {
    const moreData = await fetchMoreData(pageNumber);
    setPage(pageNumber);
    setData((prevData) => [...prevData, ...moreData]);
  };

  useEffect(() => {
    setData([]);
  }, []);

  const handleSort = (column) => {
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortColumn(column);
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return newDirection === "asc" ? -1 : 1;
      if (a[column] > b[column]) return newDirection === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  };
  const columns = [
    {
      id: "reportId",
      label: `Report Id`,
      sortable: true,
      isSorted: sortColumn === "reportId",
      sortDirection,
      className: "inc_report-id",
    },
    {
      id: "caseId",
      label: `Case Id`,
      sortable: true,
      isSorted: sortColumn === "caseId",
      sortDirection,
      className: "inc_case-id",
    },
    {
      id: "createdOn",
      label: `Date Of Entry`,
      sortable: true,
      isSorted: sortColumn === "createdOn",
      sortDirection,
      className: "inc_dateOfEntry",
    },
    {
      id: "assetName",
      label: `Asset Name`,
      sortable: true,
      isSorted: sortColumn === "assetName",
      sortDirection,
    },
    {
      id: "userInjured",
      label: `User Injured`,
      sortable: true,
      isSorted: sortColumn === "userInjured",
      sortDirection,
    },
    {
      id: "enterByName",
      label: `Entered By`,
      sortable: true,
      isSorted: sortColumn === "enterByName",
      sortDirection,
    },

    {
      id: "status",
      label: "Status",
      sortable: true,
      isSorted: sortColumn === "status",
      sortDirection,
      className: "inc_case-id",
    },
    {
      id: "blank1",
      label: "",
    },
  ];
  function navigations(navigationPath, incidentId, caseId, incidentType) {
    navigate(navigationPath, {
      state: {
        caseId: caseId,
        incidentId: incidentId,
        editMode: true,
        isDirect: true,
        incidentType: incidentType,
        apiData: props?.drillDetailFull,
        backRoute: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
        incidentURL: REPORTS_ANALYTICS_DASHBOARD_INCIDENT_Detail,
        backRouteText: "Back to Reports & Analytics",
      },
    });
  }
  const handleViewOnClick = (rows) => {
    if (
      rows?.incTypeId === 2 ||
      rows?.incTypeId === 1 ||
      rows?.incTypeId === 3 ||
      rows?.incTypeId === 4 ||
      rows?.incTypeId === 5
    ) {
      navigations(
        VIEW_INCIDENT_REPORT,
        rows?.reportId,
        rows?.caseId,
        rows?.incTypeId
      );
    }
  };

  const data1 = props?.drillDetailFull?.trendPassData || [];

  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 7;

  const getPaginatedData = () => {
    const start = currentPage * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    return data1?.slice(start, end);
  };
  const paginatedData = getPaginatedData();

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if ((currentPage + 1) * ITEMS_PER_PAGE < data1.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const truncateLabel = (label, maxLength = 30) => {
    if (label?.length <= maxLength) {
      return label;
    }
    return `${label?.substring(0, maxLength)}...`;
  };
  const handleBarClick = (evt, label) => {
    setBarDate(label);
  };
  const optionsDay = {
    indexAxis: "x",
    onClick: function (evt, element) {
      if (element.length > 0) {
        const elementIndex = element[0].index;
        const clickedLabel = IncidentDay.labels[elementIndex];
        handleBarClick(evt, clickedLabel);
      }
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "end",
        formatter: function (value, context) {
          return truncateLabel(context.chart.data.labels[context.dataIndex]);
        },
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const label = data.labels[tooltipItem.index];
          return label;
        },
      },
    },
  };
  const IncidentDay = {
    labels: paginatedData?.map((item) => getDayNameAndDate(item?.date)),
    datasets: [
      {
        label: "Number of Injuries",
        data: paginatedData?.map((item) => item?.numberOfInjuries),
        backgroundColor: [COLORS.BrightAqua],
        borderColor: [COLORS.BrightAqua],
        borderWidth: 1,
        color: COLORS.White,
      },
    ],
  };
  const rows = data?.map((asset, index) => (
    <tr className="row" key={index}>
      <td>{asset?.reportId}</td>
      <td>{asset?.caseId}</td>
      <td className="inc_row_dateOfEntry">{asset?.createdOn}</td>
      <td>{asset?.assetName}</td>
      <td>{asset?.userInjured}</td>
      <td>{asset?.enterByName}</td>
      <td>{asset?.status}</td>
      <td>
        <ReportRowManipulte
          rows={asset}
          view
          viewOnClick={() => handleViewOnClick(asset)}
          viewLabel={"View Incident Report"}
        />
      </td>
    </tr>
  ));
  const rowsPdf = data?.map((item) => [
    item?.reportId,
    item?.caseId,
    getFormatedDate(item?.createdOn),
    item?.assetName,
    item?.userInjured,
    item?.enterByName,
    item?.status,
  ]);
  // useEffect(() => {
  //   setData([]);
  //   if (barDate) {
  //     dispatch(
  //       getIncMostCommonDayOfInjury({
  //         page: 1,
  //         pageSize: PAGE_SIZE_100,
  //         fromDate: barDate && getFormatedDate(barDate),
  //         toDate: barDate && getFormatedDate(barDate),
  //         divisionId: props?.drillDetail?.divisionId,
  //         locationId: props?.drillDetail?.locationId,
  //       })
  //     );
  //   }
  // }, [barDate]);
  return (
    <div>
      {" "}
      <>
        {incReportMostCommonDayOfInjuryData?.isLoading && <Loader />}
        <Grid container className="overviewIns">
          <Grid item md={12} xs={12}>
            {/* <Grid className="commonBoxShodow">
              <h2 className="titletop">Most common day of the week</h2>
              <div className="date-range">
                Date Range{" "}
                <span>
                  {paginatedData ? paginatedData[0]?.date : ""} {"-"}{" "}
                  {paginatedData ? paginatedData[6]?.date : ""}
                </span>
              </div>
              <BarChart
                labels={IncidentDay?.labels}
                datasets={IncidentDay?.datasets}
                options={optionsDay}
                height="auto"
              />
              <div className="pagination-controls">
                <Buttons
                  label={"Previous"}
                  varientContained={true}
                  onClick={handlePrevious}
                  disabled={currentPage === 0}
                />

                <Buttons
                  label={"Next"}
                  varientContained={true}
                  onClick={handleNext}
                  disabled={(currentPage + 1) * ITEMS_PER_PAGE >= data1?.length}
                />
              </div>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid mt={4}>
          <div className="topHeaderDrill">
            <div className="topHeaderDrillLeft">
              <div className="title">
                {props?.drillDetail?.Day === 1
                  ? "Monday"
                  : props?.drillDetail?.Day === 2
                  ? "Tuesday"
                  : props?.drillDetail?.Day === 3
                  ? "Wednesday"
                  : props?.drillDetail?.Day === 4
                  ? "Thursday"
                  : props?.drillDetail?.Day === 5
                  ? "Friday"
                  : props?.drillDetail?.Day === 6
                  ? "Saturday"
                  : props?.drillDetail?.Day === 0
                  ? "Sunday"
                  : ""}
              </div>
            </div>
            <div className="topHeaderDrillRight">
              <div
                className="downloadBox"
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                <div className="downloadIcon">
                  <img src={commonImages?.downloadWhite} alt="Download" />
                </div>
                <div className="downloadText">Download Data</div>
              </div>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className="downloadListBox">
                  <div className="downloadList">
                    <div className="downloads">
                      <div
                        className="downloadListIcon"
                        onClick={() =>
                          downloadPDF(
                            rowsPdf,
                            columns?.map((col) => col.label),
                            "most-common-day-for-injury.pdf"
                          )
                        }
                      >
                        <img src={commonImages.pdfFill} alt={""} />
                        <span>Download PDF</span>
                      </div>
                    </div>
                  </div>

                  <div className="downloadList">
                    <div className="downloads">
                      <div
                        className="downloadListIcon"
                        onClick={() =>
                          downloadExcel(data, "most-common-day-for-injury.xlsx")
                        }
                      >
                        <img src={commonImages.excelDownload} alt="" />
                        <span>Download.xlsx</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
          <div className="mostCommon">
            <div className="customTableWrapper">
              <Table
                columns={columns}
                rows={rows}
                tableRef={tableRef}
                onSort={handleSort}
                customcls={"mostcmnday"}
              />
            </div>
          </div>
        </Grid>
      </>
    </div>
  );
}

export default MostCommonDayofweek;
