import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UserConfigurationEdit from "../component/users/userConfigurationEdit";
import * as userAction from "../../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import Loader from "../../../library/common/Loader";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { ADMIN_USER_CONFIGURATION } from "../../../constant/routeContant";
import { API_STATUS, ORG_ID } from "../../../constant/constant";

function UserConfigurationEditContainer() {
  const [successScreens, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const editMode = location?.state?.editMode;
  const onboardUserId = location?.state?.onboardUserId;
  const [divisionSafety, setDivisionSafety] = useState([]);
  const [roleSafety, setRoleSafety] = useState([]);
  const [divisionStormWater, setDivisionStormWater] = useState([]);
  const [roleStormWater, setRoleStormWater] = useState([]);
  const [productID, setProductID] = useState("");

  const updateUserOnboard = (data) => {
    setIsSuccess(true);
    if (editMode) {
      dispatch(userAction.updateUserOnboard(data));
    }
  };

  const showLoaders = useSelector(
    (state) => state.getDropdownDataKeyValue.isLoading
  );
  const showLoader = useSelector(
    (state) =>
      state.updateUserOnboardData.isLoading ||
      state.getUserConfigurationDetsilsData.isLoading
  );

  const getDropdownDataKeyValues = useSelector(
    (state) => state.getDropdownDataKeyValue
  );

  const updateUserOnboardData = useSelector(
    (state) => state.updateUserOnboardData
  );
  const updateUserOnboardDataStatus = useSelector(
    (state) => state.updateUserOnboardData.status
  );

  const getUserConfigurationDetsils = () => {
    dispatch(userAction.getUserConfigurationDetsils(onboardUserId));
  };
  const getUserConfigurationDetsilsData = useSelector(
    (state) => state.getUserConfigurationDetsilsData
  );

  useEffect(() => {
    if (editMode && onboardUserId) {
      getUserConfigurationDetsils();
    }
  }, []);

  const division = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.divisons
  );
  const roles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.roles
  );
  const jobTitles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.jobTitles
  );

  const getDropDowndata = (productsID) => {
    setProductID(productsID);
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: "5,6,12",
      productId: productsID,
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  };

  useEffect(() => {
    if (getDropdownDataKeyValues?.status === API_STATUS.SUCCESS) {
      if (productID === 1) {
        setDivisionSafety(getDropdownDataKeyValues?.data?.divisons);
        setRoleSafety(getDropdownDataKeyValues?.data?.roles);
      } else {
        setDivisionStormWater(getDropdownDataKeyValues?.data?.divisons);
        setRoleStormWater(getDropdownDataKeyValues?.data?.roles);
      }
    }
  }, [getDropdownDataKeyValues]);
  useEffect(() => {
    setIsSuccess(false);
  }, []);
  const successScreen = (id) => {
    return (
      <SuccessScreen
        id={id}
        isUpdate={
          editMode
            ? "Configured User Updated Successfully"
            : " Configured User Added Successfully"
        }
        route={ADMIN_USER_CONFIGURATION}
        label1="User ID"
        label2="has been generated"
        label4="User Configuration List"
      ></SuccessScreen>
    );
  };

  return (
    <>
      {showLoader && <Loader />}
      {showLoaders && <Loader />}
      {updateUserOnboardDataStatus === API_STATUS.SUCCESS &&
      updateUserOnboardData?.data &&
      successScreens ? (
        successScreen(updateUserOnboardData?.data?.id)
      ) : (
        <UserConfigurationEdit
          updateUserOnboard={updateUserOnboard}
          divisions={division}
          roles={roles}
          jobTitles={jobTitles}
          editMode={editMode}
          getUserConfigurationDetsilsData={ showLoader ? [] : editMode ? getUserConfigurationDetsilsData : ""}
          onboardUserId={onboardUserId}
          divisionSafety={divisionSafety}
          roleSafety={roleSafety}
          divisionStormWater={divisionStormWater}
          roleStormWater={roleStormWater}
          getDropDowndata={getDropDowndata}
        />
      )}
    </>
  );
}

export default UserConfigurationEditContainer;
