import { getApiUrl } from "../config";
import { PAGE_SIZE, ORG_ID } from "../constant/constant";
import { post, get } from "./axiosRequest";

// post Add Observation
export const addObservationService = async ({ data }) => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/AddObservation`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// get OPEN ITEMS LIST
export const getOpenItemListService = async ({ data: { pageNumber } }) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }/GetOpenItemList?pageNumber=${pageNumber}&pageSize=${PAGE_SIZE}&organisationId=${ORG_ID()}`;

  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// get Observation Details SERVICE
export const getObservationDetailService = async (insObservationId) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetObservationDetail?insObservationId=${insObservationId}&organisationId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// post update observation
export const postUpdateObservationService = async ({ data }) => {
  const url = `${getApiUrl().incpectionApiUrl}Inspection/UpdateObservation`;
  try {
    return await post(url, data, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};

// get Observation List Service
export const getListOfObservationService = async ({
  data: { page, searchText, fromdate = "", todate = "", Status, itemTypes },
}) => {
  const url = `${
    getApiUrl().incpectionApiUrl
  }Inspection/GetObservationList?searchText=${searchText}&page=${page}&fromdate=${fromdate}&todate=${todate}&status=${Status}&itemTypes=${itemTypes}&pageSize=${PAGE_SIZE}&organisationId=${ORG_ID()}`;
  try {
    return await get(url, {
      headers: {
        Authorization: localStorage.getItem("id_token"),
        orgId: ORG_ID(),
      },
    });
  } catch (err) {
    return err;
  }
};
