import React, { useState, useEffect } from "react";
import "./viewCredential.scss";
import { Grid } from "@mui/material";
import { InputField } from "../../../library/custom/textBox/InputField";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import RadioButton from "../../../library/custom/radioButton/RadioButton";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import Buttons from "../../../library/custom/button/Button";
import { useNavigate } from "react-router-dom";
import MultiSelectBox from "../../../library/custom/selectMenu/MultiSelectBox";
import {
  HBNEXT_ORG_ID,
  ORG_ID,
  SELECT_EXPIRATION_PERIOD,
} from "../../../constant/constant";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import Checkbox from "../../../library/checkbox/checkbox";
import { checkPrivileges } from "../../../utils/rolesHelper";

export default function AddCredentials({
  saveCredentials,
  issuingBody,
  credentialTypes,
  editMode,
  getCredentialDetsilsData,
}) {
  const [credentialType, setCredentialType] = useState(null);
  const [credentialName, setCredentilName] = useState("");
  const [issueBody, SetIssueBody] = useState([]);
  const [credentialNameId, SetcredentialNameId] = useState(0);
  const [years, setYears] = useState(null);
  const [checkBox, setcheckBox] = useState(false);

  const [credentialNameError, setCredentilNameError] = useState(false);
  const [issueBodyError, SetIssueBodyError] = useState(false);
  const [credentialTypeError, setCredentialTypeError] = useState(false);

  useEffect(() => {
    if (getCredentialDetsilsData?.data && editMode) {
      setCredentialType(
        getCredentialDetsilsData?.data?.result?.credentialTypeId
      );
      setCredentilName(getCredentialDetsilsData?.data?.result?.credentialName);
      SetIssueBody(
        getCredentialDetsilsData?.data?.result?.issueBodies?.map(
          (data) => data.value
        )
      );
      SetcredentialNameId(
        getCredentialDetsilsData?.data?.result?.credentialNameId
      );
      setcheckBox(getCredentialDetsilsData?.data?.result?.isGlobal);
      setYears(
        getCredentialDetsilsData?.data?.result?.expirationPeriod === 0
          ? 11
          : getCredentialDetsilsData?.data?.result?.expirationPeriod
      );
    }
  }, [getCredentialDetsilsData?.data]);

  const onChangeIssuingBody = (event) => {
    const {
      target: { value },
    } = event;
    SetIssueBody(typeof value === "string" ? value.split(",") : value);
  };

  const history = useNavigate();

  const onChangeSelectYear = (e) => {
    setYears(e.target.value);
  };

  const saveCredentialAction = () => {
    let isFieldEmpty = false;
    if (credentialName.trim() === "") {
      setCredentilName("");
      setCredentilNameError(true);
      isFieldEmpty = true;
    }
    if (issueBody.length === 0) {
      SetIssueBodyError(true);
      isFieldEmpty = true;
    }
    if (!editMode) {
      if (!credentialType) {
        setCredentialTypeError(true);
        isFieldEmpty = true;
      }
    }
    if (!isFieldEmpty) {
      const issuebody = [];
      for (let datas of issuingBody) {
        if (issueBody.includes(datas.value)) issuebody.push(datas.key);
      }
      const sendData = {
        credentialName: credentialName.trim(),
        credentialTypeId: credentialType,
        createdBy: localStorage.getItem("userId"),
        issuingBodyIds: issuebody,
        organisationId: ORG_ID(),
        id: credentialNameId,
        isGlobal: checkBox,
        expirationPeriod: years === 11 ? 0 : years,
      };
      const sendData1 = {
        credentialName: credentialName.trim(),
        credentialTypeId: credentialType,
        modifiedBy: localStorage.getItem("userId"),
        issuingBodyIds: issuebody,
        organisationId: ORG_ID(),
        id: credentialNameId,
        isGlobal: checkBox,
        expirationPeriod: years === 11 ? 0 : years,
      };
      saveCredentials(editMode ? sendData1 : sendData);
    }
  };

  return (
    <>
      <div>
        <h1 className="heading_style">
          {editMode ? "Edit Credential" : "Add Credential"}
        </h1>
      </div>
      <Grid container item md={9} xs={9} className="addShadowBox">
        <Grid item container spacing={3}>
          <Grid item lg={6} xs={12} sm={12} md={4}>
            <FormControl fullWidth={true}>
              <InputField
                isRequired={true}
                disabled={false}
                type="text"
                label={"Credential Name"}
                value={credentialName}
                placeholder="Add Credentials Name Text Here..."
                onChange={(e) => setCredentilName(e.target.value)}
              />
            </FormControl>
            {credentialNameError && !credentialName && (
              <div className="errorMsg">Please enter this required field</div>
            )}
          </Grid>

          <Grid item lg={6} xs={12} sm={12} md={4} className="SelectMenuValue">
            <FormControl fullWidth>
              <FormLabels label="Issuing Body" isRequired={true} />
              <MultiSelectBox
                customClass={"dropCustom"}
                placeholder={"Please Select"}
                listData={issuingBody}
                onChange={onChangeIssuingBody}
                value={issueBody}
                isError={issueBodyError}
                errorMessage="Selection is required for this field"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={0} spacing={4}>
          <Grid item lg={12} xs={12} sm={12} md={4}>
            <FormLabels label="Credential Type" isRequired={true} />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="radioGroup"
            >
              {credentialTypes?.map((creItem, index) => {
                return (
                  <RadioButton
                    key={index}
                    checked={credentialType == creItem.key ? true : false}
                    value={creItem?.key}
                    label={creItem?.value}
                    disabled={false}
                    onChange={(e) => setCredentialType(e.target.value)}
                  ></RadioButton>
                );
              })}
            </RadioGroup>
            {credentialTypeError && !credentialType && (
              <div className="errorMsg">
                Selection is required for this field
              </div>
            )}
          </Grid>
        </Grid>

        <Grid container mt={3}>
          <Grid item lg={5.7} xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <FormLabels
                label="Expiration Period (Years)"
                isRequired={false}
              />
              <SelectMenu
                placeholder="Please Select"
                listData={SELECT_EXPIRATION_PERIOD}
                value={years}
                onchangehandler={(e) => onChangeSelectYear(e)}
              />
            </FormControl>
          </Grid>
        </Grid>
        {checkPrivileges([12, 13]) && parseInt(ORG_ID()) === HBNEXT_ORG_ID && (
          <Grid container mt={3}>
            <Grid item lg={6} xs={12} sm={12} md={4} mt={2}>
              <div className="typeTxt">
                <Checkbox
                  checked={checkBox}
                  onChange={() => setcheckBox(!checkBox)}
                  label={"Global Credential"}
                />
              </div>
            </Grid>
          </Grid>
        )}

        <Grid container mb={5} mt={25} spacing={3}>
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left"></Grid>
          <Grid item lg={6} xs={12} sm={12} md={4}>
            <div className="adduser_btn">
              <div className="cancle_heading_style" onClick={() => history(-1)}>
                Cancel
              </div>
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={editMode ? "Update" : "Save"}
                varientContained={true}
                onClick={(e) => saveCredentialAction()}
              ></Buttons>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
