import React from "react";
import "../../../incidents/cases/addCaseList.scss";
import Buttons from "../../../../library/custom/button/Button";
import VehicleTable from "./vehicleTable";
import { checkPrivileges } from "../../../../utils/rolesHelper";

export default function VehicleList({
  vehicles,
  addVehicle,
  getVehicleList,
  isLoading,
  deleteVehicle = { deleteVehicle },
  divisionDropdown,
  vehicleCategory,
}) {
  return (
    <>
      <section className="grid_main_sec">
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Vehicles</div>
          <div>
            {checkPrivileges([78, 79, 12]) && (
              <Buttons
                id="btnAddVehicle"
                label={"Add Vehicle"}
                varientAddIconBlue={true}
                onClick={() => addVehicle()}
              />
            )}
          </div>
        </div>

        <VehicleTable
          vehicles={vehicles}
          getVehicleList={getVehicleList}
          isLoading={isLoading}
          filterShow={true}
          deleteVehicle={deleteVehicle}
          divisionDropdown={divisionDropdown}
          vehicleCategory={vehicleCategory}
        />
      </section>
    </>
  );
}
