import React from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterList from "./FilterIncidentList";
import { InputField } from "../../library/custom/textBox/InputField";
import Grid from "@mui/material/Grid";

const OpenItemsFilter = ({
  filterType,
  handleSearchFilter,
  handleClose,
  anchorEl,
  incReport,
  handleInputSearch,
  resetFilterAPI,
  placeholder = "Search Incident Report",
  openItemFilter = false,
  PermitFilter = false,
  firstLabel,
  firstLebelFilter,
  firstLebelOnChanges,
  firstLebelValue,
  assetType,
  assetTypeId,
  typeOnAssetChanges,
  itemTypes,
  itemTypesId,
  typeOnItemTypeChanges,
  origin,
  originId,
  typeOnOriginChanges,
  statusIds,
  fromDate,
  toDate,
  ...rest
}) => {
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="filter_of_caselist">
        <Grid container spacing={2}>
          <Grid item md={6.7}>
            <div>
              <InputField
                type={"text"}
                onChange={(e) => handleInputSearch(e)}
                name={"incidentReport"}
                value={incReport ?? ""}
                placeholder={placeholder}
                disabled={false}
                isError={false}
                errorMsg={""}
                isIcon={true}
              />
            </div>
          </Grid>
          <Grid item>
            <div className="filter_btn">
              <button
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClose(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>

              <FilterList
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                resetFilterAPI={resetFilterAPI}
                handleSearch={(event, state) =>
                  handleSearchFilter(event, state)
                }
                filterType={filterType ?? []}
                PermitFilter={PermitFilter}
                openItemFilter={openItemFilter}
                firstLabel={firstLabel}
                firstLebelFilter={firstLebelFilter}
                firstLebelOnChanges={firstLebelOnChanges}
                firstLebelValue={firstLebelValue}
                assetType={assetType}
                assetTypeId={assetTypeId}
                typeOnAssetChanges={typeOnAssetChanges}
                itemTypesShow={true}
                itemTypesId={itemTypesId}
                typeOnItemTypeChanges={typeOnItemTypeChanges}
                origin={true}
                originId={originId}
                typeOnOriginChanges={typeOnOriginChanges}
                statusIds={statusIds}
                fromDate={fromDate}
                toDate={toDate}
                singleSelect={true}
                singleCheckbox={false}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default OpenItemsFilter;
