import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "./viewCredential.scss";
import { InputField } from "../../../library/custom/textBox/InputField";
import FormControl from "@mui/material/FormControl";
import FormLabels from "../../../library/custom/formLabel/FormLabels";
import Buttons from "../../../library/custom/button/Button";
import DatePicker from "../../../library/custom/datePicker/DatePicker";
import SelectMenu from "../../../library/custom/selectMenu/SelectMenu";
import SearchAutoComplete from "../../../library/custom/searchAutoComplete/SearchAutoComplete";
import { useNavigate } from "react-router-dom";
import {
  CREDENTIAL_GRID_COLOUMNS,
  CREDENTIAL_TEMPLATE_URL,
  ORG_ID,
  USERID,
  USER_TYPE,
} from "../../../constant/constant";
import {
  dateValiadtor,
  getCurrentDate,
  getFormatedDate,
} from "../../../utils/helper";
import Checkbox from "../../../library/checkbox/checkbox";
import BulkUpload from "../../../library/custom/bulkUpload/BulkUpload";
import { checkPrivileges } from "../../../utils/rolesHelper";
import FormDropZone from "../../../library/dropZone/FormDropZone";

export default function AddAdminCredential({
  addUserCredentials,
  editMode,
  credentialListData,
  getCredentials,
  configureMode,
  getCredentialViewDetsilsData,
  credentialNameIdss,
  isLoading,
  allUsers,
  onSearch,
}) {
  const [credentialsName, SetcredentialsName] = useState("");
  const [selectCredentialsName, setSelectCredentialsName] = useState("");
  const [issueBody, SetIssueBody] = useState("");
  const [credentialType, setCredentialType] = useState([]);
  const [credentialNumber, setCredentialNumber] = useState("");
  const [users, setUsers] = useState("");
  const [selectUser, setSelectUser] = useState();
  const [passingDate, setPassingDate] = useState(getCurrentDate());
  const [classId, setClassId] = useState("");
  const [checkBox, setcheckBox] = useState(false);
  const [photos, setPhotos] = useState("");
  const [expirationDate, setExpirationDate] = useState(getCurrentDate());
  const [credentialNameId, setcredentialNameId] = useState();
  const [credentialsNameError, SetcredentialsNameError] = useState("");
  const [issueBodyError, SetIssueBodyError] = useState("");
  const [credentialNumberError, setCredentialNumberError] = useState("");
  const [passingDateError, setPassingDateError] = useState("");
  const [usersError, setUsersError] = useState("");
  const [passingDateErrMsg, setPassingDateErrMsg] = useState("");
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [newDate, setNewDate] = useState(null);
  const autoCompCre = useRef(null);

  useEffect(() => {
    setcheckBox("");
    if (getCredentialViewDetsilsData?.data && editMode) {
      SetcredentialsName(
        getCredentialViewDetsilsData?.data?.result?.credentialName
      );
      setSelectCredentialsName({
        key: getCredentialViewDetsilsData?.data?.result?.credentialNameId,
        value: getCredentialViewDetsilsData?.data?.result?.credentialName,
      });
      SetIssueBody(getCredentialViewDetsilsData?.data?.result?.issuingBodyId);
      setCredentialType(
        getCredentialViewDetsilsData?.data?.result?.credentialTypeId
      );
      setUsers(getCredentialViewDetsilsData?.data?.result?.userId);
      setSelectUser({
        key: getCredentialViewDetsilsData?.data?.result?.userId,
        value: getCredentialViewDetsilsData?.data?.result?.userName,
      });
      setCredentialNumber(
        getCredentialViewDetsilsData?.data?.result?.credentialNumber
      );
      setNewDate(getCredentialViewDetsilsData?.data?.result?.expirationPeriod);
      setPassingDate(getCredentialViewDetsilsData?.data?.result?.passingDate);
      setClassId(getCredentialViewDetsilsData?.data?.result?.classId);
      setExpirationDate(
        getCredentialViewDetsilsData?.data?.result?.expirationDate
      );
      if (
        editMode &&
        getCredentialViewDetsilsData?.data?.result?.expirationPeriod === 0 &&
        getCredentialViewDetsilsData?.data?.result?.expirationDate === null
      ) {
        setcheckBox(true);
      }

      setissuingBodys([
        {
          key: getCredentialViewDetsilsData?.data?.result?.issuingBodyId,
          value: getCredentialViewDetsilsData?.data?.result?.issuingBody,
        },
      ]);
      setcredentialNameId(
        getCredentialViewDetsilsData?.data?.result?.credentialNameId
      );
      setPhotos(getCredentialViewDetsilsData?.data?.result?.imageUrls[0]);
    }
  }, [getCredentialViewDetsilsData?.data]);
  const [maxDate, setMaxxDate] = useState(null);

  useEffect(() => {
    if (newDate && passingDate) {
      const getYears = new Date(passingDate).getFullYear() + newDate;
      const getmonths = new Date(passingDate).getMonth();
      const getdates = new Date(passingDate).getDate();
      setMaxxDate(`${getmonths + 1}/${getdates}/${getYears}`);
    }
  }, [newDate, passingDate]);

  const history = useNavigate();

  const addUserCredentialAction = () => {
    let isFieldEmpty = false;
    if (!credentialsName) {
      SetcredentialsNameError(true);
      isFieldEmpty = true;
    }

    if (!issueBody) {
      SetIssueBodyError(true);
      isFieldEmpty = true;
    }
    if (!users) {
      setUsersError(true);
      isFieldEmpty = true;
    }
    if (credentialNumber.trim() === "") {
      setCredentialNumber("");
      setCredentialNumberError(true);
      isFieldEmpty = true;
    }
    if (!passingDate) {
      setPassingDateError(true);
      isFieldEmpty = true;
    }

    if (passingDate) {
      const validDate = dateValiadtor(getFormatedDate(passingDate));
      if (validDate === false) {
        setPassingDateErrMsg(true);
        isFieldEmpty = true;
      } else if (validDate === "Valid date") {
        setPassingDateErrMsg(false);
        // isFieldEmpty = false;
      }
    }

    if (!isFieldEmpty) {
      let modifiedsBy = editMode ? "modifiedBy" : "createdBy";
      let credData = {
        credentialAssignId: 0,
        credentialNameId: credentialNameId,
        issuingBodyId: issueBody,
        credentialTypeId: credentialType,
        credentialNumber: credentialNumber,
        passingDate: passingDate ? getFormatedDate(passingDate) : "",
        classId: classId,
        isExpirationDate: !checkBox,
        imageUrls: photos,
        createdBy: USERID,
        userId: users.key,
        organisationId: ORG_ID(),
        credentialId: credentialNameIdss,
        [modifiedsBy]: localStorage.getItem("userId"),
      };

      if (!checkBox) {
        credData.expirationDate = expirationDate
          ? getFormatedDate(expirationDate)
          : "";
      }

      addUserCredentials(editMode ? credData : [credData]);
    }
  };

  useEffect(() => {
    setExpirationDate(maxDate);
  }, [maxDate]);

  const handleFileChanges = (data) => {
    const Credentials = data.map((x, index) => {
      x.credentialNameId = parseInt(x.credentialNameId);
      x.issuingBodyId = parseInt(x.issuingBodyId);
      x.credentialTypeId = parseInt(x.credentialTypeId);
      x.userId = x?.userId;
      x.credentialNumber = x?.credentialNumber;
      x.passingDate = x?.passingDate;
      x.classId = x?.classId;
      x.expirationDate = x?.expirationDate;
      x.isExpirationDate = x.expirationDate === undefined ? false : true;
      x.imageUrls = x?.imageUrls;
      x.createdBy = USERID;
      x.organisationId = ORG_ID();
      return x;
    });

    const CredentialData = {
      Credentials,
    };
    addUserCredentials(CredentialData.Credentials);
  };

  const onChangeCredential = (event) => {
    const defaultData = {
      search: event.target.value,
      organisationId: 1,
    };
    getCredentials(defaultData);
  };

  const [issuingBodys, setissuingBodys] = useState([]);
  const onSelectSearchCredential = (v1, v2) => {
    SetcredentialsName(v2?.value);
    setcredentialNameId(v2?.key);
    setissuingBodys(v2?.issuingBodies);
    setCredentialType(v2?.credentialTypeId);
    setNewDate(v2?.expirationPeriod);
    setSelectCredentialsName(v2);
  };
  const onChangeIssuingBody = (event) => {
    SetIssueBody(event.target.value);
  };

  const onChangeCredentialNumber = (e) => {
    setCredentialNumber(e.target.value);
  };

  const onchangePassingdatePicker = (e) => {
    setPassingDateErrMsg(false);
    setPassingDate(e);
    setExpirationDate(maxDate);
  };

  const onChangeClassId = (e) => {
    setClassId(e.target.value);
  };
  const onchangeExpirationdatePicker = (e) => {
    setExpirationDate(e);
  };

  const handleFileChange = (photos) => {
    setPhotos(photos);
  };

  const handleDeleteImageURL = (urls) => {
    const previousImg = photos.split("|");
    setPhotos(previousImg.filter((url) => url !== urls).join("|"));
  };

  const onChangeUsers = (value) => {
    setUsers(value);
    setSelectUser(value);
  };

  return (
    <div className="overidePersonal">
      <Grid container item lg={9} xs={12} sm={12} md={9}>
        <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
          <div className="heading_style">
            {editMode ? "Edit Credential" : "Add Credential"}
          </div>
        </Grid>
        {!editMode ? (
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="right">
            {checkPrivileges([12, 13]) && (
              <Buttons
                aria-describedby="dd"
                variant="contained"
                type="button"
                name="btn"
                label={
                  isBulkUpload ? "Single Credential" : "Multiple Credential"
                }
                varientContained={true}
                onClick={() => setIsBulkUpload(!isBulkUpload)}
              ></Buttons>
            )}
          </Grid>
        ) : (
          ""
        )}
      </Grid>

      <Grid item lg={12} xs={12} sm={12} md={12}>
        <div>
          {isBulkUpload ? (
            <Grid container>
              <Grid item lg={9} xs={9} sm={9} md={9} mt={4}>
                <BulkUpload
                  handleFileChanges={handleFileChanges}
                  paraText1={"Drop the files here ..."}
                  paraText2={"Drop the files here, "}
                  paraText3={"or Browse"}
                  templateUrl={CREDENTIAL_TEMPLATE_URL}
                  templateText="Download Template"
                  gridColoumns={CREDENTIAL_GRID_COLOUMNS}
                  isCustomColoumns={true}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container item md={9} xs={9} className="addShadowBox">
              <Grid item container mt={2} spacing={2}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label="Credentials Name" isRequired={true} />

                    <SearchAutoComplete
                      name="credential"
                      optionsData={
                        isLoading ? [] : credentialListData?.data?.result ?? []
                      }
                      typeOnchange={(event, value) => onChangeCredential(event)}
                      isKeyValuePair={true}
                      disabled={editMode}
                      value={selectCredentialsName}
                      onChange={(event, value) =>
                        onSelectSearchCredential(event, value)
                      }
                      isLoading={isLoading}
                      noOptionsTextMsg="No credential"
                      autoCompClear={autoCompCre}
                    />
                  </FormControl>
                  {credentialsNameError && !credentialsName && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>

                <Grid
                  item
                  lg={6}
                  xs={12}
                  sm={12}
                  md={4}
                  className="SelectMenuValue"
                >
                  <FormControl fullWidth>
                    <FormLabels label="Issuing Body" isRequired={true} />
                    <SelectMenu
                      customClass={"dropCustom"}
                      disabled={configureMode}
                      listData={issuingBodys || []}
                      value={issueBody}
                      onchangehandler={(e) => onChangeIssuingBody(e)}
                    />
                  </FormControl>
                  {issueBodyError && !issueBody && (
                    <div className="errorMsg">
                      Selection is required for this field
                    </div>
                  )}
                </Grid>
              </Grid>

              <Grid item container mt={1} spacing={3}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormLabels
                    label="Credential Type"
                    isRequired={false}
                    disabled={configureMode}
                  />

                  {credentialType === 0 && (
                    <div className="credType">Certificate</div>
                  )}
                  {credentialType === 1 && (
                    <div className="credType">License</div>
                  )}
                  {credentialType === 2 && (
                    <div className="credType">Degree</div>
                  )}
                </Grid>
              </Grid>
              <Grid item container mt={2} spacing={2}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormControl fullWidth>
                    <FormLabels label={"Select User"} isRequired={true} />
                    <SearchAutoComplete
                      name="selectUser"
                      optionsData={isLoading ? [] : allUsers ?? []}
                      typeOnchange={(event, value) =>
                        onSearch(event.target.value, USER_TYPE.NONPRIVILEGED)
                      }
                      isKeyValuePair={true}
                      value={selectUser}
                      disabled={editMode}
                      onChange={(event, value) => onChangeUsers(value)}
                      isLoading={isLoading}
                    />
                  </FormControl>
                  {usersError && !users && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <InputField
                    isRequired={true}
                    type="text"
                    label="Credential Number"
                    value={credentialNumber}
                    onChange={(e) => onChangeCredentialNumber(e)}
                  />
                  {credentialNumberError && !credentialNumber && (
                    <div className="errorMsg">
                      Please enter this required field
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid item container mt={2} spacing={2}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormLabels label={"Passing Date"} isRequired={true} />
                  <DatePicker
                    maxDateEnable={false}
                    value={passingDate}
                    disabled={false}
                    onChangeHandler={(e) => onchangePassingdatePicker(e)}
                  />
                  {passingDateError && !passingDate && (
                    <div className="errorMsg">Passing Date is required</div>
                  )}
                  {passingDateErrMsg && passingDate != null && (
                    <div className="errorMsg">Invalid Date</div>
                  )}
                </Grid>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <InputField
                    isRequired={false}
                    type="text"
                    label="Class ID"
                    value={classId}
                    onChange={(e) => onChangeClassId(e)}
                  />
                </Grid>
              </Grid>

              <Grid item container mt={2} spacing={2}>
                <Grid item lg={6} xs={12} sm={12} md={4}>
                  <FormLabels label={"Expiration Date"} isRequired={false} />
                  <DatePicker
                    value={expirationDate}
                    minDate={passingDate}
                    disabled={checkBox}
                    onChangeHandler={(e) => onchangeExpirationdatePicker(e)}
                    maxDate={maxDate}
                  />
                </Grid>
                {newDate <= 0 && (
                  <Grid item lg={6} xs={12} sm={12} md={4} mt={4.5}>
                    <div className="typeTxt">
                      <Checkbox
                        checked={checkBox}
                        onChange={() => setcheckBox(!checkBox)}
                        label={"No Expiration Date"}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>

              <Grid item container mt={2} spacing={2}>
                <Grid item lg={12} xs={12} sm={12} md={8}>
                  <FormLabels label={"Add Certificate File"} />
                  <>
                    <FormDropZone
                      paraText1={"Drop the files here ..."}
                      paraText2={"Drop Images/files here, "}
                      paraText3={"or Browse"}
                      handleFileChange={handleFileChange}
                      deleteImageURL={(url) => handleDeleteImageURL(url)}
                      isDynamics={true}
                      isRequired={true}
                      fileType={10}
                      isMuliple={false}
                      imageUrl={photos}
                      id="addPhoto"
                      key="addPhoto"
                      isDoc={true}
                      isImage={false}
                      allFiles={true}
                    />
                    <p>Supported file types: images or pdf</p>
                  </>
                </Grid>
              </Grid>
              <Grid item container mt={3} spacing={2}>
                <Grid
                  item
                  lg={7}
                  xs={12}
                  sm={12}
                  md={4}
                  textAlign="left"
                ></Grid>
                <Grid lg={2} xs={12} sm={6} md={4}>
                  <Buttons
                    varientText={true}
                    label="Cancel"
                    onClick={() => history(-1)}
                  />
                </Grid>
                <Grid lg={2} xs={12} sm={6} md={4}>
                  <Buttons
                    varientContained={true}
                    label={editMode ? "Update" : "Save"}
                    onClick={() => addUserCredentialAction()}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
    </div>
  );
}
