import React, { useEffect, useState, useRef } from "react";

import DrillDown from "../component/drillDownIncident";
import { useLocation } from "react-router-dom";
import Loader from "../../../../library/common/Loader";
import { useSelector, useDispatch } from "react-redux";
import * as incidentAction from "../../../../redux/actions/incidentAction";
import { PAGE_SIZE_100 } from "../../../../constant/constant";
import { getSlasFormatedDate } from "../../../../utils/helper";
const DrillDownIncidentContainer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const drillDetail = location?.state;
  const InjuredBodyPartList = useSelector(
    (state) => state.incidentReportInjuredBodyPart
  );
  const MostCommonInjuryPartData = useSelector(
    (state) => state.getIncidentReportMostCommonInjury
  );
  const incReportMostCommonDayOfInjuryData = useSelector(
    (state) => state.incReportMostCommonDayOfInjury
  );

  const incidentList = useSelector((state) => state.incidentList);
  const cases = useSelector((state) => state.casesList);
  const incUserMostIncidentList = useSelector(
    (state) => state.incUserMostIncident?.data?.result?.records
  );
  const incUserMostActionList = useSelector(
    (state) => state.incUserMostActionItem?.data?.result?.records
  );
  const projectwithmostincidentsList = useSelector(
    (state) => state.projectwithmostincidents?.data?.result
  );
  const vehiclewithmostincidentsList = useSelector(
    (state) => state.vehiclewithmostincidents?.data?.result
  );

  const startdateFormated = getSlasFormatedDate(
    drillDetail?.apiData?.startDate
  );
  const enddateFormated = getSlasFormatedDate(drillDetail?.apiData?.endDate);
  useEffect(() => {
    if (drillDetail && drillDetail.page === "most_injured_body_part") {
      const data = {
        page: 1,
        pageSize: PAGE_SIZE_100,
        incidentType:
          drillDetail?.apiData?.incidentType > 0
            ? drillDetail?.apiData?.incidentType
            : 0,
        fromDate: drillDetail?.apiData?.startDate,
        toDate: drillDetail?.apiData?.endDate,
        divisionId: drillDetail?.apiData?.divisionId,
        locationId: drillDetail?.apiData?.locationId,
      };
      getInjuredBodyPartData(data);
    } else if (drillDetail && drillDetail.page === "most_common_injury") {
      const data = {
        page: 1,
        pageSize: PAGE_SIZE_100,
        incidentType:
          drillDetail?.apiData?.incidentType > 0
            ? drillDetail?.apiData?.incidentType
            : 0,
        fromDate: drillDetail?.apiData?.startDate,
        toDate: drillDetail?.apiData?.endDate,
        divisionId: drillDetail?.apiData?.divisionId,
        locationId: drillDetail?.apiData?.locationId,
      };
      getMostCommonInjuryData(data);
    } else if (drillDetail && drillDetail.page === "user_most_incident") {
      const data = {
        incidentType:
          drillDetail?.apiData?.incidentType > 0
            ? drillDetail?.apiData?.incidentType
            : 0,
        page: 1,
        pageSize: PAGE_SIZE_100,
        fromDate: drillDetail?.apiData?.startDate,
        toDate: drillDetail?.apiData?.endDate,
        divisionId: drillDetail?.apiData?.divisionId,
        locationId: drillDetail?.apiData?.locationId,
      };
      gettUserMostIncidentData(data);
    } else if (drillDetail && drillDetail.page === "user_most_action") {
      const data = {
        incidentType:
          drillDetail?.apiData?.incidentType > 0
            ? drillDetail?.apiData?.incidentType
            : 0,
        page: 1,
        pageSize: PAGE_SIZE_100,
        fromDate: drillDetail?.apiData?.startDate,
        toDate: drillDetail?.apiData?.endDate,
        divisionId: drillDetail?.apiData?.divisionId,
        locationId: drillDetail?.apiData?.locationId,
      };
      getIncidentUserWithMostNoOfActionItemData(data);
    } else if (drillDetail && drillDetail.page === "project_most_incident") {
      const data = {
        incidentType:
          drillDetail?.apiData?.incidentType > 0
            ? drillDetail?.apiData?.incidentType
            : 0,
        page: 1,
        pageSize: PAGE_SIZE_100,
        fromDate: drillDetail?.apiData?.startDate,
        toDate: drillDetail?.apiData?.endDate,
        divisionId: drillDetail?.apiData?.divisionId,
        locationId: drillDetail?.apiData?.locationId,
      };
      getProjectWithmostIncidentsData(data);
    } else if (drillDetail && drillDetail.page === "vehicle_most_incident") {
      const data = {
        incidentType:
          drillDetail?.apiData?.incidentType > 0
            ? drillDetail?.apiData?.incidentType
            : 0,
        page: 1,
        pageSize: PAGE_SIZE_100,
        fromDate: drillDetail?.apiData?.startDate,
        toDate: drillDetail?.apiData?.endDate,
        divisionId: drillDetail?.apiData?.divisionId,
        locationId: drillDetail?.apiData?.locationId,
      };
      getVehicleWithmostIncidentsData(data);
    } else if (drillDetail && drillDetail?.isIncident === true) {
      const defaultData = {
        pageNumber: 1,
        pageSize: PAGE_SIZE_100,
        incidentType:
          drillDetail?.apiData?.incidentType > 0
            ? drillDetail?.apiData?.incidentType
            : 0,
        startDate: drillDetail?.apiData?.startDate,
        endDate: drillDetail?.apiData?.endDate,
        divisionId: drillDetail?.apiData?.divisionId,
        locationId: drillDetail?.apiData?.locationId,
      };
      getIncidentListData(defaultData);
    } else if (drillDetail && drillDetail?.page === "totalcases") {
      const defaultData = {
        page: 1,
        pageSize: PAGE_SIZE_100,
        search: "",
        //date_from: drillDetail?.apiData?.startDate,
        //date_to: drillDetail?.apiData?.endDate,
        date_from: startdateFormated,
        date_to: enddateFormated,
        statusIds: drillDetail?.statusId,
        divisionId: drillDetail?.apiData?.divisionId,
        locationId: drillDetail?.apiData?.locationId,
      };

      getCasesListData(defaultData);
    } else if (drillDetail && drillDetail?.page === "most_common_day_week") {
      const data = {
        // incidentType:
        //   drillDetail?.apiData?.incidentType > 0
        //     ? drillDetail?.apiData?.incidentType
        //     : 0,
        page: 1,
        pageSize: PAGE_SIZE_100,
        fromDate: drillDetail?.apiData?.startDate,
        toDate: drillDetail?.apiData?.endDate,
        divisionId: drillDetail?.apiData?.divisionId,
        locationId: drillDetail?.apiData?.locationId,
        Day: drillDetail?.apiData?.Day,
      };

      getMostCommnDayOfInjury(data);
    }
  }, [drillDetail]);

  const getInjuredBodyPartData = (data) => {
    dispatch(incidentAction.getIncidentReportInjuredBodyPart(data));
  };
  const getMostCommonInjuryData = (data) => {
    dispatch(incidentAction.getIncidentReportMostCommonInjury(data));
  };
  const getMostCommnDayOfInjury = (data) => {
    dispatch(incidentAction.getIncMostCommonDayOfInjury(data));
  };
  const getIncidentListData = (data) => {
    dispatch(incidentAction.getIncidentList(data));
  };

  const getCasesListData = (data) => {
    dispatch(incidentAction.getCasesList(data));
  };
  const gettUserMostIncidentData = (data) => {
    dispatch(incidentAction.getIncidentUserWithMostNoOfIncident(data));
  };
  const getIncidentUserWithMostNoOfActionItemData = (data) => {
    dispatch(incidentAction.getIncidentUserWithMostNoOfActionItem(data));
  };

  const getProjectWithmostIncidentsData = (data) => {
    dispatch(incidentAction.getProjectWithmostIncidents(data));
  };
  const getVehicleWithmostIncidentsData = (data) => {
    dispatch(incidentAction.getVehicleWithmostIncidents(data));
  };
  const showLoader = useSelector(
    (state) =>
      state.incidentReportInjuredBodyPart?.isLoading ||
      state.getIncidentReportMostCommonInjury?.isLoading ||
      state.incidentList?.isLoading ||
      state.casesList?.isLoading ||
      state.projectwithmostincidents?.isLoading ||
      state.vehiclewithmostincidents?.isLoading ||
      state.incUserMostActionItem?.isLoading ||
      state?.incUserMostIncident?.isLoading
  );

  return (
    <>
      {showLoader && <Loader />}

      <DrillDown
        drillDetail={drillDetail}
        incidentList={incidentList?.data?.incident}
        getIncidentListData={getIncidentListData}
        casesList={cases?.data?.recentCases}
        getCasesListData={getCasesListData}
        InjuredBodyPartListData={InjuredBodyPartList?.data}
        mostCommonInjuryListData={MostCommonInjuryPartData?.data}
        incReportMostCommonDayOfInjuryData={
          incReportMostCommonDayOfInjuryData?.data
        }
        getInjuredBodyPartData={getInjuredBodyPartData}
        getMostCommonInjuryData={getMostCommonInjuryData}
        getMostCommnDayOfInjury={getMostCommnDayOfInjury}
        incUserMostIncidentData={incUserMostIncidentList}
        gettUserMostIncidentData={gettUserMostIncidentData}
        incUserMostActionList={incUserMostActionList}
        getIncidentUserWithMostNoOfActionItemData={
          getIncidentUserWithMostNoOfActionItemData
        }
        projectwithmostincidentsList={projectwithmostincidentsList}
        getProjectWithmostIncidentsData={getProjectWithmostIncidentsData}
        vehiclewithmostincidentsList={vehiclewithmostincidentsList}
        getVehicleWithmostIncidentsData={getVehicleWithmostIncidentsData}
        startdateFormated={startdateFormated}
        enddateFormated={enddateFormated}
      />
    </>
  );
};

export default DrillDownIncidentContainer;
