import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import UserForm from "../component/users/userForm";
import * as userAction from "../../../redux/actions/userAction";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import { useLocation } from "react-router-dom";
import SuccessScreen from "../../../library/custom/success/successScreen";
import { ADMIN_USER } from "../../../constant/routeContant";
import Loader from "../../../library/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  notifyError,
  notifyErrorContractor,
} from "../../../../src/library/custom/toastMessage/Toast";
import { API_STATUS, ORG_ID } from "../../../constant/constant";
import DownloadCsv from "../../download_file/DownloadCsv";
import { useRef } from "react";
import { PRODUCT_ID } from "../../../constant/storm-water/constant";

const UsersContainer = () => {
  const dowloadCsv = useRef();

  const location = useLocation();
  const dispatch = useDispatch();
  const [successScreens, setSuccessScreens] = useState(false);
  const [setsubmit, setSetsubmit] = useState([]);
  const [divisionSafety, setDivisionSafety] = useState([]);
  const [roleSafety, setRoleSafety] = useState([]);
  const [divisionStormWater, setDivisionStormWater] = useState([]);
  const [roleStormWater, setRoleStormWater] = useState([]);
  const [productID, setProductID] = useState("");
  const [allUserList, setallUserList] = useState([]);

  const adduserLists = (data) => {
    setdownloadCsvFile(true);
    if (editMode) {
      setSetsubmit(true);
      dispatch(userAction.updateUserList(data));
      setSuccessScreens(true);
    } else {
      setSetsubmit(true);
      dispatch(userAction.addUserList(data));
      setallUserList(data);
    }
  };

  const showLoader = useSelector(
    (state) =>
      state.addUserListData.isLoading ||
      state.updateUserListData.isLoading ||
      state.getUserListDetsilsData.isLoading
  );

  const showLoaders = useSelector(
    (state) => state.getDropdownDataKeyValue.isLoading
  );

  const userId = location?.state?.userId;
  const editMode = location?.state?.editMode;

  const getDropdownDataKeyValues = useSelector(
    (state) => state.getDropdownDataKeyValue
  );

  const roles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.roles
  );
  const jobTitles = useSelector(
    (state) => state.getDropdownDataKeyValue?.data?.jobTitles
  );

  const userData = useSelector((state) => state.getUserListDetsilsData);

  const updateUserListData = useSelector((state) => state.updateUserListData);
  const addUserListData = useSelector((state) => state.addUserListData);
  const updateUserListDataStatus = useSelector(
    (state) => state.updateUserListData.status
  );
  const addUserListDataStatus = useSelector(
    (state) => state.addUserListData.status
  );

  const getDropDowndata = (productsID) => {
    setProductID(productsID);
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: "5,6,12",
      productId: productsID,
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  };

  const getUserListDetail = () => {
    const data = {
      userId: userId,
      orgId: ORG_ID(),
    };
    dispatch(userAction.getUserListDetsils(data));
  };

  useEffect(() => {
    dispatch(userAction.clearList());
    setSuccessScreens(false);
    // getDropDowndata()
    if (userId) {
      getUserListDetail();
    }
  }, []);

  useEffect(() => {
    if (getDropdownDataKeyValues?.status === API_STATUS.SUCCESS) {
      if (productID === 1) {
        setDivisionSafety(getDropdownDataKeyValues?.data?.divisons);
        setRoleSafety(getDropdownDataKeyValues?.data?.roles);
      } else {
        setDivisionStormWater(getDropdownDataKeyValues?.data?.divisons);
        setRoleStormWater(getDropdownDataKeyValues?.data?.roles);
      }
    }
  }, [getDropdownDataKeyValues]);

  const [duplicateUser, setDuplicateUser] = useState("");
  const [downloadCsvFile, setdownloadCsvFile] = useState(false);

  useEffect(() => {
    if (addUserListData?.status === API_STATUS.FAILURE) {
      notifyError();
    } else {
      if (addUserListData?.status === API_STATUS.SUCCESS) {
        const usersList = addUserListData?.data?.userStatusResponses?.filter(
          (user) => !user.status
        );
        if (usersList?.length > 0) {
          setDuplicateUser(usersList);
        } else {
          setSuccessScreens(true);
        }
      }
    }
  }, [addUserListData]);

  useEffect(() => {
    if (duplicateUser && duplicateUser?.length > 0 && downloadCsvFile) {
      dowloadCsv?.current?.link?.click();
      setSuccessScreens(true);
      setdownloadCsvFile(false);
    }
  }, [duplicateUser]);

  const SuccessScree = (observationid) => {
    setTimeout(() => {
      dispatch(userAction.addUserListClear());
      setallUserList([]);
    }, 7000);
    return (
      <SuccessScreen
        isUpdate={
          editMode
            ? "User Updated Successfully"
            : duplicateUser.length < allUserList?.users?.length
            ? "User Created Successfully"
            : "User Already Exists"
        }
        route={ADMIN_USER}
        label4="Users List Screen in"
        label1={true}
        label2={true}
        id={observationid}
      ></SuccessScreen>
    );
  };

  return (
    <>
      {addUserListDataStatus === API_STATUS.SUCCESS &&
      addUserListData?.data &&
      successScreens ? (
        SuccessScree()
      ) : updateUserListDataStatus === API_STATUS.SUCCESS &&
        updateUserListData?.data &&
        successScreens ? (
        SuccessScree()
      ) : (
        <>
          {showLoader && <Loader />}
          {showLoaders && <Loader />}
          <div>
            <ToastContainer />
          </div>
          <UserForm
            adduserLists={adduserLists}
            divisions={divisionSafety}
            roles={roleSafety}
            jobTitles={jobTitles}
            userData={showLoader ? [] : editMode ? userData?.data : ""}
            editMode={editMode}
            userId={userId}
            divisionStormWater={divisionStormWater}
            roleStormWater={roleStormWater}
            getDropDowndata={getDropDowndata}
          />
          <DownloadCsv
            ref={dowloadCsv}
            data={duplicateUser}
            filename={"Already Exit User List"}
          />
        </>
      )}
    </>
  );
};

export default UsersContainer;