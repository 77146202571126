import React from "react";
import "../../../incidents/cases/addCaseList.scss";
import "./contractor.scss";
import Buttons from "../../../../library/custom/button/Button";
import { Grid, Box } from "@mui/material";
import { NumberField } from "../../../../library/custom/numberField/numberField";
import { InputField } from "../../../../library/custom/textBox/InputField";
import FormControl from "@mui/material/FormControl";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  COUNTRY_CODE,
  ORG_ID,
  TEXT_FIELD_ERROR_MSG,
} from "../../../../constant/constant";

const PersonalContractorForm = ({
  editMode,
  addPersonalContractor,
  contractorId,
  viewContractors,
  companyName,
  contractorPersonnelId,
}) => {
  const userId = localStorage.getItem("userId");

  function initiateState(editModeObject) {
    console.log(parseInt(contractorId), " parseInt(contractorId)");
    const intialState = {
      personnelFirstName: editModeObject
        ? editModeObject?.personnelFirstName
        : "",
      personnelLastName: editModeObject
        ? editModeObject?.personnelLastName
        : "",
      personnelEmail: editModeObject ? editModeObject?.personnelEmail : "",
      personnelPhone: editModeObject ? editModeObject?.personnelPhone : "",
      createdBy: userId,
      organisationId: ORG_ID(),
      contractorId: parseInt(contractorId),
      contractorPersonnelId: editMode
        ? editModeObject?.contractorPersonnelId
        : 0,
    };
    return intialState;
  }

  const [formValue, setFormValue] = useState(initiateState());

  useEffect(() => {
    if (viewContractors?.data?.result) {
      setFormValue(initiateState(viewContractors?.data?.result));
    }
  }, [viewContractors]);

  const history = useNavigate();
  const [contractoFnameError, setContractoFnameError] = useState(false);
  const [contractoLnameError, setContractoLnameError] = useState(false);
  const [contractoEmailError, setContractoEmailError] = useState(false);
  const [contractoPhoneError, setContractoPhoneError] = useState(false);

  const onClickAddUser = (e) => {
    let isError = false;
    if (!formValue.personnelFirstName) {
      setContractoFnameError(true);
      isError = true;
    }
    if (!formValue.personnelLastName) {
      setContractoLnameError(true);
      isError = true;
    }
    if (!formValue.personnelEmail) {
      setContractoEmailError(true);
      isError = true;
    }
    if (!formValue.personnelPhone) {
      setContractoPhoneError(true);
      isError = true;
    }

    if (!isError) {
      addPersonalContractor(editMode ? formValue : formValue);
    }
  };

  return (
    <Grid container mt={4}>
      <Grid item lg={8} xs={12} sm={12} md={4}>
        <Grid container>
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left">
            <div className="heading_style">Personnel Contractor</div>
          </Grid>
          <Grid item lg={6} xs={12} sm={12} md={4} textAlign="right"></Grid>
        </Grid>
        <div>
          <Box mt={3}>
            <Grid container mt={1} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"First Name"}
                    value={formValue.personnelFirstName}
                    placeholder="Enter first name"
                    onChange={(e) => {
                      setContractoFnameError(false);
                      setFormValue({
                        ...formValue,
                        personnelFirstName: e.target.value,
                      });
                    }}
                    isError={contractoFnameError}
                    errorMsg={
                      contractoFnameError && !formValue.personnelFirstName
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"Last Name"}
                    value={formValue.personnelLastName}
                    placeholder="Enter last name"
                    onChange={(e) => {
                      setContractoLnameError(false);
                      setFormValue({
                        ...formValue,
                        personnelLastName: e.target.value,
                      });
                    }}
                    isError={contractoLnameError}
                    errorMsg={
                      contractoLnameError && !formValue.personnelLastName
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container mt={2} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"Email"}
                    value={formValue.personnelEmail}
                    placeholder="Enter Email"
                    onChange={(e) => {
                      setContractoEmailError(false);
                      setFormValue({
                        ...formValue,
                        personnelEmail: e.target.value,
                      });
                    }}
                    isError={contractoEmailError}
                    errorMsg={
                      contractoEmailError && !formValue.personnelEmail
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth={true}>
                  <NumberField
                    disabled={false}
                    isRequired={true}
                    type="text"
                    label={"Phone"}
                    maxLength="10"
                    value={formValue.personnelPhone}
                    placeholder={`Enter Phone number without ${COUNTRY_CODE}`}
                    onChange={(value) =>
                      value.length <= 10
                        ? setFormValue({
                            ...formValue,
                            personnelPhone: value,
                          })
                        : formValue.personnelPhone
                    }
                    isError={contractoPhoneError}
                    errorMsg={
                      contractoPhoneError && !formValue.personnelPhone
                        ? TEXT_FIELD_ERROR_MSG
                        : ""
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container mt={0} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <FormControl fullWidth={true}>
                  <InputField
                    disabled={true}
                    isRequired={false}
                    type="text"
                    label={"Company"}
                    maxLength="10"
                    value={companyName}
                    placeholder={`Company`}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container mb={10} mt={1} spacing={3}>
              <Grid item lg={6} xs={12} sm={12} md={4} textAlign="left"></Grid>
              <Grid item lg={6} xs={12} sm={12} md={4}>
                <div className="adduser_btn">
                  <div
                    className="cancle_heading_style"
                    onClick={() => history(-1)}
                  >
                    Cancel
                  </div>
                  <Buttons
                    aria-describedby="dd"
                    variant="contained"
                    type="button"
                    name="btn"
                    label={editMode ? "Update Personnel" : "Add Personnel"}
                    varientContained={true}
                    onClick={(e) => onClickAddUser(e)}
                  ></Buttons>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};
export default PersonalContractorForm;
