import React, { useEffect } from "react";
import ReportPersonallInjury from "../component/reportPersonalInjury";
import { useDispatch, useSelector } from "react-redux";
import { ORG_ID } from "../../../constant/constant";
import * as adminAction from "../../../redux/actions/adminCommonAction";
import * as toolboxAction from "../../../redux/actions/toolBoxAction";
import Loader from "../../../library/common/Loader";
import * as personalInjuryIncidentReportAction from "../../../redux/actions/personalInjuryIncidentReportAction";

const ReportPersonallInjuryContainer = () => {
  const dispatch = useDispatch();

  const getDropdownDataKeyValue = useSelector(
    (state) => state.getDropdownDataKeyValue
  );
  const getProjectSearchLList = useSelector(
    (state) => state.getProjectSearchList?.data?.projects
  );

  const supervisorByProject = useSelector(
    (state) => state.getSupervisorByProject
  );

  const getSupervisorByProject = (data) => {
    const defaultData = {
      divisionId: data?.divisionId,
      projectId: data?.projectId,
    };
    dispatch(adminAction.getSupervisorByProject(defaultData));
  };

  useEffect(() => {
    const getDropdownDataKeyValueData = {
      orgId: ORG_ID(),
      flags: [6],
    };
    dispatch(adminAction.getDropdownDataKeyValue(getDropdownDataKeyValueData));
  }, []);

  const getProjectSearch = (data) => {
    dispatch(toolboxAction.getProjectSearchList(data));
  };

  const personalInjuryIncidentReportCount = useSelector(
    (state) => state.getPersonalInjuryIncidentReportCount
  );
  const personalInjuryIncidentReportList = useSelector(
    (state) => state.getPersonalInjuryIncidentReportList
  );

  const getPiIncidentReportCount = (data) => {
    dispatch(
      personalInjuryIncidentReportAction.getPersonallInjuryIncidentReportCountReport(
        data
      )
    );
  };

  const getPiIncidentReportList = (data) => {
    dispatch(
      personalInjuryIncidentReportAction.getPersonallInjuryIncidentReportListReport(
        data
      )
    );
  };

  const showLoader = useSelector(
    (state) =>
      state.getDropdownDataKeyValue.isLoading ||
      state.getProjectSearchList?.isLoading ||
      state.getPersonalInjuryIncidentReportCount?.isLoading ||
      state.getPersonalInjuryIncidentReportList?.isLoading ||
      state.getSupervisorByProject?.isLoading
  );

  return (
    <>
      {showLoader && <Loader />}

      <ReportPersonallInjury
        divisons={getDropdownDataKeyValue?.data?.divisons}
        getProjectSearch={getProjectSearch}
        projectSearchList={getProjectSearchLList}
        getPiIncidentReportCount={getPiIncidentReportCount}
        personalInjuryIncidentReportCount={personalInjuryIncidentReportCount}
        personalInjuryIncidentReportList={personalInjuryIncidentReportList}
        getPiIncidentReportList={getPiIncidentReportList}
        getSupervisorByProject={getSupervisorByProject}
        supervisorByProject={supervisorByProject}
      />
    </>
  );
};

export default ReportPersonallInjuryContainer;
