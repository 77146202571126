import React, { useState } from "react";
import "../../../incidents/cases/addCaseList.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import GridTable from "../../../../library/custom/gridTable/GridTable";
import SearchIcon from "@mui/icons-material/Search";
import Buttons from "../../../../library/custom/button/Button";
import SettingTable from "../../../incidents/incidentList/SettingTable";
import ReportRowManipulte from "../../shared/ReportRowManipulate";
import { TableLoader } from "../../../../library/common/Loader";
import {
  API_ADMIN_STATUS_STATUS,
  HBNEXT_ORG_ID,
  ORG_ID,
  PAGE_SIZE,
  ROW_PER_PAGE_SIZE,
} from "../../../../constant/constant";
import NORow from "../../../../library/custom/noRow/noRow";
import DialogBox from "../../../../library/common/DialogBox";
import FilterCommonStatusSelect from "../../shared/FilterCommonStatusSelect";
import "./viewContact.scss";
import { checkPrivileges } from "../../../../utils/rolesHelper";
import { PRODUCT_ID } from "../../../../constant/storm-water/constant";
import { encodeSearchText } from "../../../../utils/helper";

export default function ContactList({
  contacts,
  addContact,
  getContactListData,
  isLoading,
  handleDeleteOnClick,
  handleViewOnClick,
  handleEditOnClick,
  selectedServiceTypeData,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [contactFilterData, setContactFilterData] = useState({});
  const [filter, setFilter] = useState({
    searchType: [],
  });
  const [statusIds, setStatusIds] = useState();
  const [isTableLoading, setisTableLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const deleteContact = (divisionId) => {
    setOpenModal(true);
    setSelectedRow(divisionId);
  };
  const deletehandle = () => {
    setOpenModal(false);
    handleDeleteOnClick(selectedRow);
  };

  const handleClose = () => {
    setAnchorEl(null);
    window.close();
  };

  const open = Boolean(anchorEl);
  const isShowClient =
    parseInt(ORG_ID()) === parseInt(HBNEXT_ORG_ID) && checkPrivileges([12]);
  const id = open ? "simple-popover" : undefined;
  const columns = [
    { field: "name", headerName: "Name", flex: 1.5 },
    { field: "designation", headerName: "Job Title", flex: 1 },
    { field: "companyName", headerName: "Company", flex: 1.5 },
    { field: "contactNo", headerName: "Phone Number", flex: 1.5 },
    { field: "email", headerName: "Email ID", flex: 2 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "icon",
      renderHeader: () => (
        <SettingTable
          noOfRecords={contacts?.result?.noOfRecords ?? 0}
          numberOfItemsPerPage={PAGE_SIZE}
          incidentLists={contacts?.result?.contactData ?? []}
          fileNameXL={"contactList"}
        />
      ),
      flex: 0.8,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (rows) => {
        return (
          <ReportRowManipulte
            rows={rows}
            deleteOnClick={(row) => deleteContact(row?.id)}
            viewOnClick={(row) => handleViewOnClick(row?.id, row?.statusId)}
            editOnClick={(row) => handleEditOnClick(row?.id)}
            deleted={checkPrivileges([12, 40])}
            edit={checkPrivileges([12, 40, 41])}
            view={checkPrivileges([12, 40, 41, 42])}
            viewLabel="View Contact"
            deleteLabel="Delete Contact"
            editLabel="Edit Contact"
          />
        );
      },
    },
  ];
  if (parseInt(PRODUCT_ID()) === 2 && isShowClient) {
    columns.splice(-1, 0, {
      field: "global",
      headerName: "Global",
      flex: 1,
    });
  }

  const handleSearch = (data) => {
    setAnchorEl(null);
    getContactListData(data);
  };

  const handleautoSearch = (event, state) => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    let searchText = "";
    if (event.target.value) {
      searchText = event.target.value;
    }
    setSearch(searchText);
    const data = {
      ...contactFilterData,
      page: 1,
      pageSize: pageSize,
      search: searchText ? encodeSearchText(searchText) : "",
      statusIds: statusIds ? statusIds : "",
      compnayName: "",
    };
    setContactFilterData(data);
    getContactListData(data);
  };
  const handleClear = () => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    setSearch("");
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      prevStates.searchType = [];
      return prevStates;
    });
    setStatusIds("");
    const data = {
      page: 1,
      pageSize: pageSize,
      statusIds: "",
      search: "",
      compnayName: "",
      // productId: selectedServiceTypeData,
    };
    handleSearch(data);
  };
  const handleApply = () => {
    setisTableLoading(true);
    setTimeout(() => {
      setisTableLoading(false);
    }, 1);
    setPage(1);
    const data = {
      page: 1,
      pageSize: pageSize,
      statusIds: statusIds ? statusIds : "",
      search: search ? encodeSearchText(search) : "",
      compnayName: "",
    };
    setContactFilterData(data);
    handleSearch(data);
  };

  const handlePagignation = (newPage) => {
    setPage(newPage + 1);
    const data = {
      ...contactFilterData,
      page: newPage + 1,
      pageSize: pageSize,
      statusIds: statusIds ? statusIds : "",
      compnayName: "",
      search: search ? encodeSearchText(search) : "",
    };
    setContactFilterData(data);
    getContactListData(data);
  };

  const onChangeType = (item) => {
    setFilter((prevState) => {
      const prevStates = { ...prevState };
      const searchType = prevStates.searchType;
      const filter =
        searchType && searchType.filter((its) => its?.key === item?.key);
      if (filter[0] === undefined) {
        prevStates.searchType = [item];
        setStatusIds(item?.key);
      } else {
        prevStates.searchType = [];
      }
      return prevStates;
    });
  };

  const onRowClick = (rows) => {
    if (rows.value) {
      handleViewOnClick(rows?.id);
    }
  };

  const handlePageSizeChange = (newPazeSize) => {
    setPageSize(newPazeSize);
    getContactListData({
      page: page,
      pageSize: newPazeSize,
      statusIds: statusIds ? statusIds : "",
      compnayName: "",
      search: search ? encodeSearchText(search) : "",
      // productId: selectedServiceTypeData,
    });
  };

  return (
    <>
      <section className="grid_main_sec">
        {openModal && (
          <DialogBox
            open={openModal}
            rows={[]}
            header={"Confirm Deletion"}
            deletehandle={deletehandle}
            handleCloseModal={() => setOpenModal(false)}
            content={"Are you sure you want to delete this Contact?"}
            view
          />
        )}
        <div className="grid_main_header two_grid">
          <div className="grid_heading_style">Contacts</div>
          <div>
            {checkPrivileges([12, 40, 41]) && (
              <Buttons
                id="btnAddContact"
                label={"Add Contact"}
                varientAddIconBlue={true}
                onClick={() => addContact()}
              />
            )}
          </div>
        </div>
        <div className="grid_main_body">
          <div className="filter_of_caselist">
            <div className="searchcaseid">
              <label htmlFor="search">
                <span>
                  <SearchIcon />
                </span>
                <div>
                  <input
                    id="search"
                    placeholder="Search"
                    type="text"
                    value={search}
                    className=""
                    onChange={handleautoSearch}
                    autoComplete="off"
                  />
                </div>
              </label>
            </div>
            <div className="filter_btn">
              <button
                aria-describedby={id}
                variant="contained"
                type="button"
                name="btn"
                onClick={(e) => handleClick(e)}
              >
                <FilterListIcon id="icn" /> Filters
              </button>
              {/* <FilterButton
                label={"Filters"}
                handleClick={(e) => handleClick(e)}
              /> */}
              <FilterCommonStatusSelect
                id={id}
                open={open}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleApply={handleApply}
                handleClear={handleClear}
                filter={filter}
                statusType={API_ADMIN_STATUS_STATUS}
                onChangeType={onChangeType}
                statusIds={statusIds}
                filterSelect={false}
                filterStatus={true}
                resetBtn={true}
              />
            </div>
          </div>
          {isTableLoading ? (
            ""
          ) : (
            <div className="desc_box row_uniq">
              <div className="table_style_ui">
                <GridTable
                  getRowId={(r) => r.id}
                  rows={contacts?.result?.contactData ?? []}
                  rowCount={contacts?.result?.noOfRecords ?? 0}
                  columns={columns}
                  loading={isLoading}
                  checkboxSelection={false}
                  page={page}
                  onClick={(rows) => onRowClick(rows)}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={ROW_PER_PAGE_SIZE}
                  onPageSizeChange={handlePageSizeChange}
                  onPageChange={handlePagignation}
                  backIconButtonProps={{ disabled: false }}
                  disableSelectionOnClick
                  components={{
                    LoadingOverlay: TableLoader,
                    NoRowsOverlay: NORow,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
